import { cloneDeep as _cloneDeep, kebabCase as _kebabCase, isEmpty as _isEmpty } from 'lodash';

const mapDefaultValue = (playbook, vars, pipelineVars, pipeline_variables) => {
  if (!playbook) {
    return undefined;
  }

  const p = _cloneDeep(playbook);

  p.params = p.params?.map(param => {
    let paramValue;
    const exampleValue = param.exampleValue ?? `my-${_kebabCase(param.name)}`;
    if (vars !== null && vars[param.name]) {
      paramValue = vars[param.name];
    }

    if (!_isEmpty(pipeline_variables)) {
      const playbookPipelineVariables = pipeline_variables.map(pipeline_variable => pipeline_variable.name);
      const isPlaybookPipelineVariable = playbookPipelineVariables.indexOf(param.name) !== -1;

      if (pipelineVars !== null && pipelineVars[param.name] && isPlaybookPipelineVariable) {
        paramValue = pipelineVars[param.name];
      }
    }

    return {
      name: param.name,
      defaultValue: paramValue ?? param.defaultValue ?? exampleValue,
    };
  });

  return p;
};

export default mapDefaultValue;
