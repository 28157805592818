import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './progress-meter.module.scss';

const MrProgressMeter = ({
  color,
  className,
  percentage,
  r,
  strokeWidth,
  getStatusMsg,
  testId = 'MrProgressMeter',
}) => {
  const circle = 2 * Math.PI * (r ?? 40);
  const strokePct = ((100 - percentage) * circle) / 100;
  const strokeOffset = percentage ? strokePct : 0;

  const setDefaultStatusMsg = percentage => {
    switch (true) {
      case percentage === 100:
        return 'Completed';
      case percentage >= 70:
        return 'Almost ready...';
      default:
        return 'Loading...';
    }
  };

  const message = () => {
    if (getStatusMsg) {
      return getStatusMsg(percentage);
    } else {
      return setDefaultStatusMsg(percentage);
    }
  };

  return (
    <div className={cx(s.progressMeter, className)} data-test-id={testId}>
      <svg width={80} height={80}>
        <g transform={`rotate(-90 0 0)`}>
          <circle
            r={r ?? 40}
            cx={-40}
            cy={40}
            fill="transparent"
            stroke={color}
            strokeWidth={`${strokeWidth ?? 0.5}rem`}
            strokeDasharray={circle}
            strokeDashoffset={strokeOffset}
          ></circle>
        </g>
      </svg>
      <span className={s.message}>{message()}</span>
    </div>
  );
};

MrProgressMeter.propTypes = {
  className: pt.string,
  color: pt.string.isRequired,
  percentage: pt.number.isRequired,
  r: pt.number,
  strokeWidth: pt.number,
  getStatusMsg: pt.func,
  testId: pt.string,
};

export default MrProgressMeter;
