const actions = {
  ajs_file_built: 'AJS_FILE_BUILT',
  auth_logged_in: 'AUTH_LOGGED_IN',
  auth_logged_out: 'AUTH_LOGGED_OUT',
  consent_category_created: 'CONSENT_CATEGORY_CREATED',
  consent_category_deleted: 'CONSENT_CATEGORY_DELETED',
  consent_category_updated: 'CONSENT_CATEGORY_UPDATED',
  integration_added_to_pipeline: 'INTEGRATION_ADDED_TO_PIPELINE',
  integration_created: 'INTEGRATION_CREATED',
  integration_deleted: 'INTEGRATION_DELETED',
  integration_removed_from_pipeline: 'INTEGRATION_REMOVED_FROM_PIPELINE',
  integration_updated: 'INTEGRATION_UPDATED',
  pipeline_created: 'PIPELINE_CREATED',
  pipeline_deleted: 'PIPELINE_DELETED',
  pipeline_deployed: 'PIPELINE_DEPLOYED',
  pipeline_integration_updated: 'PIPELINE_INTEGRATION_UPDATED',
  pipeline_updated: 'PIPELINE_UPDATED',
  pipeline_variable_updated: 'PIPELINE_VARIABLE_UPDATED',
  user_deleted: 'USER_DELETED',
  user_invited: 'USER_INVITED',
  user_role_edited: 'USER_ROLE_EDITED',
};

export default actions;
