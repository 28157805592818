import React from 'react';
import TimestampCell from './timestamp-cell';
import ActionCell from './action-cell';
import UserCell from './user-cell';
import DescriptionCell from './description-cell';

export default [
  {
    name: 'User',
    selector: row => row.user,
    cell: row => <UserCell user={row.user} />,
    width: '326px',
  },
  {
    name: 'Action',
    selector: row => row.action,
    cell: row => <ActionCell action={row.action} />,
    width: '250px',
  },
  {
    name: 'Description',
    selector: row => row.description,
    cell: row => <DescriptionCell description={row.description} />,
    width: '557px',
  },
  {
    name: 'Timestamp',
    selector: row => row.timestamp,
    cell: row => <TimestampCell timestamp={row.timestamp} />,
    width: '225px',
    sortable: true,
    sortField: 'timestamp',
  },
];
