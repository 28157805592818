import React from 'react';
import pt from 'prop-types';

import IntegrationLogo from 'app/components/integrations/integration-logo';
import { MrText } from '@ion/components';

import styles from './cell.module.scss';
import allowRowClicked from '../allow-row-click';

export default function IntegrationCell({ integration }) {
  return (
    <div className={styles.cell} {...allowRowClicked}>
      <IntegrationLogo id={integration.starterkitId} className={styles.logo} {...allowRowClicked} />
      <MrText testId="integrationCell" className={styles.name} {...allowRowClicked}>
        {integration.name}
      </MrText>
    </div>
  );
}

IntegrationCell.propTypes = {
  integration: pt.shape({
    starterkitId: pt.string.isRequired,
    name: pt.string.isRequired,
  }).isRequired,
};
