import React from 'react';
import pt from 'prop-types';

import BulkSelectRevisions from 'app/pipelines/bulk-stage-and-deploy/bulk-select-revisions/index.js';

const BulkSelectRevisionsPage = () => {
  return <BulkSelectRevisions />;
};

BulkSelectRevisionsPage.propTypes = {
  location: pt.object,
};

export default BulkSelectRevisionsPage;
