import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ id, name, starterkitId }) {
  const action = actions.integration_created;
  const description = `${name}`;
  const debug_data = {
    integrationId: id,
    integrationName: name,
    starterkitId,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
