import React from 'react';
import classNames from 'classnames';
import Template from '../template';

import styles from './index.module.scss';

const TEMPLATE_PROPS = {
  title: 'Use desktop browser',
  message: 'We’re gonna need a bigger boat.',
  SupportingText,
};

function SupportingText() {
  return (
    <p>The MetaRouter UI is best experienced on a desktop browser. Please use a screen width greater that 975 px.</p>
  );
}

export default function MrMobileBlocker() {
  return (
    <div className={classNames(styles.mobileBlocker, styles.overlay)}>
      <Template {...TEMPLATE_PROPS} />
    </div>
  );
}
