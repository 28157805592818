import React from 'react';
import propTypes from 'prop-types';

import { isEmpty as _isEmpty } from 'lodash';

const AssignedPipelinesTableBody = ({ playbooks }) => {
  if (_isEmpty(playbooks)) {
    return null;
  }

  return (
    <tbody>
      {playbooks.map(playbook =>
        playbook.staged_revisions.map(stagedRevision => {
          const {
            id,
            pipeline_integration: { pipeline },
          } = stagedRevision;
          return (
            <tr key={id}>
              <td> {pipeline.name} </td>
              <td> Revision {playbook.revision} </td>
            </tr>
          );
        })
      )}
    </tbody>
  );
};

AssignedPipelinesTableBody.propTypes = {
  playbooks: propTypes.array.isRequired,
};

export default AssignedPipelinesTableBody;
