import { gql } from '@apollo/client';
import integration from '../integration';

// NOTE: returning playbook here even though there isn't one, so Apollo doesn't yell when updating afterInsert
export const mutation = gql`
  mutation InsertIntegration($integrations_insert_input: integrations_insert_input!) {
    insert_integrations_one(object: $integrations_insert_input) {
      ...integration
      playbooks(order_by: { revision: desc }, limit: 1) {
        id
        revision
        playbookJson
        connectionJson
        vars
        pipeline_variables {
          playbookId
        }
      }
    }
  }
  ${integration}
`;

export function buildVariables({ id, name, starterkitId }) {
  return {
    integrations_insert_input: {
      id,
      name,
      starterkitId,
    },
  };
}
