import React from 'react';

import { MrDataTable, MrTableTitle, MrText } from '@ion/components';
import { useConsentCategoriesTableContext } from '../../context/consentCategoriesTableContext';

import { RowsActions } from '../rows-actions/RowsActions';
import styles from './consentCategoriesTable.module.scss';
import AddCategoryButton from './AddCategoryButton';
import useHasConsentModule from './useHasConsentModule';

const columns = [
  {
    name: 'Category Code',
    selector: row => row.code,
  },
  {
    name: 'Category Name',
    selector: row => row.name,
  },
  {
    name: 'Actions',
    cell: row => <RowsActions rowInfo={row} />,
  },
];

export const ConsentCategoriesTable = () => {
  const { onOpenModal, consentCategories } = useConsentCategoriesTableContext();
  const hasConsentModule = useHasConsentModule();
  const emptyText = hasConsentModule
    ? 'No categories set, use the Add Category button to create one.'
    : 'Consent Enforcement has not been enabled for your organization. Please reach out to your customer success representative for more information.';

  const onAddCategory = () => {
    onOpenModal('addCategory');
  };

  return (
    <MrDataTable
      title={<MrTableTitle title="Consent Categories" titlePill={`${consentCategories?.length} Categories`} />}
      columns={columns}
      data={consentCategories}
      actions={<AddCategoryButton onAddCategory={onAddCategory} />}
      className={styles.table}
      noDataComponent={<MrText styleNames="smallGray center">{emptyText}</MrText>}
    />
  );
};
