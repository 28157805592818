import React from 'react';
import pt from 'prop-types';
import { isNil } from 'lodash';

import MrTableTitle from '../data-table/table-title';

const MrCountTableTitle = ({ title: { singular, plural }, count }) => {
  const tableTitleProp = {
    title: plural,
  };

  if (!isNil(count)) {
    const suffix = count > 1 ? plural : singular;
    tableTitleProp.titlePill = `${count} ${suffix}`;
  }

  return <MrTableTitle {...tableTitleProp} />;
};

MrCountTableTitle.propTypes = {
  title: pt.shape({
    singular: pt.string,
    plural: pt.string,
  }).isRequired,
  count: pt.number,
};

export default MrCountTableTitle;
