import { sortBy, isString, every, isFunction } from 'lodash';

/*
  Wrapper around _.sortBy. Does consistent
  alphabetization by lowercased values from
  field 'key'.
 */
export default function alphabetizeBy(collection, keyOrFunction) {
  const getValue = buildGetValue(keyOrFunction);
  return sortBy(collection, object => {
    const value = getValue(object);
    if (isString(value)) {
      return value.toLowerCase();
    }
  });
}

function buildGetValue(keyOrFunction) {
  if (isString(keyOrFunction)) {
    return object => object[keyOrFunction];
  } else if (isFunction(keyOrFunction)) {
    return keyOrFunction;
  } else {
    throw Error('keyOrFunction must be a String or Function');
  }
}

/**
 * Determine if the collection is alphabetizable by the key.
 *
 * @param collection
 * @param key
 * @returns {boolean}
 */
export function alphabetizableBy(collection, key) {
  return every(collection, item => isString(item[key]));
}
