import { useEffect, useCallback } from 'react';
import pt from 'prop-types';

import { useGetSchemaSummary } from '@ion/api';

const GetSchemaSummary = ({ cluster, earliestSeen, timeframeHours, setSchemaSummary, setLoadingSummary }) => {
  const timeframe = timeframeHours + ' hours';
  const [getSchemaSummary] = useGetSchemaSummary({
    onCompleted: r => {
      setSchemaSummary(r.summary);
    },
    onError: err => {
      // TODO - Handle error
      console.log(err);
      setSchemaSummary([]);
    },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSchemaSummaryCallback = useCallback(getSchemaSummary, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setLoadingSummaryCallback = useCallback(setLoadingSummary, []);

  useEffect(() => {
    if (!cluster) {
      return;
    }
    setLoadingSummaryCallback();
    getSchemaSummaryCallback({ clusterId: cluster.id, earliestSeen, timeframe });
  }, [cluster, earliestSeen, timeframe, setLoadingSummaryCallback, getSchemaSummaryCallback]);

  return null;
};

GetSchemaSummary.propTypes = {
  cluster: pt.shape({
    id: pt.string,
  }),
  earliestSeen: pt.string,
  timeframeHours: pt.number,
  setSchemaSummary: pt.func.isRequired,
  setLoadingSummary: pt.func.isRequired,
};

export default GetSchemaSummary;
