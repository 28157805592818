import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './copy.module.scss';
import icon from './icon-copy.svg';

const MrCopy = ({ text = '', className, onCopy }) => {
  return (
    <span
      className={cx(s.copy, className)}
      onClick={() => {
        navigator.clipboard.writeText(text).then(() => {
          if (onCopy) {
            onCopy(text);
          }
        });
      }}
    >
      <img src={icon} className={cx(s.copyButton)} alt="Copy to clipboard" />
    </span>
  );
};

MrCopy.propTypes = {
  text: pt.string,
  className: pt.string,
  onCopy: pt.func,
};

export default MrCopy;
