import { isEmpty as _isEmpty, isEqual as _isEqual, omit as _omit } from 'lodash';

export const diffParameterAndForm = (formStateParameterized, operationData) => {
  // directAssignment
  const noTransforms = !formStateParameterized.transforms || _isEmpty(formStateParameterized?.transforms[0]);
  const directAssignment = operationData.transforms && operationData.transforms[0].directAssignment === null;

  if (noTransforms && directAssignment) {
    return !_isEqual(formStateParameterized, _omit(operationData, 'transforms'));
  }

  const defaultValue = formStateParameterized?.defaultValue;

  // nested SDK
  if (typeof defaultValue === 'object') {
    const initialized = Object.values(defaultValue).every(v => v === '') && operationData.defaultValue === undefined;
    return initialized ? false : !_isEqual(formStateParameterized, operationData);
  }
  return !_isEqual(formStateParameterized, operationData);
};

export default diffParameterAndForm;
