import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import TruncateMarkup from 'react-truncate-markup';

import styles from './truncate-with-count.module.scss';
import { buildEllipsisGenerator } from './ellipsis';

const MrTruncateWithCount = ({
  className,
  testId = 'MrTruncateWithCount',
  items,
  lines,
  lineHeight,
  ellipsisProps,
}) => {
  const ellipsis = buildEllipsisGenerator({ items, props: ellipsisProps });

  const defaultTruncateMarkupProps = { lines, lineHeight, ellipsis };

  return (
    <TruncateMarkup {...defaultTruncateMarkupProps}>
      <div className={cx(styles.truncateWithCount, className)} data-test-id={testId}>
        {items.map((item, index) => (
          <TruncateMarkup.Atom key={index}>{item}</TruncateMarkup.Atom>
        ))}
      </div>
    </TruncateMarkup>
  );
};

MrTruncateWithCount.propTypes = {
  className: pt.string,
  testId: pt.string,
  items: pt.array.isRequired,
  lines: pt.number,
  lineHeight: pt.string,
  ellipsisProps: pt.object,
};

export default MrTruncateWithCount;
