import { isEmpty as _isEmpty, cloneDeep as _cloneDeep } from 'lodash';
import addHTTPPipelineVariableIds from './addHTTPPipelineVariableIds';

export const updateDuplicateParams = (uploadedData, playbook, connection) => {
  const { version } = uploadedData;
  const connectionType = Object.keys(connection)[0];

  const newParams = uploadedData?.params ?? [];
  const playbookParams = playbook?.params ?? [];
  const connectionParams = connection[connectionType]?.params ?? [];

  // uploaded playbook
  if (version) {
    // We don't add addHTTPPipelineVariableIds to the playbook.
    // If the uploaded playbook has no pipelineVarId, it will just be untoggled in new revision.
    const duplicates = newParams.filter(({ name }) => connectionParams.find(c => c.name === name));
    if (!_isEmpty(duplicates)) {
      const p = _cloneDeep(uploadedData);
      const c = _cloneDeep(connection);

      const updatedConnectionParams = connectionParams.map(p => {
        const param = duplicates.find(({ name }) => name === p.name);
        if (param) {
          return { ...p, ...param };
        }
        return p;
      });

      const updatedPlaybookParams = newParams.map(p => {
        const param = updatedConnectionParams.find(({ name }) => name === p.name);
        if (param) {
          return { ...param, ...p };
        }
        return p;
      });

      p.params = updatedPlaybookParams;
      c[connectionType].params = updatedConnectionParams;
      return { msg: 'defaultValue is edited', p, c };
    }

    return { msg: null, p: uploadedData, c: connection };
  }

  // uploaded connection
  if (!version) {
    const duplicates = newParams.filter(({ name }) => playbookParams.find(c => c.name === name));

    const p = _cloneDeep(playbook);
    /**
     * Pipeline variables store the pipelineVarId inside connectionJson. We need that ID in the connectionData
     * We need to do this for both SDK and HTTP connections
     */
    const c = _cloneDeep(addHTTPPipelineVariableIds({ [connectionType]: uploadedData }, connection));

    if (!_isEmpty(duplicates)) {
      const updatedConnectionParams = newParams.map(p => {
        const param = connectionParams.find(({ name }) => name === p.name);
        if (param) {
          return { ...param, ...p };
        }
        return p;
      });

      const updatedPlaybookParams = playbookParams.map(p => {
        const param = duplicates.find(({ name }) => name === p.name);
        if (param) {
          return { ...p, ...param };
        }
        return p;
      });

      p.params = updatedPlaybookParams;
      c[connectionType].params = updatedConnectionParams;
      return { msg: 'defaultValue is edited', p, c };
    }

    return { msg: null, p: playbook, c };
  }
};

export default updateDuplicateParams;
