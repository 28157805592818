import { useReducer } from 'react';
import { produce } from 'immer';
import { every as _every, isNil as _isNil, isEmpty as _isEmpty } from 'lodash';

import { applyValidators } from './../components/form/validators';
import useValidatorLookup from './../hooks/use-validator-lookup';

/**
 *
 * Convert form state to key/value pairs for submission
 */
const getJson = fields => {
  const json = {};

  fields.forEach(field => {
    const { prefix = '', suffix = '' } = field.dataset;

    json[field.name] = `${prefix}${field.value}${suffix}`;
  });

  return json;
};

/**
 *
 * Get validation errors from form state
 */
const getErrors = (fields, getValidators) => {
  return fields.map(field => {
    const { prefix = '', suffix = '' } = field.dataset;

    return {
      name: field.name,
      errorMsg: applyValidators({
        prefix,
        value: field.value,
        suffix,
        validators: getValidators(field.id),
      }),
    };
  });
};

/**
 *
 * After the form has rendered, add values into form state for all rendered fields
 * (any fields not rendered initially will have their values added to state via setField or submitForm)
 *
 * @param {{ formState: object, formId: string, dispatch: Function}}
 */
const setInitialState = ({ formState, formId, dispatch }) => {
  if (_isEmpty(formState)) {
    setTimeout(() => {
      const formEl = document.getElementById(formId);

      if (formEl) {
        dispatch({
          type: actions.UPDATE_FIELDS,
          payload: {
            fields: getDomFields(formId),
          },
        });
      }
    }, 0); // delay for the form to render
  }
};

/**
 *
 * Get form state from DOM, excluding any disabled fields and those without a name attr
 *
 * @param {string} formId
 */
const getDomFields = formId => {
  const elements = [...document.getElementById(formId).elements];
  return elements
    .filter(({ disabled, name }) => {
      return !disabled && name;
    })
    .filter(({ id }) => {
      return !id.startsWith('react-select');
    })
    .map(({ name, value, id, type, dataset }) => {
      if (name) {
        return {
          name,
          id,
          value: value === 'placeholder' ? '' : value, // ignore <select> placeholder
          errorMsg: null,
          type,
          dataset,
        };
      }
    });
};

const actions = {
  UPDATE_FIELDS: Symbol(),
  DISPLAY_ERRORS: Symbol(),
  REMOVE_FIELDS: Symbol(),
  RESET_ERROR: Symbol(),
};

const handleUpdateFields = ({ payload, draft }) => {
  payload.fields.forEach(field => {
    const { name, value, errorMsg = null, disabled = false } = field;
    const draftField = draft[name];

    if (draftField) {
      // update existing entry in draft
      draftField.errorMsg = errorMsg;
      draftField.disabled = disabled ?? draftField.disabled;
      draftField.value = value ?? draftField.value;
    } else {
      // add new entry
      draft[name] = {
        errorMsg,
        disabled,
        value,
      };
    }
  });
};

const handleDisplayErrors = ({ payload, draft }) => {
  payload.errors.forEach(({ name, errorMsg }) => {
    if (draft[name]) {
      // update existing entry in draft
      draft[name].errorMsg = errorMsg;
    } else {
      // add new entry
      draft[name] = {
        errorMsg,
      };
    }
  });
};

const handleRemoveFields = ({ payload, draft }) => {
  const { fieldNames } = payload;

  fieldNames.forEach(name => {
    delete draft[name];
  });
};

const handleResetError = ({ payload, draft }) => {
  if (draft[payload.fieldName]) {
    draft[payload.fieldName].errorMsg = null;
  }
};

const useForm = (formId, initialState = {}) => {
  const { getValidators } = useValidatorLookup();

  // use Immer's produce method to immutably create the next state, via updates to its draft object
  // see https://immerjs.github.io/immer/docs/produce
  const reducer = produce((draft, { type, payload }) => {
    switch (type) {
      case actions.UPDATE_FIELDS:
        handleUpdateFields({ payload, draft });
        break;
      case actions.DISPLAY_ERRORS:
        handleDisplayErrors({ payload, draft });
        break;
      case actions.REMOVE_FIELDS:
        handleRemoveFields({ payload, draft });
        break;
      case actions.RESET_ERROR:
        handleResetError({ payload, draft });
        break;
    }
  });

  const [formState, dispatch] = useReducer(reducer, initialState); // start with empty initial state

  setInitialState({ formState, formId, dispatch });

  const getValue = ({ type, checked, value }) => {
    if (type === 'checkbox') {
      return checked;
    }

    if (type === 'select-multiple') {
      // at this point, value is an array for multi-select
      // return it as a comma separated string
      return value.join(',');
    }

    return value;
  };

  /**
   *
   * @param {object} event Browser event
   * @param {{ name, value, errorMsg, disabled }[]} otherFields Array of additional fields to update
   */
  const setField = (event, { otherFields = [] } = {}) => {
    // update value for the event target and any additional fields
    if (event?.target) {
      dispatch({
        type: actions.UPDATE_FIELDS,
        payload: {
          fields: [
            {
              name: event.target.name,
              value: getValue(event.target),
            },
            ...otherFields,
          ],
        },
      });
    }

    // update a set of fields programmatically (not triggered directly by an event)
    if (_isNil(event)) {
      dispatch({
        type: actions.UPDATE_FIELDS,
        payload: {
          fields: [...otherFields],
        },
      });
    }
  };

  const validateField = event => {
    let { name, value, type, id, checked, dataset } = event.target;
    const { prefix = '', suffix = '' } = dataset;

    // react select triggers blur via hidden input within its HTML output
    // but we want value from our hidden input used to store selected value
    if (id.startsWith('react-select')) {
      const hiddenInput = event?.target.closest('.react-select-container').querySelector('input[type=hidden]');

      value = hiddenInput.value;
      id = hiddenInput.id;
      name = hiddenInput.name;
    }

    const errorMsg = applyValidators({
      prefix,
      value,
      suffix,
      validators: getValidators(id),
    });

    dispatch({
      type: actions.UPDATE_FIELDS,
      payload: {
        fields: [
          {
            name,
            errorMsg,
            value: type === 'checkbox' ? checked : value,
          },
        ],
      },
    });

    if (errorMsg) {
      return errorMsg;
    }
  };

  /*
    Callback to set field-specific errors (eg. from server response)
    errors = [{
      name,
      errorMsg
    }]
  */
  const submitError = errors => {
    dispatch({
      type: actions.DISPLAY_ERRORS,
      payload: {
        errors,
      },
    });
  };

  /**
   *
   * Returns form data as key value pairs if all field validations pass
   *
   * @returns {{ isValid: boolean, data: object }}
   */
  const submitForm = () => {
    // refetch field values from the DOM
    // ensures that any fields rendered since the last form state update get their values added now for validation/submission
    const fields = getDomFields(formId);
    const validatedFields = getErrors(fields, getValidators);

    if (_every(validatedFields, field => _isNil(field.errorMsg))) {
      return { isValid: true, data: getJson(fields) };
    } else {
      dispatch({
        type: actions.DISPLAY_ERRORS,
        payload: {
          errors: validatedFields,
        },
      });

      return { isValid: false };
    }
  };

  const resetError = event => {
    dispatch({
      type: actions.RESET_ERROR,
      payload: {
        fieldName: event.target.name,
      },
    });
  };

  const removeFields = fieldNames => {
    dispatch({
      type: actions.REMOVE_FIELDS,
      payload: {
        fieldNames,
      },
    });
  };

  return {
    formState,
    setField,
    validateField,
    submitForm,
    resetError,
    removeFields,
    submitError,
  };
};

export default useForm;
