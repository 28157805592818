export default   {
  REQUEST_NAME: 'fpc',
  REQUEST_INPUT_NAME: 'cookieNames',
  SINGLE_SELECT_DESCRIPTION: 'First Party Cookie',
  DESCRIPTION:
    'Use this sync injector to sync first party cookies from any domain where the tag is placed. Configure it with cookie names, and the server will receive their values using the fpc name.',
  FIELDS: [
    {
      type: 'TEXT',
      property: 'name',
      title: 'Cookie Name',
      description: 'The name of the cookie that should be used to get the data',
      required: true,
      default: '',
      placeholder: 'e.g., cookieName',
    },
  ],
};
