import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './app-layout.module.scss';
import { MrLoading, MrPage } from '../../index';

const MrAppLayout = ({ title, description, children, header, className, loading, sideNav, ...props }) => {
  return (
    <MrPage className={cx(s.page, className)} title={title} description={description} {...props}>
      {sideNav ?? sideNav}
      <div className={s.content}>
        <header className={cx(s.header, className)}>{header}</header>
        <div className={s.main} id="AppLayout">
          {children}
        </div>
      </div>

      {loading && <MrLoading />}
    </MrPage>
  );
};

MrAppLayout.propTypes = {
  children: pt.node,
  header: pt.node,
  title: pt.string,
  description: pt.string,
  className: pt.string,
  loading: pt.bool,
  sideNav: pt.node,
};

export default MrAppLayout;
