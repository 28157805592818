import React from 'react';
import { cloneDeep as _cloneDeep } from 'lodash';

import BaseTable from '../../base-table/BaseTable';
import { MrInputSelectDeprecated, MrText } from '@ion/components';
import { CUSTOM_IDENTITY_SYNC, CUSTOM_IDENTITY_SYNC_SINGLE_SELECT_OPTIONS } from './consts';
import { useStagedIdentitySyncs } from '../../useStagedIdentitySyncs';

const CustomIdentitySyncTable = () => {
  const [stagedIdentitySyncs, addIdentitySyncs] = useStagedIdentitySyncs();


  // Filter out the staged identity syncs
  const options = CUSTOM_IDENTITY_SYNC_SINGLE_SELECT_OPTIONS.map((i) => {
    return {
      value: i.value,
      text: (
        <div data-test-id={`${i.value}-integration-option`}>
          {i.text}
        </div>
      ),
    };
  }).filter(item => !stagedIdentitySyncs.includes(item.value));

  return (
    <BaseTable styleNames="noBorders">
      <tbody>
        <tr>
          <td>
            <MrText styleNames="bold" component="span">
              Sync Configuration
            </MrText>
          </td>
          <td>
            <MrInputSelectDeprecated
              placeholder={CUSTOM_IDENTITY_SYNC.DESCRIPTION}
              name={CUSTOM_IDENTITY_SYNC.REQUEST_NAME}
              testId={CUSTOM_IDENTITY_SYNC.REQUEST_NAME}
              options={options}
              onChange={e => {
                let newStagedIdentitySyncs = _cloneDeep(stagedIdentitySyncs);
                newStagedIdentitySyncs.push(e.target.value);
                // remove this card
                newStagedIdentitySyncs = newStagedIdentitySyncs.filter(
                  item => item !== CUSTOM_IDENTITY_SYNC.REQUEST_NAME
                );
                addIdentitySyncs(newStagedIdentitySyncs);
              }}
            />
          </td>
        </tr>
      </tbody>
    </BaseTable>
  );
};
export default CustomIdentitySyncTable;
