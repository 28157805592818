import { gql } from '@apollo/client';

export const ORGANIZATION_MEMBERS_QUERY_NAME = 'OrganizationMembers';

export const query = gql`
  query ${ORGANIZATION_MEMBERS_QUERY_NAME}($page: Int!, $perPage: Int!) {
    auth0_organization_members(page: $page, perPage: $perPage) {
      members {
        id
        name
        email
        roles {
          id
          name
        }
      }
      page
      perPage
      totalCount
    }
  }
`;
