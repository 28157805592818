import { isEmpty as _isEmpty, parseInt } from 'lodash';
import { CUSTOM_IDENTITY_SYNC } from './identity-sync-card/components/consts';

/**
 *
 * Assemble the final analytics builder settings for request to API endpoint
 *
 * @param {{ writekey, pipelines, author }}
 *
 * @returns {object}
 */
const CROSS_DOMAIN_OPTION = 'crossDomain';
const DEFAULT_CROSS_DOMAIN_TIMEOUT = 1000;

const generateSettings = ({
  stagedIdentitySyncsData,
  stagedIntegrationsData,
  data,
  consentCategories,
  crossDomainSettings,
}) => {
  const syncInjectorSettings = [];
  let identitySyncs = [...stagedIdentitySyncsData];
  if (data.crossDomain) {
    identitySyncs.push({
      friendlyName: 'Cross Domain',
      name: 'crossDomain',
    });
  }
  let categoryCode = null;
  let categoryId = null;
  // customIdentitySync dropdown is only used to add the real syncs, however, it itself is not a sync
  identitySyncs = identitySyncs.filter(item => item.name !== CUSTOM_IDENTITY_SYNC.REQUEST_NAME);
  identitySyncs?.forEach(sync => {
    categoryId = data[`sync-${sync.name}-consentCategoryId`];
    // Only try to find the category if the category id exists.
    // And throw an error if you can't find it.

    if (categoryId) {
      const category = consentCategories.find(category => category.id === categoryId);
      if (!category) {
        throw new Error('Cannot find consent category');
      }
      categoryCode = category.code;
    }
    let selectedSyncSettings = {};
    if (sync.name === CROSS_DOMAIN_OPTION) {
      const syncUrls = data.crossDomain.split(',').map(url => url.trim());
      selectedSyncSettings = {
        cookieTimeToLive: crossDomainSettings?.cookieTimeToLive || 365,
        timeout: DEFAULT_CROSS_DOMAIN_TIMEOUT,
        syncURLs: syncUrls,
      };
    } else {
      sync.fields.forEach(field => {
        if (data[`sync-${sync.name}-${field.name}`] !== undefined) {
          let formValue = data[`sync-${sync.name}-${field.name}`];
          if (field.type === 'TEXT_AREA') {
            try {
              if (_isEmpty(formValue)) {
                return;
              } else {
                formValue = JSON.parse(formValue);
              }
            } catch (e) {
              throw new Error(`Error in Identity Syncs: ${sync.name}, field ${field.name} is invalid JSON`);
            }
          } else if (field.type === 'BOOL') {
            formValue = formValue === 'true';
          } else if (field.type === 'NUMBER') {
            formValue = parseInt(formValue);
          }
          selectedSyncSettings[field.name] = formValue;
        }
      });
    }
    const syncData = {
      name: sync.name,
      friendlyName: sync.friendlyName,
      settings: selectedSyncSettings,
      consentSettings: { code: categoryCode },
      consentCategoryId: categoryId,
    };

    syncInjectorSettings.push(syncData);
  });

  const integrationSettings = [];
  stagedIntegrationsData?.forEach(integration => {
    const selectedIntegrationSettings = {};
    const setSelectedFieldValue = (field, val) => {
      const { type } = field;
      if (type === 'BOOL') {
        return val === 'true' ? true : false;
      }
      return val;
    };
    integration.fields.forEach(field => {
      const selectedField = data[`integration-${integration.name}-${field.name}`];
      let selectedFieldValue = setSelectedFieldValue(field, selectedField);
      if (field.type === 'TEXT_AREA') {
        try {
          if (_isEmpty(selectedField)) {
            selectedFieldValue = field.defaultValue;
          } else {
            selectedFieldValue = JSON.parse(selectedFieldValue);
          }
        } catch (e) {
          throw new Error(`${integration.name} has invalid JSON in field: ${field.name}`);
        }
      } else if (field.type === 'NUMBER') {
        selectedFieldValue = parseInt(field.defaultValue, 10);
      }
      selectedIntegrationSettings[field.name] = selectedFieldValue;
    });
    const integrationData = {
      name: integration.name,
      friendlyName: integration.friendlyName,
      options: selectedIntegrationSettings,
    };
    integrationSettings.push(integrationData);
  });

  return {
    syncInjectorSettings: syncInjectorSettings,
    integrationSettings: integrationSettings,
  };
};

export default generateSettings;
