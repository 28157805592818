import React from 'react';
import propTypes from 'prop-types';

import { FormModal } from '@metarouter-private/mr-mui';

import { useUserModalContext } from '../../context/UserModalContextProvider';
import { useCreateOrganizationInvitation, useEditOrganizationMember } from '@ion/api';
import { USER_MODAL_OPTIONS } from '../../context/user-modal-options';

export default function UserModal({ open, onClose }) {
  const [sendOrgInvitation] = useCreateOrganizationInvitation({
    onCompleted: () => {
      onClose();
    },
  });

  const [editAuth0UserRole] = useEditOrganizationMember({
    onCompleted: () => {
      onClose();
    },
  });

  const { userFormModalProps, modalOption, selectedUser } = useUserModalContext();
  const formModalProps = {
    open,
    onClose,
    ...userFormModalProps,
    onSubmit: ({ email, roleId }) => {
      if (modalOption === USER_MODAL_OPTIONS.ADD) {
        sendOrgInvitation({ email, roleId });
      }

      if (modalOption === USER_MODAL_OPTIONS.EDIT) {
        editAuth0UserRole({
          roleId,
          user: selectedUser,
        });
      }
    },
  };

  return <FormModal {...formModalProps} />;
}

UserModal.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};
