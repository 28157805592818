import React from 'react';
import propTypes from 'prop-types';

import { MrWithTooltip } from '@ion/components';

const DATA_RETENTION_TOOLTIP = {
  message: '90 day data retention policy',
};

function generateMinDate() {
  const date = new Date();
  date.setDate(date.getDate() - 90); // max 90 days ago
  return date;
}

function generateMaxDate() {
  return new Date();
}

function MrWithDataRetention({ Component, componentProps, className, testId = 'MrWithDataRetention' }) {
  const maxDate = generateMaxDate();
  const minDate = generateMinDate();
  const finalProps = {
    ...componentProps,
    minDate,
    maxDate,
  };

  return (
    <MrWithTooltip {...DATA_RETENTION_TOOLTIP} className={className} data-test-id={testId}>
      <Component {...finalProps} />
    </MrWithTooltip>
  );
}

MrWithDataRetention.propTypes = {
  Component: propTypes.func,
  componentProps: propTypes.object,
  className: propTypes.string,
  testId: propTypes.string,
};

export default MrWithDataRetention;
