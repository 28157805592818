import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './button-list.module.scss';

const MrButtonList = ({ buttons, styleNames, className, testId = 'MrButtonList' }) => {
  return (
    <ul
      data-test-id={testId}
      className={cx(
        s.list,
        styleNames?.split(' ').map(c => s[c]),
        className
      )}
    >
      {buttons.map(button => (
        <li key={button.key}>{button}</li>
      ))}
    </ul>
  );
};

MrButtonList.propTypes = {
  buttons: pt.array.isRequired,
  className: pt.string,
  styleNames: pt.string,
  testId: pt.string,
};

export default MrButtonList;
