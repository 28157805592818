import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@metarouter-private/mr-mui';

export default function UserConfirmDeleteMessage({ name, currentOrgName }) {
  return (
    <Typography align="left" variant="body1" color="text.secondary">
      Are you sure you want to remove{' '}
      <Typography fontWeight="700" component="span">
        {name}
      </Typography>{' '}
      from{' '}
      <Typography fontWeight="700" component="span">
        {' '}
        {currentOrgName}{' '}
      </Typography>
      ? This action can be reversed.
    </Typography>
  );
}

UserConfirmDeleteMessage.propTypes = {
  name: PropTypes.string,
  currentOrgName: PropTypes.string,
};
