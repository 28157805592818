import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputText, MrInputNumber } from '@ion/components';

import { useFormContext } from 'app/context/form-context';

const { validateRequired, validateInteger } = formValidators;

const SplitString = ({ opId, _path, splitSeparator, maxElements }) => {
  const { validateField, resetError, formState, setField } = useFormContext();
  return (
    <>
      {((name = `${opId}-splitSeparator-${_path}`) => (
        <MrInputText
          label="Separator"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={splitSeparator}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateRequired()]}
          placeholder="Separator"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-maxElements-${_path}`) => (
        <MrInputNumber
          label="Max Elements"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={maxElements}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateRequired('MaxElements must be bigger than 0'), validateInteger()]}
          placeholder="Max Elements"
          labelPosition="top"
          min={0}
        />
      ))()}
    </>
  );
};

SplitString.propTypes = {
  splitSeparator: pt.string,
  maxElements: pt.number,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default SplitString;
