import React, { createContext, useContext, useState, useCallback } from 'react';
import propTypes from 'prop-types';

import { useDeleteOrganizationMembers } from '@ion/api';
import { useUserContext } from '@ion/user';

import title from '../fixtures/delete/title';
import confirmLabel from '../fixtures/delete/confirm-label';
import UserConfirmDeleteMessage from './UserConfirmDeleteMessage';

const UserConfirmDeleteContext = createContext(null);

export default function UserConfirmDeleteContextProvider({ children }) {
  const {
    currentOrg: { display_name: currentOrgName },
  } = useUserContext();
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = React.useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClose = useCallback(() => setOpen(false), []);

  const [removeUserFromOrg] = useDeleteOrganizationMembers({
    onCompleted: () => {
      onClose();
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onOpen = useCallback(row => {
    setSelectedUser(row);
    setOpen(true);
  }, []);

  const onConfirm = useCallback(
    () =>
      removeUserFromOrg({
        user: selectedUser,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedUser]
  );

  const confirmDeleteProps = {
    open,
    title,
    message: <UserConfirmDeleteMessage name={selectedUser.name} currentOrgName={currentOrgName} />,
    onClose,
    onConfirm,
    confirmLabel,
  };

  return (
    <UserConfirmDeleteContext.Provider value={{ confirmDeleteProps, onOpen }}>
      {children}
    </UserConfirmDeleteContext.Provider>
  );
}

export function useUserConfirmDeleteContext() {
  return useContext(UserConfirmDeleteContext);
}

UserConfirmDeleteContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};
