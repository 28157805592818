import { useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/browser';

export default function useLaunchDarklyContextFlags() {
  const ldClient = useLDClient();
  const [calledSetContext, setCalledSetContext] = useState(false);
  const [allFlagsReady, setAllFlagsReady] = useState(false);

  const setContext = ({ user, currentOrg }) => {
    if (calledSetContext) {
      return;
    }

    identityContext(
      ldClient,
      {
        kind: 'multi',
        user: {
          key: user.id,
          email: user.email,
        },
        organization: {
          key: currentOrg.id,
          name: currentOrg.display_name,
        },
      },
      () => setAllFlagsReady(true)
    );
    setCalledSetContext(true);
  };

  return [{ allFlagsReady }, setContext];
}

function identityContext(ldClient, context, onComplete) {
  ldClient
    .identify(context)
    .then(onComplete)
    .catch(error => {
      logError(error);
      // We shouldn't block page loads if LD has an error. Defaults should be used.
      onComplete();
    });
}

function logError(error) {
  Sentry.captureException(error);
}
