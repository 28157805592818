import { toNumber as _toNumber } from 'lodash';

const castType = input => {
  switch (typeof input) {
    case 'boolean':
      return input;
    case 'string':
      if (input === 'true') return true;
      if (input === 'false') return false;
      if (input !== '' && isFinite(input)) return _toNumber(input);
      return input;
    case 'number':
      return _toNumber(input);
    default:
      return input;
  }
};

export default castType;
