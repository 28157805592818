import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

/*
   This function only makes sense for boolean features.
 */
export default function isFeatureEnabled({ featureFlags, flagName }) {
  if (isNil(featureFlags) || isNil(flagName) || isEmpty(flagName)) {
    return false;
  }

  // Should be exactly true, don't want to rely on truthy.
  return featureFlags[flagName] === true;
}
