import React, { useState } from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import more from './assets/icon-more.svg';
import s from './dropdown.module.scss';

const MrDropdown = ({ children, className, icon, testId = 'MrDropdown' }) => {
  let t;
  const [open, setOpen] = useState();
  const onMouseLeave = () => {
    t = setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  // TODO cleanup the conditional blocks after icons are sorted out
  return (
    <div className={cx(s.container, className)} onClick={e => e.stopPropagation()} data-test-id={testId}>
      <img
        src={icon ?? more}
        className={cx(s.trigger, open && s.triggerOpen)}
        onMouseEnter={() => {
          setOpen(true);
          clearTimeout(t);
        }}
        onMouseLeave={onMouseLeave}
      />

      {open && (
        <div
          className={s.dropdown}
          onClick={() => setOpen(false)}
          onMouseEnter={() => {
            clearTimeout(t);
          }}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </div>
      )}
    </div>
  );
};

MrDropdown.propTypes = {
  children: pt.node,
  className: pt.string,
  icon: pt.string,
  testId: pt.string,
};

export default MrDropdown;
