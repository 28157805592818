import { Grid, Typography } from '@metarouter-private/mr-mui';
import propTypes from 'prop-types';
import React from 'react';

export default function ErrorMessage({ message }) {
  return (
    <Grid item padding={1}>
      <Typography variant="body2" whiteSpace="pre-wrap">
        {message}
      </Typography>
    </Grid>
  );
}

ErrorMessage.propTypes = {
  message: propTypes.string.isRequired,
};
