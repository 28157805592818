import { useUserConfirmDeleteContext } from '../../../context/UserConfirmDeleteContextProvider';

export const useBuildMenuItems = row => {
  const { onOpen } = useUserConfirmDeleteContext();

  return [
    {
      label: 'Remove from Org',
      onClick: () => {
        onOpen(row);
      },
    },
  ];
};
