import { useQuery } from '../../index';
import { gql } from '@apollo/client';
import permission from '../../Apollo/permissions';
import playbook from '../../playbooks/playbook';
import integration from '../../integrations/integration';
import stagedRevision from './stagedRevision';

export const query = gql`
  query StagedRevision($pipelineIntegrationId: uuid!) {
    staged_revisions(where: { pipelineIntegrationId: { _eq: $pipelineIntegrationId } }) {
      ...stagedRevision
      playbook {
        ...playbook
        integration {
          ...integration
        }
        pipeline_variables {
          id
          playbookId
          name
        }
      }
    }
  }
  ${stagedRevision}
  ${playbook}
  ${integration}
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, isAllowed, error } = useQuery(query, {
    variables, // can apply any transforms or default vars here
    fetchPolicy: 'cache-and-network',
    // TODO (Ohms 2022.01.07) - Correct permission?
    context: { permission: permission.readPipeline },
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: data?.staged_revisions,
    loading,
    isAllowed,
    error,
  };
};
