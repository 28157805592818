import React from 'react';
import propTypes from 'prop-types';

import styles from './index.module.scss';
import { MrDate, MrTimeAgo } from '@ion/components';

export default function TimestampCell({ timestamp }) {
  return (
    <div>
      <div className={styles.fromNow}>
        <MrTimeAgo date={timestamp} />
      </div>
      <div className={styles.fullDate}>
        <MrDate date={timestamp} format={'MMM do yyyy HH:mm:ss'} />
      </div>
    </div>
  );
}

TimestampCell.propTypes = {
  timestamp: propTypes.instanceOf(Date).isRequired,
};
