import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ email, oldRole, newRole }) {
  const action = actions.user_role_edited;
  const description = `${email} updated from ${oldRole} to ${newRole}`;
  const debug_data = { email, oldRole, newRole };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
