const auth0Config = {
  domain: () => {
    return process.env.AUTH0_DOMAIN ? process.env.AUTH0_DOMAIN : 'dev-metarouter.us.auth0.com';
  },
  clientID() {
    return process.env.AUTH0_CLIENT_ID ? process.env.AUTH0_CLIENT_ID : 'Gd7AFpjAzAkO7LT8UJga1eAwJ51g7OYD';
  },
};

export { auth0Config };
