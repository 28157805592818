import React from 'react';
import { routes } from 'app/constants';
import { Redirect } from 'react-router-dom';
import propTypes from 'prop-types';
import FeatureGate from './feature-gate';

export default function RedirectFeatureGate({ flagName, redirect = routes.home, children }) {
  const fallback = <Redirect to={redirect} />;
  return (
    <FeatureGate flagName={flagName} fallback={fallback}>
      {children}
    </FeatureGate>
  );
}

RedirectFeatureGate.propTypes = {
  flagName: propTypes.string.isRequired,
  redirect: propTypes.string,
  children: propTypes.node.isRequired,
};
