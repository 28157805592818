import React from 'react';
import propTypes from 'prop-types';

import './day-contents.modules.scss';

export default function DayContents({ day }) {
  return (
    <div className={'DayContents'}>
      <span className={'DayContents_day'}>{day}</span>
    </div>
  );
}

DayContents.propTypes = {
  day: propTypes.string.isRequired,
};
