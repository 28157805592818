import React, { useState } from 'react';
import MrCodeEditor from './code-editor';
import propTypes from 'prop-types';

export default function UncontrolledMrCodeEditor(props) {
  const { defaultValue, onChange, ...remainingProps } = props;
  const [input, setInput] = useState(defaultValue || '');

  const onChangeWrapper = event => {
    setInput(event.target.value || '');
    if (onChange) {
      onChange(event);
    }
  };

  return <MrCodeEditor {...remainingProps} value={input} onChange={onChangeWrapper} />;
}

UncontrolledMrCodeEditor.propTypes = {
  onChange: propTypes.func,
  ...MrCodeEditor.propTypes,
};
