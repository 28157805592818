import { validateRequired } from '@ion/components/src/components/form/validators';

export default function buildConsentCategorySelectProps(formState, setField) {
  return {
    name: 'consentCategory',
    validators: [validateRequired()],
    value: formState.consentCategory.value,
    errorMsg: formState.consentCategory.errorMsg,
    onChange: setField,
  };
}
