import React from 'react';
import propTypes from 'prop-types';

import styles from './form-label.module.scss';

export default function FormLabel({ label, id, required }) {
  return (
    <label className={styles.label} htmlFor={id}>
      {label}
      {required && <span className={styles.required}>*</span>}
    </label>
  );
}

FormLabel.propTypes = {
  label: propTypes.string.isRequired,
  id: propTypes.string,
  required: propTypes.bool,
};
