import React from 'react';
import propTypes from 'prop-types';

import FeatureGate from '../../launch-darkly/gates/feature-gate';
import { CONSENT_CATEGORY_FLAGS } from '../../context/gated-consent-categories-context';
import { ConsentCategorySelect } from './ConsentCategorySelect';
import { ConsentCategoryDisabledMessage } from './ConsentCategoryDisabledMessage';

export const GatedConsentCategoriesSelect = ({ onChange, ...props }) => {
  return (
    <FeatureGate flagName={CONSENT_CATEGORY_FLAGS.MVP}>
      <FeatureGate flagName={CONSENT_CATEGORY_FLAGS.ENABLED_ORGS} fallback={<ConsentCategoryDisabledMessage />}>
        <ConsentCategorySelect onChange={onChange} {...props} />
      </FeatureGate>
    </FeatureGate>
  );
};

GatedConsentCategoriesSelect.propTypes = {
  onChange: propTypes.func.isRequired,
};
