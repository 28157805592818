import methodToAction from './methodToAction';
import defaultGraphQLErrorMessage from './defaultGraphQLErrorMessage';
import getResource from './getResource';
import errors from './errors';

export default (method = '', path = '', writekey = '') => {
  const action = methodToAction(method);
  const resource = getResource(path);

  if (!action || !resource) {
    return defaultGraphQLErrorMessage();
  }
  return {
    title: errors.graphQL.userControlProxyErrors.title(action, resource, writekey),
    message: errors.graphQL.userControlProxyErrors.message,
  };
};
