import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import isNil from 'lodash/isNil';

import InputRadioGroupLabel from './input-radio-group-label';
import withValidateChildren from './validate-children';
import InputRadioGroupRadios from './input-radio-group-radios';
import InputRadioGroupError from './input-radio-group-error';
import InputRadioGroupInput from './input-radio-group-input';
import useSelectedValue from './hooks/use-selected-value';
import useOnSelectCallback from './hooks/use-on-select-callback';

import styles from './input-radio-group.module.scss';

function MrInputRadioGroup({
  children,
  name,
  label,
  defaultValue = '',
  required,
  onChange,
  errorMsg,
  resetError,
  className,
  testId = 'MrInputRadioGroup',
  overrideValue,
  helperText = '',
}) {
  const [{ selectedValue, isSelected }, setSelectedValue] = useSelectedValue(defaultValue);
  const onSelect = useOnSelectCallback({ name, setSelectedValue, resetError, onChange });

  // This is a hack because many of the current form elements rely
  // on the caller to handle their state rather than manage it internally.
  const hasOverride = !isNil(overrideValue);
  if (hasOverride && overrideValue !== selectedValue) {
    setSelectedValue(overrideValue);
    return null;
  }

  return (
    <div className={cx(styles.inputRadioGroup, className)} data-test-id={testId}>
      {label && <InputRadioGroupLabel label={label} required={required} />}

      {helperText && <p className={styles.helperText}>{helperText}</p>}

      <InputRadioGroupRadios {...{ isSelected, onSelect, name }}>{children}</InputRadioGroupRadios>

      <InputRadioGroupInput name={name} value={selectedValue ?? ''} required={required} />

      {errorMsg && <InputRadioGroupError message={errorMsg} />}
    </div>
  );
}

MrInputRadioGroup.propTypes = {
  children: pt.node,
  name: pt.string.isRequired,
  label: pt.string,
  defaultValue: pt.string,
  overrideValue: pt.string,
  required: pt.any,
  onChange: pt.func,
  errorMsg: pt.string,
  resetError: pt.func,
  className: pt.string,
  testId: pt.string,
  helperText: pt.string,
};

export default withValidateChildren(MrInputRadioGroup);
