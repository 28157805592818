import formatIfNan from './format-if-nan';
import { dataColumnProperties, defaultProperties } from './apply-column-defaults';
import formatDeliveryRate from './format-delivery-rate';
import sortByDeliveryRate from './sort-by-delivery-rate';
import DROP_REASONS from '../../drop-reasons';

const formatter = Intl.NumberFormat('en', { notation: 'compact' });

const filterColumns = [
  {
    name: 'Compliance Filter',
    selector: row => row[DROP_REASONS.CONSENT],
    format: row => formatIfNan(row, DROP_REASONS.CONSENT),
    ...dataColumnProperties,
    ...defaultProperties,
  },
  {
    name: 'Playbook Filter',
    selector: row => row[DROP_REASONS.PLAYBOOK_FILTER],
    format: row => formatIfNan(row, DROP_REASONS.PLAYBOOK_FILTER),
    ...dataColumnProperties,
    ...defaultProperties,
  },
];

const deliveryColumns = [
  {
    name: 'Delivered',
    selector: row => row.Success,
    format: row => formatter.format(row.Success),
    ...dataColumnProperties,
    ...defaultProperties,
  },
  {
    name: 'Not Delivered',
    selector: row => row.Errors,
    format: row => formatter.format(row.Errors),
    ...dataColumnProperties,
    ...defaultProperties,
  },
  {
    name: 'Delivery Rate',
    selector: row => row,
    format: row => formatDeliveryRate(row),
    sortFunction: sortByDeliveryRate,
    ...dataColumnProperties,
    ...defaultProperties,
  },
];

export { filterColumns, deliveryColumns };
