import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { FormFieldContext, FormSelect } from '@metarouter-private/mr-mui';
import { default as useFormRoleSelect } from '../../hooks/use-form-role-select';
import { useUserModalContext } from '../../context/UserModalContextProvider';

export const FormRoleSelect = () => {
  const { label, options } = useFormRoleSelect();
  const { selectedUser } = useUserModalContext();

  const formSelectFieldProps = {
    name: 'roleId',
    label,
    ...(!isEmpty(selectedUser) && {
      defaultValue: selectedUser.roles[0]?.id,
    }),
  };

  return (
    <FormFieldContext {...formSelectFieldProps}>
      <FormSelect options={options} data-test-id="roleSelect" />
    </FormFieldContext>
  );
};

FormRoleSelect.propTypes = {};

export default FormRoleSelect;
