export const deployActions = {
  // This is only for the old pipeline card.
  // It prevents diffing if there are missing pipeline variables.
  // If all the users use new pipeline table, we can remove this case.
  OPEN_PIPELINE_VARIABLES_MODAL: 'resolve',
  // It shows confirm modal to clear pipeline integrations data
  OPEN_CONFIRM_DELETE_PIPELINE_DATA_MODAL: 'delete',
  OPEN_PIPELINE_DIFF_MODAL: 'diff',
};

const determineDeployAction = (
  deployStatus,
  openPipelineVariablesModal,
  openClearPipelineDataModal,
  openPipelineDiffModal
) => {
  switch (deployStatus.action) {
    case deployActions.OPEN_PIPELINE_VARIABLES_MODAL:
      return openPipelineVariablesModal(true);
    case deployActions.OPEN_CONFIRM_DELETE_PIPELINE_DATA_MODAL:
      return openClearPipelineDataModal(true);
    case deployActions.OPEN_PIPELINE_DIFF_MODAL:
      return openPipelineDiffModal();
    default:
      throw new Error(`Unknown deploy action: ${deployStatus.action}`);
  }
};

export default determineDeployAction;
