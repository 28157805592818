import React from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty as _isEmpty } from 'lodash';
import {
  MrAppLayout,
  MrAppMain,
  MrForm,
  MrInputSelectDeprecated,
  MrH,
  MrText,
  useForm,
  generateId,
  MrLinkButton,
  formValidators,
  MrIcon,
} from '@ion/components';
import SideNav from 'app/components/side-nav';

import { useQueryIntegrationPlaybooks, useQueryIntegrations, useQueryPipelines } from '@ion/api';
import { routes } from 'app/constants';
import generatePipelinesList from './generate-pipelines-list';
import s from './index.module.scss';
import arrow from '../../assets/icon-arrow-right.svg';
import close from '../../assets/icon-close.svg';

const BulkSelectRevisions = () => {
  const history = useHistory();
  const formId = generateId();
  const { validateRequired, validateNotEqual, validatePipelineVars, validateStagedRevisions } = formValidators;
  const { formState, validateField, submitForm, setField } = useForm(formId);
  const { data: integrations, loading: loadingIntegrations } = useQueryIntegrations();
  const { data: pipelines } = useQueryPipelines();

  const onFormSubmit = () => {
    const { data, isValid } = submitForm();
    if (isValid) {
      history.push(routes.bulkSelectPipelines, {
        revisions: { ...data, integrationName: integrationPlaybooksData.name },
        pipelinesList: generatePipelinesList(pipelines, data.currentRevision),
      });
    }
  };

  const { data: integrationPlaybooksData, loading: loadingPlaybookIntegrations } = useQueryIntegrationPlaybooks({
    variables: { integrationId: formState.integration?.value },
  });

  const currentRevisionOptions = integrationPlaybooksData?.playbooks?.map(playbook => {
    return { text: `Revision ${playbook.revision}`, value: playbook.id };
  });

  currentRevisionOptions?.unshift({
    text: '- New Integration -',
    value: 'new-integration-' + formState.integration.value,
  });

  return (
    <>
      <MrAppLayout
        header={
          <div className={s.header}>
            <MrLinkButton className={s.close} to={routes.pipelines}>
              <img src={close} />
              Close
            </MrLinkButton>

            <MrText className={s.title}>
              <MrIcon id={'icon-bulk-deploy'} />
              <span>Bulk Stage and Deploy</span>
            </MrText>

            <MrLinkButton
              onClick={onFormSubmit}
              testId={'selectRevisionsSubmit'}
              className={s.submit}
              formid={formId}
              disabled={
                _isEmpty(formState.integration?.value) ||
                _isEmpty(formState.currentRevision?.value) ||
                _isEmpty(formState.updatedRevision?.value)
              }
            >
              Next Step
              <img src={arrow} />
            </MrLinkButton>
          </div>
        }
        sideNav={<SideNav />}
        title="Pipelines"
        description="Select Integration and Revisions"
        icon="pipelines"
      >
        <MrAppMain loading={loadingIntegrations} styleNames="list">
          <div className={s.stepList}>
            <div className={s.currentStep}>1</div> <MrText className={s.currentStepText}>Revisions</MrText>
            <div className={s.step}>2</div> <MrText className={s.stepText}>Pipelines</MrText>
            <div className={s.step}>3</div> <MrText className={s.stepText}>Confirm & Deploy</MrText>
          </div>
          <div className={s.card}>
            <MrH h="h3" styleNames="noMargin">
              Select Integration and its Revision
            </MrH>
            <MrForm onSubmit={onFormSubmit} id={formId}>
              <MrInputSelectDeprecated
                className={s.integrationSelect}
                testId={'IntegrationSelect'}
                options={integrations.map(integration => {
                  return { text: integration.name, value: integration.id };
                })}
                onBlur={validateField}
                validators={[validateRequired()]}
                name="integration"
                label="Integration to Stage:"
                placeholder={!integrations.length ? 'No integrations available' : 'Select Integration...'}
                value={formState.integration?.value || ''}
                onChange={setField}
                errorMsg={formState.Integration?.errorMsg}
                disabled={!integrations.length}
                isClearable
              />

              <div className={s.select}>
                <MrInputSelectDeprecated
                  className={s.inputSelect}
                  testId={'CurrentRevisionSelect'}
                  options={currentRevisionOptions || []}
                  onBlur={validateField}
                  validators={[
                    validateRequired(),
                    validateNotEqual(formState.updatedRevision?.value, 'Selected revisions must be different'),
                    validatePipelineVars(formState, integrationPlaybooksData?.playbooks),
                    validateStagedRevisions(pipelines, formState.currentRevision?.value),
                  ]}
                  name="currentRevision"
                  label="Current Revision (from):"
                  placeholder={
                    !loadingPlaybookIntegrations && !integrationPlaybooksData?.playbooks.length
                      ? 'Please select an integration first'
                      : '“From” Revision...'
                  }
                  value={formState.currentRevision?.value || ''}
                  onChange={setField}
                  errorMsg={formState.currentRevision?.errorMsg}
                  disabled={!formState.integration}
                  isClearable
                  alphabetized={false}
                />

                <img src={arrow} />

                <MrInputSelectDeprecated
                  className={s.inputSelect}
                  testId={'UpdatedRevisionSelect'}
                  options={
                    integrationPlaybooksData?.playbooks?.map(playbook => {
                      return { text: `Revision ${playbook.revision}`, value: playbook.id };
                    }) || []
                  }
                  onBlur={validateField}
                  validators={[
                    validateRequired(),
                    validateNotEqual(formState.currentRevision?.value, 'Selected revisions must be different'),
                    validatePipelineVars(formState, integrationPlaybooksData?.playbooks),
                  ]}
                  name="updatedRevision"
                  label="Bulk Deploy Revision (to):"
                  placeholder={
                    !loadingPlaybookIntegrations && !integrationPlaybooksData?.playbooks.length
                      ? 'Please select an integration first'
                      : '“To” Revision...'
                  }
                  value={formState.updatedRevision?.value || ''}
                  onChange={setField}
                  errorMsg={formState.updatedRevision?.errorMsg}
                  disabled={!formState.integration}
                  isClearable
                  alphabetized={false}
                />
              </div>
            </MrForm>
          </div>
        </MrAppMain>
      </MrAppLayout>
    </>
  );
};

BulkSelectRevisions.propTypes = {};

export default BulkSelectRevisions;
