import { gql } from '@apollo/client';

export const mutation = gql`
  mutation useControlProxy($clusterId: uuid!, $writekey: String, $method: String!, $path: String!, $body: json) {
    control_proxy(clusterId: $clusterId, writekey: $writekey, method: $method, path: $path, body: $body) {
      status
      data
      errors
    }
  }
`;

export function buildVariables({ clusterId, writekey, method, path, body }) {
  return { clusterId, writekey, method, path, body };
}
