import React from 'react';

import Template from './template';

import { routes } from 'app/constants';
import s from './index.module.scss';

const EmailLink = () => (
  <a className={s.link} href="mailto:support@metarouter.io">
    support@metarouter.io
  </a>
);

const ErrorSupportingText = () => {
  return (
    <p>
      Sorry. There seems to be an issue. Please retry. If the problem persists you can email <EmailLink /> additional
      support.
    </p>
  );
};

const NotFoundSupportingText = () => {
  return (
    <p>
      The page you are looking for could not be found.
      <br /> For additional support email <EmailLink />
    </p>
  );
};

const NoOrgSupportingText = () => {
  return (
    <p>
      You do not have access to any Organizations currently or are using a different login method than when you created
      your account. Please log out and try again.
      <br /> For additional support email <EmailLink />
    </p>
  );
};

const NoEmailSupportingText = () => {
  return (
    <p>
      You do not have an email address assigned to your user account.
      <br /> For additional support email <EmailLink />
    </p>
  );
};

/**
 *
 * Important: This component gets rendered outside of the BrowserRouter via the
 * ErrorBoundary fallback component and cannot use any components from, or
 * based off of React Router such as Link, NavLink, etc., including MrLink.
 *
 */
const ErrorPage = err => {
  const props = {
    title: 'Error',
    message: 'Something went wrong.',
    SupportingText: ErrorSupportingText,
    err: err,
  };
  return <Template {...props} />;
};

const NotFoundPage = () => {
  const props = {
    title: 'Not Found',
    message: 'Not Found.',
    SupportingText: NotFoundSupportingText,
    button: {
      buttonText: 'Go Back Home',
      to: routes.home,
    },
  };

  return <Template {...props} />;
};

const NoOrgsPage = () => {
  const props = {
    title: 'No Organization Found',
    message: 'No Organization Found',
    SupportingText: NoOrgSupportingText,
    button: {
      buttonText: 'Log Out and Retry',
      to: routes.logOut,
    },
  };

  return <Template {...props} />;
};

const NoEmailPage = () => {
  const props = {
    title: 'No User Email',
    message: 'No User Email',
    SupportingText: NoEmailSupportingText,
    button: {
      buttonText: 'Log Out and Retry',
      to: routes.logOut,
    },
  };

  return <Template {...props} />;
};

export { ErrorPage, NotFoundPage, NoOrgsPage, NoEmailPage };
