import React from 'react';
import { MrSearch } from '@ion/components';
import propTypes from 'prop-types';

export default function AuditLogsSearch({ onChange }) {
  return (
    <>
      <MrSearch
        testId="auditLogSearch"
        placeholder="Search Email, Action, or Description..."
        onChange={onChange}
        />
    </>
  );
}

AuditLogsSearch.propTypes = {
  onChange: propTypes.func,
};
