import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import generateId from '../../utils/uuid';
import useValidatorLookup from '../../hooks/use-validator-lookup';
import { validateRequired } from '../form/validators';

export default function InputRadioGroupInput({ name, value, required }) {
  const id = useMemo(() => generateId(), []);
  const { addValidators } = useValidatorLookup({ uuid: id });

  if (required) {
    addValidators({ uuid: id, validators: [validateRequired()] });
  }

  return <input type="hidden" id={id} name={name} value={value} />;
}

InputRadioGroupInput.propTypes = {
  name: propTypes.string.isRequired,
  value: propTypes.string,
  required: propTypes.any,
};
