import React, { createContext, useContext, useState, useCallback } from 'react';
import propTypes from 'prop-types';

import { useDeleteOrganizationInvitation } from '@ion/api';

import InvitationConfirmDeleteMessage from './InvitationConfirmDeleteMessage';

const InvitationConfirmDeleteContext = createContext(null);

const title = `Confirm Invitation Deletion`;

export default function InvitationConfirmDeleteContextProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClose = useCallback(() => setOpen(false), []);

  const onOpen = useCallback(row => {
    setSelectedInvitation(row);
    setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [deleteOrganizationInvitation] = useDeleteOrganizationInvitation({
    onCompleted: () => {
      onClose();
    },
  });

  const onConfirm = useCallback(
    () => deleteOrganizationInvitation({ email: selectedInvitation.email, invitationId: selectedInvitation.id }),
    [deleteOrganizationInvitation, selectedInvitation.email, selectedInvitation.id]
  );

  const confirmDeleteProps = {
    open,
    title,
    message: <InvitationConfirmDeleteMessage email={selectedInvitation.email} />,
    onClose,
    onConfirm,
  };

  return (
    <InvitationConfirmDeleteContext.Provider value={{ confirmDeleteProps, onOpen, setSelectedInvitation }}>
      {children}
    </InvitationConfirmDeleteContext.Provider>
  );
}

export function useInvitationConfirmDeleteContext() {
  return useContext(InvitationConfirmDeleteContext);
}

InvitationConfirmDeleteContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};
