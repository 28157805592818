import { omit as _omit, isEqual as _isEqual } from 'lodash';

import { mapOperations } from './playbook-utils';
import { relocateSingleKeys } from './relocate-single-keys';

const fields = [
  'expressionName',
  'opId',
  'opIndex',
  '_path',
  'event',
  'schema',
  '_hide',
  'pipelineVar',
  'pipelineVarId',
  'attachmentKey',
];

// remove UI-only fields that are not in the playbook spec
export const removeUiFields = (playbook, uiFields = fields) => {
  playbook = relocateSingleKeys(_omit(playbook, ['metadata', 'global.vars']));

  return mapOperations({
    playbook,
    opFunction: op => opFunction(op, uiFields),
    eventFunction: event => _omit(event, ['metadata']),
  });
};

const opFunction = (op, uiFields) => {
  const directAssignment = [{ directAssignment: null }];
  const isDirectAssignment = _isEqual(op?.transforms, directAssignment);

  return isDirectAssignment ? _omit(op, [...uiFields, 'transforms']) : _omit(op, uiFields);
};
