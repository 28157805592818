import React from 'react';
import propTypes from 'prop-types';
import { Typography } from '@metarouter-private/mr-mui';

export default function EmailCell({ email }) {
  return <Typography>{email}</Typography>;
}

EmailCell.propTypes = {
  email: propTypes.string.isRequired,
};
