import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './form-card.module.scss';

import MrH from '../h/h';

const MrFormCard = ({ children, title, success, className }) => {
  return (
    <section className={cx(s.formCard, className)}>
      {success ? (
        <MrH h="h2">&#x2705; {success}</MrH>
      ) : (
        <>
          <MrH h="h1">{title}</MrH>
          {children}
        </>
      )}
    </section>
  );
};

MrFormCard.propTypes = {
  children: pt.node,
  success: pt.string,
  className: pt.string,
  title: pt.string,
};

export default MrFormCard;
