import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './h.module.scss';

const MrH = ({ children, h = 'h1', sans, className, styleNames = '' }) => {
  const Component = h;
  return (
    <Component
      className={cx(
        s.h,
        sans && s.sans,
        className,
        styleNames.split(' ').map(c => s[c])
      )}
    >
      {children}
    </Component>
  );
};

MrH.propTypes = {
  children: pt.node,
  className: pt.string,
  styleNames: pt.string, // eg. 'center noMargin'
  h: pt.string,
  sans: pt.bool,
};

export default MrH;
