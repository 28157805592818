import { gql } from '@apollo/client';

export const mutation = gql`
  mutation CreateOrganizationInvitation($email: String!, $roleIds: [String]!) {
    auth0_create_organization_invitation(email: $email, roleIds: $roleIds) {
      status
    }
  }
`;

export function buildVariables({ email, roleIds }) {
  return { email, roleIds };
}
