import React from 'react';
import pt from 'prop-types';

import styles from './icon-tooltip.module.scss';
import { MrIcon, MrTooltip } from '../../index';

const DEFAULT_ICON = () => <MrIcon className={styles.defaultTooltipIcon} id="icon-help-icon" />;

export default function MrIconTooltip({ Icon = DEFAULT_ICON, message, position }) {
  return (
    <MrTooltip text={message} position={position}>
      <Icon />
    </MrTooltip>
  );
}

MrIconTooltip.propTypes = {
  Icon: pt.func,
  message: pt.string.isRequired,
  position: pt.string,
};
