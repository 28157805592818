// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._-component-library-src-components-form-form-module__toggle{cursor:pointer;margin-left:auto;width:1.8rem;transition:transform 200ms ease-out;transform:scaleY(-1)}._-component-library-src-components-form-form-module__toggle._-component-library-src-components-form-form-module__open{transform:scaleY(1)}._-component-library-src-components-form-form-module__fullWidth{width:100%}", "",{"version":3,"sources":["/usr/src/packages/component-library/src/components/form/form.module.scss"],"names":[],"mappings":"AA2BA,6DACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,mCAAA,CACA,oBAAA,CAEA,uHACE,mBAAA,CAIJ,gEACE,UAAA","file":"form.module.scss","sourcesContent":["@import '../../scss/theme/brand-vars';\n@import '../../scss/theme/layout-vars';\n@import '../../scss/theme/shadow-vars';\n@import '../../scss/theme/mixins';\n\n// this file is only for global variable and mixins and should not output any styles\n\n///////////////////////////////////////////////////////////////////////////////\n\n@mixin cardList($tile) {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: center;\n\n  @if ($tile == true) {\n    justify-content: flex-start;\n    margin: -1rem;\n  }\n\n  & > div {\n    margin: 1rem;\n  }\n}\n\n.toggle {\n  cursor: pointer;\n  margin-left: auto;\n  width: 1.8rem;\n  transition: transform 200ms ease-out;\n  transform: scaleY(-1);\n\n  &.open {\n    transform: scaleY(1);\n  }\n}\n\n.fullWidth {\n  width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"toggle": "_-component-library-src-components-form-form-module__toggle",
	"open": "_-component-library-src-components-form-form-module__open",
	"fullWidth": "_-component-library-src-components-form-form-module__fullWidth"
};
module.exports = exports;
