import React from 'react';
import propTypes from 'prop-types';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

export default function DescriptionCell({ description }) {
  if (!isNil(description) && !isEmpty(description)) {
    return <>{description}</>;
  }
  return <>{'-'}</>;
}

DescriptionCell.propTypes = {
  description: propTypes.string,
};
