import { useMemo } from 'react';
import { useQuery } from './../index';
import { gql } from '@apollo/client';

export const GET_USER_ORGANIZATIONS = gql`
  query UserOrganizations {
    auth0_user_orgs {
      organizations {
        display_name
        id
        name
      }
    }
  }
`;

export default () => {
  const { data, loading, error } = useQuery(GET_USER_ORGANIZATIONS, {});

  const transformedData = useMemo(() => {
    return !data?.auth0_user_orgs ? [] : data.auth0_user_orgs.organizations;
  }, [data]);

  return {
    data: transformedData,
    loading,
    error,
  };
};
