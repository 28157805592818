export function getPipelineTransform(
  pipelinesTransform = [{ transform: undefined, pipelineID: undefined }],
  pipelineID
) {
  for (const data of pipelinesTransform) {
    if (data.pipelineID === pipelineID) {
      return data.transform;
    }
  }
  return undefined;
}
