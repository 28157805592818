import React, { useState } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import { lowerCase as _lowerCase } from 'lodash';
import { useHistory } from 'react-router-dom';

import { MrAvatar, MrLink } from '@ion/components';
import NavIcon from 'app/components/nav-icon';
import OrgSwitcher from 'app/components/org-switcher';

import { useUserContext } from '@ion/user';

import s from './index.module.scss';

const AppHeader = ({ title, subTitle, icon, className, route = null }) => {
  const { currentOrg } = useUserContext();
  const [switcher, setSwitcher] = useState();
  const history = useHistory();

  return (
    <header className={cx(s.header, className)}>
      <div className={s.content}>
        <div className={s.title}>
          <NavIcon icon={icon || title?.toLowerCase()} />
          {/* Always using h1 here instead of MrH because custom sizing */}
          <MrLink className={s.link} onClick={() => history.push(route ? route : _lowerCase(title))}>
            {title}
          </MrLink>
          <h1>{subTitle && <span className={s.subTitle}>{subTitle}</span>}</h1>
        </div>
      </div>
      <MrAvatar
        id="org-switcher-link"
        name={currentOrg?.display_name}
        className={cx(s.avatar)}
        onClick={() => setSwitcher(true)}
      />
      <div className={s.overlay} />
      {switcher && <OrgSwitcher orgId={currentOrg.id} closeModal={() => setSwitcher()} />}
    </header>
  );
};

AppHeader.propTypes = {
  children: pt.node,
  small: pt.bool,
  className: pt.string,
  title: pt.string.isRequired,
  subTitle: pt.oneOfType([pt.string, pt.object]),
  icon: pt.string,
  route: pt.string,
};

export default AppHeader;
