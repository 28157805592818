import { GET_ORGANIZATION_INVITATIONS } from './auth0-organization-invitations-query-builder';
import permission from '../../Apollo/permissions';
import useLazyQuery from '../../hooks/useLazyQuery';
import getHighestTierRole from '../roles/get-highest-tier-role';

export default function useLazyQueryOrganizationInvitations({ variables, ...options } = {}) {
  const [executeQuery, { data, ...rest }] = useLazyQuery(GET_ORGANIZATION_INVITATIONS, {
    variables,
    notifyOnNetworkStatusChange: true,
    context: { permission: permission.readUsers },
    // Not using the cache so we can dynamically refresh whenever invitations are created.
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const getOrganizationInvitations = pagination => {
    const { page, perPage } = pagination;
    const variables = { page, perPage };
    executeQuery({ variables });
  };

  return [getOrganizationInvitations, { ...transformData(data), ...rest }];
}

function transformData(data) {
  if (!data) {
    return {
      invitations: [],
      pagination: { count: null },
    };
  }

  const { invitations, page, perPage, totalCount } = data.auth0_organization_invitations;

  return {
    invitations: getHighestTierRole(invitations),
    pagination: {
      page,
      perPage,
      count: totalCount,
    },
  };
}
