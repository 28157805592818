import React from 'react';
import propTypes from 'prop-types';

import { MrText } from '../../index';

import styles from './input-radio-group-label.module.scss';

export default function InputRadioGroupLabel({ label, required }) {
  return (
    <div className={styles.container}>
      <MrText component="label" className={styles.label} required={required}>
        {label}
      </MrText>
    </div>
  );
}

InputRadioGroupLabel.propTypes = {
  label: propTypes.string.isRequired,
  required: propTypes.any,
};
