import React from 'react';
import allowRowClick from './allow-row-click';
import FeatureGate from '../../../launch-darkly/gates/feature-gate';
import { MrText } from '@ion/components';
import { CONSENT_CATEGORY_FLAGS } from '../../../context/gated-consent-categories-context';

function determineStyleNames(row) {
  const isSemiBold = row.deployedRevision && row.deployedConsentCategory !== row.consentCategory?.code;
  if (isSemiBold) {
    return 'sm semiBold';
  }

  return 'sm';
}

const ConsentCategoryCell = row => {
  const styleNames = determineStyleNames(row);
  const category = row.consentCategory;
  const textProps = {
    component: 'span',
    styleNames,
    ...allowRowClick,
  };
  return (
    <FeatureGate flagName={CONSENT_CATEGORY_FLAGS.MVP}>
      <MrText {...textProps}>
        <FeatureGate flagName={CONSENT_CATEGORY_FLAGS.ENABLED_ORGS} fallback={'-'}>
          {category ? `${category.code} - ${category.name}` : '-'}
        </FeatureGate>
      </MrText>
    </FeatureGate>
  );
};
export default ConsentCategoryCell;
