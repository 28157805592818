const rate = row => {
  return (row.Success / (row.Success + row.Errors)) * 100;
};

const sortByDeliveryRate = (rowA, rowB) => {
  const a = rate(rowA);
  const b = rate(rowB);

  if (a > b) {
    return 1;
  }
  return -1;
};

export default sortByDeliveryRate;
