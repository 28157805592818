import React from 'react';
import pt from 'prop-types';

import FeatureGate from '../../launch-darkly/gates/feature-gate';
import { useConsentCategoriesContext } from '../../context/consent-categories-context';
import { CONSENT_CATEGORY_FLAGS } from '../../context/gated-consent-categories-context';
import { ConsentCategoryDisabledMessage } from '../consent-categories-select/ConsentCategoryDisabledMessage';

const ConsentCategoryRow = ({ consentCategoryId }) => {
  const { consentCategories, loading } = useConsentCategoriesContext();
  if (loading) {
    return null;
  }
  const categoryData = consentCategories.find(category => category.id === consentCategoryId);

  return (
    <FeatureGate flagName={CONSENT_CATEGORY_FLAGS.MVP}>
      <tr>
        <td>Consent Type</td>
        <td>
          <FeatureGate flagName={CONSENT_CATEGORY_FLAGS.ENABLED_ORGS} fallback={<ConsentCategoryDisabledMessage />}>
            <>{categoryData?.displayName}</>
          </FeatureGate>
        </td>
      </tr>
    </FeatureGate>
  );
};

ConsentCategoryRow.propTypes = {
  consentCategoryId: pt.string,
};
export default ConsentCategoryRow;
