import React, { useState } from 'react';
import cx from 'classnames';
import pt from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  MrH,
  MrDate,
  MrText,
  MrButton,
  MrDropdown,
  MrEditField,
  MrCopy,
  MrLink,
  MrErrorModal,
  MrIcon,
} from '@ion/components';
import { useRemovePipeline, useUpdatePipeline, useDeletePipeline, useRemovePipelineIntegrations } from '@ion/api';

import { routes } from 'app/constants';
import ConfirmDelete from 'app/components/confirm-delete/confirm-delete';

import PipelineVarsModal from '../pipeline-vars-modal';
import s from './header.module.scss';

import arrow from './assets/icon-chevron.svg';
import determineDeployAction from './determine-deploy-action';

const PipelineCardHeader = ({
  writekey,
  pipeline,
  name,
  isOpen,
  changeOpen,
  lastDeployedDate,
  deployStatus,
  cluster,
  variablesModalOpen,
  setVariablesModalOpen,
  revisionToEdit,
  setRevisionToEdit,
  setDeployedRevisions,
  openPipelineDiffModal,
}) => {
  const history = useHistory();
  const [updatePipelineName] = useUpdatePipeline();
  const [removePipelineIntegrations] = useRemovePipelineIntegrations();

  const [deletePipelineModalOpen, setDeletePipelineModalOpen] = useState();
  const [clearPipelineDataModalOpen, setClearPipelineDataModalOpen] = useState();
  const [errorMsg, setErrorMsg] = useState(false);
  const [removePipeline, { isAllowed: deletePipelinePermission }] = useRemovePipeline();

  const [clearPipeline] = useDeletePipeline({
    onCompleted: () => {
      setDeployedRevisions([]);
      removePipelineIntegrations(pipeline.pipelineIntegrations);
    },
    onError: err => {
      setErrorMsg(err?.errorMsg);
    },
  });

  const [deletePipeline] = useDeletePipeline({
    onCompleted: () => {
      removePipelineIntegrations(pipeline.pipelineIntegrations);
      removePipeline({ pipelineId: pipeline.id, name: pipeline.name });
    },
    onError: err => {
      setErrorMsg(err?.errorMsg);
    },
  });

  return (
    <header className={s.header} data-test-id={pipeline.writekey}>
      <div className={s.pipelineDetails}>
        <MrEditField
          className={s.editPipelineName}
          text={name}
          onSubmit={name => {
            updatePipelineName({ pipelineId: pipeline.id, name });
          }}
          inputProps={{ placeholder: 'pipeline name' }}
        >
          <div className={s.pipelineName}>{name}</div>
        </MrEditField>

        <ul className={s.pipelineMeta}>
          <li>
            <MrText styleNames="smallGray" className={s.metaText}>
              Last Deploy: <MrDate date={lastDeployedDate || 'Not Deployed'} format="MM/dd/yy" />
            </MrText>
          </li>
          <li>
            <MrText styleNames="smallGray" className={s.metaText}>
              Health:
              <span className={cx(s.circle, s[deployStatus.healthClass])}></span>
              <span className={s.healthText}>{deployStatus.healthText}</span>
            </MrText>
          </li>
          <li>
            <MrText styleNames="smallGray" className={s.metaText}>
              WriteKey:{' '}
              <span className={s.writekey}>
                {writekey} <MrCopy text={writekey} />
              </span>
            </MrText>
          </li>
        </ul>
      </div>

      <div className={s.pipelineControls}>
        {deletePipelinePermission &&
          !(deployStatus?.disabled === true && deployStatus.buttonText === 'Deploy Pipeline') && (
            <MrButton
              text={deployStatus?.buttonText}
              onClick={e => {
                e.preventDefault();
                determineDeployAction(
                  deployStatus,
                  setVariablesModalOpen,
                  setClearPipelineDataModalOpen,
                  openPipelineDiffModal
                );
              }}
              tooltip={deployStatus?.buttonTooltip}
              className={s.deployButton}
              styleNames={deployStatus?.styleName}
              startIcon={deployStatus.action === 'resolve' ? null : <MrIcon id="icon-deploy" />}
              disabled={deployStatus.buttonText === 'Deployed' || deployStatus.buttonText === 'Loading...'}
            />
          )}

        <MrDropdown className={s.dropdown} testId="pipelineDropdown">
          <ul>
            {deletePipelinePermission && (
              <li
                onClick={() => {
                  setDeletePipelineModalOpen(true);
                }}
              >
                Delete
              </li>
            )}
            <li
              onClick={() => {
                history.push(routes.analyticsJSBuilder, {
                  pipelineId: pipeline.id,
                  writekey: pipeline.writekey,
                  name: pipeline.name,
                });
              }}
            >
              Build AJS File
            </li>
          </ul>
        </MrDropdown>

        <div className={s.rightPercent}>
          <img
            src={arrow}
            className={cx(s.toggle, isOpen && s.open)}
            title="View Details"
            onClick={() => {
              changeOpen(!isOpen);
            }}
          />
        </div>

        {deletePipelineModalOpen && (
          <ConfirmDelete
            deleteFn={() => {
              deletePipeline({ clusterId: cluster.id, writekey });
            }}
            closeModal={() => {
              setDeletePipelineModalOpen(false);
            }}
          >
            <div className={s.header}>
              <MrH h="h3" sans styleNames="noMargin">
                Delete pipeline <strong>{name}</strong>?
              </MrH>
            </div>

            <div className={s.content}>
              <p>
                <b>WARNING!</b> This pipeline is connected to the <MrLink to={routes.clusters}>{cluster.name}</MrLink>{' '}
                cluster. If you choose to delete this pipeline, it will also be removed from the cluster. Integrations
                used by this pipeline will not be affected.
              </p>
            </div>
          </ConfirmDelete>
        )}

        {clearPipelineDataModalOpen && (
          <ConfirmDelete
            buttonClassName={s.deployButton}
            deleteFn={() => {
              clearPipeline({ clusterId: cluster.id, writekey });
            }}
            closeModal={() => {
              setClearPipelineDataModalOpen(false);
            }}
          >
            <div className={s.header}>
              <MrH h="h2" styleNames="sans">
                Remove deployed integrations from pipeline {name}?
              </MrH>
            </div>
            <div className={s.content}>
              <p>All deployed integrations connected to this pipeline are staged for deletion.</p>
            </div>
          </ConfirmDelete>
        )}

        {variablesModalOpen && (
          <PipelineVarsModal
            pipeline={pipeline}
            setVariablesModalOpen={setVariablesModalOpen}
            revisionToEdit={revisionToEdit}
            setRevisionToEdit={setRevisionToEdit}
          />
        )}

        {errorMsg && (
          <MrErrorModal
            header="Error Deleting Pipeline"
            errorMessage={errorMsg}
            closeModal={() => setErrorMsg(false)}
          />
        )}
      </div>
    </header>
  );
};

PipelineCardHeader.propTypes = {
  writekey: pt.string.isRequired,
  pipeline: pt.object.isRequired,
  name: pt.string.isRequired,
  isOpen: pt.bool,
  changeOpen: pt.func,
  lastDeployedDate: pt.string,
  deployStatus: pt.object,
  cluster: pt.object,
  variablesModalOpen: pt.bool,
  setVariablesModalOpen: pt.func,
  revisionToEdit: pt.string,
  setRevisionToEdit: pt.func,
  setDeployedRevisions: pt.func.isRequired,
  openPipelineDiffModal: pt.func.isRequired,
};

export default PipelineCardHeader;
