import React from 'react';
import propTypes from 'prop-types';

import { Chip } from '@metarouter-private/mr-mui';

import { chipProps } from './chip-props';

export default function StatusCell({ status }) {
  const props = chipProps(status);

  return <Chip {...props} />;
}

StatusCell.propTypes = {
  status: propTypes.string.isRequired,
};
