import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './button.module.scss';

const MrButton = ({
  text = 'Submit',
  type = 'submit',
  styleNames,
  disabled,
  onClick,
  id,
  formId,
  className,
  working,
  tooltip,
  testId = 'MrButton',
  startIcon,
  variant,
  size,
}) => {
  return (
    <button
      className={cx(
        s.button,
        styleNames?.split(' ').map(c => s[c]),
        className,
        s[variant],
        s[size]
      )}
      disabled={disabled}
      type={type}
      onClick={onClick}
      id={id}
      title={tooltip}
      form={formId}
      data-test-id={testId}
    >
      {startIcon && startIcon}
      {text}
      {working && <div className={s.spinner}></div>}
    </button>
  );
};

MrButton.propTypes = {
  text: pt.node,
  type: pt.string,
  disabled: pt.bool,
  onClick: pt.func,
  className: pt.string,
  styleNames: pt.string,
  id: pt.string,
  formId: pt.string,
  working: pt.bool,
  tooltip: pt.string,
  testId: pt.string,
  startIcon: pt.element,
  variant: pt.oneOf(['contained', 'outlined']),
  size: pt.oneOf(['small', 'medium', 'large']),
};

export default MrButton;
