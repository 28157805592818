import { reduce as _reduce } from 'lodash';

export const flattenOperations = operations => {
  return _reduce(
    operations,
    (result, value, key) => {
      // temporarily ignore filters
      return ['filters', 'metadata'].some(k => k === key) ? result : result.concat(value);
    },
    []
  )
    .map(op => op)
    .sort((a, b) => a.opIndex - b.opIndex);
};
