import React, { useEffect, useState, useCallback } from 'react';
import pluralize from 'pluralize';
import ReactDiffViewer from 'react-diff-viewer-continued';
import pt from 'prop-types';
import { startCase as _startCase } from 'lodash';

import { MrModal, MrButtonList, MrH, MrButton, MrErrorModal } from '@ion/components';
import { useDiffPipelines } from '@ion/api';

import mergeCurrentAndProposed from './merge-current-proposed';

import s from './index.module.scss';
import { globalModalStore } from '@ion/global';

const diffStyles = {
  variables: {
    light: {
      codeFoldGutterBackground: '#ebebeb',
      codeFoldBackground: '#ebebeb',
      codeFoldContentColor: '#5c6775',
    },
  },
};

const DeployModal = ({
  deploying,
  deployPipeline,
  closeModal,
  pipelineJson,
  clusterId,
  writekey,
  displayNames,
  setLoadingDiff,
}) => {
  const [diffOpen, setDiffOpen] = useState();

  const [diffResponse, setDiffResponse] = useState();
  const [getDiff] = useDiffPipelines();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDiffCallback = useCallback(getDiff, []);

  // using useEffect to get diff each time the pipelineJson is changed
  // Will only make a fetch call to getDiff if we have some integrations pipelineJson?.integrations?.length
  useEffect(() => {
    if (pipelineJson?.integrations?.length) {
      getDiffCallback({
        clusterId,
        writekey,
        proposedJSON: pipelineJson,
      })
        .then(response => {
          setDiffResponse(response);
          setLoadingDiff(false);
        })
        .catch(e => {
          globalModalStore.addNotification({
            title: 'Error Generating Pipeline Diff',
            message: e.message,
          });
          setLoadingDiff(false);
        });
    }
  }, [pipelineJson, clusterId, writekey, getDiffCallback, setLoadingDiff]);

  if (!diffResponse) {
    return null;
  }

  if (diffResponse?.errors) {
    return (
      <MrErrorModal
        header="Error Generating Pipeline Diff"
        errorMessage={diffResponse?.errors?.message}
        closeModal={closeModal}
      />
    );
  }

  let current;
  let proposed;

  if (diffResponse && !diffResponse?.errors) {
    current = diffResponse.current;
    proposed = diffResponse.proposed;
  }

  const integrations = mergeCurrentAndProposed({
    current,
    proposed,
    displayNames,
  });

  return (
    <MrModal closeModal={closeModal} styleNames="extraWide allowOverflow">
      <div className={s.header}>
        <MrH h="h2" styleNames="sans">
          Pipeline Change Verification
        </MrH>
      </div>
      <div className={s.content}>
        {integrations.length > 0 && (
          <>
            <p className={s.summary}>
              You&rsquo;re about to make changes {integrations.length > 1 ? 'across' : 'to'} {integrations.length}{' '}
              {pluralize('integration', integrations.length)}.
            </p>

            {integrations.map(i => {
              return (
                <div key={i.id} className={s.integration}>
                  <div className={s.integrationHeader}>
                    <MrH h="h3" styleNames="sans">
                      <span className={s[i.changeType]} /> {_startCase(i.changeType)}: {i.displayName || i.name}
                    </MrH>
                    <button
                      className={s.diffToggle}
                      onClick={() => {
                        if (diffOpen === i.id) {
                          setDiffOpen();
                        } else {
                          setDiffOpen(i.id);
                        }
                      }}
                    >
                      {diffOpen === i.id ? 'Hide' : 'Show'} details
                    </button>
                  </div>

                  {diffOpen === i.id && (
                    <div className={s[`diffBorder${_startCase(i.changeType)}`]}>
                      <div className={s.diffView}>
                        <ReactDiffViewer
                          leftTitle={<span className={s.diffHeading}>Currently Deployed</span>}
                          rightTitle={<span className={s.diffHeading}>Staged for Deploy</span>}
                          oldValue={JSON.stringify(i.current, null, 2)}
                          newValue={JSON.stringify(i.proposed, null, 2)}
                          splitView={true}
                          styles={diffStyles}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}

        <MrButtonList
          styleNames="marginTop"
          buttons={[
            <MrButton
              testId="confirmDeploy"
              text={deploying ? 'Deploying...' : 'Deploy'}
              type="button"
              key="deploy"
              working={deploying}
              onClick={deployPipeline}
              styleNames="green"
              className={s.deployButton}
            />,
          ]}
        />
      </div>
    </MrModal>
  );
};

DeployModal.propTypes = {
  deploying: pt.bool,
  deployPipeline: pt.func,
  integrationsCount: pt.number,
  closeModal: pt.func.isRequired,
  displayNames: pt.object.isRequired,
  pipelineJson: pt.object.isRequired,
  clusterId: pt.string,
  writekey: pt.string,
  setLoadingDiff: pt.func,
};

export default DeployModal;
