import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputSelectDeprecated } from '@ion/components';

import { useFormContext } from 'app/context/form-context';
import { BIG_QUERY_OPTIONS_DISABLE_DATASET_CREATION, BIG_QUERY_OPTIONS_UNSPECIFIED } from '../../consts';
const { validateRequired } = formValidators;

const options = [
  { value: BIG_QUERY_OPTIONS_UNSPECIFIED, text: 'true' },
  { value: BIG_QUERY_OPTIONS_DISABLE_DATASET_CREATION, text: 'false' },
];

/**
 *
 * @param name
 * @param defaultValue Comes from the hydrator in playbook-to-form-hydrator.js handleBigQueryOptions method
 * @param isOptional
 * @returns {JSX.Element}
 * @constructor
 */
const BigQueryOptions = ({ name, defaultValue, isOptional }) => {
  const { setField, validateField, formState } = useFormContext();
  return (
    <MrInputSelectDeprecated
      name={name}
      options={options}
      value={defaultValue}
      onChange={e => setField(e)}
      onBlur={validateField}
      errorMsg={formState[name]?.errorMsg}
      label="Create dataset if it does not exist?"
      validators={!isOptional ? [validateRequired()] : null}
    />
  );
};

BigQueryOptions.propTypes = {
  name: pt.string,
  defaultValue: pt.string,
  isOptional: pt.bool.isRequired,
};

export default BigQueryOptions;
