import { HttpLink } from '@apollo/client';
import fetch from 'cross-fetch';

// Same origin does not work across sub domains so defaulting to include
const credentials = 'include';

const httpLink = new HttpLink({
  uri: process.env.HASURA_URL,
  credentials,
  fetch,
});

export default httpLink;
