import { gql } from '@apollo/client';

export const mutation = gql`
  mutation UpdatePipelineVariable(
    $staged_revisions_pk_columns: staged_revisions_pk_columns_input!
    $staged_revisions_set_input: staged_revisions_set_input!
  ) {
    update_staged_revisions_by_pk(pk_columns: $staged_revisions_pk_columns, _set: $staged_revisions_set_input) {
      pipelineVars
      readyForDeploy
    }
  }
`;

export function buildVariables({ id, pipelineVars, readyForDeploy }) {
  return {
    staged_revisions_pk_columns: {
      id,
    },
    staged_revisions_set_input: {
      pipelineVars,
      readyForDeploy,
    },
  };
}
