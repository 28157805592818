export default {
  DESCRIPTION:
    'Use this sync injector to sync local storage keys from any domain where the tag is placed. Configure it with key names, and the server will receive their values using the ls name.',
  SINGLE_SELECT_DESCRIPTION: 'Local Storage',
  REQUEST_NAME: 'ls',
  REQUEST_INPUT_NAME: 'keyNames',
  FIELDS: [
    {
      type: 'TEXT',
      property: 'name',
      title: 'Storage Key',
      description: 'The name of the key that should be used to get the data',
      required: true,
      default: '',
      placeholder: 'e.g., localStoreKey',
    },
  ],
};
