import { v4 as uuid } from 'uuid';
import { validate } from 'parameter-validator';
import mergeQueries from '../../Apollo/merge-queries';
import permission from '../../Apollo/permissions';
import useTrackWithUser from '../../hooks/useTrackWithUser';

import {
  ConsentCategoryCreateLog,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/consent-category-created/audit-log-mutation-builder';

import { useMutation } from '../..';
import { GET_CONSENT_CATEGORIES } from '../useQueryConsentCategories';

import {
  mutation as insertConsentCategoryMutation,
  buildVariables as buildInsertVariables,
} from './insert-consent-category-mutation-builder';

export const combinedMutation = mergeQueries('InsertConsentCategoryAndAudit', [
  insertConsentCategoryMutation,
  ConsentCategoryCreateLog,
]);

export default function useInsertConsentCategory(options) {
  const [trackWithUser] = useTrackWithUser('adds_category');
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    successMsg: 'New Consent Category added.',
    context: {
      permission: permission.createConsentCategory,
    },
    refetchQueries: [{ query: GET_CONSENT_CATEGORIES }],
  });

  const insertConsentCategory = args => {
    validate(args, ['name', 'code']);
    return executeMutation(buildVariables(args)).then(response => {
      if (!response.errors) {
        const { code, id, name } = response.data.insert_consent_categories_one;
        trackWithUser({ consentCategoryId: id, name, code });
      }
    });
  };

  return [insertConsentCategory, response];
}

function buildVariables(args) {
  const id = uuid();
  return {
    variables: {
      ...buildInsertVariables({ id, ...args }),
      ...buildAuditLogVariables({ id, ...args }),
    },
  };
}
