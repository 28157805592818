import React from 'react';
import IntegrationCell from '../cells/integration';
import formatIfNan from './format-if-nan';
import { nameColumnProperties, dataColumnProperties, defaultProperties } from './apply-column-defaults';
import DROP_REASONS from '../../drop-reasons';
import { deliveryColumns, filterColumns } from './common-columns';

export default function buildIntegrationColumns({ showPipelineFilters }) {
  return [
    {
      name: 'Integration Name',
      selector: row => row.Name,
      cell: row => <IntegrationCell name={row.Name} starterkitId={row.StarterkitId} />,
      ...nameColumnProperties,
      ...defaultProperties,
    },
    ...filterColumns,
    {
      name: 'Pipeline Filter',
      id: DROP_REASONS.PIPELINE_LUA_FILTER,
      selector: row => row[DROP_REASONS.PIPELINE_LUA_FILTER],
      format: row => formatIfNan(row, DROP_REASONS.PIPELINE_LUA_FILTER),
      omit: !showPipelineFilters,
      ...dataColumnProperties,
      ...defaultProperties,
    },
    ...deliveryColumns,
  ];
}
