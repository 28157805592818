import { gql } from '@apollo/client';

export const mutation = gql`
  mutation useDashboardProxy(
    $clusters: jsonb!
    $pipelines: jsonb!
    $integrationIds: jsonb
    $dateRange: DateRange!
    $query: String!
  ) {
    dashboardProxy(
      clusters: $clusters
      pipelines: $pipelines
      integrationIds: $integrationIds
      dateRange: $dateRange
      query: $query
    ) {
      status
      data
    }
  }
`;

export function buildVariables({ clusters, pipelines, integrationIds, dateRange, query }) {
  const { since, until } = dateRange;
  since.setMilliseconds(0);
  until.setMilliseconds(0);

  return {
    clusters,
    pipelines,
    integrationIds,
    dateRange: {
      since: since.toISOString(),
      until: until.toISOString(),
    },
    query,
  };
}
