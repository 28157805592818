import { cloneDeep as _cloneDeep, mapValues as _mapValues, map as _map, assign as _assign } from 'lodash';
import { generateId } from '@ion/components';

export const replaceEventNameOnClone = (cloned, eventName) => {
  const copied = _cloneDeep(cloned);
  const replaced = _mapValues(copied, (v, k) => {
    const isTransformStanza = k === 'mappings' || k === 'expressions' || k === 'spreadings' || k === 'enrichments';

    if (!isTransformStanza) {
      return v;
    }

    const updated = _map(v, p => {
      return _assign(p, { opId: generateId(), event: eventName });
    });

    return updated;
  });

  return replaced;
};
