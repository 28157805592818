export function pipelineIntegrationConsent(data) {
  const { pipelineIntegrationConsentId, consentCategoryId } = data;
  return {
    pipeline_integration_consent: {
      on_conflict: { constraint: 'pipeline_integration_consents_pkey', update_columns: ['consentCategoryId'] },
      data: {
        id: pipelineIntegrationConsentId,
        consentCategoryId,
      },
    },
  };
}

export function stagedRevision(data) {
  const { stagedRevisionId, playbookId, pipelineVars, readyForDeploy, stagedForDelete } = data;
  return {
    staged_revision: {
      on_conflict: {
        constraint: 'pipeline_vars_pkey',
        update_columns: ['playbookId', 'pipelineVars', 'readyForDeploy', 'stagedForDelete'],
      },
      data: {
        id: stagedRevisionId,
        playbookId,
        pipelineVars,
        readyForDeploy,
        stagedForDelete,
      },
    },
  };
}
