import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  MrAppLayout,
  MrAppMain,
  MrH,
  MrForm,
  MrText,
  generateId,
  MrLinkButton,
  MrButton,
  MrInputCheckbox,
  useForm,
  MrIcon,
  MrSvg,
} from '@ion/components';
import SideNav from 'app/components/side-nav';

import { routes } from 'app/constants';
import { useQueryPipelines } from '@ion/api';
import submitAndRedirect from './submit-and-redirect';
import s from './index.module.scss';

import arrow from '../../assets/icon-arrow-right.svg';
import close from '../../assets/icon-close.svg';
import { parseBool } from '@ion/mrdash';

const SelectStageOrDeploy = () => {
  const [errorMessage, setErrorMessage] = useState(false);
  const history = useHistory();
  const formId = generateId();
  const { formState, setField } = useForm(formId);
  const { data: pipelines, loading: loadingPipelines } = useQueryPipelines();
  const onFormSubmit = () => {
    submitAndRedirect(formState, pipelines, routes, history, setErrorMessage);
  };
  return (
    <>
      <MrAppLayout
        header={
          <div className={s.header}>
            <MrLinkButton className={s.close} to={routes.pipelines}>
              <img src={close} />
              Close
            </MrLinkButton>

            <MrText className={s.title}>
              <MrIcon id={'icon-bulk-deploy'} />
              <span>Bulk Stage and Deploy</span>
            </MrText>

            <MrButton text="Next Step" testId={'selectStageOrDeploySubmit'} className={s.submit} formId={formId}>
              <img src={arrow} />
            </MrButton>
          </div>
        }
        sideNav={<SideNav />}
        title="Pipelines"
        description="Select Bulk Stage or Bulk Stage and Deploy"
        icon="pipelines"
      >
        <MrAppMain loading={loadingPipelines} styleNames="list">
          <div className={s.card}>
            <MrH h="h3" styleNames="noMargin">
              What type of action do you want to perform?
            </MrH>

            <MrForm className={s.form} onSubmit={onFormSubmit} id={formId}>
              <div
                className={
                  parseBool(formState.stageAndDeploy?.value) ? s.checkboxContainerChecked : s.checkboxContainer
                }
              >
                <MrInputCheckbox
                  styleNames="round blue"
                  name="stageAndDeploy"
                  testId={'stageAndDeployCheckbox'}
                  className={s.checkbox}
                  value={formState.stageAndDeploy?.value ?? true}
                  onChange={event => {
                    setErrorMessage(false);
                    setField(event);
                    setField({
                      target: {
                        name: 'deployOnly',
                        value: formState.stageAndDeploy?.value,
                      },
                    });
                  }}
                />
                <MrSvg className={s.optionIcon} id="stage-and-deploy" />
                <MrText className={s.option}>Bulk Stage & Deploy</MrText>
                <MrText className={s.optionDescription}>Update many Pipelines with the same Integration</MrText>
              </div>

              <div
                className={parseBool(formState.deployOnly?.value) ? s.checkboxContainerChecked : s.checkboxContainer}
              >
                <MrInputCheckbox
                  styleNames="round blue"
                  name="deployOnly"
                  testId={'deployOnlyCheckbox'}
                  className={s.checkbox}
                  value={formState.deployOnly?.value ?? false}
                  onChange={event => {
                    setErrorMessage(false);
                    setField(event);
                    setField({
                      target: {
                        name: 'stageAndDeploy',
                        value: formState.deployOnly?.value,
                      },
                    });
                  }}
                />
                <MrSvg className={s.optionIcon} id="bulk-deploy" />
                <MrText className={s.option}>Bulk Deploy</MrText>
                <MrText className={s.optionDescription}>Batch deploy already staged integrations</MrText>
              </div>
            </MrForm>
            {errorMessage && <MrText className={s.error}>Error: No currently deployable pipelines found</MrText>}
          </div>
        </MrAppMain>
      </MrAppLayout>
    </>
  );
};

SelectStageOrDeploy.propTypes = {};

export default SelectStageOrDeploy;
