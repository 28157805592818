import React from 'react';
import pt from 'prop-types';

import { Helmet } from 'react-helmet';
import { MrH, MrLinkButton, MrSvg } from '@ion/components';

import s from './index.module.scss';

const Copyright = () => <p className={s.copyright}>&copy; {new Date().getFullYear()} Nessa Labs, Inc.</p>;

const Template = ({ title, message, SupportingText, err, button: { buttonText, to } = {} }) => {
  return (
    <div className={s.page}>
      <Helmet>
        <title>{title} | MetaRouter</title>
      </Helmet>

      <div className={s.container}>
        <MrSvg id="metarouter-logo-text" />
        <MrH className={s.message}>{message}</MrH>

        <div className={s.supportingText}>
          <SupportingText />
        </div>

        {err && (
          <div className={s.error}>
            <b>Error</b> {err.error.cause.message}
          </div>
        )}

        {buttonText && to && (
          <MrLinkButton className={s.button} to={to}>
            &#8592; {buttonText}
          </MrLinkButton>
        )}
        <Copyright />
      </div>
    </div>
  );
};

Template.propTypes = {
  title: pt.string,
  message: pt.string,
  SupportingText: pt.any,
  err: pt.any,
  button: pt.shape({
    buttonText: pt.string,
    to: pt.string,
  }),
};

export default Template;
