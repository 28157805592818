import React, { useMemo } from 'react';

import pt from 'prop-types';
import { MrTruncateWithCount } from '@ion/components';

function formatItems(items) {
  return items.map((item, index) => {
    if (index === items.length - 1) return item;
    return `${item}, `;
  });
}

const MrTruncateTextWithCount = ({ items }) => {
  const formattedItems = useMemo(() => formatItems(items), [items]);

  return <MrTruncateWithCount items={formattedItems} />;
};

MrTruncateTextWithCount.propTypes = {
  items: pt.arrayOf(pt.string).isRequired,
};

export default MrTruncateTextWithCount;
