import React from 'react';
import pt from 'prop-types';

import IntegrationLogo from '@ion/app/src/components/integrations/integration-logo';

import s from '../index.module.scss';

const allowRowClicked = {
  'data-tag': 'allowRowEvents',
};

const IntegrationCell = ({ name, starterkitId }) => {
  return (
    <div className={s.cell} {...allowRowClicked}>
      <IntegrationLogo id={starterkitId} className={s.logo} {...allowRowClicked} />
      <div className={s.name} {...allowRowClicked}>
        {name}
      </div>
    </div>
  );
};

IntegrationCell.propTypes = {
  name: pt.string.isRequired,
  starterkitId: pt.string.isRequired,
};

export default IntegrationCell;
