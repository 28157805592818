import React from 'react';
import pt from 'prop-types';
import { Tooltip } from 'react-tippy';

import 'react-tippy/dist/tippy.css';
import './tippy-mr-theme.scss';

const DEFAULT_PROPS = {
  arrow: true,
  animation: 'none',
  duration: [0, 0],
  offset: '0, 10',
  hideDelay: 0,
  style: {
    display: 'inline-block',
  },
  theme: 'mr',
};

export default function MrTooltip({ children, text, ...tooltipProps }) {
  return (
    <Tooltip title={text} {...{ ...DEFAULT_PROPS, ...tooltipProps }}>
      {children}
    </Tooltip>
  );
}

MrTooltip.propTypes = {
  children: pt.node,
  text: pt.string,
};
