import { useLazyQueryOrganizationInvitations } from '@ion/api';

export function useOrganizationInvitationsTable() {
  const [getOrganizationInvitations, { invitations, pagination, loading }] = useLazyQueryOrganizationInvitations();

  return {
    onFetchPage: getOrganizationInvitations,
    data: invitations,
    pagination,
    loading,
  };
}
