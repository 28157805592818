import React from 'react';
import propTypes from 'prop-types';

import { Button, UserPlus01Icon } from '@metarouter-private/mr-mui';

import { useUserModalContext } from '../../context/UserModalContextProvider';
import { isAllowedToInviteUser } from './isAllowedToInviteUser';
import title from '../../fixtures/add/title';
import { USER_MODAL_OPTIONS } from '../../context/user-modal-options';

export default function InviteUserButton({ onClick }) {
  const { onClear, setUserFormModalOption } = useUserModalContext();
  const hasPermission = isAllowedToInviteUser();

  const handleOnClick = () => {
    onClear();
    setUserFormModalOption(USER_MODAL_OPTIONS.ADD);
    onClick();
  };

  return (
    <Button startIcon={<UserPlus01Icon />} size="large" disabled={!hasPermission} onClick={handleOnClick}>
      {title}
    </Button>
  );
}

InviteUserButton.propTypes = {
  onClick: propTypes.func.isRequired,
};
