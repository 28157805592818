import { isEmpty, xorWith, isEqual } from 'lodash';

/**
 * Determine if array and others are the equal.
 *
 * @param array
 * @param array
 * @returns {boolean}
 */
export default function isArrayEqual(array, others) {
  return isEmpty(xorWith(array, others, isEqual));
}
