import React from 'react';
import propTypes from 'prop-types';
import { afterOrgSwitch } from '@ion/app/src/after-org-switch';

import { Button, Tooltip } from '@metarouter-private/mr-mui';
import useOverrideOrganization from '@ion/api/auth0/organization-override/useOverrideOrganization';
import { useUserContext } from '@ion/user/context/UserContext';

export default function ActionCell({ row }) {
  const [overrideOrganization] = useOverrideOrganization({
    onCompleted: () => {
      afterOrgSwitch();
    },
  });
  const { isOrgOverride } = useUserContext();
  const disabledForOrg = row.metadata.orgOverride === 'disabled';
  let tooltip = '';

  if (isOrgOverride) {
    tooltip = 'Org override is currently enabled';
  } else if (disabledForOrg) {
    tooltip = `Org override is disabled for the ${row.display_name} organization`;
  }

  return (
    <Tooltip title={tooltip}>
      <span>
        <Button
          disabled={isOrgOverride || disabledForOrg}
          onClick={() => {
            overrideOrganization({ orgId: row.id });
          }}
        >
          Enable
        </Button>
      </span>
    </Tooltip>
  );
}

ActionCell.propTypes = {
  row: propTypes.shape({
    id: propTypes.string.isRequired,
    display_name: propTypes.string.isRequired,
    metadata: propTypes.shape({
      orgOverride: propTypes.string,
    }).isRequired,
  }).isRequired,
};
