import React from 'react';
import moment from 'moment';
import isNil from 'lodash/isNil';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';

import CalendarIcon from './calendar-icon';
import DayContents from './day-contents';
import transformDates from './transform-dates';

const DEFAULT_REACT_DATES_PROPS = {
  startDateId: 'start_date',
  endDateId: 'end_date',
  hideKeyboardShortcutsPanel: true,
  small: true,
  showClearDates: true,
  displayFormat: 'll',
  customInputIcon: <CalendarIcon />,
  renderDayContents,
};

function renderDayContents(day) {
  return <DayContents day={day.format('D')} />;
}

export function buildReactDatesProps({ state, onDatesChange, maxDate, minDate, allowSameDateSelection }) {
  const {
    dateRange: { startDate, endDate },
    focusedInput,
  } = state;

  return {
    ...DEFAULT_REACT_DATES_PROPS,
    startDate,
    endDate,
    onDatesChange: buildOnDatesChangeCallback({ state, onDatesChange }),
    focusedInput,
    onFocusChange: state.setFocusedInput,
    ...(maxDate && { maxDate: moment(maxDate) }),
    ...(minDate && { minDate: moment(minDate) }),
    ...buildIsOutsideRangeCallback({ minDate, maxDate }),
    ...buildInitialVisibleMonth(maxDate),
    ...(allowSameDateSelection && { minimumNights: 0 }),
  };
}

function buildOnDatesChangeCallback({ state, onDatesChange }) {
  return dateRange => {
    state.setDateRange(dateRange);
    if (onDatesChange) {
      onDatesChange(transformDates(dateRange));
    }
  };
}

export function buildIsOutsideRangeCallback({ minDate, maxDate }) {
  if (!minDate && !maxDate) {
    return {};
  }

  const isOutsideRange = day => {
    const momentDay = moment(day);
    let afterMinDate = isNil(minDate); // Vacuously true
    let beforeMaxDate = isNil(maxDate); // Vacuously true

    if (minDate && isInclusivelyAfterDay(momentDay, moment(minDate))) {
      afterMinDate = true;
    }
    if (maxDate && isInclusivelyBeforeDay(momentDay, moment(maxDate))) {
      beforeMaxDate = true;
    }

    return !afterMinDate || !beforeMaxDate;
  };

  return { isOutsideRange };
}

function buildInitialVisibleMonth(maxDate) {
  if (!maxDate) {
    return {};
  }

  return {
    initialVisibleMonth: () => {
      return moment(maxDate).subtract(1, 'month');
    },
  };
}
