import React from 'react';
import pt from 'prop-types';
import { camelCase as _camelCase } from 'lodash';
import cx from 'classnames';

import s from './icon.module.scss';

// this component uses the provided ID to display the corresponding SVG found in icon-defs.js
const MrIcon = ({ id, title, onClick, className, onMouseEnter, onMouseLeave, testId = 'MrIcon', fontSize, color }) => {
  const props = {
    title,
    onClick,
    onMouseEnter,
    onMouseLeave,
  };

  return (
    <span {...props} className={cx(className, s[fontSize])} onMouseEnter={onMouseEnter}>
      <svg data-test-id={testId} className={cx(s.icon, s[_camelCase(id)], s[fontSize], s[color])}>
        {title && <title>{title}</title>}
        <use xlinkHref={`#${id}`}></use>
      </svg>
    </span>
  );
};

MrIcon.propTypes = {
  id: pt.string.isRequired,
  title: pt.string,
  onClick: pt.func,
  className: pt.string,
  onMouseEnter: pt.func,
  onMouseLeave: pt.func,
  testId: pt.string,
  fontSize: pt.oneOf(['small', 'medium', 'large']),
  color: pt.string,
};

export default MrIcon;
