import React, { useState } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import { MrForm, MrInputText, MrButton, useForm, formValidators, generateId, MrLink, MrIcon } from '@ion/components';
import { useUpdatePipeline } from '@ion/api';
import s from './edit-field.module.scss';

const MrEditField = ({ children, text, inputProps, onSubmit, className, testId = 'MrEditField' }) => {
  const [edit, setEdit] = useState();
  const [hidden, setHidden] = useState(true);

  const { validateRequired } = formValidators;
  const formId = generateId();
  const { formState, resetError, validateField, submitForm, setField } = useForm(formId);

  const onFormSubmit = () => {
    const { data, isValid } = submitForm();

    if (isValid) {
      onSubmit(data.text);
      setEdit();
      setHidden(true);
    }
  };

  const [, { isAllowed: updatePipelinePermission }] = useUpdatePipeline();

  // TODO: would be nice to close the form onBlur, but for some reason, the onBlur event would fire onSubmit, preventing submission

  return (
    <div
      className={cx(s.editField, className)}
      data-test-id={testId}
      onMouseOver={() => setHidden(false)}
      onMouseLeave={() => setHidden(true)}
    >
      {children}
      {updatePipelinePermission && (
        <MrLink onClick={() => setEdit(true)} className={hidden ? s.hidden : s.editIcon}>
          <MrIcon id={'icon-edit'} className={s.icon} />
        </MrLink>
      )}

      {edit && (
        <MrForm onSubmit={onFormSubmit} id={formId} className={s.form}>
          <MrInputText
            onChange={setField}
            value={formState.text?.value ?? text ?? ''}
            className={s.detailsNameInput}
            onBlur={validateField}
            onInput={resetError}
            name="text"
            autoFocus
            validators={[validateRequired()]}
            errorMsg={formState.text?.errorMsg}
            {...inputProps}
          />
          <MrButton text="Save" />
          <MrButton
            text="&#x2715;"
            type="button"
            styleNames="red"
            onClick={() => {
              setEdit();
              setHidden(true);
            }}
          />
        </MrForm>
      )}
    </div>
  );
};

MrEditField.propTypes = {
  children: pt.node,
  onSubmit: pt.func,
  className: pt.string,
  text: pt.string,
  inputProps: pt.object,
  testId: pt.string,
};

export default MrEditField;
