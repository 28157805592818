import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ pipelineId, pipelineName, integrationId, integrationName }) {
  const action = actions.integration_added_to_pipeline;
  const description = `${integrationName ?? '-'} added to ${pipelineName ?? '-'}`;
  const debug_data = {
    pipelineId,
    pipelineName,
    integrationId,
    integrationName,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
