import React from 'react';
import propTypes from 'prop-types';
import EditUser from './edit-user/EditUser';
import UserKebabMenu from './user-kebab-menu/UserKebabMenu';
import { Stack } from '@metarouter-private/mr-mui';

export default function ActionCell({ row }) {
  return (
    <Stack direction="row">
      <EditUser row={row} />
      <UserKebabMenu row={row} />
    </Stack>
  );
}

ActionCell.propTypes = {
  row: propTypes.shape({
    invitationUrl: propTypes.string,
  }).isRequired,
};
