import React from 'react';
import pt from 'prop-types';

import MrButton from './../button/button';
import { saveFile } from './../../utils/downloader';

const MrDownloadButton = ({
  compressFile,
  buttonText = 'Download',
  filename,
  download,
  mimeType = 'text/javascript',
  disabled,
  className,
  testId = 'MrDownloadButton',
  compressionType = 'zip',
  trackDownload,
}) => {
  const onClick = () => {
    saveFile({
      compressFile: compressFile,
      mimeType: mimeType,
      content: download,
      filename: filename,
      compressionType: compressionType,
    });

    if (trackDownload) trackDownload();
  };

  return <MrButton className={className} onClick={onClick} text={buttonText} disabled={disabled} testId={testId} />;
};

MrDownloadButton.propTypes = {
  compressFile: pt.bool,
  buttonText: pt.string,
  filename: pt.string.isRequired,
  download: pt.oneOfType([pt.string, pt.object]).isRequired,
  mimeType: pt.string,
  disabled: pt.bool,
  className: pt.string,
  testId: pt.string,
  compressionType: pt.string,
  trackDownload: pt.func,
};

export default MrDownloadButton;
