import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import { isArray as _isArray } from 'lodash';

import s from './input-select-deprecated.module.scss';

const MultiSelectContainer = ({
  children,
  testId,
  outerRef,
  className,
  helperText,
  toggleOpen,
  label,
  disabled,
  selectedVariant,
  errorMsg,
  id,
  value,
  name,
}) => {
  const isMultiToggle = _isArray(value) ? { onMouseDown: toggleOpen } : { onClick: toggleOpen };
  return (
    <div
      data-test-id={testId}
      ref={outerRef}
      className={cx(className, 'react-select-container', s.input, helperText && s.hasHelperText)}
      {...isMultiToggle}
    >
      {label && <label>{label}</label>}

      <div className={cx(disabled ? s.disabled : '', selectedVariant)}>{children}</div>

      {/* hidden input stores the selected value for form submission */}
      <input type="hidden" id={id} name={name} value={value} />

      {helperText && <p className={s.helperText}>{helperText}</p>}
      {errorMsg && <p className={s.errorMsg}>{errorMsg}</p>}
    </div>
  );
};

MultiSelectContainer.propTypes = {
  children: pt.node,
  testId: pt.string,
  className: pt.string,
  outerRef: pt.any,
  helperText: pt.string,
  toggleOpen: pt.func,
  disabled: pt.bool,
  label: pt.string,
  selectedVariant: pt.oneOf(['green', 'orange']),
  errorMsg: pt.string,
  id: pt.string,
  value: pt.oneOfType([pt.string, pt.array, pt.object]),
  name: pt.string,
};

export default MultiSelectContainer;
