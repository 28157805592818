import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useUserState, { actions } from './useUserState';
import { useUserContext } from '../UserContext';

export const useLoginUser = ({ organization, invitation }) => {
  const { setState } = useUserContext();
  const { isAuthenticated, getIdTokenClaims, loginWithRedirect, isLoading, error } = useAuth0();
  const [state, dispatch] = useUserState();

  useEffect(() => {
    if (isAuthenticated && !state.isTokenValid) {
      getIdTokenClaims().then(idToken => {
        dispatch({
          type: actions.SET_USER_INFO,
          payload: {
            tokenInfo: idToken,
          },
        });
      });
    }

    if (!isAuthenticated && !isLoading && !error) {
      dispatch({
        type: actions.RESET_USER_INFO,
      });
      localStorage.setItem('redirectPath', window.location.pathname);
      const options = {
        // appState: { target: window.location.href}
      };

      if (organization && invitation) {
        options.organizationId = organization;
        options.invitation = invitation;
      }

      // TODO (Ohms 2022.12.01) - Investigate why using handleRedirectCallback is not working
      //    https://community.auth0.com/t/how-do-i-set-up-a-dynamic-allowed-callback-url/60268
      //    prefer that over using the localStorage
      loginWithRedirect(options).then();
    }
  }, [
    getIdTokenClaims,
    isAuthenticated,
    state.isTokenValid,
    dispatch,
    isLoading,
    loginWithRedirect,
    error,
    organization,
    invitation,
  ]);

  useEffect(() => {
    if (error) {
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          error: error,
        },
      });
    }
  }, [error, dispatch]);

  useEffect(() => {
    setState(state);
  }, [state, setState]);

  return { ...state };
};
