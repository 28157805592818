import { gql } from '@apollo/client';
import stagedRevision from '../stagedRevision';

export const mutation = gql`
  mutation CreateStagedRevision($staged_revisions_insert_input: staged_revisions_insert_input!) {
    insert_staged_revisions_one(object: $staged_revisions_insert_input) {
      ...stagedRevision
    }
  }
  ${stagedRevision}
`;

export function buildVariables({ playbookId, pipelineIntegrationId, pipelineVars, readyForDeploy }) {
  return {
    staged_revisions_insert_input: {
      playbookId,
      pipelineIntegrationId,
      pipelineVars,
      readyForDeploy,
    },
  };
}
