import AnalyticsJSBuilderPage from './pipelines/analyticsJSBuilder';
import SelectStageOrDeployPage from './pipelines/select-stage-or-deploy';
import BulkSelectRevisionsPage from './pipelines/bulk-select-revisions';
import BulkSelectPipelinesPage from './pipelines/bulk-select-pipelines';
import BulkDeployPipelinesPage from './pipelines/bulk-deploy-pipelines';

export default {
  AnalyticsJSBuilderPage,
  SelectStageOrDeployPage,
  BulkSelectRevisionsPage,
  BulkSelectPipelinesPage,
  BulkDeployPipelinesPage,
};
