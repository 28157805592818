import isString from 'lodash/isString';

// Original Comment:
// options may be an array of strings or objects in the shape of { text, value }
// transform the provided options into the shape expected by the react-select component
// ND Comment:
// This function doesn't make any sense. Every select component ever takes both
// a value and a label, we should refactor to not do this.
export default function normalizeOptions(options) {
  return options.map(normalizeOption);
}

export function normalizeOption(option) {
  if (isString(option)) {
    return {
      label: option,
      value: option,
    };
  }

  return {
    label: option.text,
    value: option.value,
    isDisabled: option.disabled ?? false,
  };
}
