import { camelCase as _camelCase } from 'lodash';

import { getParams } from '../../../get-params';
import validateRequiredParams from '../../validate-required-params';
import { globalModalStore } from '@ion/global';
import yaml from 'js-yaml';
import { downloadConnection } from './download-connection';
import { removeUiFields } from './remove-ui-fields';
import { saveFile } from '@ion/components';

const uiFields = [
  'expressionName',
  'opId',
  'opIndex',
  '_path',
  'event',
  'schema',
  '_hide',
  'pipelineVar',
  'attachmentKey',
  'applyDefault',
];

export const download = ({ playbookState, integration }) => {
  try {
    const params = getParams(playbookState.connectionJSON, playbookState.playbook);
    validateRequiredParams(params);
  } catch (e) {
    return globalModalStore.addNotification({
      title: 'Unable to download',
      message: 'Required parameters are missing. Please populate all required parameters and retry.',
      code: e,
    });
  }

  const content = {
    'connection.yaml': yaml.dump(downloadConnection(playbookState.connectionJSON, integration.starterkitId)),
    'playbook.yaml': yaml.dump(removeUiFields(playbookState.playbook, uiFields)),
  };
  saveFile({
    compressFile: true,
    mimeType: 'text/json',
    content: content,
    filename: `${_camelCase(integration.name)}.gz`,
  });
};
