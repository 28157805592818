import React from 'react';
import pt from 'prop-types';

import { useQueryIntegrationPlaybooks } from '@ion/api';
import { MrInputSelect, formValidators } from '@ion/components';

const { validateRequired } = formValidators;

export const RevisionSelect = ({ formState, validateField, onChange, integrationId }) => {
  const { data: integrationPlaybooksData, loading } = useQueryIntegrationPlaybooks({
    variables: { integrationId: integrationId },
  });

  if (loading) {
    return null;
  }

  const revisionsSelectOptions = integrationPlaybooksData
    ? integrationPlaybooksData.playbooks.map(playbook => {
        return { text: `Revision ${playbook.revision}`, value: playbook.id };
      })
    : [];

  return (
    <MrInputSelect
      options={revisionsSelectOptions}
      onBlur={validateField}
      validators={[validateRequired()]}
      name="revision"
      label="Select Revision"
      placeholder={integrationId ? 'Select...' : 'Please select an integration first...'}
      value={formState.revision.value}
      onChange={onChange}
      errorMsg={formState.revision.errorMsg}
      disabled={!integrationPlaybooksData}
      menuPosition="fixed"
      maxMenuHeight={260}
      alphabetized={false}
    />
  );
};
RevisionSelect.propTypes = {
  integrationId: pt.string,
  formState: pt.object.isRequired,
  validateField: pt.func.isRequired,
  onChange: pt.func.isRequired,
};
