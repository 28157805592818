import React from 'react';

export default function Dashboard() {
  return (
    <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 15.9988V8.99878M6 15.9988V12.9988M14 15.9988V10.9988M9.0177 1.76278L2.23539 7.0379C1.78202 7.39052 1.55534 7.56683 1.39203 7.78764C1.24737 7.98322 1.1396 8.20356 1.07403 8.43783C1 8.7023 1 8.98948 1 9.56383V16.7988C1 17.9189 1 18.4789 1.21799 18.9067C1.40973 19.2831 1.71569 19.589 2.09202 19.7808C2.51984 19.9988 3.07989 19.9988 4.2 19.9988H15.8C16.9201 19.9988 17.4802 19.9988 17.908 19.7808C18.2843 19.589 18.5903 19.2831 18.782 18.9067C19 18.4789 19 17.9189 19 16.7988V9.56383C19 8.98948 19 8.7023 18.926 8.43783C18.8604 8.20356 18.7526 7.98322 18.608 7.78764C18.4447 7.56683 18.218 7.39052 17.7646 7.03791L10.9823 1.76278C10.631 1.48953 10.4553 1.3529 10.2613 1.30038C10.0902 1.25404 9.9098 1.25404 9.73865 1.30038C9.54468 1.3529 9.36902 1.48953 9.0177 1.76278Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
