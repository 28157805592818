import React, { useState, useMemo } from 'react';
import pt from 'prop-types';

import { MrAppLayout, MrAppMain, MrSearch } from '@ion/components';

import SideNav from 'app/components/side-nav';
import Header from 'app/components/app-header';
import s from '../index.module.scss';
import StarterkitCard from './starterkit-card';
import StarterKits from '../../starterkits/starterkits-state';
import NewIntegrationForm from '../form';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from '../../launch-darkly/is-feature-enabled';

const sortByName = function (a, b) {
  return a.friendlyName.localeCompare(b.friendlyName);
};

function filterDraftKits(kits, showBetaStarterKits) {
  if (showBetaStarterKits) {
    return kits.filter(kit => !kit.metadata?.isDraft).sort(sortByName);
  }
  return kits.filter(kit => !kit.metadata?.isDraft && !kit.metadata?.isBeta).sort(sortByName);
}

const NewIntegrationPage = ({ location }) => {
  const featureFlags = useFlags();
  const showBetaStarterKits = isFeatureEnabled({ featureFlags, flagName: 'ecmIntegrationsBetaStarterKits' });

  const kits = useMemo(() => filterDraftKits(StarterKits().kits, showBetaStarterKits), [showBetaStarterKits]);

  const { starterkitId } = location.state ?? '';
  // sort integrations by friendlyName - https://stackoverflow.com/a/8900922

  const [starterkits, setStarterkits] = useState(kits);

  // retrieve selected integration starterkit from starterkits data
  const starterkit = kits?.length ? kits.find(kit => kit.id === starterkitId) : null;

  return (
    <MrAppLayout
      title="New Integration"
      sideNav={<SideNav />}
      header={<Header icon="integrations" title="New Integration" route="integrations" />}
      description="Choose an integration playbook from our library, or create one completely from scratch."
      icon="integrations"
    >
      {starterkitId && <NewIntegrationForm starterkitId={starterkitId} starterkit={starterkit} />}
      {!starterkitId && (
        <MrAppMain
          styleNames="list"
          header={
            <>
              <MrSearch
                placeholder="Search Integrations"
                data={kits}
                fields={['friendlyName']}
                onChange={setStarterkits}
              />
            </>
          }
        >
          <div className={s.container}>
            {!starterkitId && starterkits?.length > 0 && (
              <div className={s.list}>
                {starterkits.map((starterkit, index) => {
                  return (
                    <StarterkitCard
                      key={index}
                      starterkitId={starterkit.id}
                      friendlyName={starterkit.friendlyName}
                      isBeta={starterkit.metadata.isBeta}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </MrAppMain>
      )}
    </MrAppLayout>
  );
};

NewIntegrationPage.propTypes = {
  location: pt.object,
};

export default NewIntegrationPage;
