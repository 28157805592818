import React from 'react';
import pt from 'prop-types';

import s from '../index.module.scss';
import { MrSvg } from '@ion/components';

const allowRowClicked = {
  'data-tag': 'allowRowEvents',
};

const PipelineCell = ({ name }) => {
  return (
    <div className={s.cell} {...allowRowClicked}>
      <div className={s.avatar} {...allowRowClicked}>
        <MrSvg id="avatar" className={s.avatar} {...allowRowClicked} />
      </div>
      <div className={s.name} {...allowRowClicked}>
        {name}
      </div>
    </div>
  );
};

PipelineCell.propTypes = {
  name: pt.string.isRequired,
};

export default PipelineCell;
