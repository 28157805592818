import { useMemo } from 'react';
import { useQueryRoles } from '@ion/api';

export default function useFormRoleSelect() {
  const { data: roles, loading: loading } = useQueryRoles();
  let label = 'Permission Set';
  const options = useMemo(() => {
    return roles.auth0_roles.map(role => {
      return { label: role.name, value: role.id };
    });
  }, [roles.auth0_roles]);

  if (loading) {
    label = 'Loading...';
  } else if (!options.length) {
    label = 'No Roles Available';
  }

  return { label, options };
}
