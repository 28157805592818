import { gql } from '@apollo/client';

const removePipelineFragment = gql`
  fragment removePipeline on pipelines {
    id
  }
`;

export const mutation = gql`
  mutation RemovePipeline(
    $pipelines_pk_columns: pipelines_pk_columns_input!
    $pipelines_set_input: pipelines_set_input!
  ) {
    update_pipelines_by_pk(pk_columns: $pipelines_pk_columns, _set: $pipelines_set_input) {
      ...removePipeline
    }
  }
  ${removePipelineFragment}
`;

export function buildVariables({ pipelineId }) {
  return {
    pipelines_pk_columns: {
      id: pipelineId,
    },
    pipelines_set_input: {
      deletedAt: 'now()', // Sets to current db time.
    },
  };
}
