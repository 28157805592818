import React from 'react';
import pt from 'prop-types';

import { components } from 'react-select';

import { MrInputCheckbox } from '../../../index';

import { find as _find } from 'lodash';

const GroupHeading = ({ hasSelectedOption, ...props }) => {
  const minus = 'minus';
  const {
    data: { options, label },
    selectProps: { value, isOptionDisabled },
  } = props;
  const isAllSelected = options.every(o => _find(value, o));

  const checkBoxProps = {
    value: hasSelectedOption,
    name: 'multi-select',
    onChange: () => {},
    ...(!isAllSelected && { checkboxIcon: minus }),
    ...(isOptionDisabled() && { disabled: isOptionDisabled() }),
  };

  return (
    <components.GroupHeading {...props} isoptiondisabled={isOptionDisabled()?.toString()}>
      <MrInputCheckbox {...checkBoxProps} />
      <span>{label}</span>
    </components.GroupHeading>
  );
};

GroupHeading.propTypes = {
  data: pt.shape({
    options: pt.array,
    label: pt.string,
  }),
  selectProps: pt.shape({
    value: pt.array,
    isOptionDisabled: pt.func,
  }),
  hasSelectedOption: pt.bool,
};

export default GroupHeading;
