import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputSelectDeprecated } from '@ion/components';

import { useFormContext } from 'app/context/form-context';
const { validateRequired } = formValidators;

const Timestamp = ({ opId, _path, timestamp }) => {
  const { setField, validateField, formState } = useFormContext();

  return (
    <>
      {((name = `${opId}-epoch-${_path}`) => (
        <MrInputSelectDeprecated
          name={name}
          options={[{ text: 'epoch', value: 'epoch' }]}
          value="epoch"
          onChange={setField}
          onBlur={validateField}
          errorMsg={formState[name]?.errorMsg}
          placeholder
          label="type"
          validators={[validateRequired()]}
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-timestamp-${_path}`) => (
        <MrInputSelectDeprecated
          name={name}
          options={[
            { text: 'seconds', value: 'seconds' },
            { text: 'milliseconds', value: 'milliseconds' },
          ]}
          value={timestamp}
          onChange={setField}
          onBlur={validateField}
          errorMsg={formState[name]?.errorMsg}
          placeholder="select one"
          label="format"
          validators={[validateRequired()]}
          labelPosition="top"
        />
      ))()}
    </>
  );
};

Timestamp.propTypes = {
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
  timestamp: pt.oneOf(['seconds', 'milliseconds']).isRequired,
};

export default Timestamp;
