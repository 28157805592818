import React from 'react';

import pt from 'prop-types';
import { useOverlay, usePreventScroll, useModal, OverlayContainer } from '@react-aria/overlays';
import { FocusScope } from '@react-aria/focus';
import cx from 'classnames';

import s from './modal.module.scss';

const ModalDialog = ({ styleNames = '', className, children, testId = 'MrModal', ...props }) => {
  // Handle interacting outside the dialog and pressing the Escape key to close the modal.
  const ref = React.useRef();
  const { overlayProps, underlayProps } = useOverlay(props, ref);

  // Prevent scrolling while the modal is open, and hide content outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  return (
    <div
      className={cx(s.modal, className, styleNamesToClassNames(styleNames))}
      data-test-id={testId}
      {...underlayProps}
    >
      <FocusScope contain restoreFocus autoFocus>
        <div {...overlayProps} {...modalProps} ref={ref} className={s.content}>
          {children}
        </div>
      </FocusScope>
    </div>
  );
};

const MrModal = ({ children, closeModal, ...props }) => {
  return (
    <>
      <OverlayContainer>
        <ModalDialog {...props} isOpen onClose={closeModal} isDismissable>
          {children}
        </ModalDialog>
      </OverlayContainer>
    </>
  );
};

ModalDialog.propTypes = {
  styleNames: pt.string,
  className: pt.string,
  children: pt.node,
  testId: pt.string,
};

MrModal.propTypes = {
  children: pt.node,
  closeModal: pt.func.isRequired,
};

export default MrModal;

function styleNamesToClassNames(styleNames) {
  return styleNames.split(' ').map(c => s[c]);
}
