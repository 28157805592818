import { useMemo } from 'react';
import { useQueryRoles } from '@ion/api';
import { useMutation } from '../../index';
import mapAuth0UserRoleData from '../organization-members/map-auth0-user-role-data';
import {
  mutation,
  buildVariables as buildCreateOrganizationInvitationVariables,
} from './auth0-create-organization-invitation-mutation-builder';
import permission from '../../Apollo/permissions';
import useTrackWithUser from '../../hooks/useTrackWithUser';
import {
  mutation as userInvitedMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/user-invited/audit-log-mutation-builder';
import mergeQueries from '../../Apollo/merge-queries';
import { ORGANIZATION_INVITATIONS_QUERY_NAME } from './auth0-organization-invitations-query-builder';

export const combinedMutation = mergeQueries('CreateOrganizationInvitationAndAudit', [mutation, userInvitedMutation]);

export default function useCreateOrganizationInvitation({ onCompleted, ...options } = {}) {
  const [trackWithUser] = useTrackWithUser('user_invited');
  const {
    data: { auth0_roles },
    loading: auth0RolesLoading,
  } = useQueryRoles();
  const [executeMutation, response] = useMutation(combinedMutation, {
    context: { permission: permission.createUsers },
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(getResponseBody({ data }));
      }
    },
    refetchQueries: [ORGANIZATION_INVITATIONS_QUERY_NAME],
    ...options,
  });

  const roleListMap = useMemo(
    () => Object.assign({}, ...auth0_roles.map(role => ({ [role.name]: role.id }))),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth0RolesLoading]
  );

  const createOrganizationInvitation = ({ email, roleId }) => {
    const { roleIds, roleName } = mapAuth0UserRoleData(roleListMap, roleId);
    return executeMutation({
      variables: buildVariables({ email, roleIds, roleName }),
    }).then(response => {
      if (!response.errors) {
        trackWithUser({ invitee: email });
      }
      return response;
    });
  };

  return [createOrganizationInvitation, response];
}

function getResponseBody(response) {
  return response.data.auth0_create_organization_invitation;
}

export function buildVariables({ email, roleIds, roleName }) {
  return {
    ...buildCreateOrganizationInvitationVariables({ email, roleIds }),
    ...buildAuditLogVariables({ email, roleName }),
  };
}
