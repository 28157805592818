const buildDisabledMessage = rowInfo => {
  let message = null;

  const identitySyncsCount = rowInfo.analyticsFileIdentitySyncs.aggregate.count;
  const integrationsCount = rowInfo.pipelineIntegrationConsents.aggregate.count;

  const identitySyncsMessage =
    identitySyncsCount > 1 ? `${identitySyncsCount} Identity Syncs` : `${identitySyncsCount} Identity Sync`;
  const integrationsMessage =
    integrationsCount > 1 ? `${integrationsCount} Integrations` : `${integrationsCount} Integration`;

  if (identitySyncsCount > 0 && integrationsCount > 0) {
    message = `Attached to ${identitySyncsMessage} and ${integrationsMessage}`;
  } else if (identitySyncsCount > 0) {
    message = `Attached to ${identitySyncsMessage}`;
  } else if (integrationsCount > 0) {
    message = `Attached to ${integrationsMessage}`;
  }

  return message;
};

export default buildDisabledMessage;
