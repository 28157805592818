import React from 'react';
import propTypes from 'prop-types';
import { useFormFieldContext } from './form-field-context-provider';

export default function HiddenInput({ name, value }) {
  const { id } = useFormFieldContext();
  return <input type="hidden" id={id} name={name} value={value ?? ''} />;
}

HiddenInput.propTypes = {
  name: propTypes.string.isRequired,
  value: propTypes.string,
};
