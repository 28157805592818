import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import styles from './index.module.scss';
import UserIcon from './user-icon';

export default function UserCell({ user }) {
  return (
    <div>
      <div className={styles.cellItem}>
        <UserIcon />
      </div>
      <div className={classNames(styles.email, styles.cellItem)}>{user.email}</div>
    </div>
  );
}

UserCell.propTypes = {
  user: propTypes.shape({
    email: propTypes.string.isRequired,
  }),
};
