import { useCallback } from 'react';
import searchAuditLogsFetchPage from '@ion/api/audit-logs/search/search-audit-logs-fetch-page';
import { searchTextToActions } from '../search-text-to-actions';
import useApolloClient from '@ion/api/hooks/useApolloClient';

export function useAuditLogsFetchPage({ searchText, dateRange, timestampSortOrder }) {
  const apolloClient = useApolloClient();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    buildAuditLogsFetchPage(apolloClient, {
      searchText,
      dateRange,
      timestampSortOrder,
    }),
    [searchText, dateRange, timestampSortOrder]
  );
}

export function buildAuditLogsFetchPage(apolloClient, { searchText, dateRange, timestampSortOrder }) {
  const options = {
    ...(searchText && optionsFromSearchText(searchText)),
    dateRange,
    sortOrder: timestampSortOrder,
  };

  const fetchPage = searchAuditLogsFetchPage(apolloClient, options);

  return pagination => {
    return fetchPage(pagination).then(transformResponse);
  };
}

export function optionsFromSearchText(searchText) {
  return {
    email: searchText,
    description: searchText,
    actions: searchTextToActions(searchText),
  };
}

export function transformResponse({ auditLogs, pagination }) {
  const data = auditLogs.map(({ user, org, action, description, timestamp }) => {
    return {
      user,
      org,
      action,
      description,
      timestamp,
    };
  });

  return {
    data,
    pagination,
  };
}
