import React, { useEffect } from 'react';
import propTypes from 'prop-types';

import MrDataTable from '../data-table/mr-data-table';
import useMrPageDataTableState from './state';
import fetchPageWrapper from './fetch-page-wrapper';
import buildDataTableProps from './build-data-table-props';
import MrPageDataTableContextProvider from './mr-page-data-table-context-provider';

/*
  Wrapper around MrDataTable for server-side paginated data tables.
  Takes in a fetchPage function and takes care of fetching data,
  setting state and handling page change events. Takes an onSort
  callback which will be called if a column is sorted.

  Google react-data-table-component docs for more info.
 */

const PER_PAGE = 10;

export default function MrPageDataTable({
  columns,
  fetchPage,
  onSort,
  onError,
  loadingMessage = null,
  perPage = PER_PAGE,
  ...props
}) {
  const state = useMrPageDataTableState();

  useEffect(() => {
    state.beforePageFetched();
    fetchPageWrapper(fetchPage)({
      page: state.currentPage,
      perPage,
    })
      .then(({ data, pagination: { count } }) => {
        state.pageFetched({ data, pagination: { count } });
      })
      .catch(onError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentPage, fetchPage]);

  const dataTableProps = {
    columns,
    onSort,
    loadingMessage,
    ...buildDataTableProps({ state, perPage }),
    ...props,
  };

  return (
    <MrPageDataTableContextProvider pagination={{ count: state.pageCount }}>
      <MrDataTable {...dataTableProps} />
    </MrPageDataTableContextProvider>
  );
}

MrPageDataTable.propTypes = {
  columns: propTypes.arrayOf(propTypes.object).isRequired,
  fetchPage: propTypes.func.isRequired, // ({ page, perPage }) => ({ data, pagination: { count })
  onSort: propTypes.func,
  onError: propTypes.func,
  loadingMessage: propTypes.string,
  perPage: propTypes.number,
};
