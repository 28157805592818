import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './../button/button.module.scss';
import Link from '../link/link';

const MrLinkButton = ({ className, styleNames = 'blueMed', testId = 'MrLinkButton', ...props }) => {
  return (
    <Link
      className={cx(
        s.button,
        styleNames?.split(' ').map(c => s[c]),
        className
      )}
      data-test-id={testId}
      {...props}
    />
  );
};

MrLinkButton.propTypes = {
  children: pt.node,
  className: pt.string,
  styleNames: pt.string,
  testId: pt.string,
};

export default MrLinkButton;
