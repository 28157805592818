export function transformConsentCategories(data) {
  if (!data?.consentCategories) {
    return [];
  }
  return data.consentCategories.map(category => {
    const displayName = `${category.code} - ${category.name}`;
    return {
      displayName,
      ...category,
    };
  });
}
