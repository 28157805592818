import { gql } from '@apollo/client';
import stagedRevision from '../stagedRevision';

export const mutation = gql`
  mutation RemoveStagedRevision($id: uuid!) {
    delete_staged_revisions_by_pk(id: $id) {
      ...stagedRevision
    }
  }
  ${stagedRevision}
`;

export function buildVariables({ id }) {
  return { id };
}
