import pt from 'prop-types';
import React from 'react';

import { MrH, MrInputCheckbox, MrWithTooltip } from '@ion/components';
import { Accordion, AccordionDetails, AccordionSummary } from '@metarouter-private/mr-mui';
import AnalyticsBuilderAdvancedSettings, { STORAGE_TYPE } from './analytics-builder-advanced-settings';
import s from './index.module.scss';

const AnalyticsBuilderAdvancedSettingsAccordion = ({ formState, setField, validateField, resetError }) => {
  return (
    <Accordion color="neutral">
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <MrH h="h5" sans styleNames="noMargin">
          Advanced Settings
        </MrH>
      </AccordionSummary>
      <AccordionDetails>
        <div className={s.checkboxContainer}>
          <MrInputCheckbox
            value={formState.fileCompression?.value ?? false}
            labelPosition="right"
            onChange={setField}
            name="fileCompression"
            label="File compression"
            className={s.checkbox}
          />
          <p className={s.checkboxHelperText}>Reduce the file size with compression</p>
        </div>
        <div className={s.checkboxContainer}>
          <MrWithTooltip message="Allow MetaRouter to set HTTP (server) cookies on user browsers in addition to javascript cookies. This improves the lifetime of the MetaRouter anonymousId in certain contexts where javascript cookies are limited. Note that some additional sync latency may occur.">
            <MrInputCheckbox
              value={formState.httpCookie?.value ?? false}
              labelPosition="right"
              onChange={setField}
              name="httpCookie"
              label="Activate Enhanced Tracking with HTTP Cookies"
              className={s.checkbox}
            />
          </MrWithTooltip>
          <p className={s.checkboxHelperText}>Increase cookie lifetime. Adds an extra POST call.</p>
        </div>
        <AnalyticsBuilderAdvancedSettings
          storageTypeValue={formState?.storageType?.value ?? STORAGE_TYPE.COOKIES}
          formState={formState}
          setField={setField}
          validateField={validateField}
          resetError={resetError}
        />
      </AccordionDetails>
    </Accordion>
  );
};

AnalyticsBuilderAdvancedSettingsAccordion.propTypes = {
  formState: pt.object,
  setField: pt.func,
  validateField: pt.func,
  resetError: pt.func,
  storageTypeValue: pt.string,
};

export default AnalyticsBuilderAdvancedSettingsAccordion;
