import React, { useEffect, useState } from 'react';
import { routes } from 'app/constants';
import { useUserContext } from '@ion/user';
import useInsertAuthLoggedIn from '@ion/api/audit-logs/auth-logged-in/useInsertAuthLoggedIn';
import { MrLoading } from '@ion/components';
import { Redirect } from 'react-router-dom';
import propTypes from 'prop-types';
import { generateTimestamp } from '@ion/api/audit-logs/generate-timestamp';
import * as Sentry from '@sentry/browser';
import contexts from '@ion/api/audit-logs/auth-contexts';

export default function BaseLoginRedirectPage({
  auditLogContext,
  redirectTo = routes.home,
  shouldAudit = () => true,
  ...props
}) {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { user } = useUserContext();
  const [insertAuditLog] = useInsertAuthLoggedIn({
    onCompleted: () => setShouldRedirect(true),
    onError: error => {
      captureSentryError({ error, user });
      setShouldRedirect(true);
    },
  });

  useEffect(() => {
    if (shouldAudit(props)) {
      insertAuditLog({ userId: user?.id, context: auditLogContext });
    } else {
      setShouldRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!shouldRedirect) {
    return <MrLoading />;
  }

  // Where should this redirect to?
  return <Redirect to={redirectTo} />;
}

BaseLoginRedirectPage.propTypes = {
  auditLogContext: propTypes.oneOf(Object.values(contexts)).isRequired,
  redirectTo: propTypes.string,
  shouldAudit: propTypes.func,
  location: propTypes.shape({
    search: propTypes.string.isRequired,
  }).isRequired,
};

export function captureSentryError({ error, user }) {
  const errorMessage =
    `Unable to create Auth Logged In Audit Log for ` +
    `user: ${user?.id} at: ${generateTimestamp()}; error=${error.message}`;
  Sentry.captureException(new Error(errorMessage));
}
