import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import styles from './two-pane-layout.module.scss';

const MrTwoPaneLayout = ({ className, testId = 'MrTwoPaneLayout', paneOne, paneTwo, paneOneClassName }) => {
  return (
    <div className={cx(styles.container, className)} data-test-id={testId}>
      <div className={cx(styles.paneOne, paneOneClassName)}>{paneOne}</div>
      {paneTwo}
    </div>
  );
};

MrTwoPaneLayout.propTypes = {
  className: pt.string,
  testId: pt.string,
  paneOne: pt.node,
  paneTwo: pt.node,
  paneOneClassName: pt.string,
};

export default MrTwoPaneLayout;
