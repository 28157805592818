import React from 'react';
import Actions from './actions';
import { ActionColumn, columns, CategoryColumn } from './columns';

export default function buildViewableDataTableProps(canTakeActions, onRowClicked, hasConsentMvp) {
  let tableColumns = columns;

  if (hasConsentMvp) {
    tableColumns = [...tableColumns, CategoryColumn];
  }

  if (canTakeActions) {
    return {
      actions: <Actions />,
      columns: [...tableColumns, ActionColumn],
      pointerOnHover: true,
      onRowClicked,
    };
  }

  return {
    columns: tableColumns,
  };
}
