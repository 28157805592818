import React, { useEffect } from 'react';

import { MrTwoPaneLayout } from '@ion/components';
import RedirectFeatureGate from '../../launch-darkly/gates/redirect-feature-gate';
import { CONSENT_CATEGORY_FLAGS } from '../../context/gated-consent-categories-context';

import { ConsentCategoriesTable, Documentation, ConsentCategoryModal } from './components';
import useTrackWithUser from '@ion/api/hooks/useTrackWithUser';

export const ConsentCategoryPage = () => {
  const [trackWithUser] = useTrackWithUser('consent_settings_page_view');

  useEffect(() => {
    trackWithUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RedirectFeatureGate flagName={CONSENT_CATEGORY_FLAGS.MVP}>
        <ConsentCategoryModal />
        <MrTwoPaneLayout paneOne={<Documentation />} paneTwo={<ConsentCategoriesTable />} />
      </RedirectFeatureGate>
    </>
  );
};
