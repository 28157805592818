import auditLogMutation from '../insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const ConsentCategoryCreateLog = auditLogMutation;

export function buildVariables({ id, name, code }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      id,
      name,
      code,
    }),
  };
}
