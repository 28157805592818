import React from 'react';
import propTypes from 'prop-types';
import BaseLoginRedirectPage from './base-login-redirect';
import contexts from '@ion/api/audit-logs/auth-contexts';

export default function LoginRedirectPage(props) {
  return <BaseLoginRedirectPage shouldAudit={probablyFromAuth0} auditLogContext={contexts.auth0} {...props} />;
}

LoginRedirectPage.propTypes = {
  location: propTypes.shape({
    search: propTypes.string.isRequired,
  }).isRequired,
};

export function probablyFromAuth0({ location }) {
  // On login redirect, Auth0 sends `code` as a query param.
  return location.search.includes('code=');
}
