import React from 'react';
import pt from 'prop-types';

import { MrH, MrText } from '@ion/components';
import { IntegrationSelect } from './IntegrationSelect';

const AddIntegrationFields = ({
  setField,
  formState,
  validateField,
  pipeline,
  excludedIntegrationId,
  removeFields,
}) => {
  const onChangeIntegration = event => {
    setField(event, { otherFields: [{ name: `revision`, value: '', errorMsg: null }] });
    removeFields(Object.keys(formState).filter(field => field.startsWith('pipeline-variable')));
  };
  return (
    <>
      <MrText styleNames="text marginBottom">
        Select one of your Integration Revisions below to add it to a draft update of your Pipeline. You’ll still need
        to deploy for these updates to be sent to your Cluster.
      </MrText>
      <MrText styleNames="sans noMargin" h="h4">
        Pipeline name
      </MrText>
      <MrH h="h2" styleNames="sans">
        {pipeline.name}
      </MrH>
      <IntegrationSelect
        onChange={onChangeIntegration}
        formState={formState}
        validateField={validateField}
        pipeline={pipeline}
        excludedIntegrationId={excludedIntegrationId}
      />
    </>
  );
};
AddIntegrationFields.propTypes = {
  setField: pt.func.isRequired,
  formState: pt.object.isRequired,
  validateField: pt.func.isRequired,
  pipeline: pt.object.isRequired,
  excludedIntegrationId: pt.string,
  removeFields: pt.func.isRequired,
};
export default AddIntegrationFields;
