import React from 'react';

import { MrAppLayout, MrAppMain } from '@ion/components';
import SideNav from 'app/components/side-nav';
import AppHeader from 'app/components/app-header';

import GetSchemaSummary from 'app/event-schemas/get-schema-summary';
import Header from 'app/event-schemas/header';
import ReportList from 'app/event-schemas/list';
import ReportPanel from 'app/event-schemas/panel';
import NewSchemaReport from 'app/event-schemas/new-report';

import useEventSchemasState from 'app/event-schemas/state';

import s from './header/index.module.scss';

const EventSchemasPage = () => {
  const state = useEventSchemasState();

  return (
    <MrAppLayout
      title="Schema Report"
      description="Run a report to analyze the historical schema combinations MetaRouter has seen for a given cluster."
      sideNav={<SideNav />}
      header={<AppHeader icon="schemaReport" title="Schema Report" route="schema-report" />}
    >
      {!state.cluster && (
        <MrAppMain>
          <div className={s.container}>
            <NewSchemaReport
              updateReportSettings={state.updateReportSettings}
              onClose={() => {}}
              cluster={state.cluster}
            />
          </div>
        </MrAppMain>
      )}
      {state.cluster && (
        <MrAppMain
          header={
            <Header cluster={state.cluster} timeframeHours={state.timeframeHours} resetReports={state.resetReports} />
          }
        >
          <GetSchemaSummary
            cluster={state.cluster}
            earliestSeen={state.earliestSeen}
            timeframeHours={state.timeframeHours}
            setSchemaSummary={state.setReports}
            setLoadingSummary={state.loadingReports}
          />
          <ReportList
            loading={state.isLoadingReports}
            reports={state.reports}
            setSelectedReport={state.setSelectedReport}
            selectedReport={state.selectedReport}
            sortReports={state.sortReports}
          />
          {state.selectedReport && (
            <ReportPanel
              cluster={state.cluster}
              earliestSeen={state.earliestSeen}
              timeframeHours={state.timeframeHours}
              report={state.selectedReport}
              clearReport={() => state.setSelectedReport(null)}
            />
          )}
        </MrAppMain>
      )}
    </MrAppLayout>
  );
};

export default EventSchemasPage;
