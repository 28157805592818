import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './card.module.scss';

const MrCard = ({ children, top, bottom, styleNames, className, closeBottom, testId = 'MrCard', ...props }) => {
  return (
    <div
      {...props}
      className={cx(
        s.card,
        (top || bottom) && s.noPadding,
        styleNames?.split(' ').map(c => s[c]),
        className
      )}
      data-test-id={testId}
    >
      {top && <div className={s.top}>{top}</div>}
      {children}
      {bottom && <div className={cx(s.bottom, closeBottom ? s.closeBottom : null)}>{bottom}</div>}
    </div>
  );
};

MrCard.propTypes = {
  children: pt.node,
  top: pt.node,
  bottom: pt.node,
  styleNames: pt.string,
  className: pt.string,
  testId: pt.string,
  closeBottom: pt.bool,
};

export default MrCard;
