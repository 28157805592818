import React from 'react';
import pt from 'prop-types';

import { useUpdateIntegration, useQueryIntegrationPlaybooks } from '@ion/api';

import usePlaybookNavState from 'app/playbook-editor/nav-state.js';
import StarterKits from '../starterkits/starterkits-state';
import usePlaybookState from './playbook-state';

import GetPlaybooks from './get-playbooks';
import { MrAppLayout, MrEditField, MrHeaderTabs, MrLink, MrTab, MrTabList, MrTabPanel } from '@ion/components';
import { globalModalStore } from '@ion/global';

import PlaybookEditor from 'app/playbook-editor/parameters';
import PlaybookRevisionHeader from 'app/playbook-editor/revision-header';
import Overview from 'app/playbook-editor/overview';
import Notes from 'app/playbook-editor/notes';
import SideNav from 'app/components/side-nav';
import Header from 'app/components/app-header';
import EventMappingsTab from './tabs/event-mappings';
import { TransformPlaybookContextProvider } from '../context/transform-playbook-context';

const PlaybookEditorPage = ({ location }) => {
  // probably move this into the nav state
  const { integrationId } = location.state || {};
  const [updateIntegrationName, { isAllowed }] = useUpdateIntegration();

  // Getting all playbooks for revisions dropdown
  const { data: integration } = useQueryIntegrationPlaybooks({
    variables: { integrationId },
  });

  const playbookState = usePlaybookState();
  const navState = usePlaybookNavState();

  const { kits, kitById, version } = StarterKits();
  const kit = kitById(kits, integration?.starterkitId);

  return (
    <TransformPlaybookContextProvider playbook={playbookState.playbook}>
      <MrAppLayout
        title="Integrations"
        icon="integrations"
        sideNav={<SideNav />}
        header={
          <Header
            icon="integrations"
            title="Integrations"
            subTitle={
              <MrEditField
                testId="editIntegration"
                text={integration?.name}
                onSubmit={name => {
                  if (playbookState.hasChanges) {
                    return globalModalStore.addNotification({
                      title: 'Unable to save integration name',
                      message: 'Save a revision prior to changing the integration name.',
                    });
                  }
                  updateIntegrationName({ integrationId: integrationId, name: name });
                }}
                inputProps={{ placeholder: 'integration name' }}
              >
                <MrLink style={{ color: '#1C1134' }} onClick={e => e.preventDefault}>
                  {integration?.name}
                </MrLink>
              </MrEditField>
            }
          />
        }
      >
        {integration && (
          <GetPlaybooks
            location={location}
            integration={integration}
            navState={navState}
            setRevision={playbookState.setRevision}
            kit={kit}
            opIndices={playbookState.opIndices}
          />
        )}
        {playbookState.playbook && (
          <>
            <MrHeaderTabs defaultIndex={1}>
              <MrTabList>
                <MrTab>Overview</MrTab>
                <MrTab>
                  <EventMappingsTab playbookState={playbookState} />
                </MrTab>
                <MrTab>Notes</MrTab>
              </MrTabList>

              <MrTabPanel>
                <Overview integration={integration} kit={kit} />
              </MrTabPanel>
              <MrTabPanel>
                <>
                  <PlaybookRevisionHeader
                    navState={navState}
                    integration={integration}
                    playbookState={playbookState}
                    version={version}
                    isAllowed={isAllowed}
                  />
                  <PlaybookEditor
                    navState={navState}
                    playbookState={playbookState}
                    playbookData={playbookState.playbook}
                    connectionJson={playbookState.connectionJSON}
                    isAllowed={isAllowed}
                  />
                </>
              </MrTabPanel>
              <MrTabPanel>
                <Notes integration={integration} />
              </MrTabPanel>
            </MrHeaderTabs>
          </>
        )}
      </MrAppLayout>
    </TransformPlaybookContextProvider>
  );
};

PlaybookEditorPage.propTypes = {
  location: pt.object,
};
export default PlaybookEditorPage;
