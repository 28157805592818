import { useReducer } from 'react';
import { produce } from 'immer';

const actions = {
  LOADING_DETAILS: Symbol(),
  SET_DETAILS: Symbol(),
  SET_FILTER_PIPELINES: Symbol(),
  SET_SHOW: Symbol(),
};

const initialState = report => {
  return {
    count: parseInt(report.totalCount).toLocaleString(),
    combinations: parseInt(report.combinations).toLocaleString(),
    lastSeen: dateToString(report.lastSeen),
    isLoadingDetails: false,
    filteredPipelines: [],
    show: true,
  };
};

const dateToString = date => {
  const d = date.toLocaleDateString().split('/');
  const hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  const seconds = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();

  return `${d[2]}-${d[0]}-${d[1]} ${hours}:${minutes}:${seconds}`;
};

const reducer = produce((state, { type, payload }) => {
  switch (type) {
    case actions.LOADING_DETAILS:
      state.isLoadingDetails = true;
      break;
    case actions.SET_DETAILS:
      state = { ...state, ...payload };
      state.details = JSON.parse(atob(payload.details));
      state.count = parseInt(payload.count).toLocaleString();
      state.combinations = parseInt(payload.combinations).toLocaleString();
      state.lastSeen = dateToString(new Date(payload.lastSeen));
      state.isLoadingDetails = false;
      break;
    case actions.SET_FILTER_PIPELINES:
      state.filteredPipelines = payload;
      break;
    case actions.SET_SHOW:
      state.show = payload;
      break;
  }
  return state;
});

const useSchemaPanelState = report => {
  const [state, dispatch] = useReducer(reducer, initialState(report));

  const loadingDetails = () => {
    dispatch({ type: actions.LOADING_DETAILS });
  };

  const setDetails = details => {
    dispatch({
      type: actions.SET_DETAILS,
      payload: details,
    });
  };

  const setFilterPipelines = pipelines => {
    dispatch({
      type: actions.SET_FILTER_PIPELINES,
      payload: pipelines,
    });
  };

  const setShow = show => {
    dispatch({
      type: actions.SET_SHOW,
      payload: show,
    });
  };

  return {
    loadingDetails,
    setDetails,
    setFilterPipelines,
    setShow,
    ...state,
  };
};

export default useSchemaPanelState;
