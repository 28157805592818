const defaultState = {
  background: '#FFFFFF',
  border: '1px solid #D7D8E5',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  borderRadius: '6px',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#06010F',
  padding: '10px 14px',
  fontFamily: 'IBM Plex Sans',
};

const placeholder = {
  color: '#6A6C81',
  fontWeight: 400,
  maxWidth: 'calc(100% - 50px)',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const hoverState = {
  background: '#F7F7FB',
};

const focusedState = {
  border: '1px solid #338CF1',
  boxShadow:
    '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06), 0px 0px 0px 4px rgba(51, 140, 241, 0.3)',
};

const openState = {
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
};

const disabledState = {
  color: '#D7D8E5',
  fontWeight: 600,
  background: '#FFFFFF',
  fontSize: '14px',
  lineHeight: '20px',
};

const groupHeading = {
  background: '#FCFCFD',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  color: '#525365',
  textTransform: 'none',
  padding: '6px 8px',
  margin: 0,
  display: 'inline-flex',
  width: '100%',
};

const defaultIndicator = {
  color: '#6A6C81',
  display: 'inline-flex',
  alignItems: 'center',
};

const disabledIndicator = {
  color: '#D7D8E5',
};

const menu = {
  border: '1px solid #EBECF4',
  boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  borderRadius: '8px',
  margin: '2px 0px',
  padding: '6px',
  'z-index': 2,
};

const option = {
  padding: '10px 8px',
  fontSize: '16px',
  lineHeight: '24px',
};

const checkboxBase = {
  width: '16px',
  height: '16px',
  borderRadius: '4px',
  padding: 0,
};

export default {
  control: (base, state) => ({
    ...base,
    minHeight: '40px',
    backgroundColor: defaultState.background,
    borderRadius: defaultState.borderRadius,
    fontFamily: defaultState.fontFamily,
    border: state.selectProps.menuIsOpen ? focusedState.border : defaultState.border,
    cursor: 'pointer',
    ':hover': focusedState,
    boxShadow: (() => {
      if (state.isFocused && !state.selectProps.menuIsOpen) {
        return openState.boxShadow;
      }

      if (state.isFocused && state.selectProps.menuIsOpen) {
        return focusedState.boxShadow;
      }

      return defaultState.boxShadow;
    })(),
  }),
  valueContainer: (base, state) => ({
    ...base,
    fontSize: defaultState.fontSize,
    padding: '0 14px',
    position: null,
    color: state.isDisabled ? disabledState.color : defaultState.color,
    fontWeight: state.isDisabled ? disabledState.fontWeight : defaultState.fontWeight,
    lineHeight: defaultState.lineHeight,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled ? disabledIndicator.color : defaultIndicator.color,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg) scaleX(-1)' : 'none',
    paddingRight: '15px',
    paddingLeft: '8px',
    display: defaultIndicator.display,
    alignItems: defaultIndicator.alignItems,
  }),
  clearIndicator: base => ({
    ...base,
    padding: '0',
    ':hover': {
      color: '#338CF1',
    },
    display: defaultIndicator.display,
    alignItems: defaultIndicator.alignItems,
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  indicatorsContainer: base => ({
    ...base,
    padding: 0,
  }),
  menu: base => ({
    ...base,
    background: defaultState.background,
    fontFamily: defaultState.fontFamily,
    ...menu,
  }),
  menuList: base => ({
    ...base,
    padding: 0,
  }),
  noOptionsMessage: base => ({
    ...base,
  }),
  option: (base, state) => ({
    ...base,
    ...option,
    color: (() => {
      if (state.isDisabled) {
        return disabledState.color;
      }

      return defaultState.color;
    })(),
    cursor: 'pointer',
    display: state.isMulti ? 'inline-flex' : 'block',
    borderRadius: defaultState.borderRadius,
    backgroundColor: (() => {
      return state.isSelected ? focusedState.background : defaultState.background;
    })(),
    fontWeight: defaultState.fontWeight,
    fontSize: option.fontSize,
    lineHeight: option.lineHeight,
    ':hover': (() => {
      return state.isDisabled
        ? {
            background: defaultState.background,
            cursor: 'not-allowed',
          }
        : {
            background: hoverState.background,
          };
    })(),
    alignItems: 'center',
    '> span': state.isMulti ? { marginLeft: '8px' } : '',
  }),
  placeholder: (base, state) => ({
    ...base,
    fontSize: state.isDisabled ? disabledState.fontSize : defaultState.fontSize,
    lineHeight: state.isDisabled ? disabledState.lineHeight : defaultState.lineHeight,
    margin: 0,
    color: state.isDisabled ? disabledState.color : placeholder.color,
    fontWeight: state.isDisabled ? disabledState.fontWeight : placeholder.fontWeight,
    maxWidth: placeholder.maxWidth,
    textOverflow: placeholder.textOverflow,
    whiteSpace: placeholder.whiteSpace,
    overflow: placeholder.overflow,
  }),
  groupHeading: (base, state) => ({
    ...base,
    ...groupHeading,
    color: (() => {
      if (state.isoptiondisabled === 'true') {
        return disabledState.color;
      }
    })(),
    ':hover': (() => {
      return state.isoptiondisabled === 'true'
        ? {
            background: defaultState.background,
            pointerEvents: 'none',
          }
        : {
            background: hoverState.background,
          };
    })(),
  }),
  singleValue: base => ({
    ...base,
    color: defaultState.color,
    fontSize: defaultState.fontSize,
    lineHeight: defaultState.lineHeight,
    fontWeight: defaultState.fontWeight,
    maxWidth: 'calc(100% - 50px)',
  }),
  input: base => ({
    ...base,
    padding: 0,
  }),
  group: base => ({
    ...base,
    padding: 0,
    margin: 0,
    '> div > span': {
      marginLeft: '8px',
    },
    '> div > div > input': checkboxBase,
  }),
};
