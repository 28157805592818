import auditLogMutation from '../insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const mutation = auditLogMutation;

export function buildVariables({ id, name, writekey, clusterId }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      id,
      name,
      writekey,
      clusterId,
    }),
  };
}
