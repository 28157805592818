import React from 'react';
import pt from 'prop-types';

import ReplaceString from './replace-string';
import Substring from './substring';
import SplitString from './split-string';
import TruncateFloat from './truncate-float';
import ToHash from './to-hash';
import PluckValues from './pluck-values';
import JoinValues from './join-values';

const TransformLookup = ({
  selectedSubOperation,
  opId,
  replace,
  replaceWith,
  _path,
  joinSeparator,
  start,
  end,
  splitSeparator,
  maxElements,
  toHash,
  extractKey,
  precision,
}) => {
  switch (selectedSubOperation) {
    case 'replaceString':
      return <ReplaceString opId={opId} replace={replace} replaceWith={replaceWith} _path={_path} />;
    case 'substring':
      return <Substring opId={opId} start={start} end={end} _path={_path} />;
    case 'splitString':
      return <SplitString opId={opId} splitSeparator={splitSeparator} maxElements={maxElements} _path={_path} />;
    case 'truncateFloat':
      return <TruncateFloat opId={opId} precision={precision} _path={_path} />;
    case 'toHash':
      return <ToHash opId={opId} toHash={toHash} _path={_path} />;
    case 'pluckValues':
      return <PluckValues opId={opId} extractKey={extractKey} _path={_path} />;
    case 'joinValues':
      return <JoinValues opId={opId} joinSeparator={joinSeparator} _path={_path} />;
    default:
      return null;
  }
};

TransformLookup.propTypes = {
  precision: pt.number,
  extractKey: pt.string,
  toHash: pt.oneOf(['md5', 'sha256']),
  splitSeparator: pt.string,
  maxElements: pt.number,
  selectedSubOperation: pt.string,
  replace: pt.string,
  replaceWith: pt.string,
  joinSeparator: pt.string,
  start: pt.number,
  end: pt.number,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default TransformLookup;
