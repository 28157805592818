import moment from 'moment';

/**
 * To get the difference between two dates, pass that measurement as the second argument.
 * https://momentjs.com/docs/#/displaying/difference/
 * @param new Date(since)
 * @param new Date(until)
 * @param string for example; 'years', 'months', 'weeks', 'days', 'hours', 'minutes', and 'seconds'
 * @returns {number}
 */
export default function diffInDays(since, until, unitOfTime) {
  return moment(until).diff(moment(since), unitOfTime);
}
