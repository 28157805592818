import { filter as _filter } from 'lodash';

const saslTypes = {
  scram: 'SCRAM',
  plain: 'PLAIN',
};

const saslParamNames = {
  scram: 'SASL_SCRAM',
  plain: 'SASL_PLAIN',
  sasl: 'AUTHENTICATION (SASL)',
};

const stageTypes = {
  s3: 'S_3',
  gcs: 'GCS',
};

const stageParamNames = {
  s3: 'S_3',
  gcs: 'GCS',
  stage: 'STAGE',
};

const saslType = plain => {
  if (plain.defaultValue) return saslTypes.plain;
  return saslTypes.scram;
};

const stageType = gcs => {
  if (gcs.defaultValue) return stageTypes.gcs;
  return stageTypes.s3;
};

export const oneOfSdkParams = (params, previousParams) => {
  const scram = params?.find(({ name }) => name === saslParamNames.scram);
  const plain = params?.find(({ name }) => name === saslParamNames.plain);

  if (scram && plain) {
    const sasl = previousParams?.find(({ name }) => name === saslParamNames.sasl);
    const filtered = _filter(params, p => p.name !== saslParamNames.plain && p.name !== saslParamNames.scram);

    if (sasl) {
      return [...filtered, sasl];
    }

    const isOptional = false;
    const initialValue = { username: '', password: '', algorithm: '2' };
    const type = saslType(plain);
    const defaultValue = plain.defaultValue ?? scram.defaultValue ?? initialValue;

    const newSaslParam = {
      ...plain,
      name: saslParamNames.sasl,
      isOptional,
      defaultValue: { ...defaultValue, saslType: type },
    };
    return [...filtered, newSaslParam];
  }

  const s3 = params?.find(({ name }) => name === stageParamNames.s3);
  const gcs = params?.find(({ name }) => name === stageParamNames.gcs);

  if (s3 && gcs) {
    const stage = previousParams?.find(({ name }) => name === stageParamNames.stage);
    const filtered = _filter(params, p => p.name !== stageParamNames.s3 && p.name !== stageParamNames.gcs);

    if (stage) {
      return [...filtered, stage];
    }

    const isOptional = false;
    const initialValue = {
      region: '',
      bucket: '',
      prefix: '',
      accessKey: '',
      secretKey: '',
      serverSideEncryption: '',
      compression: '2',
      credentialJson: '',
    };
    const type = stageType(gcs);
    const defaultValue = gcs.defaultValue ?? s3.defaultValue ?? initialValue;

    const newStageParam = {
      ...gcs,
      name: stageParamNames.stage,
      isOptional,
      defaultValue: { ...defaultValue, stageType: type },
    };
    return [...filtered, newStageParam];
  }

  return params;
};
