import { gql } from '@apollo/client';
import pipelineIntegration from '../pipelineIntegration';

export const mutation = gql`
  mutation RemovePipelineIntegration(
    $pipelineIntegrationId: uuid!
    $pipelineIntegration: pipeline_integrations_set_input!
  ) {
    update_pipeline_integrations_by_pk(pk_columns: { id: $pipelineIntegrationId }, _set: $pipelineIntegration) {
      ...pipelineIntegration
    }
  }
  ${pipelineIntegration}
`;

export function buildVariables({ pipelineIntegrationId }) {
  return {
    pipelineIntegrationId,
    pipelineIntegration: {
      deletedAt: 'now()', // Sets to current db time.
    },
  };
}
