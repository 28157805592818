import { fetchSync, localStorage, firstPartyCookie } from './customSyncs';
import { hydrateToRequestFetchSync } from './hydrateToRequestFetchSync';

export const hydrateToRequest = (syncName, data) => {
  switch (syncName) {
    case fetchSync.REQUEST_NAME:
      return hydrateToRequestFetchSync(data);
    case localStorage.REQUEST_NAME:
    case firstPartyCookie.REQUEST_NAME:
      return JSON.stringify(data.map(item => item.name));
    default:
      return JSON.stringify(data);
  }
};
