import React from 'react';

import ActionCell from './action-cell/ActionCell';
import RoleCell from '../role-cell';
import StatusCell from './status-cell/StatusCell';

export const COLUMNS = [
  {
    name: 'Email address',
    selector: row => row.email,
    width: '300px',
  },
  {
    name: 'Status',
    selector: row => <StatusCell status={row.status} />,
  },
  {
    name: 'Roles',
    cell: row => <RoleCell roles={row.roles} />,
  },
  {
    button: true,
    width: '77px',
    cell: row => <ActionCell row={row} />,
  },
];
