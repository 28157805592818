import React, { useMemo } from 'react';
import pt from 'prop-types';
import { isEmpty as _isEmpty } from 'lodash';

import s from './index.module.scss';
import { MrInputMultiSelect } from '@ion/components';
import useIntegrationList from './use-integration-list';
import NO_DATA_COMPONENT_MESSAGE from '../no-data-component-message';

const IntegrationSelect = ({
  since,
  until,
  configuredClusters,
  configuredPipelines,
  setQueryParameter,
  integrations,
  isDisabled,
}) => {
  const [{ integrationList, loading }] = useIntegrationList({
    configuredClusters,
    configuredPipelines,
    since,
    until,
    integrations,
    setQueryParameter,
  });

  const options = useMemo(
    () => [
      {
        label: 'Deselect all',
        options: integrationList,
      },
    ],
    [integrationList]
  );

  return (
    <MrInputMultiSelect
      disabled={isDisabled}
      className={s.multiSelectDropdown}
      name="multiSelectIntegrationOptionDropdown"
      options={options}
      onChange={e => {
        const selected = e.target.value.map(v => v.value);
        setQueryParameter({
          integrationIds: selected,
          filterCount: selected.length,
          defaultFilter: false,
          ...(_isEmpty(selected) && {
            noDataComponentMessage: NO_DATA_COMPONENT_MESSAGE.MAKE_SELECTION,
          }),
        });
      }}
      optionName="Integration"
      defaultValue={options[0].options}
      disableOptions={loading}
    />
  );
};

IntegrationSelect.propTypes = {
  configuredClusters: pt.array.isRequired,
  configuredPipelines: pt.array.isRequired,
  setQueryParameter: pt.func.isRequired,
  since: pt.instanceOf(Date).isRequired,
  until: pt.instanceOf(Date).isRequired,
  integrations: pt.array,
  isDisabled: pt.bool.isRequired,
};

export default IntegrationSelect;
