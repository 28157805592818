import formatDeliveryRate from './format-delivery-rate';
import formatIfNan from './format-if-nan';
import { nameColumnProperties, dataColumnProperties } from './apply-column-defaults';
import DROP_REASONS from '../../drop-reasons';

export default function buildEventColumns({ showPipelineFilters }) {
  return [
    {
      selector: row => row.eventName,
      ...nameColumnProperties,
    },
    {
      selector: row => row.CONSENT,
      format: row => formatIfNan(row, 'CONSENT'),
      ...dataColumnProperties,
    },
    {
      selector: row => row.PLAYBOOK_FILTER,
      format: row => formatIfNan(row, 'PLAYBOOK_FILTER'),
      ...dataColumnProperties,
    },
    {
      id: DROP_REASONS.PIPELINE_LUA_FILTER,
      selector: row => row[DROP_REASONS.PIPELINE_LUA_FILTER],
      format: row => formatIfNan(row, DROP_REASONS.PIPELINE_LUA_FILTER),
      omit: !showPipelineFilters,
      ...dataColumnProperties,
    },
    {
      selector: row => row.Success,
      format: row => formatIfNan(row, 'Success'),
      ...dataColumnProperties,
    },
    {
      selector: row => row.Errors,
      format: row => formatIfNan(row, 'Errors'),
      ...dataColumnProperties,
    },
    {
      selector: row => row,
      format: row => formatDeliveryRate(row),
      ...dataColumnProperties,
    },
  ];
}
