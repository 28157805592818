import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './tag-list.module.scss';

const MrTagList = ({ taglist, className }) => {
  const tagListItems = taglist.map((item, idx) => {
    return (
      <li key={idx} className={cx(s.tagli, className)} style={{ background: `${item.color}` }}>
        {item.text}
      </li>
    );
  });
  return <ul className={cx(s.tagul, className)}>{tagListItems}</ul>;
};

MrTagList.propTypes = {
  children: pt.object,
  taglist: pt.array,
  className: pt.string,
  validators: pt.arrayOf(pt.func),
};

export default MrTagList;
