import React from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';

import DataTable from 'react-data-table-component';
import customStyles from './table-styles/custom-styles';
import styles from './mr-data-table.module.scss';
import ProgressComponent from './progress-component';
import SortIcon from './sort-icon';

/*
  Thin wrapper around react-data-table-component
  to add defaults and styling.

  https://github.com/jbetancur/react-data-table-component
 */
export default function MrDataTable({ loadingMessage = null, className, ...props }) {
  const propsWithDefault = {
    customStyles: props.customStyles ?? customStyles,
    progressComponent: <ProgressComponent message={loadingMessage} />,
    sortIcon: <SortIcon />,
    ...props,
  };

  return (
    <div className={cx(className, styles.container)}>
      <DataTable {...propsWithDefault} />
    </div>
  );
}

// Feel free to add more propTypes from react-data-table-component as needed.
MrDataTable.propTypes = {
  columns: propTypes.arrayOf(propTypes.object).isRequired,
  data: propTypes.arrayOf(propTypes.object).isRequired,
  onChangePage: propTypes.func,
  paginationTotalRows: propTypes.number,
  progressPending: propTypes.bool,
  loadingMessage: propTypes.string,
  noDataComponent: propTypes.oneOfType([propTypes.string, propTypes.element]),
  className: propTypes.string,
  customStyles: propTypes.object,
  title: propTypes.oneOfType([propTypes.string, propTypes.element]),
  fixedHeader: propTypes.bool,
  fixedHeaderScrollHeight: propTypes.string,
};
