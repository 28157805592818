import React from 'react';
import pt from 'prop-types';

import { uniq as _uniq, assign as _assign } from 'lodash';

import { useUpdatePipelineVariable } from '@ion/api';
import {
  MrH,
  MrText,
  MrLink,
  MrModal,
  MrForm,
  MrInputText,
  MrButton,
  MrButtonList,
  useForm,
  generateId,
  formValidators,
} from '@ion/components';

import s from './index.module.scss';

const PipelineVarsModal = ({ pipeline, setVariablesModalOpen, revisionToEdit, setRevisionToEdit }) => {
  const stagedRevisions = pipeline.pipelineIntegrations.map(pipelineIntegration => pipelineIntegration.stagedRevision);
  const stagedRevision = revisionToEdit
    ? stagedRevisions.find(p => p.playbookId === revisionToEdit)
    : stagedRevisions.find(p => p.readyForDeploy === false);

  const formId = generateId();
  const [updatePipelineVariable, { isAllowed: updatePipelinePermission }] = useUpdatePipelineVariable();

  const { formState, resetError, submitForm, setField } = useForm(formId);
  const { validateRequired } = formValidators;

  const onVariablesFormSubmit = () => {
    const { data: pipelineVars, isValid } = submitForm();

    // if submitted user data is valid, update pipeline var with data and mark as populated
    // TODO (EunHye 2024.02.01.) we upsert stagedRevision.pipelineVars
    // Until we refactor the data model, it updates values by merging
    if (pipelineVars && isValid) {
      updatePipelineVariable({
        id: stagedRevision.id,
        pipelineName: pipeline.name,
        pipelineVars: _assign({}, stagedRevision.pipelineVars, pipelineVars),
        readyForDeploy: true,
      });
      setRevisionToEdit(null);
      setVariablesModalOpen(false);
    }
  };

  if (!stagedRevision) {
    return null;
  }

  const uniquePipelineVariableNames = _uniq(stagedRevision.playbook.pipeline_variables.map(({ name }) => name));

  return (
    <MrModal
      closeModal={() => {
        setRevisionToEdit(null);
        setVariablesModalOpen(false);
      }}
      styleNames="allowOverflow"
    >
      <div className={s.header}>
        <MrH h="h3" sans styleNames="noMargin">
          Editing Pipeline Variables
        </MrH>
      </div>
      <div className={s.content}>
        <MrForm onSubmit={onVariablesFormSubmit} id={formId}>
          <>
            <MrText styleNames="marginBottom">
              Set <b>Pipeline Variables</b> for{' '}
              <b>Revision {stagedRevision.revision || stagedRevision?.playbook?.revision}</b> of your{' '}
              <b>{stagedRevision.displayName || stagedRevision?.playbook?.integration?.name}</b> integration. Until you
              provide values for this pipeline, you will not be able to deploy this Revision.
            </MrText>

            {uniquePipelineVariableNames.map(name => {
              const value = stagedRevision.pipelineVars[name];

              return (
                <MrInputText
                  onInput={resetError}
                  validators={[validateRequired()]}
                  label={name}
                  name={name}
                  value={formState[name]?.value ?? value ?? ''}
                  errorMsg={formState[name]?.errorMsg}
                  key={name}
                  onChange={setField}
                  fullWidth
                />
              );
            })}
            <MrButtonList
              styleNames="marginTop"
              buttons={[
                <MrButton text="Save" key="save" disabled={!updatePipelinePermission} />,
                <MrLink
                  onClick={() => {
                    setRevisionToEdit(null);
                    setVariablesModalOpen(false);
                  }}
                  styleNames="gray"
                  key="cancel"
                >
                  Cancel
                </MrLink>,
              ]}
            />
          </>
        </MrForm>
      </div>
    </MrModal>
  );
};

PipelineVarsModal.propTypes = {
  pipeline: pt.object,
  setVariablesModalOpen: pt.func,
  revisionToEdit: pt.string,
  setRevisionToEdit: pt.func,
};

export default PipelineVarsModal;
