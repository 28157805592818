export default {
  totalEvents: {
    description: 'Total Events Ingested',
    leftIcon: 'icon-arrow-start',
    tooltip: {
      message: 'Number of events sent to MetaRouter within the specified timeframe',
    },
  },
  notDelivered: {
    description: 'Compliance Filter / Playbook Filter / Not Delivered',
    tooltip: {
      message: 'Events not delivered due to a combination of compliance filtering, playbook filtering, or errors',
    },
  },
  delivered: {
    description: 'Total Events Delivered (Rate)',
    rightIcon: 'icon-arrow-end',
  },
};
