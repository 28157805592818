import updateDuplicateParams from './update-duplicate-params';
import { getParams } from '../../../get-params';
import { globalModalStore } from '@ion/global';

export const onSaveRevision = ({ uploadedData, integration, playbookState, uploadState, insertPlaybook }) => {
  const {
    msg,
    p: playbookJson,
    c: connectionJson,
  } = updateDuplicateParams(uploadedData, playbookState.playbook, playbookState.connectionJSON);

  const params = getParams(connectionJson, playbookJson);
  insertPlaybook({
    integrationId: integration.id,
    playbookJson: playbookJson,
    connectionJson: connectionJson,
    vars: null, //Deprecated field will remove but will be available for backwards compatability
    pipeline_variables: {
      data: params.filter(param => param.pipelineVarId).map(operation => ({ name: operation.name })),
    },
    staged_revisions: null,
  });
  uploadState.resetModal();

  if (msg !== null) {
    return globalModalStore.addNotification({
      title: 'Check your params',
      message: msg,
    });
  }
};
