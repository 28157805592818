import React from 'react';
import { MrIcon } from '@ion/components';
import styles from './index.module.scss';

/*
  This can be extracted to component-library if needed
  In the future, hopefully it will actually show profile pic.
 */

export default function UserIcon() {
  return <MrIcon id={'icon-user'} className={styles.userIcon} />;
}
