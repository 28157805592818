import React from 'react';
import { ReactCountDataTableTitle } from '@metarouter-private/mr-mui';

export const UserManagementTableTitle = () => {
  const countTableTitleProps = {
    title: {
      singular: 'User',
      plural: 'Users',
    },
  };
  return <ReactCountDataTableTitle {...countTableTitleProps} />;
};
