import customStyles from '../../data-table/table-styles/custom-styles';
const borderBottom = '1px solid #EBECF4';

export default {
  ...customStyles,
  rows: {
    style: {
      '&:not(:last-of-type)': {
        borderBottom: borderBottom,
      },
      paddingLeft: '48px',
    },
  },
};
