import { gql } from '@apollo/client';
import integrationFragment from '../integration';

export const mutation = gql`
  mutation RemoveIntegration(
    $integrationId: uuid!
    $integration: integrations_set_input!
    $notes: integration_notes_set_input!
  ) {
    update_integrations_by_pk(pk_columns: { id: $integrationId }, _set: $integration) {
      ...integration
    }
    update_integration_notes(where: { integrationId: { _eq: $integrationId } }, _set: $notes) {
      ...integration
    }
  }
  ${integrationFragment}
`;

export function buildVariables({ integrationId }) {
  return {
    integrationId,
    integration: {
      deletedAt: 'now()', // Sets to current db time.
    },
    notes: {
      deletedAt: 'now()', // Sets to current db time.
    },
  };
}
