import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ userId, context }) {
  const action = actions.auth_logged_in;
  const description = '';
  const debug_data = {
    userId,
    context,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
