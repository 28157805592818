import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputText, MrInputNumber } from '@ion/components';

import { useFormContext } from 'app/context/form-context';
const { validateRequired, validateInRange } = formValidators;

const Spreading = ({ separator, prefix, depth, opId, _path }) => {
  const { validateField, formState, resetError, setField } = useFormContext();
  return (
    <>
      {((name = `${opId}-separator-${_path}`) => (
        <MrInputText
          label="Separator"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={separator}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateRequired()]}
          placeholder="Separator"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-prefix-${_path}`) => (
        <MrInputText
          label="Prefix"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={prefix}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          placeholder="Prefix"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-depth-${_path}`) => (
        <MrInputNumber
          label="Depth"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={depth}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          labelPosition="top"
          validators={[validateInRange(0, 5)]}
          min={0}
          max={5}
        />
      ))()}
    </>
  );
};

Spreading.propTypes = {
  separator: pt.string,
  prefix: pt.string,
  depth: pt.number,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default Spreading;
