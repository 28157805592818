import React from 'react';
import { MrDateRangePicker, MrWithTooltip } from '@ion/components';
import propTypes from 'prop-types';

const DATA_RETENTION_TOOLTIP = {
  message: '90 day data retention policy',
};

export function AuditLogDateRange({ onDatesChange }) {
  const maxDate = generateMaxDate();
  const minDate = generateMinDate();
  return (
    <MrWithTooltip {...DATA_RETENTION_TOOLTIP}>
      <MrDateRangePicker onDatesChange={onDatesChange} maxDate={maxDate} minDate={minDate} />
    </MrWithTooltip>
  );
}

AuditLogDateRange.propTypes = {
  onDatesChange: propTypes.func,
};

function generateMinDate() {
  const date = new Date();
  date.setDate(date.getDate() - 90); // max 90 days ago
  return date;
}

function generateMaxDate() {
  return new Date();
}
