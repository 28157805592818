import { useState, useCallback, useEffect } from 'react';

import { useDashboardProxy } from '@ion/api';
import onCompleteDashboardProxy from './on-complete-dashboard-proxy';
import isValidInput from '../is-valid-input';
import NO_DATA_COMPONENT_MESSAGE from '../no-data-component-message';

function useIntegrationList({
  configuredClusters,
  configuredPipelines,
  since,
  until,
  integrations,
  setQueryParameter,
}) {
  const [integrationList, setIntegrationList] = useState([]);
  const [getIntegrationList, { loading }] = useDashboardProxy({
    onCompleted: r => {
      onCompleteDashboardProxy({
        result: r,
        integrations,
        integrationList,
        setIntegrationList,
        setQueryParameter,
      });
    },
    onError: () => {
      setQueryParameter({ integrationIds: [], noDataComponentMessage: NO_DATA_COMPONENT_MESSAGE.GENERIC_ERROR });
      setIntegrationList([]);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getIntegrationListCallback = useCallback(getIntegrationList, []);

  useEffect(() => {
    if (isValidInput({ clusters: configuredClusters, pipelines: configuredPipelines, since, until })) {
      getIntegrationListCallback({
        clusters: configuredClusters,
        pipelines: configuredPipelines,
        dateRange: {
          since,
          until,
        },
        query: 'IntegrationList',
      });
    } else {
      setIntegrationList([]);
    }
  }, [configuredClusters, configuredPipelines, since, until, getIntegrationListCallback]);

  return [{ integrationList, loading }];
}

export default useIntegrationList;
