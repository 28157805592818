/*
   This component is deprecated, use `input-select` aka `MrInputSelect` instead.
   Context:
    When we introduced the `input-multi-select`, there was a lot of duplication between
    this component and the new `input-multi-select` component. Also, 99% of the complexity
    in this file comes from the fact that it originally supported both the single and multi-select.

    For the single select use case, since we're using the `react-select` 3rd Party Component, the
    `input-select` component can and should be very simple, especially compared to the complexity
    in this component.

    We can and should replace all uses of MrInputSelectDeprecated with MrInputSelect and delete
    this file.
 */

import React, { useRef } from 'react';
import pt from 'prop-types';
import Select from 'react-select';

import { alphabetizeBy, alphabetizableBy } from '@ion/mrdash';

import { isString as _isString, isNil as _isNil } from 'lodash';

import MultiSelectContainer from './multi-select-container';
import DropdownIndicator from './dropdown-indicator';

import useValidatorLookup from './../../hooks/use-validator-lookup';
import useDismissable from './../../hooks/use-dismissable';
import generateId from './../../utils/uuid';

// js styles applied to the Select component
import styles from './input-select.styles.js';
import useToggle from '../../hooks/use-toggle';

// options may be an array of strings or objects in the shape of { text, value }
// transform the provided options into the shape expected by the react-select component
function normalizeOptions(options) {
  return options.map(o => {
    if (_isString(o)) {
      return {
        label: o,
        value: o,
      };
    }

    return {
      label: o.text,
      value: o?.value,
      isDisabled: o.disabled,
    };
  });
}

function transformOptions(options, { alphabetized }) {
  const normalizedOptions = normalizeOptions(options);
  if (alphabetized) {
    if (!alphabetizableBy(normalizedOptions, 'label')) {
      return normalizedOptions;
    }
    return alphabetizeBy(normalizedOptions, 'label');
  }
  return normalizedOptions;
}

const MrInputSelectDeprecated = ({
  options,
  placeholder,
  value,
  onChange,
  name,
  isClearable = false,
  isSearchable = false,
  disabled,
  label,
  validators,
  errorMsg,
  onBlur,
  onFocus,
  helperText,
  className,
  selectedVariant,
  testId = 'MrInputSelect',
  alphabetized = true,
  // isInline,
}) => {
  const selectRef = useRef();
  const outerRef = useRef();
  const [isOpen, onToggle] = useToggle();

  useDismissable(outerRef, () => {
    if (isOpen) {
      onToggle();
    }
  });

  const uuid = generateId();
  const { addValidators } = useValidatorLookup(uuid);

  addValidators({ uuid, validators });

  const transformedOptions = transformOptions(options, { alphabetized });
  const defaultValue = transformedOptions.filter(o => !_isNil(o.value)).find(o => o.value === value);

  return (
    <MultiSelectContainer
      testId={testId}
      outerRef={outerRef}
      className={className}
      label={label}
      helperText={helperText}
      toggleOpen={onToggle}
      disabled={disabled}
      selectedVariant={selectedVariant}
      errorMsg={errorMsg}
      id={uuid}
      value={value}
      name={name}
    >
      <Select
        components={{
          DropdownIndicator,
        }}
        defaultValue={defaultValue}
        options={transformedOptions}
        placeholder={placeholder === true ? `Select...` : placeholder}
        ref={selectRef}
        styles={styles}
        key={uuid}
        onChange={(selected, { action }) => {
          // selected value is null when cleared via isClearable
          if (isClearable && _isNil(selected)) {
            onChange({
              target: {
                name,
                value: '',
                type: 'select-one',
              },
            });
            return;
          }

          onChange({
            target: {
              name,
              value: selected.value,
              type: 'select-one',
            },
          });

          if (action === 'select-option' || action === 'deselect-option') {
            onToggle();
          }
        }}
        isMulti={false}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={disabled}
        noOptionsMessage={() => `No options available`}
        onBlur={e => {
          if (onBlur) {
            onBlur(e);
          }

          onToggle();
        }}
        onFocus={onFocus}
        menuIsOpen={!disabled ? isOpen : null}
        // custom props below here
        selectedVariant={selectedVariant}
        closeMenuOnSelect={true}
      />
    </MultiSelectContainer>
  );
};

MrInputSelectDeprecated.propTypes = {
  options: pt.oneOfType([
    pt.arrayOf(pt.string),
    pt.arrayOf(
      pt.shape({
        text: pt.oneOfType([pt.string, pt.element]).isRequired,
        value: pt.oneOfType([pt.string, pt.object]).isRequired,
        disabled: pt.bool,
      })
    ),
  ]).isRequired,
  value: pt.oneOfType([pt.string, pt.array, pt.object]),
  placeholder: pt.oneOfType([pt.string, pt.bool]),
  onChange: pt.func,
  name: pt.string.isRequired,
  isMulti: pt.bool,
  isSearchable: pt.bool,
  isClearable: pt.bool,
  label: pt.string,
  disabled: pt.bool,
  validators: pt.arrayOf(pt.func),
  errorMsg: pt.string,
  onBlur: pt.func,
  onFocus: pt.func,
  helperText: pt.string,
  className: pt.string,
  selectedVariant: pt.oneOf(['green', 'orange']),
  // isInline: pt.bool,
  testId: pt.string,
  alphabetized: pt.bool,
};

export default MrInputSelectDeprecated;
