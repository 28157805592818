import React from 'react';
import pt from 'prop-types';

import FeatureGate from '../../../../launch-darkly/gates/feature-gate';
import { CONSENT_CATEGORY_FLAGS } from '../../../../context/gated-consent-categories-context';

import { IdentitySyncFieldsTable, ConsentCategoryTable } from './index';
import BringYourOwnSyncFieldsTable from './BringYourOwnSyncFieldsTable';
import { BRING_YOUR_OWN_SYNCS, CUSTOM_IDENTITY_SYNC } from './consts';
import CustomIdentitySyncTable from './CustomIdentitySyncTable';

const CardBody = ({
  fields,
  resetError,
  formState,
  setField,
  syncName,
  validateField,
  validateNumber,
  validateRequired,
}) => {
  return (
    <>
      {syncName !== CUSTOM_IDENTITY_SYNC.REQUEST_NAME && (
        <FeatureGate flagName={CONSENT_CATEGORY_FLAGS.MVP}>
          <ConsentCategoryTable
            resetError={resetError}
            formState={formState}
            setField={setField}
            syncName={syncName}
            validateField={validateField}
            validateRequired={validateRequired}
            validateNumber={validateNumber}
          />
        </FeatureGate>
      )}
      <SyncType
        fields={fields}
        setField={setField}
        resetError={resetError}
        formState={formState}
        syncName={syncName}
        validateField={validateField}
        validateRequired={validateRequired}
        validateNumber={validateNumber}
      />
    </>
  );
};

const SyncType = props => {
  const { syncName } = props;
  switch (syncName) {
    case CUSTOM_IDENTITY_SYNC.REQUEST_NAME:
      return <CustomIdentitySyncTable {...props} />;
    case BRING_YOUR_OWN_SYNCS[syncName]?.REQUEST_NAME:
      return (
        <BringYourOwnSyncFieldsTable
          {...props}
          requestInputName={BRING_YOUR_OWN_SYNCS[syncName].REQUEST_INPUT_NAME}
          dataFields={BRING_YOUR_OWN_SYNCS[syncName].FIELDS}
        />
      );
    default:
      return <IdentitySyncFieldsTable {...props} />;
  }
};

SyncType.propTypes = {
  syncName: pt.string.isRequired,
};

CardBody.propTypes = {
  fields: pt.array.isRequired,
  resetError: pt.func.isRequired,
  formState: pt.object.isRequired,
  setField: pt.func.isRequired,
  syncName: pt.string.isRequired,
  validateField: pt.func.isRequired,
  validateNumber: pt.func.isRequired,
  validateRequired: pt.func.isRequired,
};
export default CardBody;
