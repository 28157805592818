import { useCallback, useEffect, useState } from 'react';
import { useDashboardProxy } from '@ion/api';
import { isEmpty as _isEmpty } from 'lodash';
import isValidInput from '../is-valid-input';

function useDashboardMetrics(
  clusters,
  pipelines,
  setMetrics,
  setError,
  loadingMetrics,
  since,
  until,
  integrations,
  integrationIds,
  groupBy,
  filterCount,
  defaultFilter
) {
  const [mainMetrics, setMainMetrics] = useState(null);
  const [getMetrics, { loading }] = useDashboardProxy({
    onCompleted: r => {
      if (!r || _isEmpty(r)) {
        setError('NO_DATA');
        setMainMetrics([]);
      } else {
        setError('');
        setMainMetrics(r);
      }
    },
    onError: () => {
      setError('GENERIC_ERROR');
      setMainMetrics([]);
    },
  });

  const [getFilteredEventsMetrics, { filteredEventsLoading }] = useDashboardProxy({
    onCompleted: r => {
      setMetrics(mainMetrics, integrations, r);
    },
    onError: () => {
      setMetrics(mainMetrics, integrations, []);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMetricsCallback = useCallback(getMetrics, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFilteredMetricsCallback = useCallback(getFilteredEventsMetrics, []);

  const common = {
    clusters: clusters,
    pipelines: pipelines,
    dateRange: {
      since,
      until,
    },
  };
  const track = {
    filterCount,
    defaultFilter,
  };

  useEffect(() => {
    if (isValidInput({ clusters, pipelines, since, until })) {
      if (groupBy === 'integration') {
        loadingMetrics();
        getMetricsCallback({
          ...common,
          track,
          query: 'Integration',
        });
      }

      if (groupBy === 'pipeline' && !_isEmpty(integrationIds)) {
        loadingMetrics();
        getMetricsCallback({
          ...common,
          integrationIds,
          track,
          query: 'Pipeline',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusters, pipelines, since, until, integrationIds, getMetricsCallback]);

  useEffect(() => {
    if (mainMetrics !== null) {
      if (groupBy === 'integration') {
        getFilteredMetricsCallback({
          ...common,
          query: 'FilteredEventsWithIntegration',
        });
      }

      if (groupBy === 'pipeline' && !_isEmpty(integrationIds)) {
        getFilteredMetricsCallback({
          ...common,
          integrationIds,
          query: 'FilteredEventsWithPipeline',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainMetrics, getFilteredMetricsCallback]);

  return { isLoading: loading, filteredEventsLoading };
}

export default useDashboardMetrics;
