import React from 'react';

export default function Pipelines() {
  return (
    <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.6 15.5C8.14052 15.5 10.2 13.4853 10.2 11C10.2 8.51471 8.14052 6.49999 5.60001 6.49999C3.05949 6.49999 0.999999 8.51471 0.999999 11C0.999999 13.4853 3.05949 15.5 5.6 15.5ZM5.6 15.5L13.15 15.5L13.15 21L21 11L13.15 1L13.15 6.49999L11.35 6.49999"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
