import { useMutation } from '../../index';
import { buildVariables, mutation } from './switch-org-mutation-builder';

export default function useSwitchOrg(options = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
  });

  const switchOrgMutation = ({ orgId }) => {
    // TODO: add validation check, throw errors, catch it with useForm's onSubmit
    if (!orgId) {
      throw new Error({ param: 'org_id', msg: 'No available organization id' });
    }

    return executeMutation({
      variables: {
        ...buildVariables({ orgId }),
      },
    });
  };

  return [switchOrgMutation, response];
}
