import { getPipelineFilter } from './get-pipeline-filter';
import { getPipelineTransform } from './get-pipeline-transform';
import { isEmpty as _isEmpty } from 'lodash';

const initiateBulkDeploy = (
  pipelines,
  allPipelines,
  email,
  setDeploying,
  buildPipelineJson,
  deploy,
  setDeployResults,
  removePipelineIntegrations,
  pipelinesFilter = [{ filter: undefined, pipelineID: undefined }],
  pipelinesTransform = [{ transform: undefined, pipelineID: undefined }]
) => {
  setDeploying(true);
  let deployPromises = pipelines.map(p => {
    const pipeline = allPipelines.find(pipeline => pipeline.id === p);

    const pipelineFilter = getPipelineFilter(pipelinesFilter, p);
    const pipelineTransform = getPipelineTransform(pipelinesTransform, p);
    const pipelineJson = buildPipelineJson(
      pipeline.pipelineIntegrations,
      email,
      pipeline.writekey,
      pipelineFilter,
      pipelineTransform
    );
    return deploy({
      id: pipeline.id,
      name: pipeline.name,
      clusterId: pipeline.clusterId,
      writekey: pipeline.writekey,
      pipelineJSON: pipelineJson,
    }).then(response => {
      if (response.errors) {
        const message = response.errors.message.substring(response.errors.message.indexOf('{'));
        return {
          pipelineId: pipeline.id,
          name: pipeline.name,
          deployStatus: `Error: ${message}`,
          deployIcon: 'failure',
        };
      } else {
        const pipelineIntegrationsToDelete = pipeline.pipelineIntegrations.filter(
          pipelineIntegration => pipelineIntegration.stagedRevision.stagedForDelete
        );
        if (!_isEmpty(pipelineIntegrationsToDelete)) removePipelineIntegrations(pipelineIntegrationsToDelete);
        return {
          pipelineId: pipeline.id,
          name: pipeline.name,
          deployStatus: 'Deployed successfully',
          deployIcon: 'success',
        };
      }
    });
  });
  Promise.allSettled(deployPromises).then(results => {
    setDeployResults(results.map(r => r.value));
  });
};

export default initiateBulkDeploy;
