/**
 *
 * Returns true when the integration is deployed.
 *
 * @param (integrationId, deployedPipelines)
 *
 * @returns boolean
 */

const hasDeployedIntegrations = (id, deployedPipelines) => {
  if (!deployedPipelines) {
    return false;
  }
  for (const dp of deployedPipelines) {
    if (dp?.pipeline?.integrations) {
      for (const di of dp.pipeline.integrations) {
        if (id === di.id) {
          return true;
        }
      }
    }
  }
  return false;
};

export default hasDeployedIntegrations;
