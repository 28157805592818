import React from 'react';
import pt from 'prop-types';

import { MrInputSelectDeprecated, formValidators } from '@ion/components';

import { useFormContext } from 'app/context/form-context';
const { validateRequired } = formValidators;

const SelectOperation = ({ name, selectedOperation, opId, _path }) => {
  const { setField, validateField, formState, removeFields } = useFormContext();

  return (
    <MrInputSelectDeprecated
      name={name}
      options={[
        { text: 'Mapping', value: 'mappings' },
        { text: 'Enrichment', value: 'enrichments' },
        { text: 'Expression', value: 'expressions' },
        { text: 'Spreading', value: 'spreadings' },
      ]}
      value={selectedOperation}
      onChange={event => {
        //clear the subOperation to reset defaults
        setField(event);
        removeFields([`${opId}-subOperation-${_path}`]);
      }}
      placeholder
      label="Transform:"
      onBlur={validateField}
      errorMsg={formState[name]?.errorMsg}
      validators={[validateRequired()]}
    />
  );
};

SelectOperation.propTypes = {
  name: pt.string.isRequired,
  selectedOperation: pt.string,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default SelectOperation;
