import React from 'react';
import pt from 'prop-types';

import { MrInputSelectDeprecated } from '@ion/components';

import { useConsentCategoriesContext } from '../../context/consent-categories-context';

const legalDisclaimer =
  'We recommend that you consult with your legal team when determining your consent requirements.';

export const ConsentCategorySelect = ({ onChange, ...props }) => {
  const { consentCategories } = useConsentCategoriesContext();
  const consentCategoriesOptions = consentCategories?.map(category => {
    return {
      value: category.id,
      text: category.displayName,
    };
  });
  return (
    <MrInputSelectDeprecated
      placeholder="Select Consent Categories"
      testId="consentCategoriesSelect"
      name="consentCategories"
      options={consentCategoriesOptions}
      onChange={onChange}
      helperText={legalDisclaimer}
      {...props}
    />
  );
};
ConsentCategorySelect.propTypes = {
  onChange: pt.func.isRequired,
};
