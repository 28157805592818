import React from 'react';

import { PageReactDataTable } from '@metarouter-private/mr-mui';

import { useOrganizationInvitationsTable } from './hooks/use-organization-invitations-table';
import { UserInvitationsTableTitle } from './UserInvitationsTableTitle';
import InvitationConfirmDeleteContextProvider from '../../context/InvitationConfirmDeleteContextProvider';

import { COLUMNS } from './columns';
import ConfirmDeleteInvitation from './confirm-delete-invitation/ConfirmDeleteInvitation';

const PER_PAGE = 3;

export const UserInvitationsTable = () => {
  const invitationsProps = useOrganizationInvitationsTable({ perPage: PER_PAGE });

  return (
    <InvitationConfirmDeleteContextProvider>
      <ConfirmDeleteInvitation />
      <PageReactDataTable
        title={<UserInvitationsTableTitle />}
        columns={COLUMNS}
        perPage={PER_PAGE}
        {...invitationsProps}
      />
    </InvitationConfirmDeleteContextProvider>
  );
};
