import generateSettings from './generate-settings';
import { DEFAULT_WINDOW_VARIABLE_NAME } from './analytics-builder-advanced-settings';

const preparePathToUpdate = (path, part, isFirst) => {
  return isFirst ? `window.${part}` : `${path}.${part}`;
};

const prepareValueToUpdate = (path, part, isFirst, isLast) => {
  if (isLast) {
    return '[]';
  }
  return isFirst ? `window.${part} || {}` : `${path}.${part} || {}`;
};

const prepareWindowVarName = windowVariableNameParts => {
  return windowVariableNameParts.reduce(
    (acc, part, index, arr) => {
      const isFirst = index === 0;
      const isLast = index === arr.length - 1;
      const path = preparePathToUpdate(acc.path, part, isFirst);
      const value = prepareValueToUpdate(acc.path, part, isFirst, isLast);
      return {
        path,
        updatedWindowVar: `${acc.updatedWindowVar}${path} = ${value}\n`,
      };
    },
    { path: '', updatedWindowVar: '' }
  ).updatedWindowVar;
};

const isSinglePartName = splitName => splitName.length === 1;

export const initializeWindowVar = windowVariableName => {
  const windowVariableNameParts = windowVariableName.split('.');
  if (isSinglePartName(windowVariableNameParts)) {
    return '';
  }
  return prepareWindowVarName(windowVariableNameParts);
};

const prepareSnippet = (writekey, cndURL, isCompressed, windowVariableName = DEFAULT_WINDOW_VARIABLE_NAME) => {
  if (cndURL !== undefined) {
    cndURL = cndURL.replace(/^https?:\/\//, '');
  }
  let extension = '.js';
  if (isCompressed) {
    extension = '.js.gz';
  }
  const windowVarInit = initializeWindowVar(windowVariableName);
  return `
<script type='text/javascript'>
!(function() {
  ${windowVarInit}
  var analytics = (window.${windowVariableName} = window.${windowVariableName} || [])
  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console && console.error && console.error('Analytics.js snippet included twice.')
    } else {
      analytics.invoked = !0
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ]
      analytics.factory = function(e) {
        return function() {
          var t = Array.prototype.slice.call(arguments)
          t.unshift(e)
          analytics.push(t)
          return analytics
        }
      }
      for (var e = 0; e < analytics.methods.length; e++) {
        var key = analytics.methods[e]
        analytics[key] = analytics.factory(key)
      }
      analytics.load = function(key, e) {
        var t = document.createElement('script')
        t.type = 'text/javascript'
        t.async = !0
        t.src = ("https:"===document.location.protocol?"https://":"http://")+"${cndURL}/"+key+"${extension}"
        var n = document.getElementsByTagName('script')[0]
        n.parentNode.insertBefore(t, n)
        analytics._loadOptions = e
      }
      analytics.SNIPPET_VERSION = '4.13.1'
      analytics.load("${writekey}")
      window.${windowVariableName}.page()
    }
  }
})()
</script>`;
};

/**
 *
 * Create analyticsSnippet and settingsTemplate for analytics builder
 *
 * @param {{ stagedIdentitySyncsData, stagedIntegrationsData, formState, data, writekey  }}
 *
 * @returns {object}
 */

const createAjsBuilderFile = ({
  stagedIdentitySyncsData,
  stagedIntegrationsData,
  data,
  writekey,
  crossDomainSettings,
  optimizeBuild,
  consentCategories,
  queueTasks,
  hasConsentModule,
}) => {
  // generate settings object from staged settings data
  const builderSettings = generateSettings({
    stagedIdentitySyncsData,
    stagedIntegrationsData,
    data,
    consentCategories,
    crossDomainSettings,
  });

  const {
    fileHostingType,
    cdnURL,
    host,
    fileCompression,
    impliedConsent,
    cookieName,
    httpCookie,
    windowVariableName,
    storagePrefix,
    storageType,
    storageDatabase,
  } = data;

  const { syncInjectorSettings, integrationSettings } = builderSettings;
  const consentBoolean = impliedConsent === 'false' ? false : Boolean(impliedConsent);
  const consentCodes = consentCategories.map(category => category.code);
  const isCompressed = fileCompression === 'true';
  const settingsTemplate = {
    host: host,
    cdnURL: cdnURL,
    fileHostingType: fileHostingType,
    writeKey: writekey,
    compressFile: isCompressed,
    windowVariableName,
    syncInjectorSettings: {
      queueTasks,
      timeout: 10000,
      syncs: syncInjectorSettings,
      privacy: {
        impliedConsent: consentBoolean,
        cookieName: cookieName,
        consentProvider: 'OneTrust',
      },
      storage: {
        type: storageType,
        marker: storagePrefix,
        database: storageDatabase,
      },
      itpSettings: {
        enabled: httpCookie === 'true',
        timeout: 1000,
      },
    },
    integrations: integrationSettings,
    optimizeBuild,
  };
  if (hasConsentModule) {
    settingsTemplate.syncInjectorSettings.consent = {
      codes: consentCodes,
      explicitConsent: !consentBoolean,
      cookieName: cookieName,
    };
  }

  const analyticsSnippet = prepareSnippet(writekey, cdnURL, isCompressed, windowVariableName);
  return {
    analyticsSnippet: analyticsSnippet,
    settingsTemplate: settingsTemplate,
  };
};

export default createAjsBuilderFile;
