import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ pipelineIntegrationId, pipelineName, integrationName }) {
  const action = actions.pipeline_integration_updated;

  const description = `${integrationName} updated on ${pipelineName}`;
  const debug_data = {
    pipelineIntegrationId,
    pipelineName,
    integrationName,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
