import React from 'react';
import propTypes from 'prop-types';

import ReactDateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import buildDateTimeRangePickerProps from './build-date-time-range-picker-props';

import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import './react-datetimerange-picker-overrides.scss';

import styles from '../input-text/input-text.module.scss';

export default function DateTimeRangePicker(props) {
  const errorMsg = props.errorMsg;
  return (
    <>
      <ReactDateTimeRangePicker {...buildDateTimeRangePickerProps(props)} />
      {errorMsg && (
        <div className={styles.input}>
          <p className={styles.errorMsg}>{errorMsg}</p>
        </div>
      )}
    </>
  );
}

DateTimeRangePicker.propTypes = {
  startTime: propTypes.instanceOf(Date),
  endTime: propTypes.instanceOf(Date),
  onChange: propTypes.func.isRequired,
  minDate: propTypes.instanceOf(Date),
  maxDate: propTypes.instanceOf(Date),
  disabled: propTypes.bool,
  disabledColor: propTypes.oneOf(['grey', 'white']), // default grey
  errorMsg: propTypes.string,
};
