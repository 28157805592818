const sumMetrics = metricsData => {
  return metricsData.reduce(
    (total, data) => {
      total.Success += data.Success;
      total.Errors += data.Errors;
      total.CONSENT += data.CONSENT ?? 0;
      total.PLAYBOOK_FILTER += data.PLAYBOOK_FILTER ?? 0;
      return total;
    },
    { Success: 0, Errors: 0, CONSENT: 0, PLAYBOOK_FILTER: 0 }
  );
};

export default sumMetrics;
