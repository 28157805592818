import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ pipelineId, pipelineName }) {
  const action = actions.ajs_file_built;
  const description = `File built for ${pipelineName}`;
  const debug_data = {
    pipelineId,
    pipelineName,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
