import { omit as _omit } from 'lodash';

const saslProperty = {
  PLAIN: 'saslPlain',
  SCRAM: 'saslScram',
};

const stageProperty = {
  S_3: 's3',
  GCS: 'gcs',
};

const omitOneOfType = (property, params, paramName) => {
  const param = params.find(({ name }) => name === paramName);

  // (EunHye 2023.07.13) - This sasl param AUTHENTICATION (SASL) only exists in UI
  if ((property === saslProperty.PLAIN || property === saslProperty.SCRAM) && !param) {
    const saslName = 'AUTHENTICATION (SASL)';
    const saslParam = params.find(({ name }) => name === saslName);
    const saslType = saslParam?.defaultValue?.saslType;
    if (property === saslProperty[saslType]) {
      return _omit(saslParam, 'defaultValue.saslType');
    }
  }

  // (EunHye 2024.03.21) - This STAGE param only exists in UI
  if ((property === stageProperty.S_3 || property === stageProperty.GCS) && !param) {
    const stageName = 'STAGE';
    const stageParam = params.find(({ name }) => name === stageName);
    const stageType = stageParam?.defaultValue?.stageType;
    if (property === stageProperty[stageType]) {
      return _omit(stageParam, 'defaultValue.stageType');
    }
  }

  return param;
};

export { omitOneOfType, saslProperty, stageProperty };
