import { useCallback, useReducer } from 'react';
import { produce } from 'immer';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

const actions = {
  SET_APOLLO_ERROR: Symbol('SET_APOLLO_ERROR'),
  SET_SEARCH_TEXT: Symbol('SET_SEARCH_TEXT'),
  SET_TIMESTAMP_SORT_ORDER: Symbol('SET_TIMESTAMP_SORT_ORDER'),
  SET_DATE_RANGE: Symbol('SET_DATE_RANGE'),
};

const initialState = {
  apolloError: null,
  dateRange: buildDateRange(),
  searchText: null,
  timestampSortOrder: null,
};

// This is just an interim function, it will be replaced by a DateRangePicker
export function buildDateRange() {
  const endDate = new Date();
  endDate.setHours(24, 0, 0, 0);

  const startDate = new Date();
  startDate.setDate(endDate.getDate() - 90);
  startDate.setHours(0, 0, 0, 0);

  return {
    startDate,
    endDate,
  };
}

const reducer = produce((state, { type, payload }) => {
  switch (type) {
    case actions.SET_APOLLO_ERROR:
      state = { ...state, apolloError: payload };
      break;
    case actions.SET_SEARCH_TEXT: {
      const searchText = isEmpty(payload) ? null : payload;
      state = { ...state, searchText };
      break;
    }
    case actions.SET_TIMESTAMP_SORT_ORDER: {
      state = { ...state, timestampSortOrder: payload };
      break;
    }
    case actions.SET_DATE_RANGE: {
      state = { ...state, dateRange: payload };
    }
  }
  return state;
});

export default function useAuditLogsSearchTableState(initialStateOverrides = {}) {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...initialStateOverrides });

  const setApolloError = apolloError => {
    dispatch({
      type: actions.SET_APOLLO_ERROR,
      payload: apolloError,
    });
  };

  const debouncedSetSearch = debounce(searchText => {
    dispatch({
      type: actions.SET_SEARCH_TEXT,
      payload: searchText,
    });
  }, 300);

  /* eslint-disable react-hooks/exhaustive-deps */
  const setSearchText = useCallback(debouncedSetSearch, []);

  const onSort = sortOrder => {
    dispatch({
      type: actions.SET_TIMESTAMP_SORT_ORDER,
      payload: sortOrder,
    });
  };

  const setDateRange = dateRange => {
    dispatch({
      type: actions.SET_DATE_RANGE,
      payload: dateRange,
    });
  };

  return {
    setApolloError,
    setDateRange,
    setSearchText,
    onSort,
    ...state,
  };
}
