import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import { MrH, MrCard } from '@ion/components';

import s from '../index.module.scss';

const GeneralCard = ({ title, className, ...props }) => {
  return (
    <MrCard
      className={cx(s.card, className)}
      top={
        <div className={s.header}>
          <MrH h="h1" sans styleNames="noMargin">
            {title}
          </MrH>
        </div>
      }
      {...props}
    />
  );
};
GeneralCard.propTypes = {
  icon: pt.string,
  title: pt.string,
  className: pt.string,
};

export default GeneralCard;
