import React, { useState } from 'react';
import cx from 'classnames';
import pt from 'prop-types';

import LoadingSpinner from 'app/event-schemas/list/loading-spinner';
import ReportRow from 'app/event-schemas/list/row';

import s from './index.module.scss';
import arrow from '../assets/last-seen.svg';

const ReportList = ({ loading, reports, setSelectedReport, selectedReport, sortReports }) => {
  const [desc, setDesc] = useState(true);
  if (loading) {
    return <LoadingSpinner />;
  }

  if (!reports || reports.length === 0) {
    return null;
  }

  const sameReport = (r1, r2) => {
    return r1 && r2 && r1.eventName === r2.eventName && r1.writekey === r2.writekey;
  };

  return (
    <div className={s.list}>
      <section>
        <header>
          <div className={s.col}>Event Name</div>
          <div className={s.col}>Pipelines</div>
          <div className={s.col}>
            Last Seen
            <img
              src={arrow}
              className={cx(s.desc, !desc && s.asc)}
              onClick={() => {
                setDesc(!desc);
                sortReports('lastSeen', desc ? 'desc' : 'asc');
              }}
            />
          </div>
          <div className={s.col}>Combinations</div>
          <div className={s.col}>Sample Count</div>
        </header>
        {reports.map(r => (
          <ReportRow
            key={`${r.eventName}_${r.writekey}`}
            report={r}
            onClick={() => (sameReport(selectedReport, r) ? setSelectedReport(null) : setSelectedReport(r))}
          />
        ))}
      </section>
    </div>
  );
};

const reportShape = {
  eventName: pt.string,
  writekey: pt.string,
  totalCount: pt.string,
  combinations: pt.string,
  lastSeen: pt.object,
  pipeline: pt.shape({ name: pt.string }),
};
ReportList.propTypes = {
  loading: pt.bool.isRequired,
  setSelectedReport: pt.func.isRequired,
  selectedReport: pt.shape(reportShape),
  reports: pt.arrayOf(pt.shape(reportShape)),
  sortReports: pt.func.isRequired,
};

export default ReportList;
