import React, { createContext, useContext } from 'react';
import propTypes from 'prop-types';
import { isValidateUserModalOption, USER_FORM_MODAL_PROPS, USER_MODAL_OPTIONS } from './user-modal-options';

const UserModalContext = createContext(null);

export default function UserModalContextProvider({ children }) {
  const [userFormModalProps, setUserFormModalProps] = React.useState(USER_FORM_MODAL_PROPS[USER_MODAL_OPTIONS.ADD]);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [modalOption, setModalOption] = React.useState(USER_MODAL_OPTIONS.ADD);
  const onClear = () => {
    setSelectedUser({});
  };
  const setUserFormModalOption = modalOption => {
    if (isValidateUserModalOption(modalOption)) {
      setModalOption(modalOption);
      setUserFormModalProps(USER_FORM_MODAL_PROPS[modalOption]);
    }
  };
  return (
    <UserModalContext.Provider
      value={{ userFormModalProps, setUserFormModalOption, selectedUser, setSelectedUser, onClear, modalOption }}
    >
      {children}
    </UserModalContext.Provider>
  );
}

export function useUserModalContext() {
  return useContext(UserModalContext);
}

UserModalContextProvider.propTypes = {
  row: propTypes.object,
  children: propTypes.node.isRequired,
};
