import React from 'react';
import pt from 'prop-types';

import { MrCard, MrInputText } from '@ion/components';

import s from '../index.module.scss';

const ClusterCard = ({ cluster }) => {
  return (
    <div>
      <MrCard className={s.card}>
        <div>
          <MrInputText disabled={true} value={cluster.name ?? ''} name="name" label="Cluster Name" fullWidth />

          <MrInputText
            disabled={true}
            value={cluster.url.replace(/(^\w+:|^)\/\//, '') ?? ''}
            name="url"
            label="Cluster URL"
            prefix="https://"
          />

          <MrInputText
            disabled={true}
            value={cluster.metricNames?.join(', ') ?? ''}
            name="metricNames"
            label="Cluster Metric Names"
            fullWidth
          />

          <p>{'Pipelines:'}</p>

          <p>{cluster.connectedPipelines}</p>
        </div>
      </MrCard>
    </div>
  );
};

ClusterCard.propTypes = {
  cluster: pt.object,
};

export default ClusterCard;
