import { isUndefined as _isUndefined } from 'lodash';

import SELECTED_COMPONENT_DEBOUNCE_SECONDS from './selected-component-debouce-seconds';

export const defaultDebounceSeconds = 3000;

export function mapDebounceSeconds(args) {
  const { selectedComponent } = args[0];
  const debounceSeconds = SELECTED_COMPONENT_DEBOUNCE_SECONDS[selectedComponent];
  if (!_isUndefined(debounceSeconds)) {
    return debounceSeconds;
  }

  return defaultDebounceSeconds;
}

export function customDebounce(mainFunction, delayFunction) {
  let timer;
  return function (...args) {
    const delay = delayFunction(args);
    clearTimeout(timer);

    timer = setTimeout(() => {
      mainFunction(...args);
    }, delay);
  };
}
