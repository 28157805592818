const multiplePipelineNames = connectedPipelines => {
  const writeKeyMapWithPipelineNames = connectedPipelines.reduce((writeKeyMap, pipeline) => {
    const pipelineName = pipeline.label;
    const writekey = pipeline.value.split('&')[1];
    const pipelines = writeKeyMap[writekey];
    if (pipelines) {
      writeKeyMap[writekey] = [...pipelines, pipelineName];
    } else {
      writeKeyMap[writekey] = [pipelineName];
    }
    return writeKeyMap;
  }, {});

  return writeKeyMapWithPipelineNames;
};

export default multiplePipelineNames;
