import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ integrationId, name }) {
  const action = actions.integration_deleted;
  const description = `${name}`;
  const debug_data = {
    integrationId,
    integrationName: name,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
