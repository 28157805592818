import React, { createContext } from 'react';
import propTypes from 'prop-types';

export const RadioGroupChildrenContext = createContext(null);

export default function InputRadioGroupRadios({ isSelected, onSelect, name, children }) {
  return (
    <RadioGroupChildrenContext.Provider value={{ isSelected, onSelect, name }}>
      {children}
    </RadioGroupChildrenContext.Provider>
  );
}

InputRadioGroupRadios.propTypes = {
  isSelected: propTypes.func.isRequired,
  onSelect: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
};
