import React from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';
import MrIconTooltip from '../icon-tooltip/icon-tooltip';

import styles from './with-tooltip.module.scss';

export default function MrWithTooltip({ children, message, Icon, position, className, testId = 'MrWithTooltip' }) {
  return (
    <div className={cx(styles.container, className)} data-test-id={testId}>
      <div>{children}</div>
      <div>
        <MrIconTooltip message={message} Icon={Icon} position={position} />
      </div>
    </div>
  );
}

MrWithTooltip.propTypes = {
  children: propTypes.node,
  message: propTypes.string.isRequired,
  Icon: propTypes.node,
  position: propTypes.string,
  className: propTypes.string,
  testId: propTypes.string,
};
