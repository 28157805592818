import React from 'react';

import {
  formValidators,
  generateId,
  MrButton,
  MrButtonList,
  MrForm,
  MrH,
  MrInputSelect,
  MrLink,
  useForm,
} from '@ion/components';
import { useQueryClusters } from '@ion/api';

import pt from 'prop-types';
import s from 'app/event-schemas/new-report/index.module.scss';

const { validateRequired } = formValidators;

const allowedHours = [1, 3, 6, 12, 24, 48, 72];
const initialFormState = { hour: { value: '24' } };

const NewSchemaReport = ({ updateReportSettings, onClose, cluster }) => {
  const formId = generateId();
  const { formState, validateField, resetError, submitForm, setField } = useForm(formId, initialFormState);
  const { data: clusters, loading } = useQueryClusters();

  const onFormSubmit = () => {
    const { isValid } = submitForm();
    if (isValid) {
      const c = clusters.find(c => c.id === formState?.cluster?.value);
      updateReportSettings(c, formState.hour.value);
      onClose();
    }
  };

  const clusterSelect = () => {
    return (
      <MrInputSelect
        disabled={!clusters.length}
        errorMsg={formState.cluster?.errorMsg}
        isClearable
        label="Source Cluster"
        name="cluster"
        onBlur={validateField}
        onChange={setField}
        onInput={resetError}
        options={clusters.map(c => ({ text: c.name, value: c.id }))}
        placeholder={loading ? 'Loading...' : !clusters.length ? 'No clusters available' : 'Select Cluster...'}
        validators={[validateRequired()]}
        value={formState.cluster?.value || cluster?.id || ''}
        menuPosition="fixed"
      />
    );
  };

  const hourSelect = () => {
    return (
      <MrInputSelect
        label="Reporting timeframe"
        name="hour"
        onBlur={validateField}
        onChange={setField}
        options={allowedHours.map(h => ({ text: `Last ${h} hours`, value: `${h}` }))}
        validators={[validateRequired()]}
        value={formState.hour.value}
        alphabetized={false}
        menuPosition="fixed"
        maxMenuHeight={250}
      />
    );
  };

  const bottomButtons = () => {
    const buttons = [<MrButton text="Generate Report" key="create" working={false} />];
    if (cluster) {
      buttons.push(
        <MrLink onClick={onClose} styleNames="gray" key="cancel">
          Cancel
        </MrLink>
      );
    }
    return <MrButtonList styleNames="marginTop" buttons={buttons} />;
  };

  return (
    <>
      <div className={s.header}>
        <MrH h="h3" sans styleNames="noMargin">
          New Schema Report
        </MrH>
      </div>
      <div className={s.content}>
        <MrForm className={s.formCard} id={formId} onSubmit={onFormSubmit}>
          <p>Run a report to analyze the historical schema combinations MetaRouter has seen for a given cluster.</p>
          {clusterSelect()}
          {hourSelect()}
          {bottomButtons()}
        </MrForm>
      </div>
    </>
  );
};

NewSchemaReport.propTypes = {
  updateReportSettings: pt.func.isRequired,
  onClose: pt.func,
  cluster: pt.object,
};

export default NewSchemaReport;
