import { validate } from 'parameter-validator';

import { useMutation } from '../../index';
import permission from '../../Apollo/permissions';
import mergeQueries from '../../Apollo/merge-queries';
import useTrackWithUser from '../../hooks/useTrackWithUser';

import {
  ConsentCategoryCreateLog,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/consent-category-updated/audit-log-mutation-builder';

import {
  buildVariables as buildConsentCategoryVariables,
  updateConsentCategoryMutation,
} from './update-consent-category-mutation-builder';

export const combinedMutation = mergeQueries('UpdateConsentCategoryAndAudit', [
  updateConsentCategoryMutation,
  ConsentCategoryCreateLog,
]);

export default function useUpdateConsentCategory(options = {}) {
  const [trackWithUser] = useTrackWithUser('edits_category');
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Consent Category updated.',
    context: { permission: permission.updateConsentCategory },
    ...options,
  });

  const updateConsentCategoryMutation = args => {
    const { consentCategoryId } = validate(args, ['consentCategoryId']);
    const { code, name } = args;
    const variables = buildVariables({ consentCategoryId, name, code });
    return executeMutation(variables).then(response => {
      if (!response.errors) {
        trackWithUser({ consentCategoryId, name, code });
      }
    });
  };

  return [updateConsentCategoryMutation, response];
}
export function buildVariables({ consentCategoryId, name, code }) {
  return {
    variables: {
      ...buildConsentCategoryVariables({ consentCategoryId, name, code }),
      ...buildAuditLogVariables({ consentCategoryId, name, code }),
    },
  };
}
