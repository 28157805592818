import React from 'react';

import { afterOrgSwitch } from '@ion/app/src/after-org-switch';
import { Alert, Stack, Button, Typography } from '@metarouter-private/mr-mui';
import { useUserContext } from '@ion/user/context/UserContext';
import { useRemoveOverrideOrganization } from '@ion/api/';

const OrgOverrideBanner = () => {
  const [removeOverrideOrganization] = useRemoveOverrideOrganization({
    onCompleted: () => {
      afterOrgSwitch();
    },
  });

  const { isOrgOverride } = useUserContext();

  if (!isOrgOverride) return <></>;

  const alertStyles = {
    position: 'absolute',
    zIndex: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    top: 5,
    width: '33%',
    justifyContent: 'center',
    padding: 0,
  };

  return (
    <Alert variant="filled" severity="error" icon={false} sx={alertStyles}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography>Organization Override in Effect</Typography>
        <Button
          onClick={() => {
            removeOverrideOrganization();
          }}
          sx={{ border: 'solid' }}
          color="error"
        >
          REMOVE
        </Button>
      </Stack>
    </Alert>
  );
};

export default OrgOverrideBanner;
