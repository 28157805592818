import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from '../../../../launch-darkly/is-feature-enabled';
import { CONSENT_CATEGORY_FLAGS } from '../../../../context/gated-consent-categories-context';

// TODO: ND: Refactor directory structure so this has somewhere to live and refactor to reuse.
export default function useHasConsentModule() {
  const featureFlags = useFlags();
  return isFeatureEnabled({
    featureFlags,
    flagName: CONSENT_CATEGORY_FLAGS.ENABLED_ORGS,
  });
}
