import React from 'react';
import propTypes from 'prop-types';

import { CONSENT_CATEGORY_FLAGS } from '../../../../context/gated-consent-categories-context';
import { MrFormLabel } from '@ion/components';
import { GatedConsentCategoriesSelect } from '../../../../components/consent-categories-select/GatedConsentCategoriesSelect';
import FeatureGate from '../../../../launch-darkly/gates/feature-gate';
import buildConsentCategorySelectProps from '../build-consent-category-select-props';

export const ConsentCategorySelect = ({ formState, setField }) => {
  const consentCategorySelectProps = buildConsentCategorySelectProps(formState, setField);

  return (
    <FeatureGate flagName={CONSENT_CATEGORY_FLAGS.MVP}>
      <div className="content">
        <MrFormLabel label="Select Consent Category" />
        <GatedConsentCategoriesSelect {...consentCategorySelectProps} />
      </div>
    </FeatureGate>
  );
};

ConsentCategorySelect.propTypes = {
  formState: propTypes.object.isRequired,
  setField: propTypes.func.isRequired,
};
