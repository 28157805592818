import { gql } from '@apollo/client';

const removeConsentCategoryFragment = gql`
  fragment removeConsentCategory on consent_categories {
    id
  }
`;

export const mutation = gql`
  mutation RemoveConsentCategory(
    $consent_categories_pk_columns: consent_categories_pk_columns_input!
    $consent_categories_set_input: consent_categories_set_input!
  ) {
    update_consent_categories_by_pk(pk_columns: $consent_categories_pk_columns, _set: $consent_categories_set_input) {
      ...removeConsentCategory
    }
  }
  ${removeConsentCategoryFragment}
`;

export function buildVariables({ consentCategoryId }) {
  return {
    consent_categories_pk_columns: {
      id: consentCategoryId,
    },
    consent_categories_set_input: {
      deletedAt: 'now()', // Sets to current db time.
    },
  };
}
