import React from 'react';
import NameCell from './name-cell';
import EmailCell from './email-cell';
import RoleCell from './role-cell';
import ActionCell from './action-cell/ActionCell';

export const COLUMNS = [
  {
    name: 'Name',
    cell: row => <NameCell name={row.name} />,
  },
  {
    name: 'Email address',
    cell: row => <EmailCell email={row.email} />,
  },
  {
    name: 'Role',
    cell: row => <RoleCell roles={row.roles} />,
  },
  {
    button: true,
    cell: row => <ActionCell row={row} />,
  },
];
