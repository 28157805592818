import React from 'react';
import { OverlayProvider } from '@react-aria/overlays';
import * as Sentry from '@sentry/react';

// Important: This has to go ahead of other CSS.
import './root.scss';

import Apollo from '@ion/api/Apollo';
import { MrIconDefs, MrSvgDefs } from '@ion/components';
import { NotificationRoot, MrModalRoot, notificationStore, globalModalStore } from '@ion/global';
import { UserContext } from '@ion/user';
import { MrMuiThemeProvider, LocalizationProvider } from '@metarouter-private/mr-mui';
import { ErrorPage } from 'app/error-pages';
import MrMobileBlocker from '../error-pages/mobile-blocker';

import { Routes } from '../routes/Routes';

// import { useFlags } from 'launchdarkly-react-client-sdk'

// uncomment to manually test the error boundary fallback component
// function Bomb() {
//   throw new Error('💥 BOOM 💥')
// }

const Root = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <UserContext>
        <MrMuiThemeProvider>
          <LocalizationProvider>
            {/* <Bomb /> */}
            <MrModalRoot />
            <NotificationRoot />
            <MrIconDefs />
            <MrSvgDefs />
            <MrMobileBlocker />

            <Apollo
              notifications={{
                success: notificationStore.success,
                warning: notificationStore.warning,
                error: globalModalStore.addNotification,
              }}
            >
              <OverlayProvider>
                <Routes />
              </OverlayProvider>
            </Apollo>
          </LocalizationProvider>
        </MrMuiThemeProvider>
      </UserContext>
    </Sentry.ErrorBoundary>
  );
};
export default Root;
