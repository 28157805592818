import React, { useMemo } from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import styles from './base-input-radio.module.scss';
import generateId from '../../utils/uuid';

export default function MrBaseInputRadio({
  label,
  name,
  value,
  disabled,
  checked,
  onSelect,
  className,
  testId = 'MrInputRadio',
}) {
  const id = useMemo(() => generateId(), []);

  return (
    <div className={cx(styles.container, className)} data-test-id={testId}>
      <div className={cx(styles.inputWrapper)}>
        <input
          id={id}
          className={cx(styles.radio)}
          type="radio"
          disabled={disabled}
          name={name}
          onChange={onSelect}
          checked={checked}
          value={value}
        />
      </div>
      <label className={cx(styles.label, styles.labelRight)} htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

MrBaseInputRadio.propTypes = {
  label: pt.string.isRequired,
  name: pt.string.isRequired,
  value: pt.string.isRequired,
  disabled: pt.bool,
  checked: pt.bool,
  onSelect: pt.func,
  className: pt.string,
  testId: pt.string,
};
