import auditLogMutation from '../insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const mutation = auditLogMutation;

export function buildVariables({ pipelineId, pipelineName }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      pipelineId,
      pipelineName,
    }),
  };
}
