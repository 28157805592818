import pt from 'prop-types';

import React, { createContext, useContext, useState } from 'react';
import { DEFAULT_DATA_USER_INFO } from './hooks/useUserState';

const UserContextValue = createContext(DEFAULT_DATA_USER_INFO);

export const UserContext = ({ children }) => {
  const [state, setState] = useState(DEFAULT_DATA_USER_INFO);

  const { sessionToken, currentOrg, hasRoles, isLoading, isOrgOverride, user } = state;

  return (
    <UserContextValue.Provider value={{ sessionToken, user, currentOrg, hasRoles, isLoading, isOrgOverride, setState }}>
      {children}
    </UserContextValue.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContextValue);
};

UserContext.propTypes = {
  children: pt.node.isRequired,
};
