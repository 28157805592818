import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import { MrAvatar, MrH } from '@ion/components';

import s from './index.module.scss';

const OrgAvatar = ({ org, className }) => {
  return (
    <div className={cx(s.org, className)}>
      <MrAvatar name={org?.name} small />
      <MrH h="h4" sans>
        {org.display_name}
      </MrH>
    </div>
  );
};

OrgAvatar.propTypes = {
  className: pt.string,
  showDetails: pt.bool,
  org: pt.object,
};

export default OrgAvatar;
