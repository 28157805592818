import { gql } from '@apollo/client';

export const mutation = gql`
  mutation SwitchOrg($org_id: String!) {
    auth0_user_switch_orgs(org_id: $org_id) {
      orgId
      orgName
    }
  }
`;

export function buildVariables({ orgId }) {
  return {
    org_id: orgId,
  };
}
