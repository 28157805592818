import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ email, roleName }) {
  const action = actions.user_invited;
  const description = `${email} invited as ${roleName}`;
  const debug_data = { email, roleName };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
