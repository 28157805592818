import React from 'react';
import pt from 'prop-types';
import NavLink from './nav-link';

export default function NavLinkSection({ links, section, type, isActive }) {
  const sectionMidlinks = links.filter(l => l.section === section);
  return (
    <>
      {sectionMidlinks.map(l => (
        <NavLink name={l.name} key={l.name} isActive={isActive(l.routes)} label={type === 'label'} />
      ))}
    </>
  );
}

NavLinkSection.propTypes = {
  links: pt.array,
  section: pt.string,
  type: pt.string,
  isActive: pt.func,
};
