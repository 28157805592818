import React from 'react';

import pt from 'prop-types';
import s from 'app/event-schemas/list/index.module.scss';

const ReportRow = ({ report, onClick, isSelected }) => {
  return (
    <div className={isSelected ? s.selected : s.row} onClick={onClick}>
      <div className={s.col}>{report.eventName}</div>
      <div className={s.col}>{report.pipelineNames}</div>
      <div className={s.col}>
        {report.lastSeenDate} <span className={s.time}>{report.lastSeenTime}</span>
      </div>
      <div className={s.col}>{report.combinations}</div>
      <div className={s.col}>{report.totalCount}</div>
    </div>
  );
};

ReportRow.propTypes = {
  report: pt.shape({
    eventName: pt.string,
    writekey: pt.string,
    totalCount: pt.string,
    combinations: pt.string,
    lastSeenDate: pt.string,
    lastSeenTime: pt.string,
    pipeline: pt.shape({ name: pt.string }),
    pipelineNames: pt.string,
  }).isRequired,
  onClick: pt.func.isRequired,
  isSelected: pt.bool,
};

export default ReportRow;
