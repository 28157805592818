import { isNil } from 'lodash';
export function getClusterId(data) {
  if (isNil(data)) {
    return null;
  }
  const mostRecentlyUpdatedPipelineIntegration = data.pipeline_integrations[0];

  if (mostRecentlyUpdatedPipelineIntegration) {
    return mostRecentlyUpdatedPipelineIntegration.pipeline.clusterId;
  }

  const firstCluster = data.clusters[0];

  if (firstCluster) {
    return firstCluster.id;
  }

  return null;
}
