import { gql } from '@apollo/client';

import { default as consentCategory } from '../consent-category';

export const mutation = gql`
  mutation InsertConsentCategory($consent_categories_insert_input: consent_categories_insert_input!) {
    insert_consent_categories_one(object: $consent_categories_insert_input) {
      ...consentCategory
    }
  }
  ${consentCategory}
`;

export function buildVariables({ id, code, name }) {
  return {
    consent_categories_insert_input: {
      id,
      code,
      name,
    },
  };
}
