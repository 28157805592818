import React, { useState } from 'react';
import { MrDateTimeRangePicker } from '../../index';
import { omit } from 'lodash';
import propTypes from 'prop-types';

export default function UncontrolledDateTimeRangePicker(props) {
  const [startTime, setStartTime] = useState(props.defaultStartTime ?? new Date());
  const [endTime, setEndTime] = useState(props.defaultEndTime ?? new Date());

  const onChange = ({ startTime, endTime }) => {
    setStartTime(startTime);
    setEndTime(endTime);
    if (props.onChange) {
      props.onChange({ startTime, endTime });
    }
  };

  return <MrDateTimeRangePicker {...props} onChange={onChange} startTime={startTime} endTime={endTime} />;
}

UncontrolledDateTimeRangePicker.propTypes = {
  ...omit(MrDateTimeRangePicker.propTypes, 'startTime', 'endTime', 'onChange'),
  defaultStartTime: propTypes.instanceOf(Date),
  defaultEndTime: propTypes.instanceOf(Date),
  onChange: propTypes.func,
};
