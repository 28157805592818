import React from 'react';
import propTypes from 'prop-types';

import { MrCountTableTitle } from '@ion/components';

export const PipelineIntegrationsTableTitle = ({ tableData }) => {
  const count = tableData.length;

  const countTableTitleProps = {
    title: {
      singular: 'Integration',
      plural: 'Integrations',
    },
    count,
  };

  return <MrCountTableTitle {...countTableTitleProps} />;
};

PipelineIntegrationsTableTitle.propTypes = {
  tableData: propTypes.array,
};
