import React, { useState } from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './input-upload.module.scss';

const MrInputUpload = ({ className, accept, onChange, testId = 'MrInputUpload' }) => {
  const defaultText = 'No file chosen';
  const [filename, setFilename] = useState(defaultText);

  return (
    <div className={cx(s.inputUpload, className)} title={filename} data-test-id={testId}>
      <input
        id="upload"
        type="file"
        accept={accept}
        onChange={e => {
          const name = e.target?.files[0]?.name;

          setFilename(name || defaultText);

          onChange(e);
        }}
      />

      <label htmlFor="upload">Select File</label>

      <span>{filename}</span>
    </div>
  );
};

MrInputUpload.propTypes = {
  className: pt.string,
  accept: pt.string,
  onChange: pt.func,
  testId: pt.string,
};

export default MrInputUpload;
