export default (uploadedConnection, currentConnection) => {
  const uploadedFileConnectionDataParams = uploadedConnection?.endpointSchema?.params ?? [];
  const currentPipelineParam = currentConnection?.endpointSchema?.params ?? [];
  uploadedFileConnectionDataParams.forEach((param, index) => {
    currentPipelineParam.forEach(currentParam => {
      if (param.name === currentParam.name && currentParam?.pipelineVarId !== undefined) {
        uploadedConnection.endpointSchema.params[index].pipelineVarId = currentParam.pipelineVarId;
      }
    });
  });
  return uploadedConnection;
};
