import { keyBy as _keyBy, isEmpty as _isEmpty } from 'lodash';
import { AUTH0_USER_ROLES } from './constants';

export default function getHighestTierRole(members) {
  return members.map(member => {
    const roleMap = _keyBy(member.roles, 'name');

    if (_isEmpty(member.roles)) {
      return member;
    }

    if (roleMap[AUTH0_USER_ROLES.ORGANIZATION_ADMIN]) {
      return {
        ...member,
        roles: [roleMap[AUTH0_USER_ROLES.ORGANIZATION_ADMIN]],
      };
    }

    if (roleMap[AUTH0_USER_ROLES.DEPLOYMENT_MANAGER]) {
      return {
        ...member,
        roles: [roleMap[AUTH0_USER_ROLES.DEPLOYMENT_MANAGER]],
      };
    }

    if (roleMap[AUTH0_USER_ROLES.INTEGRATION_MANAGER]) {
      return {
        ...member,
        roles: [roleMap[AUTH0_USER_ROLES.INTEGRATION_MANAGER]],
      };
    }

    if (roleMap[AUTH0_USER_ROLES.READ_ONLY]) {
      return {
        ...member,
        roles: [roleMap[AUTH0_USER_ROLES.READ_ONLY]],
      };
    }
  });
}
