import { keys as _keys, uniqWith as _uniqWith, groupBy as _groupBy, map as _map, flatten as _flatten } from 'lodash';
import { getParams } from './get-params';
import { flattenOperations } from './flatten-operations';

export const getOperations = (section, state) => {
  if (section === 'global') {
    return flattenOperations(state.playbook.global);
  }

  if (section === 'default') {
    return flattenOperations(state.playbook.default);
  }

  if (section === 'eventSpecific') {
    const eventOperations = {};
    _keys(state.playbook.eventSpecific).forEach(event => {
      eventOperations[event] = flattenOperations(state.playbook.eventSpecific[event]);
    });
    return eventOperations;
  }

  // We remove params duplicates and updates it bubble here
  if (section === 'params') {
    const params = getParams(state.connectionJSON, state.playbook);

    const updateBubble = _flatten(
      _map(
        _groupBy(params, p => p.name),
        grouped => {
          if (grouped.length !== 1) {
            return [...grouped.map(p => ({ ...p, _path: 'connection/playbook.params' }))];
          }
          return grouped;
        }
      ) ?? []
    );

    return _uniqWith(updateBubble, (a, b) => a.name === b.name)
      .map(op => op)
      .sort((a, b) => a.opIndex - b.opIndex);
  }
};
