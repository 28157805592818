import { useCallback } from 'react';

export default function useOnSelectCallback({
  name,
  setSelectedValue,
  resetError = () => null,
  onChange = () => null,
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSelectedValueCallback = useCallback(setSelectedValue, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetErrorCallback = useCallback(resetError, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeCallback = useCallback(onChange, []);

  return useCallback(
    event => {
      const value = event.target.value;
      setSelectedValueCallback(value);

      if (resetErrorCallback) {
        // Wow this is gross
        // We have to do this because a radio group corresponds to multiple inputs
        // and the existing `resetError` code assumes the event.target is the
        // field with the validator.
        resetErrorCallback({ target: { name } });
      }

      if (onChangeCallback) {
        onChangeCallback({ target: { name, value } });
      }
    },
    [name, setSelectedValueCallback, resetErrorCallback, onChangeCallback]
  );
}
