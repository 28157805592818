import { useMutation } from '../../../index';
import permission from '../../../Apollo/permissions';
import mergeQueries from '../../../Apollo/merge-queries';
import { query as StagedRevisions } from '../../staged-revisions/useQueryStagedRevisions';

import { mutation as updatePipelineVariableMutation } from './update-pipeline-variable-mutation-builder';
import { buildVariables as buildUpdatePipelineVariables } from './update-pipeline-variable-mutation-builder';

import { mutation as pipelineVariableUpdatedMutation } from '../../../audit-logs/pipeline-variable-updated/audit-log-mutation-builder';
import { buildVariables as buildPipelineVariableUpdatedVariables } from '../../../audit-logs/pipeline-variable-updated/audit-log-mutation-builder';

export const combinedMutation = mergeQueries('UpdatePipelineVariableAndAudit', [
  updatePipelineVariableMutation,
  pipelineVariableUpdatedMutation,
]);

export default function useUpdatePipelineVariable(options = {}) {
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Pipeline variable updated.',
    context: { permission: permission.updatePipeline },
    refetchQueries: [
      {
        query: StagedRevisions,
        context: { permission: permission.updatePipeline },
      },
    ],
    ...options,
  });
  const updateAndAuditMutation = ({ id, pipelineName, pipelineVars, readyForDeploy }) => {
    const variables = buildVariables({ id, pipelineName, pipelineVars, readyForDeploy });
    return executeMutation(variables);
  };

  return [updateAndAuditMutation, response];
}

export function buildVariables({ id, pipelineName, pipelineVars, readyForDeploy }) {
  return {
    variables: {
      ...buildUpdatePipelineVariables({ id, pipelineVars, readyForDeploy }),
      ...buildPipelineVariableUpdatedVariables({ id, pipelineName, pipelineVars, readyForDeploy }),
    },
  };
}
