import { invert } from 'lodash';

import { AUTH0_USER_ROLES } from '../roles/constants';

export default function mapAuth0UserRoleData(roleListMap, roleId) {
  const selectedAuth0UserRole = invert(roleListMap)[roleId];
  const roleData = { roleIds: [], roleName: selectedAuth0UserRole };

  if (selectedAuth0UserRole === AUTH0_USER_ROLES.ORGANIZATION_ADMIN) {
    roleData.roleIds = [
      roleListMap[AUTH0_USER_ROLES.ORGANIZATION_ADMIN],
      roleListMap[AUTH0_USER_ROLES.DEPLOYMENT_MANAGER],
      roleListMap[AUTH0_USER_ROLES.INTEGRATION_MANAGER],
      roleListMap[AUTH0_USER_ROLES.READ_ONLY],
    ];
    return roleData;
  }

  if (selectedAuth0UserRole === AUTH0_USER_ROLES.DEPLOYMENT_MANAGER) {
    roleData.roleIds = [
      roleListMap[AUTH0_USER_ROLES.DEPLOYMENT_MANAGER],
      roleListMap[AUTH0_USER_ROLES.INTEGRATION_MANAGER],
      roleListMap[AUTH0_USER_ROLES.READ_ONLY],
    ];
    return roleData;
  }

  if (selectedAuth0UserRole === AUTH0_USER_ROLES.INTEGRATION_MANAGER) {
    roleData.roleIds = [roleListMap[AUTH0_USER_ROLES.INTEGRATION_MANAGER], roleListMap[AUTH0_USER_ROLES.READ_ONLY]];
    return roleData;
  }

  if (selectedAuth0UserRole === AUTH0_USER_ROLES.READ_ONLY) {
    roleData.roleIds = [roleListMap[AUTH0_USER_ROLES.READ_ONLY]];
    return roleData;
  }
}
