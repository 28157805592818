import _ from 'lodash';

export const hydrateToRequestFetchSync = data => {
  return JSON.stringify(data.map(fetch => singleFetch(fetch)));
};

const singleFetch = fetch => {
  return {
    name: fetch.name,
    fetchSettings: getFetchSettings(fetch),
    jsonPathIdentities: getJsonPathIdentities(fetch),
    headerIdentities: getHeaderIdentities(fetch),
  };
};

const getFetchSettings = fetch => {
  return {
    cookieSuccessAge: fetch.cookieSuccessAge,
    cookieFailureAge: fetch.cookieFailureAge,
    url: fetch.url,
    method: fetch.method,
    maxTimeout: fetch.maxTimeout,
  };
};

const getJsonPathIdentities = fetch => {
  if (!fetch?.jsonPathIdentities) return [];
  let values = [];
  const identities = fetch?.jsonPathIdentities?.split(',').map(i => i.trim());
  if (identities !== undefined) {
    values = identities.map(identity => getIdentityValues(identity));
  }
  return values;
};

const getHeaderIdentities = fetch => {
  if (!fetch?.headerIdentities) return [];
  let values = [];
  const headerIdentities = fetch?.headerIdentities?.split(',').map(i => i.trim());
  if (headerIdentities !== undefined) {
    values = headerIdentities.map(identity => {
      return {
        name: identity,
        required: true,
      };
    });
  }
  return values;
};

const getIdentityValues = identity => {
  const values = identity.split('.').map(i => i.trim());
  return {
    jsonPath: values,
    name: _.last(values),
    required: true,
  };
};
