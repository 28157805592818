import React from 'react';
import pt from 'prop-types';

import BulkDeployPipelines from 'app/pipelines/bulk-stage-and-deploy/bulk-deploy-pipelines/index.js';

const BulkDeployPipelinesPage = ({ location }) => {
  const { pipelines, pipelinesFilter, pipelinesTransform } = location.state;
  return (
    <BulkDeployPipelines
      pipelines={pipelines}
      pipelinesFilter={pipelinesFilter}
      pipelinesTransform={pipelinesTransform}
    />
  );
};

BulkDeployPipelinesPage.propTypes = {
  location: pt.object,
};

export default BulkDeployPipelinesPage;
