import useEventListener from './use-event-listener';

const useKey = (key, callback) => {
  useEventListener(
    'keydown',
    e => {
      if (e.key === key) {
        callback();
      }
    },
    document
  );
};

export default useKey;
