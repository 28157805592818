import React from 'react';
import pt from 'prop-types';

import { useFormContext } from 'app/context/form-context';

import S3 from './s3';
import Gcs from './Gcs';
import ParamTypeSelect from './ParamTypeSelect';

const stageOptions = [
  {
    text: 'Amazon S3',
    value: 'S_3',
  },
  {
    text: 'Google Cloud Storage',
    value: 'GCS',
  },
];

const Stage = ({
  opId,
  _path,
  stageType,
  region,
  bucket,
  prefix,
  accessKey,
  secretKey,
  serverSideEncryption,
  compression,
  credentialJson,
}) => {
  const { validateField, formState, setField } = useFormContext();

  const isGcs = stageType === 'GCS';
  const isS3 = stageType === 'S_3';

  return (
    <>
      <ParamTypeSelect
        fieldName="stageType"
        opId={opId}
        _path={_path}
        formState={formState}
        validateField={validateField}
        setField={setField}
        options={stageOptions}
        value={stageType}
      />
      {isS3 && (
        <S3
          opId={opId}
          _path={_path}
          region={region}
          bucket={bucket}
          prefix={prefix}
          accessKey={accessKey}
          secretKey={secretKey}
          serverSideEncryption={serverSideEncryption}
          compression={compression}
        />
      )}

      {isGcs && (
        <Gcs
          opId={opId}
          _path={_path}
          bucket={bucket}
          prefix={prefix}
          credentialJson={credentialJson}
          compression={compression}
        />
      )}
    </>
  );
};

Stage.propTypes = {
  stageType: pt.string,
  region: pt.string,
  bucket: pt.string,
  prefix: pt.string,
  accessKey: pt.string,
  secretKey: pt.string,
  serverSideEncryption: pt.string,
  compression: pt.string,
  credentialJson: pt.string,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default Stage;
