import React from 'react';
import pt from 'prop-types';
import { cloneDeep as _cloneDeep, toString as _toString } from 'lodash';
import IntegrationLogo from 'app/components/integrations/integration-logo';
import FeatureGate from '@ion/app/src/launch-darkly/gates/feature-gate';
import {
  MrH,
  MrInputText,
  MrCard,
  MrInputTextarea,
  MrInputSelectDeprecated,
  MrInputNumber,
  MrToggleText,
} from '@ion/components';
import s from './index.module.scss';
import IntegrationTableTitle from './integration-table-title/IntegrationTableTitle';

const AnalyticsBuilderIntegrations = ({
  formState,
  integrations,
  stagedIntegrations = [],
  stagedIntegrationsData = [],
  setField,
  validateField,
  validateRequired,
  validateNumber,
  resetError,
  setModalOpen,
  setStagedIntegrations,
}) => {
  // remove staged options from integration dropdowns
  const integrationOptions = integrations
    .map(i => ({
      value: i.name,
      text: (
        <>
          <IntegrationLogo id={i.name} type="dropdownLogo" />
          {i.friendlyName}
        </>
      ),
    }))
    .filter(i => {
      return !stagedIntegrations.includes(i.value);
    });

  return (
    <FeatureGate flagName="ecmAjsClientSideIntegrations">
      <section className={s.section}>
        <MrH h="h5" sans styleNames="sans noMargin">
          <div className={s.circle}>&#65290;</div> Client-Side Integrations
        </MrH>
        <p className={s.headerHelperText}>
          Configure your client-side integrations here. Note that these are slated for deprecation and are no longer in
          use by most MetaRouter customers. If you require help with a client-side integration, please reach out to your
          MetaRouter Customer Success Manager.
        </p>
        <div className={s.subSection}>
          {stagedIntegrationsData.map(integration => (
            <MrCard
              className={s.builderCard}
              key={integration.name}
              top={
                <IntegrationTableTitle
                  logoName={integration.name}
                  title={integration.friendlyName}
                  onClickDelete={() => {
                    setModalOpen({ data: integration, type: 'Client-Side Integration' });
                  }}
                  deleteTitle="Remove Staged Integration"
                />
              }
              bottom={integration.fields.map(field => {
                if (field.type === 'TEXT_INPUT') {
                  const name = `integration-${integration.name}-${field.name}`;
                  if (typeof field.defaultValue === 'number') {
                    return (
                      <div className={s.field} key={name}>
                        <MrInputNumber
                          key={name}
                          onChange={setField}
                          value={formState[name]?.value ?? ''}
                          onBlur={validateField}
                          onInput={resetError}
                          name={name}
                          errorMsg={formState[name]?.errorMsg}
                          validators={field.required ? [validateRequired(), validateNumber()] : [validateNumber()]}
                          placeholder={field.defaultValue}
                          label={field.friendlyName}
                          labelPosition="top"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div className={s.field} key={name}>
                        <MrInputText
                          key={name}
                          onChange={setField}
                          value={formState[name]?.value ?? ''}
                          onBlur={validateField}
                          onInput={resetError}
                          name={name}
                          errorMsg={formState[name]?.errorMsg}
                          validators={field.required ? [validateRequired()] : []}
                          placeholder={field.defaultValue?.toString()}
                          label={field.friendlyName}
                        />
                      </div>
                    );
                  }
                }
                if (field.type === 'TEXT_AREA') {
                  const name = `integration-${integration.name}-${field.name}`;
                  return (
                    <div className={s.field} key={name}>
                      <MrInputTextarea
                        key={name}
                        onChange={setField}
                        value={formState[name]?.value ?? ''}
                        onBlur={validateField}
                        onInput={resetError}
                        name={name}
                        errorMsg={formState[name]?.errorMsg}
                        validators={field.required ? [validateRequired()] : []}
                        placeholder={field.defaultValue ? JSON.stringify(field.defaultValue) : ''}
                        label={field.friendlyName}
                      />
                    </div>
                  );
                }
                if (field.type === 'BOOL') {
                  const name = `integration-${integration.name}-${field.name}`;
                  const options = { option1: { name: 'OFF', value: 'false' }, option2: { name: 'ON', value: 'true' } };
                  const value = _toString(formState[name]?.value ?? field.defaultValue);

                  return (
                    <div className={s.field} key={name}>
                      <MrToggleText
                        label={field.friendlyName}
                        onChange={setField}
                        name={name}
                        value={value}
                        options={options}
                      />
                    </div>
                  );
                }
              })}
            ></MrCard>
          ))}
          <div className={s.selectContainer}>
            <div className={s.selectTitle}>Add new Integration</div>
            <MrInputSelectDeprecated
              testId="clientSideIntegrations"
              className={s.inputSelect}
              name="integrations"
              options={integrationOptions}
              placeholder="Select Client-Side Integrations..."
              value={stagedIntegrations}
              isSearchable={true}
              alphabetized={false}
              onChange={e => {
                // add selected value to list of staged integrations
                const newStagedIntegrations = _cloneDeep(stagedIntegrations);
                newStagedIntegrations.push(e.target.value);
                setStagedIntegrations(newStagedIntegrations);
              }}
            />
          </div>
        </div>
      </section>
    </FeatureGate>
  );
};

AnalyticsBuilderIntegrations.propTypes = {
  formState: pt.object,
  integrations: pt.array,
  stagedIntegrations: pt.array,
  stagedIntegrationsData: pt.array,
  setField: pt.func,
  validateField: pt.func,
  validateRequired: pt.func,
  validateNumber: pt.func,
  resetError: pt.func,
  setModalOpen: pt.func,
  setStagedIntegrations: pt.func,
};

export default AnalyticsBuilderIntegrations;
