const validateStagedPipeline = pipelineIntegrations => {
  pipelineIntegrations.forEach(pipelineIntegration => {
    if (pipelineIntegration.stagedRevision.playbook === undefined) {
      throw new Error(`Invalid staged revision`);
    }

    const {
      playbook: { connectionJson, playbookJson, vars, integration, revision },
      pipelineVars,
    } = pipelineIntegration.stagedRevision;

    if (integration?.starterkitId === undefined) {
      throw new Error(`Integration not found for staged revision`);
    }

    if (connectionJson === undefined) {
      throw new Error(`Connection data not found for staged revision: ${integration.name} revision ${revision}`);
    }

    if (playbookJson === undefined) {
      throw new Error(`Playbook JSON data not found for staged revision: ${integration.name} revision ${revision}`);
    }

    if (vars === undefined) {
      throw new Error(`Vars not found for staged revision: ${integration.name} revision ${revision}`);
    }

    if (pipelineVars === undefined) {
      throw new Error(`Pipeline variables not found for staged revision: ${integration.name} revision ${revision}`);
    }
  });
};

export default validateStagedPipeline;
