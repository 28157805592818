import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { groupBy } from 'lodash';
import ErrorsSection from './ErrorsSection';
import { Grid, Typography } from '@metarouter-private/mr-mui';
import useTrackWithUser from '@ion/api/hooks/useTrackWithUser';

export const OutputError = ({ outputErrors }) => {
  const groupedErrors = groupBy(outputErrors, 'type');
  const errorTypes = Object.keys(groupedErrors).sort();
  const [trackInputErrorReceived] = useTrackWithUser('input_event_error_received');

  useEffect(() => {
    trackInputErrorReceived();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid direction="column" container padding={1} paddingTop={0}>
      <Typography align="center" variant="h5">
        Unable to transform event with Playbook
      </Typography>
      {errorTypes.map(errorType => (
        <ErrorsSection key={errorType} errorType={errorType} errors={groupedErrors[errorType]} />
      ))}
    </Grid>
  );
};

OutputError.propTypes = {
  outputErrors: propTypes.array.isRequired,
};
