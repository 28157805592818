export default {
  REQUEST_NAME: 'fetchSync',
  SINGLE_SELECT_DESCRIPTION: 'Fetch Sync',
  DESCRIPTION:
    'Fetch syncs are used to fetch data from a URL and extract data from the response. The data can be extracted using JSONPath or from the response headers.',
  REQUEST_INPUT_NAME: 'syncs',
  FIELDS: [
    {
      type: 'TEXT',
      property: 'name',
      title: 'Name',
      description: 'The name of the sync',
      required: true,
      default: '',
      placeholder: 'fetchIdentity',
    },
    {
      type: 'NUMBER',
      property: 'cookieSuccessAge',
      title: 'Storage Success Age (in days)',
      description: 'How long should the data be persisted, before making another call',
      required: true,
      default: 7,
      placeholder: '',
    },
    {
      type: 'NUMBER',
      property: 'cookieFailureAge',
      title: 'Storage Failure Age (in days)',
      description: 'How long should we wait before trying again',
      required: true,
      default: 1,
      placeholder: '',
    },
    {
      type: 'TEXT',
      property: 'url',
      title: 'URL',
      description: 'The URL where the request should be made',
      required: true,
      default: '',
      placeholder: 'e.g., https://my.identity.com',
    },
    {
      type: 'TEXT',
      property: 'method',
      title: 'Method (POST/GET/PUT/DELETE)',
      description: 'The HTTP method that should be used when making a call',
      required: true,
      default: '',
      placeholder: 'e.g., POST',
    },
    {
      type: 'NUMBER',
      property: 'maxTimeout',
      title: 'Max timeout (in milliseconds)',
      description: 'How long should we wait until the request is cancelled',
      required: true,
      default: 1000,
      placeholder: '',
    },
    {
      type: 'TEXT_AREA',
      property: 'jsonPathIdentities',
      title: 'JSON response data path (dot notation)',
      description: 'Where in the response data should we look for the identities. Use comma separated values.',
      required: false,
      default: '',
      placeholder: 'e.g., user.data.id, personalization.color',
    },
    {
      type: 'TEXT_AREA',
      property: 'headerIdentities',
      title: 'Header names',
      description: 'The name of the headers that should be used to get the identities. Use comma separated values.',
      required: false,
      default: '',
      placeholder: 'e.g., userID,matchID',
    },
  ],
};
