import { useState } from 'react';

const useToggle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  return [isOpen, onToggle];
};

export default useToggle;
