import React from 'react';
import propTypes from 'prop-types';
import moment from 'moment';

export default function MrTimeAgo({ date }) {
  return <>{moment(date).fromNow()}</>;
}

MrTimeAgo.propTypes = {
  date: propTypes.instanceOf(Date).isRequired,
};
