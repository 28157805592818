import ajsstarterkits from '@metarouter/ajs-starter-kit';
import { map as _map, omit as _omit } from 'lodash';
import packageJson from '../../package.json';

const kits = ajskits => {
  const kits = _map(_omit(ajskits, 'etags'), (kit, integrationName) => {
    const { endpointSchema, connectionTemplate, playbook, metadata } = kit;
    const connectionJson = {
      ...(endpointSchema && { endpointSchema }),
      ...(connectionTemplate && { connectionTemplate }),
    };
    return {
      id: integrationName,
      metadata,
      playbookJson: playbook,
      connectionJson: connectionJson,
      friendlyName: metadata.friendlyName,
    };
  });

  return kits;
};

const kitById = (kits, starterkitId) => {
  return kits.find(kit => kit.id === starterkitId);
};

const StarterKits = (ajskits = ajsstarterkits) => {
  return {
    kits: kits(ajskits),
    version: packageJson.dependencies['@metarouter/ajs-starter-kit'],
    kitById,
  };
};

export default StarterKits;
