import React from 'react';
import propTypes from 'prop-types';

import { MrInputRadio, MrInputRadioGroup } from '@ion/components';

const RADIO_GROUP_LABEL = 'How would you like to host your Analytics.js file?';

export const HOSTING_TYPES = {
  self: 'SELF',
  metaRouter: 'META_ROUTER',
};

const HOSTING_TYPE_VALUES = Object.values(HOSTING_TYPES);

export const READABLE_HOSTING_TYPE = {
  [HOSTING_TYPES.self]: 'Self-hosted',
  [HOSTING_TYPES.metaRouter]: 'MetaRouter-hosted',
};

export default function AnalyticsBuilderFileHosting({ value = '', onChange, resetError, errorMsg }) {
  return (
    <MrInputRadioGroup
      name="fileHostingType"
      label={RADIO_GROUP_LABEL}
      overrideValue={value}
      onChange={onChange}
      errorMsg={errorMsg}
      resetError={resetError}
      required
    >
      <MrInputRadio
        value={HOSTING_TYPES.self}
        label="Self-hosted (Recommended)"
        tooltip="We recommend that you host your own Analytics.js file in order to receive the full benefits of first-party tracking."
      />
      <MrInputRadio
        value={HOSTING_TYPES.metaRouter}
        label="MetaRouter-hosted"
        tooltip="MetaRouter will host your Analytics.js file, removing the need for you to track down your CDN and deploy your file."
      />
    </MrInputRadioGroup>
  );
}

AnalyticsBuilderFileHosting.propTypes = {
  value: propTypes.oneOf([...HOSTING_TYPE_VALUES, '']),
  onChange: propTypes.func.isRequired,
  resetError: propTypes.func.isRequired,
  errorMsg: propTypes.string,
};
