import { useReducer } from 'react';
import { produce } from 'immer';

const actions = {
  SET_SECTION: Symbol(),
  SET_EVENT: Symbol(),
  SET_TRANSFORMATION: Symbol(),
  SET_REVISION: Symbol(),
};

const initialState = {
  selectedRevision: null,
  selectedSection: 'params', // Enum
  selectedEvent: null,
  selectedTransformation: null,
  sectionNumber: 1,
};

const sectionNumbers = {
  params: 1,
  global: 2,
  eventSpecific: 3,
  default: 4,
};

const reducer = produce((state, { type, payload }) => {
  switch (type) {
    case actions.SET_SECTION:
      state.selectedSection = payload.section;
      state.sectionNumber = sectionNumbers[payload.section];
      if (payload.section === 'eventSpecific') {
        const event = payload.events.length > 0 ? payload.events[0] : 'newEvent';
        state.selectedEvent = event;
      } else {
        state.selectedEvent = null;
      }
      state.selectedTransformation = null;
      break;
    case actions.SET_EVENT:
      //check for valid Enum and do not update if not valid
      state.selectedEvent = payload;
      state.selectedTransformation = null;
      break;
    case actions.SET_TRANSFORMATION:
      state.selectedTransformation = payload;
      break;
    case actions.SET_REVISION:
      state.selectedRevision = payload;
      break;
  }
  return state;
});

const usePlaybookNavState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setSection = (section, events) => {
    dispatch({
      type: actions.SET_SECTION,
      payload: {
        section,
        events,
      },
    });
  };

  const setEvent = event => {
    dispatch({
      type: actions.SET_EVENT,
      payload: event,
    });
  };

  const setTransformation = transformation => {
    dispatch({
      type: actions.SET_TRANSFORMATION,
      payload: transformation,
    });
  };

  const setRevision = revision => {
    dispatch({
      type: actions.SET_REVISION,
      payload: revision,
    });
  };

  return {
    setTransformation,
    setEvent,
    setSection,
    setRevision,
    ...state,
  };
};

export default usePlaybookNavState;
