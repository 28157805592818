import { query } from './auth0-organization-members-query-builder';
import permission from '../../Apollo/permissions';
import getHighestTierRole from '../roles/get-highest-tier-role';
import useLazyQuery from '../../hooks/useLazyQuery';

export default function useLazyQueryOrganizationMembers({ onCompleted, ...options } = {}) {
  const [executeQuery, { data, ...rest }] = useLazyQuery(query, {
    context: { permission: permission.readOrganizationMembers },
    onCompleted: response => {
      if (onCompleted) {
        onCompleted({ ...transformData(response.data), ...response });
      }
    },
    ...options,
  });

  const getOrganizationMembers = pagination => {
    const { page, perPage } = pagination;
    const variables = { page, perPage };
    return executeQuery({ variables });
  };

  return [getOrganizationMembers, { ...transformData(data), ...rest }];
}

function transformData(data) {
  if (!data) {
    return {
      data: [],
      pagination: { count: null },
    };
  }
  const { members, totalCount } = data.auth0_organization_members;

  return {
    members: getHighestTierRole(members),
    pagination: { count: totalCount },
  };
}
