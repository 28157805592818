import { useCallback, useState } from 'react';

export default function useRegisterCallbacks(initialValue = {}) {
  const [callbacks, setCallbacks] = useState(initialValue);

  const registerCallback = useCallback(
    (key, callback) => {
      setCallbacks({
        ...callbacks,
        [key]: callback,
      });
    },
    [callbacks]
  );

  const executeCallbacks = useCallback(
    args => {
      Object.values(callbacks).forEach(callback => callback(args));
    },
    [callbacks]
  );

  return [registerCallback, executeCallbacks, { callbacks }];
}
