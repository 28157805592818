import React from 'react';
import pt from 'prop-types';
import { Box, Stack, Typography } from '@metarouter-private/mr-mui';
import { default as RoleIcon } from './RoleIcon';

export default function RoleList({ roles }) {
  return roles.map(role => {
    return (
      <Box key={role.id}>
        <Stack direction="row" spacing={2}>
          <RoleIcon roleName={role.name} />
          <Typography variant="body2">{`${role.name} - ${role.description}`}</Typography>
        </Stack>
        <br />
      </Box>
    );
  });
}

RoleList.propTypes = {
  roles: pt.arrayOf(
    pt.shape({
      id: pt.string.isRequired,
      name: pt.string.isRequired,
      description: pt.string.isRequired,
    })
  ).isRequired,
};
