export const ERROR_TYPES = {
  SPREADING_FAILED: 'SPREADING_FAILED',
  MAPPING_FAILED: 'MAPPING_FAILED',
  EMPTY_EVENT: 'EMPTY_EVENT',
  FILTERED: 'FILTERED',
  EXPRESSION_FAILED: 'EXPRESSION_FAILED',
  ENRICHMENT_FAILED: 'ENRICHMENT_FAILED',
  INVALID_LUA: 'INVALID_LUA',
};

const ERROR_PREFIX_TO_ERROR_TYPE = {
  'spreading failed': ERROR_TYPES.SPREADING_FAILED,
  'mapping failed': ERROR_TYPES.MAPPING_FAILED,
  'empty event': ERROR_TYPES.EMPTY_EVENT,
  'did not pass filter condition': ERROR_TYPES.FILTERED,
  'expression failed': ERROR_TYPES.EXPRESSION_FAILED,
  'enrichment failed': ERROR_TYPES.ENRICHMENT_FAILED,
  'invalid lua': ERROR_TYPES.INVALID_LUA,
};

function splitErrorMessage(errorMessage) {
  const [prefix, ...rest] = errorMessage.split(':');
  const message = rest.join(':') || null;
  return {
    prefix,
    message,
  };
}

function buildError(errorMessage) {
  const { prefix, message } = splitErrorMessage(errorMessage);
  const type = ERROR_PREFIX_TO_ERROR_TYPE[prefix];
  if (!type) {
    throw new Error(`Unable to determine error type: ${prefix}`);
  }
  return { type, message };
}

export default function transformErrors(errors) {
  return errors.map(error => buildError(error.message));
}
