import { isEmpty as _isEmpty } from 'lodash';
import mapIntegration from './map-integration';
import NO_DATA_COMPONENT_MESSAGE from '../no-data-component-message';

function onCompleteDashboardProxy({ result, integrations, integrationList, setIntegrationList, setQueryParameter }) {
  if (_isEmpty(result)) {
    setIntegrationList([]);
    setQueryParameter({ integrationIds: [], noDataComponentMessage: NO_DATA_COMPONENT_MESSAGE.NO_DATA });
    return;
  }

  const { options } = mapIntegration(result, integrations);
  setIntegrationList(options);
  setQueryParameter({
    integrationIds: options.map(o => o.value),
    filterCount: options.length,
    ...(_isEmpty(integrationList) && {
      defaultFilter: true,
    }),
  });
}

export default onCompleteDashboardProxy;
