import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import { MrTable } from '@ion/components';

import styles from './index.module.scss';

const BaseTable = ({ children, styleNames, ...props }) => {
  return (
    <MrTable
      className={cx(
        styles.wrappedTable,
        styleNames?.split(' ').map(style => styles[style])
      )}
      {...props}
    >
      {children}
    </MrTable>
  );
};

BaseTable.propTypes = {
  children: pt.node.isRequired,
  styleNames: pt.string,
};

export default BaseTable;
