import React from 'react';
import pt from 'prop-types';

import ConfirmDelete from 'app/components/confirm-delete/confirm-delete';

import { MrH } from '@ion/components';
import s from './index.module.scss';

const DeleteEvent = ({ deleteEventName, playbookState, setDeleteEventName, navState }) => {
  return (
    <ConfirmDelete
      closeModal={() => {
        setDeleteEventName(null);
      }}
      deleteFn={() => {
        playbookState.deleteEvent(deleteEventName);
        navState.setEvent(null);

        //Default back to the top of the list
        const sortedEvents = playbookState.getSortedEvents();
        const firstEvent = deleteEventName === sortedEvents[0] ? sortedEvents[1] : sortedEvents[0];
        const defaultEvent = firstEvent ?? 'newEvent';
        navState.setEvent(defaultEvent);
      }}
    >
      <div className={s.header}>
        <MrH h="h2" styleNames="sans">
          Delete Event
        </MrH>
      </div>

      <div className={s.content}>
        <p>
          Delete <strong>{deleteEventName}</strong> from Event Parameters?
        </p>
      </div>
    </ConfirmDelete>
  );
};

DeleteEvent.propTypes = {
  deleteEventName: pt.string.isRequired,
  playbookState: pt.object.isRequired,
  setDeleteEventName: pt.func.isRequired,
  navState: pt.object.isRequired,
};
export default DeleteEvent;
