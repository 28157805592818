import useEventListener from './use-event-listener';

const useClickOutside = (ref, callback) => {
  // close if clicks outside
  useEventListener(
    'click',
    e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    },
    document,
    // Seems to fix issue where useClickOutside isn't working as expected.
    // See https://legacy.reactjs.org/blog/2020/08/10/react-v17-rc.html
    { capture: true }
  );
};

export default useClickOutside;
