import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ id, name, code }) {
  const action = actions.consent_category_created;
  const description = `${code} - ${name}`;
  const debug_data = {
    consentCategoryId: id,
    consentCategoryName: name,
    consentCategoryCode: code,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
