import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './avatar.module.scss';
import MrH from '../h/h';

const MrAvatar = ({ src, name, small, className, onClick, ...props }) => {
  const first = name ? name.charAt(0).toUpperCase() : '';
  return (
    <div className={cx(s.avatar, small && s.small, className)} onClick={onClick} {...props}>
      {src ? (
        <img src={src} alt={first} {...props} />
      ) : (
        <MrH h="h4" className={s.letter}>
          {first}
        </MrH>
      )}
    </div>
  );
};

MrAvatar.propTypes = {
  src: pt.string,
  name: pt.string,
  small: pt.bool,
  className: pt.string,
  onClick: pt.func,
};

export default MrAvatar;
