import { gql } from '@apollo/client';

export default gql`
  fragment integration on integrations {
    id
    name
    createdAt
    starterkitId
    starterkitVersion
    integration_notes {
      id
      notes
    }
  }
`;
