import React from 'react';
import pt from 'prop-types';

import { determineInputType, PIPELINE_VARIABLE_INPUT_TYPES } from '../determine-input-type';
import { MrInputTextarea, MrInputText, formValidators } from '@ion/components';
const { validateRequired } = formValidators;

export const PipelineVariableInput = ({ fieldName, formField, variableName, resetError, setField }) => {
  const type = determineInputType(variableName);

  const commonProps = {
    onInput: resetError,
    validators: [validateRequired()],
    label: variableName,
    name: fieldName,
    value: formField?.value ?? '',
    errorMsg: formField?.errorMsg ?? '',
    onChange: setField,
    required: true,
    fullWidth: true,
  };

  // TODO (EunHye 2024.05.31) - removes labelPosition="top"
  switch (type) {
    case PIPELINE_VARIABLE_INPUT_TYPES.TEXT_AREA:
      return <MrInputTextarea {...commonProps} />;
    case PIPELINE_VARIABLE_INPUT_TYPES.TEXT:
      return <MrInputText {...commonProps} labelPosition="top" />;
    default:
      throw new Error(`Unable to determine input field type from type: ${type}`);
  }
};

PipelineVariableInput.propTypes = {
  fieldName: pt.string.isRequired,
  formField: pt.object,
  variableName: pt.string.isRequired,
  resetError: pt.func,
  setField: pt.func,
};
