import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputText } from '@ion/components';

import { useFormContext } from 'app/context/form-context';
import Compression from './compression';

const { validateRequired } = formValidators;

const S3 = ({ region, bucket, prefix, accessKey, secretKey, serverSideEncryption, compression, opId, _path }) => {
  const { validateField, resetError, formState, setField } = useFormContext();

  return (
    <>
      {((name = `${opId}-region-${_path}`) => (
        <MrInputText
          label="REGION"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={region}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateRequired()]}
          placeholder="REGION"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-bucket-${_path}`) => (
        <MrInputText
          label="BUCKET"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={bucket}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateRequired()]}
          placeholder="BUCKET"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-prefix-${_path}`) => (
        <MrInputText
          label="PREFIX"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={prefix}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          placeholder="PREFIX"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-accessKey-${_path}`) => (
        <MrInputText
          label="ACCESS_KEY"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={accessKey}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateRequired()]}
          placeholder="ACCESS_KEY"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-secretKey-${_path}`) => (
        <MrInputText
          label="SECRET_KEY"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={secretKey}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateRequired()]}
          placeholder="SECRET_KEY"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-serverSideEncryption-${_path}`) => (
        <MrInputText
          label="SERVER_SIDE_ENCRYPTION"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={serverSideEncryption}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          placeholder="SERVER_SIDE_ENCRYPTION"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-compression-${_path}`) => (
        <Compression name={name} defaultValue={compression} isOptional={true} />
      ))()}
    </>
  );
};

S3.propTypes = {
  region: pt.string,
  bucket: pt.string,
  prefix: pt.string,
  accessKey: pt.string,
  secretKey: pt.string,
  serverSideEncryption: pt.string,
  compression: pt.string,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default S3;
