import { gql } from '@apollo/client';

export default gql`
  fragment stagedRevision on staged_revisions {
    id
    playbookId
    pipelineIntegrationId
    pipelineVars
    readyForDeploy
    stagedForDelete
  }
`;
