import { gql } from '@apollo/client';
import pipelineIntegration from '../pipelineIntegration';

export const mutation = gql`
  mutation InsertPipelineIntegration($pipeline_integrations_insert_input: pipeline_integrations_insert_input!) {
    insert_pipeline_integrations_one(object: $pipeline_integrations_insert_input) {
      ...pipelineIntegration
    }
  }
  ${pipelineIntegration}
`;

export function buildVariables({
  pipelineId,
  integrationId,
  consentCategoryId,
  playbookId,
  pipelineVars,
  readyForDeploy,
}) {
  return {
    pipeline_integrations_insert_input: {
      pipelineId,
      integrationId,
      ...(consentCategoryId && {
        pipeline_integration_consent: {
          data: {
            consentCategoryId,
          },
        },
      }),
      staged_revision: {
        data: {
          pipelineVars,
          readyForDeploy,
          playbookId,
        },
      },
    },
  };
}
