import React from 'react';

import { MrIconTooltip } from '@ion/components';

const tooltipMessage = 'This is an unmanaged integration';

const UnmanagedWarning = () => {
  return <MrIconTooltip message={tooltipMessage} />;
};

UnmanagedWarning.propTypes = {};

export default UnmanagedWarning;
