import React from 'react';
import pt from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  MrAppMain,
  MrCard,
  MrBack,
  MrH,
  MrButton,
  MrInputText,
  MrForm,
  useForm,
  generateId,
  formValidators,
} from '@ion/components';
import { useInsertIntegration, useQueryIntegrations } from '@ion/api';

import IntegrationLogo from '../../components/integrations/integration-logo';

import { routes } from 'app/constants';

import s from '../index.module.scss';

const { validateRequired, validateNotInArray } = formValidators;

const NewIntegrationForm = ({ starterkitId, starterkit }) => {
  const { friendlyName } = starterkit;
  const history = useHistory();
  const { data: integrations } = useQueryIntegrations();
  const integrationNames = integrations.map(i => i.name);

  const [createIntegration, { loading }] = useInsertIntegration({
    onCompleted: data => {
      history.push(routes.editPlaybook, { integrationId: data.insert_integrations_one.id });
    },
  });
  const formId = generateId();
  const { formState, validateField, resetError, submitForm, setField } = useForm(formId);

  const onFormSubmit = () => {
    const { data, isValid } = submitForm();

    if (isValid) {
      createIntegration({ name: data.name, starterkitId });
    }
  };

  return (
    <MrAppMain
      loading={loading || !starterkitId}
      styleNames="form"
      header={<MrBack to={routes.newIntegration} className={s.back} />}
    >
      <MrCard
        className={s.formCard}
        top={
          <div className={s.card}>
            <IntegrationLogo id={starterkitId} className={s.logo} />

            <div className={s.truncated}>
              <MrH h="h3" sans styleNames="ellipsis noMargin">
                {friendlyName || starterkitId}
              </MrH>
            </div>
          </div>
        }
        bottom={
          <MrForm onSubmit={onFormSubmit} id={formId}>
            <MrInputText
              onBlur={validateField}
              onInput={resetError}
              value={formState.name?.value ?? friendlyName ?? starterkitId ?? ''}
              onChange={setField}
              label="name"
              name="name"
              placeholder="Integration name"
              validators={[
                validateRequired(),
                validateNotInArray(integrationNames, 'Name already in use by another integration'),
              ]}
              errorMsg={formState.name?.errorMsg}
              fullWidth
              autoFocus
            />
            <MrButton text="Create" styleNames="marginTop" />
          </MrForm>
        }
      ></MrCard>
    </MrAppMain>
  );
};

NewIntegrationForm.propTypes = {
  starterkitId: pt.string.isRequired,
  starterkit: pt.object.isRequired,
};

export default NewIntegrationForm;
