const defaultSupportMessage = 'There was an error. Please try again or contact support.';
const permissionErrorMessage = `You do not have the required permission set to take this action`;

const permissionDenied = 'permission-denied';

const mapErrors = e => {
  if (!e) return null;
  const { message, graphQLErrors, networkError } = e;

  let errors;
  if (graphQLErrors?.length) errors = graphQLErrors;
  else if (networkError?.result?.errors) errors = networkError.result.errors;
  else if (networkError?.result?.error) errors = networkError.result.error;
  else if (networkError) errors = networkError;

  const isAllowed = graphQLErrors?.[0]?.extensions?.code !== permissionDenied;

  return {
    errorMsg: isAllowed ? message || defaultSupportMessage : permissionErrorMessage,
    errors,
    isAllowed,
  };
};

export default mapErrors;
