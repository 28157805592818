import buildPipelineJson from '@ion/app/src/pipelines/build-pipeline-json';
import mergeCurrentAndProposed from '@ion/app/src/pipelines/deploy-modal/merge-current-proposed';
const generatePipelineDiff = (
  diffPipeline,
  getDiff,
  integrations,
  allIntegrations,
  setIntegrations,
  user,
  setWaiting,
  diffPipelines
) => {
  let current;
  let proposed;
  let displayNames = {};
  const clusterId = diffPipeline.clusterId;
  const writekey = diffPipeline.writekey;
  let json;

  const updateDiffList = integrationDiff => {
    integrations[diffPipeline?.id] = integrationDiff;
    setIntegrations(integrations);
    if (Object.keys(integrations).length === diffPipelines) setWaiting(false);
  };

  try {
    json = buildPipelineJson(diffPipeline.pipelineIntegrations, user?.email, diffPipeline.writekey);
  } catch (err) {
    console.log(err);
    return;
  }

  getDiff({ clusterId, writekey, proposedJSON: json })
    .then(r => {
      if (r.errors) {
        updateDiffList([]);
        return;
      }

      const diff = r;
      current = diff.current;
      proposed = diff.proposed;

      if (current !== null) {
        current.integrations.forEach(i => {
          displayNames[i.id] = allIntegrations.find(integration => i?.id === integration?.id)?.name;
        });
      }

      if (proposed !== null) {
        proposed.integrations.forEach(i => {
          displayNames[i.id] = allIntegrations.find(integration => i?.id === integration?.id)?.name;
        });
      }

      const integrationDiff = mergeCurrentAndProposed({
        current,
        proposed,
        displayNames,
      });
      updateDiffList(integrationDiff);
    })
    .catch(() => {
      updateDiffList([]);
    });
};

export default generatePipelineDiff;
