import React from 'react';
import propTypes from 'prop-types';
import { isEmpty as _isEmpty } from 'lodash';
import { Typography } from '@metarouter-private/mr-mui';

export default function RoleCell({ roles }) {
  return !_isEmpty(roles) ? <Typography>{roles[0].name}</Typography> : <Typography>-</Typography>;
}

RoleCell.propTypes = {
  roles: propTypes.array.isRequired,
};
