import { useReducer } from 'react';
import { produce } from 'immer';
import { USER_FRIENDLY_ERROR_MESSAGE, GRPC_ERROR_CODE } from './user-friendly-error-message';

const uploadPermissionOptions = {
  playbook: `uploadPlaybooks`,
  connection: `uploadEndpointSchemas`,
};

const actions = {
  SET_MODAL: Symbol('SET_MODAL'),
  RESET_MODAL: Symbol('RESET_MODAL'),
  SET_ERROR: Symbol('SET_ERROR'),
  SET_PERMISSION: Symbol('SET_PERMISSION'),
};

const initialState = {
  isModalOpen: false,
  error: null,
  isUploadAllowed: false,
  isDropDownViewable: false,
};

const reducer = produce((state, { type, payload }) => {
  switch (type) {
    case actions.SET_MODAL:
      state.isModalOpen = true;
      break;
    case actions.RESET_MODAL:
      state.isModalOpen = false;
      state.error = null;
      break;
    case actions.SET_ERROR:
      if (payload.error && payload.error.includes(GRPC_ERROR_CODE[7])) {
        state.error = USER_FRIENDLY_ERROR_MESSAGE[GRPC_ERROR_CODE[7]];
        break;
      }
      state.error = payload.error;
      break;
    case actions.SET_PERMISSION:
      state.isUploadAllowed = payload.hasUploadEndpointSchemasPermission || payload.hasUploadPlaybooksPermission;
      state.isDropDownViewable = payload.hasDownloadPermission || state.isUploadAllowed;
      break;
  }
  return state;
});

const useUploadState = (initialStateOverrides = {}) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...initialStateOverrides });

  const setModal = () => {
    dispatch({
      type: actions.SET_MODAL,
    });
  };

  const resetModal = () => {
    dispatch({
      type: actions.RESET_MODAL,
    });
  };

  const setError = error => {
    dispatch({
      type: actions.SET_ERROR,
      payload: { error },
    });
  };

  const setPermission = (hasDownloadPermission, hasUploadEndpointSchemasPermission, hasUploadPlaybooksPermission) => {
    dispatch({
      type: actions.SET_PERMISSION,
      payload: { hasDownloadPermission, hasUploadEndpointSchemasPermission, hasUploadPlaybooksPermission },
    });
  };

  return {
    setModal,
    resetModal,
    setError,
    setPermission,
    ...state,
    uploadPermissionOptions,
  };
};

export default useUploadState;
