import { reject as _reject, isEmpty as _isEmpty } from 'lodash';

const generatePipelinesList = (pipelines, currentRevision) => {
  if (currentRevision?.startsWith('new-integration-')) {
    // Returns pipelines without any revisions of the new integration staged
    return _reject(pipelines, function (pipeline) {
      return pipeline.pipelineIntegrations?.some(
        pipelineIntegration =>
          pipelineIntegration.stagedRevision.playbook.integration.id === currentRevision.replace('new-integration-', '')
      );
    }).map(pipeline => {
      return {
        name: pipeline.name,
        id: pipeline.id,
        pipelineVars: {},
        stagedRevisionId: 'new-integration',
        clusterId: pipeline.clusterId,
        writekey: pipeline.writekey,
        pipelineIntegration: {},
      };
    });
  } else if (currentRevision === 'deploy-only') {
    // Returns all pipelines with at least one staged revision and no empty pipeline variables
    return pipelines
      .filter(pipeline => !_isEmpty(pipeline.pipelineIntegrations))
      .filter(pipeline => {
        const pipelineVars = [];
        pipeline.pipelineIntegrations.forEach(pipelineIntegration =>
          pipelineVars.push(Object.values(pipelineIntegration.stagedRevision.pipelineVars)[0])
        );
        if (!pipelineVars.includes('')) return pipeline;
      })
      .map(pipeline => {
        return {
          name: pipeline.name,
          id: pipeline.id,
          pipelineVars: {},
          stagedRevisionId: 'deploy-only',
          clusterId: pipeline.clusterId,
          writekey: pipeline.writekey,
          pipelineIntegration: {},
        };
      });
  } else {
    // Returns pipelines with the selected revision staged
    return pipelines
      .filter(pipeline =>
        pipeline.pipelineIntegrations.some(
          pipelineIntegration => pipelineIntegration.stagedRevision.playbookId === currentRevision
        )
      )
      .map(pipeline => {
        const pipelineIntegration = pipeline.pipelineIntegrations?.find(
          pipelineIntegration => pipelineIntegration.stagedRevision.playbookId === currentRevision
        );
        return {
          name: pipeline.name,
          id: pipeline.id,
          pipelineVars: pipelineIntegration.stagedRevision.pipelineVars,
          stagedRevisionId: pipelineIntegration.stagedRevision.id,
          clusterId: pipeline.clusterId,
          writekey: pipeline.writekey,
          pipelineIntegration,
        };
      });
  }
};

export default generatePipelinesList;
