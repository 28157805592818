/**
 * This module adds UUIDs to all playbook operations (mappings, enrichments, etc.)
 * and other metadata as needed to simplify consumption by the UI.
 */

import {
  keys as _keys,
  cloneDeep as _cloneDeep,
  isEmpty as _isEmpty,
  map as _map,
  toUpper as _toUpper,
  snakeCase as _snakeCase,
} from 'lodash';

import { generateId } from '@ion/components';
import { flattenOperations } from './flatten-operations';
import { oneOfSdkParams } from './one-of-sdk-params';

const getPlaybookData = (p = {}, c = {}, vars = {}, i) => {
  const preProcess = (operation, path, event) => {
    // (EunHye 2023.5.19) - This is necessary for backwards compatability
    // Use case 1: uploaded playbook
    // Use case 2: cloned before and saved in a wrong way
    const isEventSpecific = operation.event && event;
    if (!operation.opId || (operation.event !== event && isEventSpecific)) {
      operation.opId = generateId();
    }

    // (EunHye 2023.02.01) - This is necessary for backwards compatability
    delete operation._hide;

    operation.event = event;
    operation._path = path;
    operation.attachmentKey = path.split('.')[1];

    // direct assignment is defined by a mapping without transforms
    // explicitly add a direct assignment object here to simplify things for the UI
    // TODO we might be able to leverage this for better default checking
    if (path.endsWith('mappings')) {
      const noTransforms = !operation.transforms || _isEmpty(operation.transforms[0]);
      const defaultType =
        operation.defaultBool !== undefined ||
        operation.defaultFloat !== undefined ||
        operation.defaultInt !== undefined ||
        operation.defaultString !== undefined;
      const singleKey = !noTransforms && operation?.transforms[0].expression;
      if (noTransforms) {
        operation.transforms = [{ directAssignment: null }];
      }

      if (defaultType && !singleKey) {
        operation.applyDefault = true;
      }

      if (!defaultType && !singleKey) {
        operation.applyDefault = false;
      }
    }

    // // (EunHye 2023.02.01) - This is necessary for backwards compatability. vars are deprecated field
    if (path.endsWith('params') && !_isEmpty(vars)) {
      const name = operation.name;
      if (vars[name]) {
        operation.defaultValue = vars[name];
      }
    }

    if (path.endsWith('expressions') && operation.expressionName === undefined) {
      operation.expressionName = '';
    }

    if (path.endsWith('spreadings') && operation.expressionName === undefined) {
      operation.expressionName = '';
    }
    return operation;
  };

  // making a copy of immutable API data
  const playbook = _cloneDeep(p);
  const connection = _cloneDeep(c);
  const opIndices = _cloneDeep(i);

  if (!playbook.global) {
    playbook.global = {};
  }
  if (!playbook.default) {
    playbook.default = {};
  }
  if (!playbook.eventSpecific) {
    playbook.eventSpecific = {};
  }
  if (!playbook.params) {
    playbook.params = [];
  }

  // (EunHye 2023.02.01) - This is necessary for backwards compatability
  delete playbook.global.vars;

  playbook.global.mappings = playbook.global.mappings?.map(op => preProcess(op, 'global.mappings', null)) ?? [];
  playbook.global.enrichments =
    playbook.global.enrichments?.map(op => preProcess(op, 'global.enrichments', null)) ?? [];
  playbook.global.expressions =
    playbook.global.expressions?.map(op => preProcess(op, 'global.expressions', null)) ?? [];
  playbook.global.spreadings = playbook.global.spreadings?.map(op => preProcess(op, 'global.spreadings', null)) ?? [];

  // default configs
  playbook.default.mappings = playbook.default.mappings?.map(op => preProcess(op, 'default.mappings', null)) ?? [];
  playbook.default.enrichments =
    playbook.default.enrichments?.map(op => preProcess(op, 'default.enrichments', null)) ?? [];
  playbook.default.expressions =
    playbook.default.expressions?.map(op => preProcess(op, 'default.expressions', null)) ?? [];
  playbook.default.spreadings =
    playbook.default.spreadings?.map(op => preProcess(op, 'default.spreadings', null)) ?? [];

  playbook.params =
    playbook.params.map(op => preProcess({ ...op, isOptional: op.isOptional ?? false }, 'playbook.params', null)) ?? [];

  if (connection.endpointSchema) {
    connection.endpointSchema.params =
      connection.endpointSchema.params?.map(op => {
        const duplicate = playbook.params?.find(p => p.name === op.name);
        if (duplicate) {
          op.opId = duplicate.opId;
        }

        if (duplicate && duplicate.pipelineVarId) {
          op.pipelineVarId = duplicate.pipelineVarId;
        }

        return preProcess({ ...op, isOptional: op.isOptional ?? false }, 'connection.params', null);
      }) ?? [];
  }

  if (connection.connectionTemplate) {
    const previousParams =
      connection.connectionTemplate.params?.map(op => {
        const duplicate = playbook.params?.find(p => p.name === op.name);
        if (duplicate) {
          op.opId = duplicate.opId;
        }

        if (duplicate && duplicate.pipelineVarId) {
          op.pipelineVarId = duplicate.pipelineVarId;
        }

        return op;
      }) ?? [];

    const sdkParams = _map(connection.connectionTemplate.properties, (op, property) => {
      const paramName = _toUpper(_snakeCase(property));

      const isOldParam = previousParams.find(p => p.name === property);
      const isNewParam = previousParams.find(p => p.name === paramName);

      const pipelineVarId = isNewParam?.pipelineVarId;
      const defaultValue = isNewParam?.defaultValue;
      const opId = isNewParam?.opId;
      const opIndex = isNewParam?.opIndex;
      const isOptional = connection.connectionTemplate.required.findIndex(p => p === property) === -1 ? true : false;

      return preProcess(
        {
          name: paramName,
          isOptional: isOptional,
          ...(isOldParam?.pipelineVarId && { pipelineVarId: isOldParam.pipelineVarId }),
          ...(vars?.[property] && { defaultValue: String(vars[property]) }),
          ...(pipelineVarId && { pipelineVarId: pipelineVarId }),
          ...(defaultValue && { defaultValue: defaultValue }),
          ...(opId && { opId: opId }),
          ...(opIndex && { opIndex: opIndex }),
        },
        'connection.params',
        null
      );
    });

    connection.connectionTemplate.params = oneOfSdkParams(sdkParams, previousParams);
  }

  const addOpIndex = (op, section) => {
    if (!op.opIndex && !op.eventIndex && op.eventIndex !== 0) {
      const i = opIndices[section] === undefined ? 1 : opIndices[section] + 1;
      opIndices[section] = i;
      op.opIndex = i;
    }

    if (op.opIndex) {
      opIndices[section] = op.opIndex;
    }

    return op;
  };

  playbook.params.forEach(op => addOpIndex(op, 'playbook'));

  if (connection.endpointSchema) {
    connection.endpointSchema.params.forEach(op => addOpIndex(op, 'connection'));
  }

  if (connection.connectionTemplate) {
    connection.connectionTemplate.params.forEach(op => addOpIndex(op, 'connection'));
  }

  flattenOperations(playbook.global).forEach(op => addOpIndex(op, 'global'));
  flattenOperations(playbook.default).forEach(op => addOpIndex(op, 'default'));

  // event configs
  const { eventSpecific } = playbook;
  let eventIndices = [];
  const subSections = ['mappings', 'enrichments', 'expressions', 'spreadings'];

  _keys(eventSpecific).forEach(event => {
    eventSpecific[event].mappings = eventSpecific[event].mappings ?? [];
    eventSpecific[event].enrichments = eventSpecific[event].enrichments ?? [];
    eventSpecific[event].expressions = eventSpecific[event].expressions ?? [];
    eventSpecific[event].spreadings = eventSpecific[event].spreadings ?? [];

    subSections.forEach(section => {
      eventSpecific[event][section]?.forEach(op => {
        preProcess(op, `eventSpecific.${section}`, event);
      });
    });

    if (!eventSpecific[event].metadata) {
      const nextEventIndex = _isEmpty(eventIndices) ? 1 : Math.max(...eventIndices) + 1;
      eventSpecific[event].metadata = {
        eventIndex: nextEventIndex,
      };
    }
    eventIndices = [...eventIndices, eventSpecific[event].metadata.eventIndex];
  });

  _keys(playbook.eventSpecific).forEach(event => {
    flattenOperations(playbook.eventSpecific[event]).forEach(op => addOpIndex(op, 'eventSpecific'));
  });

  return { playbook, connection, opIndices };
};

export default getPlaybookData;
