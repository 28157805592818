import isNil from 'lodash/isNil';

export default function fetchPageWrapper(fetchPage) {
  return ({ page, perPage }) => {
    return fetchPage({ page, perPage }).then(response => {
      validateResponse(response);
      return response;
    });
  };
}

export function validateResponse({ data, pagination: { count } = {} }) {
  if (isNil(data)) {
    throw new Error('PageDataTable: data must be returned in fetchPage promise');
  }
  if (isNil(count)) {
    throw new Error('PageDataTable: pagination.count must be returned in fetchPage promise');
  }
}
