// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../app/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._-component-library-src-components-input-select-input-select-module__toggle{cursor:pointer;margin-left:auto;width:1.8rem;transition:transform 200ms ease-out;transform:scaleY(-1)}._-component-library-src-components-input-select-input-select-module__toggle._-component-library-src-components-input-select-input-select-module__open{transform:scaleY(1)}._-component-library-src-components-input-select-input-select-module__disabled{cursor:not-allowed}", "",{"version":3,"sources":["/usr/src/packages/component-library/src/components/input-select/input-select.module.scss"],"names":[],"mappings":"AA2BA,6EACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,mCAAA,CACA,oBAAA,CAEA,uJACE,mBAAA,CAOJ,+EACE,kBAAA","file":"input-select.module.scss","sourcesContent":["@import '../../scss/theme/brand-vars';\n@import '../../scss/theme/layout-vars';\n@import '../../scss/theme/shadow-vars';\n@import '../../scss/theme/mixins';\n\n// this file is only for global variable and mixins and should not output any styles\n\n///////////////////////////////////////////////////////////////////////////////\n\n@mixin cardList($tile) {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: center;\n\n  @if ($tile == true) {\n    justify-content: flex-start;\n    margin: -1rem;\n  }\n\n  & > div {\n    margin: 1rem;\n  }\n}\n\n.toggle {\n  cursor: pointer;\n  margin-left: auto;\n  width: 1.8rem;\n  transition: transform 200ms ease-out;\n  transform: scaleY(-1);\n\n  &.open {\n    transform: scaleY(1);\n  }\n}\n\n@import '../../scss/inputs/inputs';\n\n// cursor can't be changed directly on disabled react-select due to pointer-events none\n.disabled {\n  cursor: not-allowed;\n}\n"]}]);
// Exports
exports.locals = {
	"toggle": "_-component-library-src-components-input-select-input-select-module__toggle",
	"open": "_-component-library-src-components-input-select-input-select-module__open",
	"disabled": "_-component-library-src-components-input-select-input-select-module__disabled"
};
module.exports = exports;
