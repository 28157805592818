const bulkStageRevisions = (data, pipelinesList, revision, updatePipelineIntegration, createPipelineIntegration) => {
  const updatedPipelines = [];
  for (const pipelineId in data) {
    if (data[pipelineId] === 'true') {
      const pipeline = pipelinesList.find(p => p.id === pipelineId);
      if (pipeline.stagedRevisionId === 'new-integration') {
        createPipelineIntegration({
          pipeline,
          integration: {
            id: revision.integration,
            name: revision.integrationName,
          },
          playbookId: revision.updatedRevision,
          pipelineVars: {},
          readyForDeploy: true,
        });
      } else if (pipeline.stagedRevisionId !== 'deploy-only') {
        updatePipelineIntegration({
          pipelineIntegration: pipeline.pipelineIntegration,
          playbookId: revision.updatedRevision,
          pipelineVars: pipeline.pipelineVars,
          readyForDeploy: !Object.values(pipeline.pipelineVars).includes(''),
        });
      }
      updatedPipelines.push(pipeline.id);
    }
  }
  return updatedPipelines;
};

export default bulkStageRevisions;
