import { gql } from '@apollo/client';
import stagedRevision from '../stagedRevision';

export const mutation = gql`
  mutation UpdateStagedRevision(
    $id: uuid!
    $playbookId: uuid!
    $pipelineVars: jsonb!
    $readyForDeploy: Boolean!
    $stagedForDelete: Boolean!
  ) {
    update_staged_revisions_by_pk(
      pk_columns: { id: $id }
      _set: {
        playbookId: $playbookId
        pipelineVars: $pipelineVars
        readyForDeploy: $readyForDeploy
        stagedForDelete: $stagedForDelete
      }
    ) {
      ...stagedRevision
    }
  }
  ${stagedRevision}
`;

export function buildVariables({ id, playbookId, pipelineVars, readyForDeploy, stagedForDelete = false }) {
  return {
    id,
    playbookId,
    pipelineVars,
    readyForDeploy,
    stagedForDelete,
  };
}
