import { mapKeys as _mapKeys } from 'lodash';
import fileSaver from 'file-saver';
import slugify from 'slugify';
import JSZip from 'jszip';
import pako from 'pako';

const saveFile = ({
  compressFile,
  content,
  filename,
  mimeType,
  callback = console.log('File Saved'),
  compressionType = 'zip',
}) => {
  if (compressFile) {
    if (compressionType === 'gzip') {
      const compressedFileArray = pako.gzip(content);
      const compressedFile = compressedFileArray.buffer;
      const blob = new Blob([compressedFile], { type: 'application/gzip' });
      fileSaver.saveAs(blob, slugify(filename));
    } else {
      const zip = new JSZip();
      const folder = zip.folder(filename);
      // if contents are passed as an object of data map them
      if (typeof content === 'object') {
        _mapKeys(content, (v, k) => folder.file(k, v));
      } else {
        // in the case of the AJS file, we just pass the contents
        // remove the last extension of the file ".gz"
        folder.file(filename.replace(/\.[^/.]+$/, ''), content);
      }
      folder
        .generateAsync({ type: 'blob', compression: 'DEFLATE', compressionOptions: { level: 9 } })
        .then(result => fileSaver.saveAs(result, slugify(filename)));
    }
  } else {
    const _content = {
      'text/plain': content,
      'text/javascript': content,
      'text/json': JSON.stringify(content, null, 2),
    };

    const file = new Blob([_content[mimeType]], {
      type: `${mimeType};charset=utf-8`,
    });

    fileSaver.saveAs(file, slugify(filename));
  }

  return callback;
};

export { saveFile };
