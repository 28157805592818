import pt from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const DEFAULT_DATA_TRANSFORM_PLAYBOOK = {
  input: '',
  output: null,
  clusterId: '',
  hasValidInput: false,
  isLoading: false,
  playbook: null,
  outputErrors: null,
  setInput: () => {},
  setOutput: () => {},
  setClusterId: () => {},
  setHasValidInput: () => {},
  setIsLoading: () => {},
  setOutputErrors: () => {},
};

const TransformPlaybookContextValue = createContext(DEFAULT_DATA_TRANSFORM_PLAYBOOK);

export const TransformPlaybookContextProvider = ({ playbook, children }) => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState(null);
  const [clusterId, setClusterId] = useState('');
  const [hasValidInput, setHasValidInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [outputErrors, setOutputErrors] = useState(null);
  return (
    <TransformPlaybookContextValue.Provider
      value={{
        input,
        output,
        clusterId,
        hasValidInput,
        isLoading,
        playbook,
        outputErrors,
        setInput,
        setOutput,
        setClusterId,
        setHasValidInput,
        setIsLoading,
        setOutputErrors,
      }}
    >
      {children}
    </TransformPlaybookContextValue.Provider>
  );
};

export const useTransformPlaybookContext = () => {
  return useContext(TransformPlaybookContextValue);
};

TransformPlaybookContextProvider.propTypes = {
  playbook: pt.object,
  children: pt.node.isRequired,
};
