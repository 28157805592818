/**
 * Transforms the control API's diff response into the shape needed for the UI diff view
 */

import { isEmpty as _isEmpty, omit as _omit } from 'lodash';

const addChangeType = merged => {
  return merged.map(m => {
    let changeType = 'updating';

    if (_isEmpty(m.proposed)) {
      changeType = 'removing';
    }
    if (_isEmpty(m.current)) {
      changeType = 'adding';
    }

    if (JSON.stringify(m.current) === JSON.stringify(m.proposed)) {
      changeType = 'noChanges';
    }

    m.changeType = changeType;

    return m;
  });
};

const addDisplayNames = (merged, displayNames) => {
  return merged.map(m => {
    m.displayName = displayNames[m.id];

    return m;
  });
};

const mergeCurrentAndProposed = ({ current, proposed, displayNames }) => {
  const merged = [];

  current?.integrations.forEach(i => {
    merged.push({
      name: i.integrationName,
      id: i.id,
      current: _omit(i, 'connection.legacy', 'scope', 'disabled'),
    });
  });

  proposed?.integrations.forEach(i => {
    const existing = merged.find(m => m.id === i.id);

    if (!existing) {
      merged.push({
        name: i.integrationName,
        id: i.id,
        proposed: _omit(i, 'connection.legacy', 'scope', 'disabled'),
      });
    } else {
      existing.proposed = _omit(i, 'connection.legacy', 'scope', 'disabled');
    }
  });

  return addDisplayNames(addChangeType(merged), displayNames);
};

export default mergeCurrentAndProposed;
