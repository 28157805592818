import auditLogMutation from '../insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const mutation = auditLogMutation;

export function buildVariables({ email, oldRole, newRole }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      email,
      oldRole: oldRole || 'no role',
      newRole,
    }),
  };
}
