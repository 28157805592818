import { v4 as uuid } from 'uuid';
import { validate } from 'parameter-validator';
import permission from '../../Apollo/permissions';
import mergeQueries from '../../Apollo/merge-queries';
import useMutationWithBaseOptions from './useMutationWithBaseOptions';
import {
  mutation as insertPipelineMutation,
  buildVariables as buildInsertVariables,
} from './insert-pipeline-mutation-builder';
import {
  mutation as pipelineCreatedMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/pipeline-created/audit-log-mutation-builder';

export const combinedMutation = mergeQueries('InsertPipelineAndAudit', [
  insertPipelineMutation,
  pipelineCreatedMutation,
]);

export default function useInsertPipeline(options) {
  const [executeMutation, response] = useMutationWithBaseOptions(combinedMutation, {
    ...options,
    successMsg: 'New pipeline added.',
    context: { permission: permission.createPipeline },
  });

  const insertAndAuditMutation = args => {
    validate(args, ['name', 'writekey', 'clusterId']);
    return executeMutation(buildVariables(args));
  };
  return [insertAndAuditMutation, response];
}

function buildVariables(args) {
  const id = uuid();
  return {
    variables: {
      ...buildInsertVariables({ id, ...args }),
      ...buildAuditLogVariables({ id, ...args }),
    },
  };
}
