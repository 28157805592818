export const PIPELINE_VARIABLE_INPUT_TYPES = {
  TEXT: 'TEXT',
  TEXT_AREA: 'TEXT_AREA',
};

export const TO_TEXTAREA = {
  CREDENTIALS: 'CREDENTIALS',
  CREDENTIALS_JSON: 'CREDENTIALS_JSON',
  CREDENTIAL_JSON: 'CREDENTIAL_JSON',
};

export function determineInputType(variableName) {
  if (TO_TEXTAREA[variableName]) {
    return PIPELINE_VARIABLE_INPUT_TYPES.TEXT_AREA;
  }
  return PIPELINE_VARIABLE_INPUT_TYPES.TEXT;
}
