const DATA_TABLE_DEFAULT_PROPS = {
  pagination: true,
  paginationServer: true,
  sortServer: true,
  paginationComponentOptions: {
    noRowsPerPage: true,
  },
};

export default function buildDataTableProps({ state, perPage }) {
  return {
    onChangePage: state.setCurrentPage,
    progressPending: state.loading,
    paginationTotalRows: state.pageCount,
    data: state.data,
    ...DATA_TABLE_DEFAULT_PROPS,
    paginationPerPage: perPage,
  };
}
