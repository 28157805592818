import { isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash';
/**
 *
 * IsEditedNotes returns true or false so that it only saves edited notes.
 *
 * @params initialNotes: {}, previousNotes: {}
 *
 * @returns Boolean
 */
const IsEditedNotes = (initial, previous) => {
  return !!previous && ((_isEmpty(initial) && _isEmpty(previous)) || !_isEqual(initial, previous));
};

export default IsEditedNotes;
