import React from 'react';
import { AuditLogsTable } from '../audit-logs-table';

import { ApolloError } from '@apollo/client/errors';
import useAuditLogsSearchTableState from './state';
import { useAuditLogsFetchPage } from './use-audit-logs-fetch-page';

import AuditLogsSearch from '../audit-logs-search';

import styles from './index.module.scss';
import invariant from 'tiny-invariant';
import { AuditLogDateRange } from '../audit-logs-date-range';

export default function AuditLogsSearchTable() {
  const state = useAuditLogsSearchTableState();
  const fetchPage = useAuditLogsFetchPage(state);

  if (state.apolloError) {
    // Apollo error will popup a modal, this is just a placeholder
    // ND TODO: Still figuring out with product what we want to do on errors.
    return null;
  }

  const auditLogsTableProps = {
    ...buildAuditLogsTableProps(state),
    fetchPage,
  };

  return (
    <div>
      <div className={styles.header}>
        <div>
          <AuditLogsSearch onChange={state.setSearchText} />
        </div>
        <div>
          <AuditLogDateRange onDatesChange={state.setDateRange} />
        </div>
      </div>
      <div>
        <AuditLogsTable {...auditLogsTableProps} />
      </div>
    </div>
  );
}

export function buildAuditLogsTableProps(state) {
  return {
    onSort: buildOnSort(state),
    onError: handleError(state.setApolloError),
  };
}

export function buildOnSort({ onSort }) {
  return (column, direction) => {
    invariant(column.sortField === 'timestamp', 'Can only sort on timestamp');
    onSort(direction);
  };
}

export function handleError(setApolloError) {
  return error => {
    if (error instanceof ApolloError) {
      setApolloError(error);
    } else {
      // ugh, this feels hacky ... https://github.com/facebook/react/issues/14981#issuecomment-468460187
      // We should rethink how we handle errors.
      setApolloError(() => {
        throw error;
      });
    }
  };
}
