import React from 'react';
import pt from 'prop-types';

import { MrIcon } from '@ion/components';
import { useRemovePipelineIntegration } from '@ion/api';

const RemoveStagedRevision = row => {
  const [removePipelineIntegration] = useRemovePipelineIntegration();

  const { pipelineIntegration } = row;

  return (
    <MrIcon
      testId="removeStagedRevision"
      id="icon-trash"
      onClick={() => {
        removePipelineIntegration(pipelineIntegration);
      }}
      title="Remove staged revision"
    />
  );
};

RemoveStagedRevision.propTypes = {
  row: pt.object,
};

export default RemoveStagedRevision;
