import { useQuery } from '@apollo/client';

import mapErrors from './mapErrors';
import { isAllowed } from '../Apollo/allower';

/**
 * A wrapper around useQuery hook for default patterns
 *  extracting onCompleted and onError in case we want to add default functionality (eg. event logging)
 */

export default (query, { onCompleted, ...options }) => {
  const { error, ...response } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    displayName: query.definitions[0]?.name?.value + 'Query',
    onCompleted: data => {
      if (onCompleted) onCompleted(data);
    },
    ...options,
  });

  const { context } = options;

  return {
    ...response,
    isAllowed: isAllowed(context?.permission),
    error: mapErrors(error),
  };
};
