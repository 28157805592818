import { useFlags } from 'launchdarkly-react-client-sdk';
import propTypes from 'prop-types';
import isFeatureEnabled from '../is-feature-enabled';

export default function FeatureGate({ flagName, children, fallback = null }) {
  const featureFlags = useFlags();

  if (isFeatureEnabled({ featureFlags, flagName })) {
    return children;
  }

  return fallback;
}

FeatureGate.propTypes = {
  flagName: propTypes.string,
  children: propTypes.node.isRequired,
};
