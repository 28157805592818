// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-playbook-editor-parameters-index-module__toggle{cursor:pointer;margin-left:auto;width:1.8rem;transition:transform 200ms ease-out;transform:scaleY(-1)}.src-playbook-editor-parameters-index-module__toggle.src-playbook-editor-parameters-index-module__open{transform:scaleY(1)}.src-playbook-editor-parameters-index-module__editor{overflow:auto;width:calc(100vw - 429px);position:fixed;top:167px;left:429px;height:90%}", "",{"version":3,"sources":["/usr/src/packages/app/src/playbook-editor/parameters/index.module.scss"],"names":[],"mappings":"AA2BA,qDACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,mCAAA,CACA,oBAAA,CAEA,uGACE,mBAAA,CAIJ,qDACE,aAAA,CACA,yBAAA,CACA,cAAA,CACA,SAAA,CACA,UAAA,CACA,UAAA","file":"index.module.scss","sourcesContent":["@import '../../../../component-library/src/scss/theme/brand-vars';\n@import '../../../../component-library/src/scss/theme/layout-vars';\n@import '../../../../component-library/src/scss/theme/shadow-vars';\n@import '../../../../component-library/src/scss/theme/mixins';\n\n// this file is only for global variable and mixins and should not output any styles\n\n///////////////////////////////////////////////////////////////////////////////\n\n@mixin cardList($tile) {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: center;\n\n  @if ($tile == true) {\n    justify-content: flex-start;\n    margin: -1rem;\n  }\n\n  & > div {\n    margin: 1rem;\n  }\n}\n\n.toggle {\n  cursor: pointer;\n  margin-left: auto;\n  width: 1.8rem;\n  transition: transform 200ms ease-out;\n  transform: scaleY(-1);\n\n  &.open {\n    transform: scaleY(1);\n  }\n}\n\n.editor {\n  overflow: auto;\n  width: calc(100vw - 429px);\n  position: fixed;\n  top: $headerHeight + $editorTabHeight + $revisionHeaderHeight;\n  left: $navWidth + $editorSideNavWidth;\n  height: 90%;\n}\n"]}]);
// Exports
exports.locals = {
	"toggle": "src-playbook-editor-parameters-index-module__toggle",
	"open": "src-playbook-editor-parameters-index-module__open",
	"editor": "src-playbook-editor-parameters-index-module__editor"
};
module.exports = exports;
