import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ id, email, name }) {
  const action = actions.user_deleted;
  const description = `${email || name} deleted`;
  const debug_data = { id, email, name };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
