import React from 'react';

const MrIconDefs = () => {
  return (
    <>
      <svg
        style={{ position: 'absolute', width: 0, height: 0, overflow: 'hidden' }}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <svg
            id="icon-trash"
            fill="none"
            height="17"
            viewBox="0 0 16 17"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="#808295" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
              <path d="m13.1667 7.99854v6.85716c0 .3031-.1316.5938-.3661.8081-.2344.2143-.5523.3347-.8839.3347h-7.49995c-.33152 0-.64947-.1204-.88389-.3347s-.36611-.505-.36611-.8081v-6.85716" />
              <path d="m1.16675 5.00098 13.66665-.05201" />
              <path d="m5.16675 4.00098v-1c0-1.10457.89543-2 2-2h1c1.10457 0 1.99995.89543 1.99995 2v1" />
            </g>
          </svg>
          <symbol id="icon-dots-three-vertical" viewBox="0 0 20 20">
            <path d="M10.001 7.8c-1.215 0-2.201 0.985-2.201 2.2s0.986 2.2 2.201 2.2c1.215 0 2.199-0.985 2.199-2.2s-0.984-2.2-2.199-2.2zM10.001 5.2c1.215 0 2.199-0.986 2.199-2.2s-0.984-2.2-2.199-2.2c-1.215 0-2.201 0.985-2.201 2.2s0.986 2.2 2.201 2.2zM10.001 14.8c-1.215 0-2.201 0.985-2.201 2.2s0.986 2.2 2.201 2.2c1.215 0 2.199-0.985 2.199-2.2s-0.984-2.2-2.199-2.2z"></path>
          </symbol>
          <symbol id="icon-settings" viewBox="0 0 18 18">
            <path
              d="M16.2825 8.46745L14.52 6.74995V4.22995C14.52 3.81574 14.1842 3.47995 13.77 3.47995H11.2875L9.53245 1.71745C9.39163 1.57547 9.19993 1.49561 8.99995 1.49561C8.79998 1.49561 8.60828 1.57547 8.46745 1.71745L6.74995 3.47995H4.22995C3.81574 3.47995 3.47995 3.81574 3.47995 4.22995V6.74995L1.71745 8.46745C1.57547 8.60828 1.49561 8.79998 1.49561 8.99995C1.49561 9.19993 1.57547 9.39163 1.71745 9.53245L3.47995 11.2875V13.77C3.47995 14.1842 3.81574 14.52 4.22995 14.52H6.74995L8.50495 16.2825C8.64578 16.4244 8.83748 16.5043 9.03745 16.5043C9.23743 16.5043 9.42913 16.4244 9.56995 16.2825L11.325 14.52H13.8075C14.2217 14.52 14.5575 14.1842 14.5575 13.77V11.2875L16.32 9.53245C16.4571 9.38669 16.5303 9.19215 16.5232 8.99214C16.5162 8.79212 16.4295 8.60321 16.2825 8.46745ZM5.99995 8.99995C5.99995 7.3431 7.3431 5.99995 8.99995 5.99995C10.6568 5.99995 12 7.3431 12 8.99995C12 10.6568 10.6568 12 8.99995 12C7.3431 12 5.99995 10.6568 5.99995 8.99995ZM7.49995 8.99995C7.49995 9.82838 8.17153 10.5 8.99995 10.5C9.82838 10.5 10.5 9.82838 10.5 8.99995C10.5 8.17153 9.82838 7.49995 8.99995 7.49995C8.17153 7.49995 7.49995 8.17153 7.49995 8.99995ZM13.02 10.98C13.0208 10.7795 13.1018 10.5878 13.245 10.4475L14.6925 8.99995L13.245 7.55245C13.1018 7.41215 13.0208 7.22039 13.02 7.01995V4.97995H10.98C10.7795 4.97912 10.5878 4.8981 10.4475 4.75495L8.99995 3.30745L7.55245 4.75495C7.41215 4.8981 7.22039 4.97912 7.01995 4.97995H4.97995V7.01995C4.97912 7.22039 4.8981 7.41215 4.75495 7.55245L3.30745 8.99995L4.75495 10.4475C4.8981 10.5878 4.97912 10.7795 4.97995 10.98V13.02H7.01995C7.22039 13.0208 7.41215 13.1018 7.55245 13.245L8.99995 14.6925L10.4475 13.245C10.5878 13.1018 10.7795 13.0208 10.98 13.02H13.02V10.98Z"
              fill="#2D4457"
            />
          </symbol>

          <symbol id="icon-pencil" viewBox="0 0 16 16">
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </symbol>
          <symbol id="icon-key" viewBox="0 0 32 32">
            <path d="M31.317 9.82l-3.16-3.159c-0.775-0.775-2.043-2.043-2.818-2.818l-3.16-3.159c-0.775-0.775-2.155-0.911-3.067-0.304l-8.639 5.759c-0.912 0.608-1.257 1.907-0.767 2.887l2.203 4.405c0.067 0.135 0.145 0.278 0.231 0.427l-11.142 11.142-1 7h6v-2h4v-4h4v-4h4v-2.225c0.2 0.119 0.392 0.226 0.569 0.314l4.405 2.203c0.98 0.49 2.279 0.145 2.887-0.767l5.759-8.639c0.608-0.912 0.471-2.292-0.304-3.066zM4.707 26.707l-1.414-1.414 9.737-9.737 1.414 1.414-9.737 9.737zM28.657 13.243l-1.414 1.414c-0.389 0.389-1.025 0.389-1.414 0l-8.485-8.485c-0.389-0.389-0.389-1.025 0-1.414l1.414-1.414c0.389-0.389 1.025-0.389 1.414 0l8.485 8.485c0.389 0.389 0.389 1.025 0 1.414z"></path>
          </symbol>
          <symbol id="icon-calendar-check-o" viewBox="0 0 26 28">
            <path d="M20.359 15.062l-8 8c-0.203 0.187-0.516 0.187-0.719 0l-4.5-4.5c-0.187-0.203-0.187-0.516 0-0.703l0.719-0.719c0.187-0.187 0.5-0.187 0.703 0l3.437 3.437 6.937-6.937c0.203-0.187 0.516-0.187 0.703 0l0.719 0.719c0.187 0.187 0.187 0.5 0 0.703zM2 26h22v-16h-22v16zM8 7v-4.5c0-0.281-0.219-0.5-0.5-0.5h-1c-0.281 0-0.5 0.219-0.5 0.5v4.5c0 0.281 0.219 0.5 0.5 0.5h1c0.281 0 0.5-0.219 0.5-0.5zM20 7v-4.5c0-0.281-0.219-0.5-0.5-0.5h-1c-0.281 0-0.5 0.219-0.5 0.5v4.5c0 0.281 0.219 0.5 0.5 0.5h1c0.281 0 0.5-0.219 0.5-0.5zM26 6v20c0 1.094-0.906 2-2 2h-22c-1.094 0-2-0.906-2-2v-20c0-1.094 0.906-2 2-2h2v-1.5c0-1.375 1.125-2.5 2.5-2.5h1c1.375 0 2.5 1.125 2.5 2.5v1.5h6v-1.5c0-1.375 1.125-2.5 2.5-2.5h1c1.375 0 2.5 1.125 2.5 2.5v1.5h2c1.094 0 2 0.906 2 2z"></path>
          </symbol>
          <symbol id="icon-aid-kit" viewBox="0 0 32 32">
            <path d="M 28.444417,0 H 3.5555833 C 1.5910833,0 0.01775,1.5910833 0.01775,3.5555833 L 0,28.444417 C 0,30.408917 1.5910833,32 3.5555833,32 H 28.4445 C 30.408917,32 32,30.408917 32,28.444417 V 3.5555833 C 32,1.5910833 30.408917,0 28.444417,0 Z m -1.77775,19.555583 h -7.111084 v 7.111084 H 12.4445 V 19.555583 H 5.3333333 V 12.4445 H 12.444417 V 5.3333333 H 19.5555 v 7.1110837 h 7.111167 z" />
          </symbol>
          <symbol id="icon-database" viewBox="0 0 32 32">
            <path d="M16 0c-8.837 0-16 2.239-16 5v4c0 2.761 7.163 5 16 5s16-2.239 16-5v-4c0-2.761-7.163-5-16-5z"></path>
            <path d="M16 17c-8.837 0-16-2.239-16-5v6c0 2.761 7.163 5 16 5s16-2.239 16-5v-6c0 2.761-7.163 5-16 5z"></path>
            <path d="M16 26c-8.837 0-16-2.239-16-5v6c0 2.761 7.163 5 16 5s16-2.239 16-5v-6c0 2.761-7.163 5-16 5z"></path>
          </symbol>
          <symbol id="icon-x-altx-alt" viewBox="0 0 32 32">
            <path d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM23.914 21.086l-2.828 2.828-5.086-5.086-5.086 5.086-2.828-2.828 5.086-5.086-5.086-5.086 2.828-2.828 5.086 5.086 5.086-5.086 2.828 2.828-5.086 5.086 5.086 5.086z"></path>
          </symbol>
          <symbol id="icon-cloud-check" viewBox="0 0 32 32">
            <path d="M27.883 16.078c0.076-0.347 0.117-0.708 0.117-1.078 0-2.761-2.239-5-5-5-0.445 0-0.875 0.058-1.285 0.167-0.775-2.417-3.040-4.167-5.715-4.167-2.73 0-5.033 1.823-5.76 4.318-0.711-0.207-1.462-0.318-2.24-0.318-4.418 0-8 3.582-8 8s3.582 8 8 8h19c2.761 0 5-2.239 5-5 0-2.46-1.777-4.505-4.117-4.922zM13 24l-5-5 2-2 3 3 7-7 2 2-9 9z"></path>
          </symbol>
          <symbol id="icon-question-circle" viewBox="0 0 24 28">
            <path d="M14 21.5v-3c0-0.281-0.219-0.5-0.5-0.5h-3c-0.281 0-0.5 0.219-0.5 0.5v3c0 0.281 0.219 0.5 0.5 0.5h3c0.281 0 0.5-0.219 0.5-0.5zM18 11c0-2.859-3-5-5.688-5-2.547 0-4.453 1.094-5.797 3.328-0.141 0.219-0.078 0.5 0.125 0.656l2.063 1.563c0.078 0.063 0.187 0.094 0.297 0.094 0.141 0 0.297-0.063 0.391-0.187 0.734-0.938 1.047-1.219 1.344-1.437 0.266-0.187 0.781-0.375 1.344-0.375 1 0 1.922 0.641 1.922 1.328 0 0.812-0.422 1.219-1.375 1.656-1.109 0.5-2.625 1.797-2.625 3.313v0.562c0 0.281 0.219 0.5 0.5 0.5h3c0.281 0 0.5-0.219 0.5-0.5v0c0-0.359 0.453-1.125 1.188-1.547 1.188-0.672 2.812-1.578 2.812-3.953zM24 14c0 6.625-5.375 12-12 12s-12-5.375-12-12 5.375-12 12-12 12 5.375 12 12z"></path>
          </symbol>

          <symbol id="icon-arrow-up-circle" viewBox="0 0 24 24">
            <path d="M23 12c0-3.037-1.232-5.789-3.222-7.778s-4.741-3.222-7.778-3.222-5.789 1.232-7.778 3.222-3.222 4.741-3.222 7.778 1.232 5.789 3.222 7.778 4.741 3.222 7.778 3.222 5.789-1.232 7.778-3.222 3.222-4.741 3.222-7.778zM21 12c0 2.486-1.006 4.734-2.636 6.364s-3.878 2.636-6.364 2.636-4.734-1.006-6.364-2.636-2.636-3.878-2.636-6.364 1.006-4.734 2.636-6.364 3.878-2.636 6.364-2.636 4.734 1.006 6.364 2.636 2.636 3.878 2.636 6.364zM13 16v-5.586l2.293 2.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-4-4c-0.096-0.096-0.206-0.168-0.324-0.217-0.122-0.051-0.253-0.076-0.383-0.076-0.256 0-0.512 0.098-0.707 0.293l-4 4c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l2.293-2.293v5.586c0 0.552 0.448 1 1 1s1-0.448 1-1z"></path>
          </symbol>

          <symbol id="icon-minus-circle" viewBox="0 0 24 24">
            <path d="M23 12c0-3.037-1.232-5.789-3.222-7.778s-4.741-3.222-7.778-3.222-5.789 1.232-7.778 3.222-3.222 4.741-3.222 7.778 1.232 5.789 3.222 7.778 4.741 3.222 7.778 3.222 5.789-1.232 7.778-3.222 3.222-4.741 3.222-7.778zM21 12c0 2.486-1.006 4.734-2.636 6.364s-3.878 2.636-6.364 2.636-4.734-1.006-6.364-2.636-2.636-3.878-2.636-6.364 1.006-4.734 2.636-6.364 3.878-2.636 6.364-2.636 4.734 1.006 6.364 2.636 2.636 3.878 2.636 6.364zM8 13h8c0.552 0 1-0.448 1-1s-0.448-1-1-1h-8c-0.552 0-1 0.448-1 1s0.448 1 1 1z"></path>
          </symbol>
          <svg
            id="plus-circle"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg id="icon-copy" fill="none" height="21" viewBox="0 0 20 18" width="20" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m13.3335 3.83341c.775 0 1.1625 0 1.4804.08519.8627.23117 1.5366.90504 1.7677 1.76777.0852.31791.0852.7054.0852 1.48038v7.66665c0 1.4001 0 2.1002-.2725 2.635-.2396.4704-.6221.8528-1.0925 1.0925-.5348.2725-1.2348.2725-2.635.2725h-5.3333c-1.40014 0-2.1002 0-2.63498-.2725-.47041-.2397-.85286-.6221-1.09254-1.0925-.27248-.5348-.27248-1.2349-.27248-2.635v-7.66665c0-.77498 0-1.16247.08518-1.48038.23117-.86273.90504-1.5366 1.76777-1.76777.31791-.08519.7054-.08519 1.48038-.08519m1.33333 1.66667h4.00004c.4667 0 .7 0 .8783-.09083.1568-.07989.2843-.20737.3642-.36418.0908-.17826.0908-.41161.0908-.87832v-.66667c0-.46671 0-.70006-.0908-.87832-.0799-.15681-.2074-.28429-.3642-.36418-.1783-.09083-.4116-.09083-.8783-.09083h-4.00004c-.46671 0-.70006 0-.87832.09083-.1568.07989-.28429.20737-.36418.36418-.09083.17826-.09083.41161-.09083.87832v.66667c0 .46671 0 .70006.09083.87832.07989.15681.20738.28429.36418.36418.17826.09083.41161.09083.87832.09083z"
              stroke="#0847b4"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.66667"
            />
          </svg>
          <svg id="icon-user" fill="none" height="24" viewBox="0 0 25 24" width="25" xmlns="http://www.w3.org/2000/svg">
            <g strokeLinecap="round" strokeWidth="2">
              <path d="m18.9 21h-12.8c-.88366 0-1.6-.7163-1.6-1.6 0-3.5346 2.86538-6.4 6.4-6.4h3.2c3.5346 0 6.4 2.8654 6.4 6.4 0 .8837-.7163 1.6-1.6 1.6z" />
              <circle cx="12" cy="6.5" r="3.5" />
            </g>
          </svg>
          <svg
            id="icon-exclamation-circle-background"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEECEC" />
            <path
              d="M28 24V28M28 32H28.01M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF6F5" strokeWidth="8" />
          </svg>
          <svg
            id="icon-exclamation-circle"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99984 6.66602V9.99935M9.99984 13.3327H10.0082M18.3332 9.99935C18.3332 14.6017 14.6022 18.3327 9.99984 18.3327C5.39746 18.3327 1.6665 14.6017 1.6665 9.99935C1.6665 5.39698 5.39746 1.66602 9.99984 1.66602C14.6022 1.66602 18.3332 5.39698 18.3332 9.99935Z"
              stroke="currentColor"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            id="icon-download"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 16.5H1.5M14 8.16667L9 13.1667M9 13.1667L4 8.16667M9 13.1667V1.5"
              stroke="#0847B4"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            id="icon-help-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M6.06016 6.0026C6.2169 5.55705 6.52626 5.18134 6.93347 4.94203C7.34067 4.70271 7.81943 4.61523 8.28495 4.69508C8.75047 4.77493 9.17271 5.01695 9.47688 5.37829C9.78106 5.73963 9.94753 6.19695 9.94683 6.66927C9.94683 8.0026 7.94683 8.66927 7.94683 8.66927M8.00016 11.3359H8.00683M14.6668 8.0026C14.6668 11.6845 11.6821 14.6693 8.00016 14.6693C4.31826 14.6693 1.3335 11.6845 1.3335 8.0026C1.3335 4.32071 4.31826 1.33594 8.00016 1.33594C11.6821 1.33594 14.6668 4.32071 14.6668 8.0026Z"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            id="icon-arrow-down"
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m8.00004 3.3335v9.3333m0 0 4.66666-4.66664m-4.66666 4.66664-4.66667-4.66664"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.33333"
            />
          </svg>
          <svg id="icon-edit" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2">
              <path d="m5 19h13" />
              <path d="m14.0242 3.89673 3.5355 3.53553-8.0597 8.05074h-3.64437l.39037-3.8081z" strokeLinejoin="round" />
            </g>
          </svg>
          <svg
            id="icon-deploy"
            fill="none"
            viewBox="0 0 20 16"
            height="20"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="#fff" strokeLinecap="round" strokeWidth="2">
              <path d="m5.95 1h-4.95v14h18v-14h-4.95" strokeLinejoin="round" />
              <path d="m10 1v9" />
              <path d="m14 7-4 4-4-4" strokeLinejoin="round" />
            </g>
          </svg>
          <svg id="icon-bulk-deploy" fill="none" viewBox="0 0 41 41" xmlns="http://www.w3.org/2000/svg">
            <g stroke="currentColor" strokeLinecap="round" strokeWidth="3.32867">
              <path d="m5.88086 12.1749v-3.32868c0-1.83838 1.4903-3.32867 3.32867-3.32867h23.30067c1.8384 0 3.3287 1.49029 3.3287 3.32867v3.32868c0 1.8384-1.4903 3.3287-3.3287 3.3287h-3.8953c-.9353 0-1.3587 1.1694-.6401 1.7682l6.6664 5.5553c.7589.6325 1.1977 1.5693 1.1977 2.5572v6.7626c0 1.8384-1.4903 3.3287-3.3287 3.3287h-23.30067c-1.83837 0-3.32867-1.4903-3.32867-3.3287v-6.7626c0-.9879.4388-1.9247 1.19771-2.5572l6.66643-5.5553c.7185-.5988.2951-1.7682-.6402-1.7682h-3.89527c-1.83837 0-3.32867-1.4903-3.32867-3.3287z" />
              <path d="m20.8594 12.1748v13.3147" />
              <path d="m25.8532 23.8252-4.993 4.993-4.993-4.993" strokeLinejoin="round" />
            </g>
          </svg>
          <svg
            id="icon-arrow-start"
            fill="none"
            height="36"
            viewBox="0 0 42 36"
            width="42"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="18" cy="18" fill="#f5f5fa" r="18" />
            <path d="m19 18h23" stroke="#9294aa" strokeWidth="2.09672" />
            <path d="m12.3828 13h10v10h-10z" fill="#9294aa" />
          </svg>
          <svg
            id="icon-arrow-end"
            fill="none"
            height="36"
            viewBox="0 0 42 36"
            width="42"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="18" fill="#f5f5fa" r="18" />
            <path d="m0 18h23" stroke="#9294aa" strokeWidth="2.09672" />
            <path d="m18 13h8.7097l6.2903 5-6.2903 5h-8.7097z" fill="#9294aa" />
          </svg>
          <svg
            id="icon-integrations"
            width="20"
            height="20"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 19H13C7.06008 19 12 11 1 11C12 11 7.2 3 13 3H15M15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17C15.8954 17 15 17.8954 15 19ZM15 3C15 4.10457 15.8954 5 17 5C18.1046 5 19 4.10457 19 3C19 1.89543 18.1046 1 17 1C15.8954 1 15 1.89543 15 3ZM10 11H15M15 11C15 12.1046 15.8954 13 17 13C18.1046 13 19 12.1046 19 11C19 9.89543 18.1046 9 17 9C15.8954 9 15 9.89543 15 11Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            id="icon-pipelines"
            width="20"
            height="20"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.6 15.5C8.14052 15.5 10.2 13.4853 10.2 11C10.2 8.51471 8.14052 6.49999 5.60001 6.49999C3.05949 6.49999 0.999999 8.51471 0.999999 11C0.999999 13.4853 3.05949 15.5 5.6 15.5ZM5.6 15.5L13.15 15.5L13.15 21L21 11L13.15 1L13.15 6.49999L11.35 6.49999"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            id="icon-flip-backward"
            fill="none"
            height="18"
            viewBox="0 0 22 22"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m3 9h13.5c2.4853 0 4.5 2.0147 4.5 4.5s-2.0147 4.5-4.5 4.5h-4.5m-9-9 4-4m-4 4 4 4"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
          <svg id="trash-02" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <svg
            id="start-icon"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_96_5010)">
              <path
                d="M9.99996 6.66675V13.3334M6.66663 10.0001H13.3333M18.3333 10.0001C18.3333 14.6025 14.6023 18.3334 9.99996 18.3334C5.39759 18.3334 1.66663 14.6025 1.66663 10.0001C1.66663 5.39771 5.39759 1.66675 9.99996 1.66675C14.6023 1.66675 18.3333 5.39771 18.3333 10.0001Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_96_5010">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </defs>
      </svg>
    </>
  );
};

export default MrIconDefs;
