import { useMutation } from '../index';
import { gql } from '@apollo/client';
import permission from '../Apollo/permissions';

const mutation = gql`
  mutation RemoveEventSchema($eventSchemaId: uuid!, $eventSchema: event_schemas_set_input!) {
    update_event_schemas_by_pk(pk_columns: { id: $eventSchemaId }, _set: $eventSchema) {
      id
    }
  }
`;

export default (options = {}) => {
  const [call, response] = useMutation(mutation, {
    successMsg: 'Event Schema removed.',
    context: { permission: permission.updateIntegration }, // TODO - update
    ...options,
    // Remove from cache on "soft delete"
    update: (cache, { data }) => {
      const newItem = data.update_event_schemas_by_pk;
      __mrTracking.track('event_schema_removed', {
        id: newItem.id,
      });
      cache.evict({ id: cache.identify(data.update_event_schemas_by_pk) });
      cache.gc();
      /*
        TODO: clean up dangling references
       eg. causes this warning: Missing cache result fields: orgs_by_pk

       https://www.apollographql.com/docs/react/caching/garbage-collection/#dangling-references
      */
    },
  });
  return [
    // The soft delete function
    ({ eventSchemaId }) => {
      if (!eventSchemaId) throw new Error('No event schema selected');
      return call({
        variables: {
          eventSchemaId,
          eventSchema: {
            deleted_at: 'now()', // Sets to current db time.
          },
        },
      });
    },
    response,
  ];
};
