import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import useValidatorLookup from '../../../hooks/use-validator-lookup';
import { generateId } from '../../../index';

import styles from './form-field.module.scss';
import classNames from 'classnames';
import FormFieldContextProvider from './form-field-context-provider';
import FormLabel from './form-label';

export default function FormField({
  label,
  required,
  helperText,
  validators,
  errorMsg,
  className,
  testId = 'FormField',
  children,
  fullWidth,
}) {
  const id = useMemo(() => generateId(), []);
  const { addValidators } = useValidatorLookup({ uuid: id });

  if (validators) {
    addValidators({ uuid: id, validators });
  }

  const formLabelProps = {
    required,
    id,
    label,
  };

  return (
    <div className={classNames(className, styles.input, fullWidth && styles.fullWidth)} data-test-id={testId}>
      {label && <FormLabel {...formLabelProps} />}

      <FormFieldContextProvider id={id}>{children}</FormFieldContextProvider>

      {helperText && <p className={styles.helperText}>{helperText}</p>}
      {errorMsg && <p className={styles.errorMsg}>{errorMsg}</p>}
    </div>
  );
}

FormField.propTypes = {
  label: propTypes.string,
  required: propTypes.bool,
  validators: propTypes.arrayOf(propTypes.func),
  helperText: propTypes.string,
  errorMsg: propTypes.string,
  className: propTypes.string,
  testId: propTypes.string,
  children: propTypes.node,
  fullWidth: propTypes.bool,
};
