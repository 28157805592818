import { alphabetizeBy } from '@ion/mrdash';
import { get as _get } from 'lodash';
import mapPipelineVarsFromPipelineVariables from '../map-pipeline-vars-from-pipeline-variables.js';

export const INTEGRATION_TYPES = {
  MANAGED: 'MANAGED',
  UNMANAGED: 'UNMANAGED',
};

export function alphabetizeTableData(tableData, path) {
  return alphabetizeBy(tableData, object => _get(object, path));
}

export default function buildTableData({ deployedRevisions, pipeline }) {
  const deployedIntegrations = mapDeployedIntegrations(deployedRevisions, pipeline);
  const unmanagedDeployedIntegrations = mapUnmanagedDeployedIntegrations(deployedRevisions, pipeline);

  return alphabetizeTableData([...deployedIntegrations, ...unmanagedDeployedIntegrations], 'integration.name');
}

function mapDeployedIntegrations(deployedRevisions, pipeline) {
  return pipeline.pipelineIntegrations.map(pipelineIntegration => {
    const stagedRevision = pipelineIntegration.stagedRevision;
    const deployedRevision = deployedRevisions?.find(deployedRevision => deployedRevision?.id === stagedRevision.id);

    // `stagedRevision.pipelineVars` has the merged values but some revision won't need them.
    // So, we map it here for the table data.
    const pipelineVars = mapPipelineVarsFromPipelineVariables(
      stagedRevision.playbook.pipeline_variables,
      stagedRevision.pipelineVars
    );

    return {
      pipeline,
      integration: stagedRevision.playbook.integration,
      pipelineVars: pipelineVars,
      stagedForDelete: stagedRevision.stagedForDelete,
      stagedRevisionId: stagedRevision.id,
      playbookId: stagedRevision.playbookId,
      readyForDeploy: stagedRevision.readyForDeploy,
      stagedRevision: stagedRevision.playbook.revision,
      deployedRevision: deployedRevision?.revision,
      deployedPipelineVars: deployedRevision?.pipelineVars,
      deployedConsentCategory: deployedRevision?.consent?.category,
      pipelineIntegration: pipelineIntegration,
      consentCategory: pipelineIntegration.pipelineIntegrationConsent?.consentCategory,
      type: INTEGRATION_TYPES.MANAGED,
      mergedPipelineVars: stagedRevision.pipelineVars,
    };
  });
}

function mapUnmanagedDeployedIntegrations(deployedRevisions, pipeline) {
  return deployedRevisions
    .filter(deployedRevision => {
      const isDeployedStagedRevision = pipeline.pipelineIntegrations.some(
        pipelineIntegration => deployedRevision.id === pipelineIntegration.stagedRevision.id
      );
      return !isDeployedStagedRevision;
    })
    .map(deployedRevision => {
      return {
        pipeline,
        integration: deployedRevision.integration ?? {
          starterkitId: deployedRevision.starterkitId,
          name: deployedRevision.starterkitId,
        },
        deployedPipelineVars: deployedRevision.pipelineVars,
        stagedForDelete: deployedRevision.stagedForDelete,
        type: INTEGRATION_TYPES.UNMANAGED,
      };
    });
}
