import { isEmpty as _isEmpty, every as _every, some as _some } from 'lodash';
import { isArrayEqual } from '@ion/mrdash';
import mapPipelineVarsFromPipelineVariables from './map-pipeline-vars-from-pipeline-variables';

// compare if deployedRevisions and stagedRevisions are the same
export function isEqualPipelineIntegrations(deployedRevisions, pipelineIntegrations) {
  const deployedRevisionsComparator = deployedRevisions.map(deployedRevision => {
    return {
      integrationId: deployedRevision.integrationId,
      revision: deployedRevision.revision,
      stagedForDelete: deployedRevision.stagedForDelete,
      pipelineVars: deployedRevision.pipelineVars,
      consentCategoryCode: deployedRevision.consent?.category ?? null,
    };
  });

  const pipelineIntegrationsComparator = pipelineIntegrations.map(pipelineIntegration => {
    const { stagedRevision, pipelineIntegrationConsent } = pipelineIntegration;
    const pipelineVars = mapPipelineVarsFromPipelineVariables(
      stagedRevision.playbook.pipeline_variables,
      stagedRevision.pipelineVars
    );
    return {
      integrationId: stagedRevision.playbook.integration.id,
      revision: stagedRevision.playbook.revision,
      stagedForDelete: stagedRevision.stagedForDelete,
      pipelineVars: pipelineVars,
      consentCategoryCode: pipelineIntegrationConsent?.consentCategory.code ?? null,
    };
  });

  return isArrayEqual(deployedRevisionsComparator, pipelineIntegrationsComparator);
}

const deployState = ({ assignedToCluster, deployedRevisions, pipelineIntegrations, loadingDiff }) => {
  const stagedRevisions = pipelineIntegrations.map(pipelineIntegration => pipelineIntegration.stagedRevision);
  const noStagedRevisions = _isEmpty(stagedRevisions);
  const noDeployedIntegrations = _isEmpty(deployedRevisions);

  const needStagedRevisions = _some(stagedRevisions, ['readyForDeploy', false]);
  const noPipelineIntegrationChanges = isEqualPipelineIntegrations(deployedRevisions, pipelineIntegrations);

  const enabled = {
    action: 'diff',
    styleName: 'blueMed',
    buttonText: 'Deploy Pipeline',
    buttonTooltip: 'Ready to deploy',
  };
  if (loadingDiff) {
    return {
      buttonText: 'Loading...',
      buttonTooltip: 'Waiting for pipeline diff response',
    };
  }

  if (!assignedToCluster) {
    return {
      disabled: true,
      buttonText: 'Deploy Pipeline',
      buttonTooltip: 'This pipeline is not assigned to a cluster',
    };
  }

  // set 'Delete' state if there are deployed integrations, and all stagedRevisions are staged for deletion
  if (!noDeployedIntegrations && _every(stagedRevisions, ['stagedForDelete', true])) {
    return {
      styleName: 'blueMed',
      buttonText: 'Deploy Pipeline',
      action: 'delete',
      buttonTooltip: 'Deployed integrations staged for deletion',
    };
  }

  // disable deployment if there are no staged changes
  if (noStagedRevisions && noDeployedIntegrations) {
    return { disabled: true, buttonText: 'Deploy Pipeline', buttonTooltip: 'No staged changes to deploy' };
  }

  // disable deployment if there are no differences from previous deployment
  if (noPipelineIntegrationChanges) {
    return { disabled: true, buttonText: 'Deployed', buttonTooltip: 'No changes from previous deployment' };
  }

  // disable deployment if there are staged integrations with missing pipeline vars
  if (needStagedRevisions) {
    return {
      styleName: 'orange',
      buttonText: 'Add Missing Variables',
      buttonTooltip: `Missing required variables, click to resolve`,
      action: 'resolve',
    };
  }

  return enabled;
};

export default deployState;
