import { Grid, Typography } from '@metarouter-private/mr-mui';
import propTypes from 'prop-types';
import React from 'react';
import { ERROR_TYPES } from '@ion/api/control/transform-event/transform-errors';
import ErrorMessages from './ErrorMessages';

const ERROR_TYPE_TO_HEADING = {
  [ERROR_TYPES.SPREADING_FAILED]: 'Spreading failed',
  [ERROR_TYPES.MAPPING_FAILED]: 'Mapping failed',
  [ERROR_TYPES.EMPTY_EVENT]: 'Empty Event',
  [ERROR_TYPES.FILTERED]: 'Event Filtered',
  [ERROR_TYPES.EXPRESSION_FAILED]: 'Expression failed',
  [ERROR_TYPES.ENRICHMENT_FAILED]: 'Enrichment failed',
  [ERROR_TYPES.INVALID_LUA]: 'Invalid Lua',
};

export default function ErrorsSection({ errorType, errors }) {
  const errorHeading = ERROR_TYPE_TO_HEADING[errorType] ?? errorType;
  return (
    <>
      <Grid>
        <Typography variant="subtitle1">{errorHeading}</Typography>
      </Grid>
      <ErrorMessages errorType={errorType} errors={errors} />
    </>
  );
}

ErrorsSection.propTypes = {
  errorType: propTypes.string.isRequired,
  errors: propTypes.array.isRequired,
};
