import { useQuery } from './../index';
import { gql } from '@apollo/client';

import integration from './integration';
import playbook from '../playbooks/playbook';
import permission from '../Apollo/permissions';
/**
 *  Get Integration and all related playbooks
 */
export const query = gql`
  query IntegrationPlaybooks($integrationId: uuid!) {
    integrations_by_pk(id: $integrationId) {
      ...integration
      playbooks(order_by: { revision: desc }) {
        ...playbook
        pipeline_variables {
          id
          playbookId
          name
        }
        staged_revisions {
          id
          playbookId
          pipeline_integration {
            pipeline {
              name
            }
          }
        }
      }
    }
  }
  ${integration}
  ${playbook}
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, error, isAllowed } = useQuery(query, {
    variables,
    // ND - Not using the cache to prevent a multi-tab bug - SC-22807
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    context: { permission: permission.readIntegration },
    skip: !variables?.integrationId,
    ...options,
  });

  return {
    data: data?.integrations_by_pk,
    loading,
    isAllowed,
    error,
  };
};
