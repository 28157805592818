import React from 'react';
import AuditLogsSearchTable from './audit-logs-search-table';
import { MrAppMain } from '@ion/components';
import { MrAppLayout } from '@ion/components';

import Header from 'app/components/app-header';
import SideNav from 'app/components/side-nav';

import styles from './index.module.scss';

export default function AuditLogsPage() {
  return (
    <MrAppLayout
      title="Audit Log"
      description="View audit log"
      sideNav={<SideNav />}
      header={<Header icon="auditLog" title="Audit Log" route="audit-log" />}
    >
      <MrAppMain loading={false} className={styles.main}>
        <AuditLogsSearchTable />
      </MrAppMain>
    </MrAppLayout>
  );
}
