export default (path = '') => {
  const parts = path.split('/');
  if (parts.length < 3) {
    return undefined;
  } else if (parts.length === 3) {
    return parts[2];
  } else {
    return `${parts[2]} ${parts[3]}`;
  }
};
