import React, { useMemo } from 'react';
import pt from 'prop-types';
import {
  MrButton,
  MrButtonList,
  MrForm,
  MrH,
  MrInputText,
  MrLink,
  formValidators,
  generateId,
  useForm,
} from '@ion/components';
const { validateRequired, validatePattern, validateNotInArray, validateMaxLength } = formValidators;

export const CONSENT_FORM_TYPES = {
  Add: 'add',
  Update: 'update',
};

export const ConsentCategoryForm = ({ consentCategory, consentCategories, onSubmit, type, onCancel }) => {
  const { name, code } = consentCategory || {};

  const formId = generateId();
  const consentCategoryCodes = consentCategories.map(category => {
    if (category.id === consentCategory?.id) {
      return null;
    }
    return category.code;
  });

  const { formState, resetError, validateField, submitForm, setField } = useForm(formId, {
    name: { value: name },
    code: { value: code },
  });

  const isDisableButton = useMemo(
    () => consentCategory && formState.code?.value === code && formState.name?.value === name,
    [consentCategory, name, code, formState]
  );

  const isAddCategory = type === CONSENT_FORM_TYPES.Add;

  const onSubmitForm = () => {
    const { data, isValid } = submitForm();
    if (isValid) {
      onSubmit(data);
    }
  };

  return (
    <>
      <div className="header">
        <MrH h="h2" sans styleNames="noMargin">
          {`${isAddCategory ? 'Add' : 'Edit'} Consent Category`}
        </MrH>
      </div>
      <div className="content">
        <MrForm onSubmit={onSubmitForm} id={formId}>
          <MrInputText
            label="Category Code"
            onChange={setField}
            onBlur={validateField}
            onInput={resetError}
            validators={[
              validateRequired(),
              validateNotInArray(consentCategoryCodes || [], `Code already in use by another category`),
              validatePattern(/^[_a-zA-Z0-9]+$/, 'Only alphanumeric characters and _ are allowed'),
              validateMaxLength(32),
            ]}
            value={formState.code?.value ?? ''}
            placeholder="Provide a consent category code, i.e.: “MR1”"
            errorMsg={formState.code?.errorMsg}
            fullWidth
            name="code"
            required
          />

          <MrInputText
            label="Category Name"
            onChange={setField}
            onBlur={validateField}
            onInput={resetError}
            errorMsg={formState.name?.errorMsg}
            validators={[validateRequired()]}
            value={formState.name?.value ?? ''}
            placeholder="Human readable, descriptive name, i.e.: “Analytics”"
            fullWidth
            name="name"
            required
          />

          <MrButtonList
            styleNames="marginTop"
            buttons={[
              <MrButton text={isAddCategory ? 'Add Category' : 'Save'} key="create" disabled={isDisableButton} />,
              <MrLink onClick={onCancel} styleNames="gray" key="cancel">
                Cancel
              </MrLink>,
            ]}
          />
        </MrForm>
      </div>
    </>
  );
};

ConsentCategoryForm.propTypes = {
  consentCategories: pt.array.isRequired,
  consentCategory: pt.object,
  onSubmit: pt.func.isRequired,
  onCancel: pt.func.isRequired,
  type: pt.string.isRequired,
};
