import React from 'react';
import pt from 'prop-types';
import { useHistory } from 'react-router-dom';

import { MrCard, MrH, MrPill } from '@ion/components';

import IntegrationLogo from '../../components/integrations/integration-logo';

import useTrackWithUser from '@ion/api/hooks/useTrackWithUser';

import styles from '../index.module.scss';

const StarterkitCard = ({ starterkitId, friendlyName, isBeta }) => {
  const history = useHistory();
  const [trackIntegrationOpened] = useTrackWithUser('new_integration_opened');

  const handleOnClick = () => {
    trackIntegrationOpened({ starterkitId, starterkitName: friendlyName });
    history.push({
      state: {
        starterkitId,
      },
    });
  };

  return (
    <MrCard className={styles.card} styleNames="autoWidth link" onClick={handleOnClick}>
      <IntegrationLogo id={starterkitId} className={styles.logo} />

      <MrH h="h3" sans styleNames="ellipsis noMargin">
        {friendlyName || starterkitId}
      </MrH>

      {isBeta && <MrPill className={styles.starterkitPill} text="BETA"></MrPill>}
    </MrCard>
  );
};

StarterkitCard.propTypes = {
  friendlyName: pt.string,
  starterkitId: pt.string.isRequired,
  isBeta: pt.bool,
};

export default StarterkitCard;
