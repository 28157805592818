import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import { isUndefined as _isUndefined, isEmpty as _isEmpty } from 'lodash';

import buildFormFromParameterData from './hydrators/playbook-to-form-hydrator';
import { normalizeFormData, buildParameterFromFormData } from './hydrators/form-to-playbook-hydrator';
import diffParameterAndForm from './diff-parameter-and-form';

import { MrButton, MrLink, MrButtonList, MrForm, useForm } from '@ion/components';
import DeleteParameter from 'app/playbook-editor/parameters/editor-panel/modals/delete-parameter';

import { FormProvider } from 'app/context/form-context';
import DefaultValue from './body/default-value';
import TransformLookup from './body/mapping';
import Expression from './body/expression';
import Spreading from './body/spreading';
import ParamLookup from './body/param';
import EnrichmentLookup from './body/enrichment';

import ParameterSubheader from './header/subheader';
import ParameterHeader from './header';

import s from './index.module.scss';

const Parameter = ({ operationData = {}, isExpanded, navState, playbookState, isAllowed }) => {
  const { _path, opIndex, opId, event } = operationData;
  const [section] = _path.split('.');
  const { formState, setField, validateField, resetError, submitForm, removeFields } = useForm(opId);

  const calculatedForm = buildFormFromParameterData(formState, operationData);

  const hasChanges = diffParameterAndForm(
    buildParameterFromFormData(normalizeFormData(calculatedForm, _path, opId)),
    operationData
  );
  const [operationToDelete, setOperationToDelete] = useState(null);
  const isNewParam = opId === 'newParam';

  const addParam = () => {
    const { isValid, data } = submitForm();
    if (!isValid) {
      console.log(data);
    }

    if (isValid) {
      let normalizedData = normalizeFormData(data, _path, opId);

      const parameter = buildParameterFromFormData(normalizedData);

      //Clears the form so the playbook state repopulates
      removeFields(Object.keys(formState));
      playbookState.promoteNewParameterToPlaybook(section, event, parameter.attachmentKey, parameter);
      //Close the card
      navState.setTransformation(null);
    }
  };

  //Remove all of the form fields which will be repopulated by the playbook state
  const cancelEdit = () => {
    removeFields(Object.keys(formState));
    navState.setTransformation(null);
  };

  //Apply Changes function updates the playbook model and set the form state to empty
  const onSubmit = () => {
    const { isValid, data } = submitForm();
    if (isValid) {
      const normalizedData = normalizeFormData(data, _path, opId);
      const parameter = buildParameterFromFormData(normalizedData);

      //Clears the form so the playbook state repopulates
      playbookState.updateParameter(section, event, parameter.attachmentKey, parameter, operationData);
      removeFields(Object.keys(formState));
      //Close the card
      navState.setTransformation(null);
    }
  };

  // When revision is selected, it keeps the previous formState. This enables to re-render it.
  // TODO (Chris 2023.07.26) - refactor useEffect hook logic
  useEffect(() => {
    if (navState.selectedRevision) {
      removeFields(Object.keys(formState));
    }
  }, [navState.selectedRevision]);

  return (
    <div className={cx(s.card, isExpanded && s.expanded)}>
      <MrForm id={opId} onSubmit={onSubmit}>
        <FormProvider value={{ formState, setField, validateField, resetError, removeFields }}>
          <input name={`${opId}-opIndex-${_path}`} type="hidden" value={opIndex} />
          <input name={`${opId}-opId-${_path}`} type="hidden" value={opId} />
          <input name={`${opId}-path-${_path}`} type="hidden" value={_path} />
          {calculatedForm.event && <input name={`${opId}-event-${_path}`} type="hidden" value={calculatedForm.event} />}
          <div id={`${_path || navState.selectedSection}-${opIndex}`}>
            {!_isEmpty(calculatedForm.operation) && (
              <ParameterHeader
                isNewParam={isNewParam}
                showDeleteModal={() => {
                  setOperationToDelete(operationData);
                }}
                playbookState={playbookState}
                navState={navState}
                selectedSubOperation={calculatedForm.subOperation}
                selectedOperation={calculatedForm.operation}
                isPipelineVar={calculatedForm.pipelineVar}
                pipelineVarId={calculatedForm.pipelineVarId}
                inputKey={calculatedForm.inputKey}
                outputKey={calculatedForm.outputKey}
                isExpanded={isExpanded}
                opId={opId}
                _path={_path}
                name={operationData.name}
                expressionName={calculatedForm.expressionName}
                isAllowed={isAllowed}
              />
            )}
            <div className={s.expandable}>
              {/* <em>Define the value to send for this output key.</em> */}
              {/* <em>Define the keys and values to send as a result of this expression.</em> */}
              {/* <em>Define the transformations you'd like applied to this mapping.</em> */}
              {/* <em>This mapping type has no additional options.</em> */}

              {calculatedForm.operation !== 'params' && (
                <ParameterSubheader
                  opId={opId}
                  _path={_path}
                  selectedSubOperation={calculatedForm.subOperation}
                  selectedOperation={calculatedForm.operation}
                />
              )}

              <section className={s.body}>
                {calculatedForm.operation === 'mappings' && (
                  <div className={s.row}>
                    <TransformLookup
                      selectedSubOperation={calculatedForm.subOperation}
                      opId={opId}
                      replace={calculatedForm.replace}
                      replaceWith={calculatedForm.replaceWith}
                      _path={_path}
                      joinSeparator={calculatedForm.joinSeparator}
                      start={calculatedForm.start}
                      end={calculatedForm.end}
                      splitSeparator={calculatedForm.splitSeparator}
                      maxElements={calculatedForm.maxElements}
                      toHash={calculatedForm.toHash}
                      extractKey={calculatedForm.extractKey}
                      precision={calculatedForm.precision}
                    />
                  </div>
                )}

                {calculatedForm.operation === 'enrichments' && (
                  <div className={s.row}>
                    <EnrichmentLookup
                      selectedSubOperation={calculatedForm.subOperation}
                      uuid={calculatedForm.uuid}
                      opId={opId}
                      _path={_path}
                      timestamp={calculatedForm.timestamp}
                      date={calculatedForm.date}
                      directValue={calculatedForm.directValue}
                      directType={calculatedForm.directType}
                      directValueOptions={calculatedForm.directValueOptions}
                    />
                  </div>
                )}

                {calculatedForm.operation === 'spreadings' && (
                  <div className={s.row}>
                    <Spreading
                      separator={calculatedForm.separator}
                      prefix={calculatedForm.prefix}
                      depth={calculatedForm.depth}
                      opId={opId}
                      _path={_path}
                    />
                  </div>
                )}

                {calculatedForm.operation === 'params' && (
                  <div className={s.row}>
                    <input name={`${opId}-operation-${_path}`} type="hidden" value="params" />
                    <input name={`${opId}-name-${_path}`} type="hidden" value={calculatedForm.name} />
                    {calculatedForm.exampleValue && (
                      <input name={`${opId}-exampleValue-${_path}`} type="hidden" value={calculatedForm.exampleValue} />
                    )}
                    <input name={`${opId}-isOptional-${_path}`} type="hidden" value={calculatedForm.isOptional} />
                    {calculatedForm.type && (
                      <input name={`${opId}-type-${_path}`} type="hidden" value={calculatedForm.type} />
                    )}
                    <ParamLookup
                      name={calculatedForm.name}
                      isPipelineVar={calculatedForm.pipelineVar}
                      opId={opId}
                      exampleValue={calculatedForm.exampleValue}
                      isOptional={calculatedForm.isOptional}
                      defaultValue={calculatedForm.defaultValue}
                      _path={_path}
                      saslType={calculatedForm.saslType}
                      stageType={calculatedForm.stageType}
                      password={calculatedForm.password}
                      username={calculatedForm.username}
                      algorithm={calculatedForm.algorithm}
                      region={calculatedForm.region}
                      bucket={calculatedForm.bucket}
                      prefix={calculatedForm.prefix}
                      accessKey={calculatedForm.accessKey}
                      secretKey={calculatedForm.secretKey}
                      serverSideEncryption={calculatedForm.serverSideEncryption}
                      compression={calculatedForm.compression}
                      credentialJson={calculatedForm.credentialJson}
                    />
                  </div>
                )}

                {calculatedForm.operation === 'expressions' && (
                  <Expression body={calculatedForm.body} lang={calculatedForm.lang} opId={opId} _path={_path} />
                )}
              </section>

              {calculatedForm.operation === 'mappings' && !_isUndefined(calculatedForm.inputKey) && (
                <div className={s.default}>
                  <DefaultValue opId={opId} _path={_path} calculatedForm={calculatedForm} />
                </div>
              )}

              <footer className={s.footer}>
                {!isNewParam && (
                  <MrButtonList
                    buttons={[
                      <MrButton key="save" text="Apply Changes" disabled={!hasChanges || !isAllowed} />,
                      <MrLink styleNames="gray" key="cancel" onClick={cancelEdit}>
                        Cancel
                      </MrLink>,
                    ]}
                  />
                )}

                {isNewParam && (
                  <MrButtonList
                    buttons={[
                      <MrButton text="Add Param" type="button" onClick={addParam} key="add" disabled={!isAllowed} />,
                      <MrLink
                        onClick={() => {
                          playbookState.cancelNewParameterForSection(navState.selectedSection, navState.selectedEvent);
                          navState.setTransformation(null);
                        }}
                        styleNames="gray"
                        key="cancel"
                      >
                        Cancel
                      </MrLink>,
                    ]}
                  />
                )}
              </footer>
            </div>
          </div>

          {operationToDelete && (
            <DeleteParameter
              operationToDelete={operationToDelete}
              playbookState={playbookState}
              setOperationToDelete={setOperationToDelete}
              navState={navState}
            />
          )}
        </FormProvider>
      </MrForm>
    </div>
  );
};

Parameter.propTypes = {
  playbookState: pt.shape({
    promoteNewParameterToPlaybook: pt.func,
    updateParameter: pt.func,
    cancelNewParameterForSection: pt.func,
  }).isRequired,
  operationData: pt.shape({
    opId: pt.string.isRequired,
    opIndex: pt.number.isRequired,
    _path: pt.string,
    inputKey: pt.string,
    outputKey: pt.string,
    uuid: pt.string,
    transforms: pt.arrayOf(pt.object),
    defaultBool: pt.any,
    defaultString: pt.any,
    defaultInt: pt.any,
    defaultFloat: pt.any,
    body: pt.string,
    lang: pt.string,
    event: pt.string,
    expressionName: pt.string,
    name: pt.string,
    schema: pt.object,
  }).isRequired,
  isExpanded: pt.bool,
  // setNewParam: pt.func,
  navState: pt.shape({
    setTransformation: pt.func,
    selectedRevision: pt.string,
    selectedSection: pt.string,
    selectedEvent: pt.string,
  }).isRequired,
  isAllowed: pt.bool.isRequired,
};

export default Parameter;
