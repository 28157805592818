// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-playbook-editor-parameters-editor-panel-modals-index-module__toggle{cursor:pointer;margin-left:auto;width:1.8rem;transition:transform 200ms ease-out;transform:scaleY(-1)}.src-playbook-editor-parameters-editor-panel-modals-index-module__toggle.src-playbook-editor-parameters-editor-panel-modals-index-module__open{transform:scaleY(1)}.src-playbook-editor-parameters-editor-panel-modals-index-module__header{display:flex;flex-direction:row;align-items:center}.src-playbook-editor-parameters-editor-panel-modals-index-module__content{flex:none}", "",{"version":3,"sources":["/usr/src/packages/app/src/playbook-editor/parameters/editor-panel/modals/index.module.scss"],"names":[],"mappings":"AA2BA,yEACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,mCAAA,CACA,oBAAA,CAEA,+IACE,mBAAA,CAIJ,yEACE,YAAA,CACA,kBAAA,CACA,kBAAA,CAGF,0EACE,SAAA","file":"index.module.scss","sourcesContent":["@import '../../../../../../component-library/src/scss/theme/brand-vars';\n@import '../../../../../../component-library/src/scss/theme/layout-vars';\n@import '../../../../../../component-library/src/scss/theme/shadow-vars';\n@import '../../../../../../component-library/src/scss/theme/mixins';\n\n// this file is only for global variable and mixins and should not output any styles\n\n///////////////////////////////////////////////////////////////////////////////\n\n@mixin cardList($tile) {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: center;\n\n  @if ($tile == true) {\n    justify-content: flex-start;\n    margin: -1rem;\n  }\n\n  & > div {\n    margin: 1rem;\n  }\n}\n\n.toggle {\n  cursor: pointer;\n  margin-left: auto;\n  width: 1.8rem;\n  transition: transform 200ms ease-out;\n  transform: scaleY(-1);\n\n  &.open {\n    transform: scaleY(1);\n  }\n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.content {\n  flex: none;\n}\n"]}]);
// Exports
exports.locals = {
	"toggle": "src-playbook-editor-parameters-editor-panel-modals-index-module__toggle",
	"open": "src-playbook-editor-parameters-editor-panel-modals-index-module__open",
	"header": "src-playbook-editor-parameters-editor-panel-modals-index-module__header",
	"content": "src-playbook-editor-parameters-editor-panel-modals-index-module__content"
};
module.exports = exports;
