import React from 'react';
import pt from 'prop-types';

import { MrInputSelectDeprecated } from '@ion/components';
import buildName from '../build-name';

function ParamTypeSelect({ fieldName, opId, _path, formState, validateField, setField, options, value }) {
  const name = buildName(fieldName, opId, _path);

  return (
    <MrInputSelectDeprecated
      name={name}
      options={options}
      value={value}
      onChange={setField}
      onBlur={validateField}
      errorMsg={formState[name]?.errorMsg}
      placeholder="select one"
      label="TYPE"
      labelPosition="top"
    />
  );
}

ParamTypeSelect.propTypes = {
  fieldName: pt.string,
  opId: pt.string,
  _path: pt.string,
  formState: pt.object,
  validateField: pt.func,
  setField: pt.func,
  options: pt.array,
  value: pt.string,
};

export default ParamTypeSelect;
