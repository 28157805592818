export function buildAnalyticsFileData(settings, identitySyncs) {
  const analyticsFileSettings = {
    host: settings.host,
    cdnURL: settings.cdnURL,
    compressFile: settings.compressFile,
    impliedConsent: settings.syncInjectorSettings.privacy.impliedConsent,
    cookieName: settings.syncInjectorSettings.privacy.cookieName,
    itpSettings: settings.syncInjectorSettings.itpSettings.enabled,
    fileHostingType: settings.fileHostingType,
    windowVariableName: settings.windowVariableName,
    /**
     * We use settings.syncInjectorSettings.storage.marker because that is the location of those settings in
     * the builder server, see file packages/app/src/components/analytics-builder/create-ajs-builder-file.js, that
     * is the actual request that goes to the builder server.
     */
    storagePrefix: settings.syncInjectorSettings.storage.marker,
    storageType: settings.syncInjectorSettings.storage.type,
    storageDatabase: settings.syncInjectorSettings.storage.database,
  };

  const analyticsFileIdentitySyncs = identitySyncs.map(identitySync => {
    return {
      settings: identitySync.settings,
      consentCategoryId: identitySync.consentCategoryId ?? null,
      identity_sync: {
        on_conflict: {
          constraint: 'identity_syncs_name_key',
          update_columns: ['friendlyName'],
        },
        data: {
          name: identitySync.name,
          friendlyName: identitySync.friendlyName,
        },
      },
    };
  });

  return {
    analyticsFileSettings,
    analyticsFileIdentitySyncs,
  };
}
