import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputText, MrInputPassword, MrInputSelectDeprecated } from '@ion/components';

import { useFormContext } from 'app/context/form-context';
import ParamTypeSelect from './ParamTypeSelect';

const { validateRequired } = formValidators;

const algorithmOptions = [
  {
    text: 'SHA_256',
    value: '1',
  },
  {
    text: 'SHA_512',
    value: '2',
  },
];

const saslOptions = [
  {
    text: 'Encrypted (SCRAM)',
    value: 'SCRAM',
  },
  {
    text: 'Plain text (PLAIN)',
    value: 'PLAIN',
  },
];

const Sasl = ({ opId, _path, saslType, username, password, algorithm }) => {
  const { validateField, resetError, formState, setField } = useFormContext();
  const scram = saslType === 'SCRAM';

  return (
    <>
      <ParamTypeSelect
        fieldName="saslType"
        opId={opId}
        _path={_path}
        formState={formState}
        validateField={validateField}
        setField={setField}
        options={saslOptions}
        value={saslType}
      />
      {((name = `${opId}-username-${_path}`) => (
        <MrInputText
          label="USERNAME"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={username}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateRequired()]}
          placeholder="USERNAME"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-password-${_path}`) => (
        <MrInputPassword
          label="PASSWORD"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={password}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateRequired()]}
          placeholder="PASSWORD"
          labelPosition="top"
        />
      ))()}

      {scram &&
        ((name = `${opId}-algorithm-${_path}`) => (
          <MrInputSelectDeprecated
            name={name}
            options={algorithmOptions}
            value={algorithm}
            onChange={setField}
            onBlur={validateField}
            validators={scram ? [validateRequired()] : []}
            errorMsg={formState[name]?.errorMsg}
            placeholder="select one"
            label="HASH FUNCTION"
            labelPosition="top"
          />
        ))()}
    </>
  );
};

Sasl.propTypes = {
  saslType: pt.string,
  username: pt.string,
  password: pt.string,
  algorithm: pt.string,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default Sasl;
