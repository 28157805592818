import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import pt from 'prop-types';

import {
  MrAppLayout,
  MrAppMain,
  MrInputCheckbox,
  MrForm,
  MrH,
  MrTable,
  MrText,
  useForm,
  generateId,
  MrLinkButton,
  MrIcon,
} from '@ion/components';
import SideNav from 'app/components/side-nav';

import { omit as _omit } from 'lodash';
import { routes } from 'app/constants';
import { useUpdatePipelineIntegration, useInsertPipelineIntegration, useGetPipeline } from '@ion/api';
import bulkStageRevisions from './bulk-stage-revisions';
import close from '../../assets/icon-close.svg';
import arrow from '../../assets/icon-arrow-right.svg';

import s from './index.module.scss';

const BulkSelectPipelines = ({ revisions, pipelinesList }) => {
  const history = useHistory();
  const formId = generateId();
  const { setField, submitForm, formState } = useForm(formId);
  const [createPipelineIntegration] = useInsertPipelineIntegration();
  const [updatePipelineIntegration] = useUpdatePipelineIntegration();
  const [lastDeployedMap, setLastDeployedMap] = useState({});
  const [pipelinesFilterAndTransform, setPipelinesFilterAndTransform] = useState([]);
  const [nextStepDisabled, setNextStepDisabled] = useState(false);
  const [deploymentDetailsError, setDeploymentDetailsError] = useState('');
  const [getDeployedPipeline] = useGetPipeline();

  const isAllowedError = error => {
    return !error || error?.message?.includes('"error":"not found"');
  };

  useEffect(() => {
    const pipelineFilterAndTransform = Promise.all(
      pipelinesList.map(async p => {
        const data = await getDeployedPipeline({ clusterId: p.clusterId, writekey: p.writekey });
        if (!isAllowedError(data.errors)) {
          throw new Error('Error retrieving pipeline details');
        }
        if (!lastDeployedMap[p.id] && data?.pipeline?.createdAt) {
          lastDeployedMap[p.id] = new Date(data?.pipeline?.createdAt).toDateString();
          setLastDeployedMap(lastDeployedMap);
        }
        return {
          pipelineID: p.id,
          filter: data?.pipeline?.filter,
          transform: data?.pipeline?.transform,
        };
      })
    );

    pipelineFilterAndTransform
      .then(filtersAndTransforms => {
        setPipelinesFilterAndTransform(filtersAndTransforms);
      })
      .catch(() => {
        /**
         * If we cannot get the filters of each pipeline we need to stop the action here because we could remove the filters on accident
         */
        setDeploymentDetailsError('Failed to get pipeline details. Cannot continue with bulk action.');
        setNextStepDisabled(true);
      });
    // TODO (Chris 2023.08.14) - refactor useEffect hook to include missing dependencies
  }, [pipelinesList]);

  const toggleAll = checked => {
    pipelinesList.forEach(pipeline => {
      setField({
        target: {
          name: pipeline.id,
          value: checked,
        },
      });
    });
  };

  const onFormSubmit = () => {
    if (nextStepDisabled) {
      return;
    }
    const { data, isValid } = submitForm();
    if (isValid && Object.values(data).includes('true')) {
      const updatedPipelines = bulkStageRevisions(
        _omit(data, 'selectAll'),
        pipelinesList,
        revisions,
        updatePipelineIntegration,
        createPipelineIntegration
      );
      // At this point pass list of updated pipelines to confirm and deploy step
      const pushState = {
        pipelines: updatedPipelines,
        pipelinesFilter: pipelinesFilterAndTransform,
        pipelinesTransform: pipelinesFilterAndTransform,
      };
      history.push(routes.bulkDeployPipelines, pushState);
    }
  };

  return (
    <>
      <MrAppLayout
        header={
          <div className={s.header}>
            <MrLinkButton className={s.close} to={routes.pipelines}>
              <img src={close} />
              Close
            </MrLinkButton>

            <MrText className={s.title}>
              <MrIcon id={'icon-bulk-deploy'} />
              <span>Bulk Stage and Deploy</span>
            </MrText>

            <MrLinkButton
              onClick={onFormSubmit}
              testId={'selectPipelinesSubmit'}
              className={s.submit}
              formid={formId}
              disabled={nextStepDisabled}
            >
              Next Step
              <img src={arrow} />
            </MrLinkButton>
          </div>
        }
        sideNav={<SideNav />}
        title="Pipelines"
        description={'Select Pipelines'}
        icon="pipelines"
      >
        <MrAppMain styleNames="list">
          <div className={s.stepList}>
            <div className={s.completedStep}>✓</div> <MrText className={s.stepText}>Revisions</MrText>
            <div className={s.currentStep}>2</div> <MrText className={s.currentStepText}>Pipelines</MrText>
            <div className={s.step}>3</div> <MrText className={s.stepText}>Confirm & Deploy</MrText>
          </div>
          <div className={s.card}>
            <MrH h="h3" styleNames="noMargin">
              Select Pipelines
            </MrH>
            {deploymentDetailsError && (
              <div>
                <b>{deploymentDetailsError}</b>
              </div>
            )}

            <MrForm onSubmit={onFormSubmit} id={formId}>
              <MrTable className={s.table}>
                <tbody>
                  <tr>
                    <th>
                      <MrInputCheckbox
                        className={s.checkbox}
                        testId={'selectAll'}
                        value={formState.selectAll?.value}
                        labelPosition="right"
                        onChange={event => {
                          setField(event);
                          toggleAll(event.target?.checked);
                        }}
                        name={'selectAll'}
                        label={'Pipeline Name'}
                      />
                    </th>
                    <th>Last Deploy</th>
                  </tr>
                  {pipelinesList?.map(pipeline => {
                    return (
                      <tr key={pipeline.id}>
                        <td>
                          <MrInputCheckbox
                            className={s.checkbox}
                            value={formState[pipeline.id]?.value}
                            labelPosition="right"
                            onChange={setField}
                            name={pipeline.id}
                            label={pipeline.name}
                          />
                        </td>
                        <td>{lastDeployedMap[pipeline.id] || 'None'}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </MrTable>
            </MrForm>
          </div>
        </MrAppMain>
      </MrAppLayout>
    </>
  );
};

BulkSelectPipelines.propTypes = {
  revisions: pt.object,
  pipelinesList: pt.array.isRequired,
};

export default BulkSelectPipelines;
