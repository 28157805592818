import { gql } from '@apollo/client';

import { default as consentCategory } from '../consent-category';

export const updateConsentCategoryMutation = gql`
  mutation UpdateConsentCategory(
    $consent_categories_pk_columns: consent_categories_pk_columns_input!
    $consent_categories_set_input: consent_categories_set_input!
  ) {
    update_consent_categories_by_pk(pk_columns: $consent_categories_pk_columns, _set: $consent_categories_set_input) {
      ...consentCategory
    }
  }
  ${consentCategory}
`;

export function buildVariables({ consentCategoryId, name, code }) {
  return {
    consent_categories_pk_columns: {
      id: consentCategoryId,
    },
    consent_categories_set_input: {
      ...(name && { name }),
      ...(code && { code }),
    },
  };
}
