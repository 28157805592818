import React from 'react';
import pt from 'prop-types';
import { MrTabs } from './tabs';

import './header-tabs-overrides.scss';

export default function MrHeaderTabs({ children, defaultIndex }) {
  return (
    <MrTabs className={'header-tabs'} defaultIndex={defaultIndex}>
      {children}
    </MrTabs>
  );
}

MrHeaderTabs.propTypes = {
  children: pt.node,
  defaultIndex: pt.number,
};
