import { readFile } from './read-file';
import getDataFromFile from './get-data-from-file';
import preventUpload from './prevent-upload';

export async function selectFile(
  event,
  validate,
  playbookState,
  uploadState,
  integration,
  setFileName,
  setUploadedData,
  clusterId
) {
  const file = event.target.files[0];

  // file is undefined if the user cancels after opening the file selector
  if (file) {
    const { name } = file;
    const extension = name.substring(name.lastIndexOf('.') + 1);
    const fileName = name.replace(/\.[^/.]+$/, '');
    setFileName(fileName);

    try {
      const fileData = await readFile(file);
      const result = getDataFromFile(extension, fileData);

      const connectionType = Object.keys(playbookState.connectionJSON)[0];
      const msg = preventUpload(fileName, extension, result, playbookState, clusterId);
      if (msg) {
        uploadState.setError(msg);
        return;
      }
      validate({
        clusterId,
        integration: {
          ...(fileName === 'connection' ? { [connectionType]: { ...result } } : playbookState.connectionJSON),
          integrationName: integration.starterkitId,
          transformStages: [
            {
              data: {
                playbook: {
                  ...(fileName === 'playbook' ? { ...result } : playbookState.playbook),
                },
              },
              desc: integration.starterkitId,
              name: `integration for ${integration.starterkitId}`,
            },
          ],
        },
      });

      setUploadedData(result);
    } catch (e) {
      // display error if file cannot be parsed as JSON
      uploadState.setError(e.message);
    }
  } else {
    // clear any existing errors on cancel
    uploadState.setError(null);
  }
}
