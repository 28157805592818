import React from 'react';
import propTypes from 'prop-types';

export default function withValidateChildren(Component) {
  function ValidateChildrenWrapper(props) {
    validateChildren(props.children);
    return <Component {...props} />;
  }
  ValidateChildrenWrapper.propTypes = {
    children: propTypes.node,
  };
  return ValidateChildrenWrapper;
}

export function validateChildren(children) {
  const nonRadio = React.Children.toArray(children).find(child => child.type.displayName !== 'MrInputRadio');

  if (nonRadio) {
    const message = `MrInputRadioGroup: All children must be an MrInputRadio component. Found ${nonRadio.type}`;
    throw new Error(message);
  }
}
