import React, { useEffect } from 'react';
import pt from 'prop-types';

import { EditorProvider } from 'app/context/editor-context';
import { MrAppMain } from '@ion/components';
import { scrollTimeout } from 'app/constants';

import EditorPanel from 'app/playbook-editor/parameters/editor-panel';
import ParametersSideNav from 'app/playbook-editor/parameters/side-nav';

import s from './index.module.scss';

const PlaybookEditor = ({ navState, playbookState, playbookData, connectionJson, isAllowed }) => {
  // scroll the current card into view when selected from the nav menu
  useEffect(() => {
    // TODO Might need to add scroll to back
    if (navState.selectedTransformation?.scrollTo) {
      setTimeout(() => {
        document
          .getElementById(navState.selectedTransformation)
          .scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, scrollTimeout);
    }
  }, [navState.selectedTransformation]);

  return (
    <>
      <EditorProvider
        value={{
          playbookData,
          opIndices: playbookData?.metadata?.opIndices,
          connectionJson,
          integrationName: playbookData?.integrationName,
        }}
      >
        <ParametersSideNav navState={navState} playbookState={playbookState} />
        <div className={s.editor}>
          <MrAppMain styleNames="form scrollable">
            <EditorPanel navState={navState} playbookState={playbookState} isAllowed={isAllowed} />
          </MrAppMain>
        </div>
      </EditorProvider>
    </>
  );
};

PlaybookEditor.propTypes = {
  navState: pt.object.isRequired,
  playbookState: pt.object.isRequired,
  playbookData: pt.object,
  connectionJson: pt.object,
  isAllowed: pt.bool.isRequired,
};

export default PlaybookEditor;
