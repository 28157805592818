import { useMutation } from '../index';
import { gql } from '@apollo/client';
import permission from '../Apollo/permissions';

const mutation = gql`
  mutation RemoveAnalyticsFile($analyticsFileId: uuid!) {
    delete_analytics_files_by_pk(id: $analyticsFileId) {
      id
      settings
      identitySyncs
      integrations
    }
  }
`;

export default (options = {}) => {
  const [call, response] = useMutation(mutation, {
    ...options,
    context: { permission: permission.updatePipelineAJSBuilder },
  });
  return [
    ({ analyticsFileId }) => {
      return call({
        variables: {
          analyticsFileId,
        },
      });
    },
    response,
  ];
};
