const mapPipelineVarsFromPipelineVariables = (pipeline_variables, pipelineVars) => {
  return Object.fromEntries(
    pipeline_variables.map(({ name }) => {
      const pipelineVariableValue = pipelineVars[name] ?? '';
      return [name, pipelineVariableValue];
    })
  );
};

export default mapPipelineVarsFromPipelineVariables;
