import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './nav-link.module.scss';
import NavIcon from 'app/components/nav-icon';
import { routes } from 'app/constants';
import { MrLink } from '@ion/components';
import { NavLink as _NavLink } from 'react-router-dom';
import { startCase as _startCase } from 'lodash';

const NavLink = ({ name, label, href, ...props }) => {
  if (href) {
    props.href = href;
    props.target = '_blank';
  } else {
    props.to = routes[name];
    props.activeClassName = s.active;
  }

  return (
    <MrLink
      {...props}
      component={_NavLink}
      id={`${name}-${label ? 'label' : 'icon'}`}
      // apply hover styles to corresponding label / icon
      onMouseOver={() => {
        const el = document.getElementById(`${name}-${!label ? 'label' : 'icon'}`);
        el.classList.add(s.hover);
      }}
      onMouseOut={() => {
        const el = document.getElementById(`${name}-${!label ? 'label' : 'icon'}`);
        el.classList.remove(s.hover);
      }}
      className={cx(s.link, label ? s.label : s.icon)}
    >
      {label ? _startCase(name) : <NavIcon icon={name} />}
    </MrLink>
  );
};

NavLink.propTypes = {
  name: pt.string,
  href: pt.string,
  label: pt.bool,
  active: pt.bool,
};

export default NavLink;
