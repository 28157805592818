export default (method = '') => {
  switch (method.toLowerCase()) {
    case 'post':
      return 'create';
    case 'get':
    case 'delete':
      return method.toLowerCase();
    default:
      return undefined;
  }
};
