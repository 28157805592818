import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import { MrIcon } from '@ion/components';
import s from './warning.module.scss';

const MrWarning = ({ header, body, visible, className }) => {
  if (visible) {
    return (
      <div className={cx(className, s.warningContainer)}>
        <MrIcon className={s.icon} id="icon-exclamation-circle" />
        <div className={s.warningText}>
          <span>{header}</span>
          <br></br>
          <span className={s.warningBody}>{body}</span>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

MrWarning.propTypes = {
  header: pt.string,
  body: pt.oneOfType([pt.string, pt.node]),
  visible: pt.bool,
  className: pt.string,
};

export default MrWarning;
