import React from 'react';

import { MrAppLayout } from '@ion/components';

import SideNav from 'app/components/side-nav';
import Header from 'app/components/app-header';

import pt from 'prop-types';

import AnalyticsBuilder from 'app/components/analytics-builder';
import { Redirect } from 'react-router-dom';
import { ConsentCategoriesContext } from '../../context/consent-categories-context';
import { StagedIdentitySyncsProvider } from '../../components/analytics-builder/useStagedIdentitySyncs';

const AnalyticsJSBuilderPage = ({ location }) => {
  const stateData = location.state;
  if (stateData === undefined) {
    return <Redirect to="/pipelines" />;
  }

  const { name, writekey } = stateData;

  const title = `Pipelines`;
  const subTitle = `${name} - ${writekey}`;
  const desc = 'Analytics.js Builder';

  return (
    <MrAppLayout
      title={title}
      description={desc}
      icon="pipelines"
      sideNav={<SideNav />}
      header={<Header icon="pipelines" title="Pipelines" subTitle={subTitle} route={'/pipelines'} />}
    >
      <ConsentCategoriesContext>
        <StagedIdentitySyncsProvider>
          <AnalyticsBuilder name={name} writekey={writekey} />
        </StagedIdentitySyncsProvider>
      </ConsentCategoriesContext>
    </MrAppLayout>
  );
};

AnalyticsJSBuilderPage.propTypes = {
  location: pt.object,
};
export default AnalyticsJSBuilderPage;
