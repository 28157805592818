import React from 'react';

import { MrText } from '@ion/components';

export const consentCategoryDisabled =
  'Consent Enforcement has not been enabled for your organization, but all your events will be captured and processed. Please reach out to your customer success representative for more information.';

export const ConsentCategoryDisabledMessage = () => {
  return <MrText styleNames="smallGray text-sm">{consentCategoryDisabled}</MrText>;
};

ConsentCategoryDisabledMessage.propTypes = {};
