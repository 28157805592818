import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './logo-enterprise.module.scss';
import logo from './logo.svg';

const MrLogoEnterprise = ({ className }) => {
  return <img src={logo} alt="MetaRouter" className={cx(s.logoEnterprise, className)} />;
};

MrLogoEnterprise.propTypes = {
  className: pt.string,
};

export default MrLogoEnterprise;
