import React from 'react';
import pt from 'prop-types';

import { MrModal, MrButton, MrLink, MrButtonList } from '@ion/components';

const ConfirmDeleteModal = ({ children, closeModal, deleteFn, disableDelete, buttonClassName }) => {
  return (
    <MrModal closeModal={closeModal}>
      <>
        {children}
        <MrButtonList
          styleNames="paddingModal"
          buttons={[
            <MrButton
              disabled={disableDelete}
              text="Delete"
              type="button"
              styleNames="red"
              className={buttonClassName}
              onClick={() => {
                closeModal();
                deleteFn();
              }}
              key="delete"
              testId="ConfirmDelete"
            />,
            <MrLink onClick={closeModal} styleNames="gray" key="cancel">
              Cancel
            </MrLink>,
          ]}
        />
      </>
    </MrModal>
  );
};

ConfirmDeleteModal.propTypes = {
  children: pt.node.isRequired,
  closeModal: pt.func.isRequired,
  deleteFn: pt.func.isRequired,
  disableDelete: pt.bool,
  buttonClassName: pt.string,
};

export default ConfirmDeleteModal;
