import { useQuery } from './../index';
import { gql } from '@apollo/client';

import permission from '../Apollo/permissions';

export const query = gql`
  query EventSchemas {
    event_schemas {
      id
      json_schema
    }
  }
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, error, isAllowed } = useQuery(query, {
    variables, // can apply any transforms or default vars here
    context: { permission: permission.readCluster }, // TODO - update
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: data?.event_schemas || [],
    loading,
    isAllowed,
    error,
  };
};
