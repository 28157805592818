import React from 'react';

import { ConfirmDelete, ModalLauncher } from '@metarouter-private/mr-mui';

import { useUserConfirmDeleteContext } from '../../context/UserConfirmDeleteContextProvider';

import UserModal from '../modal/UserModal';
import InviteUserButton from './InviteUserButton';

export default function TableAction() {
  const { confirmDeleteProps } = useUserConfirmDeleteContext();
  return (
    <>
      <ModalLauncher Launcher={InviteUserButton} Modal={UserModal} />
      <ConfirmDelete {...confirmDeleteProps} />
    </>
  );
}
