import React from 'react';

import { ConfirmDelete } from '@metarouter-private/mr-mui';

import { useInvitationConfirmDeleteContext } from '../../../context/InvitationConfirmDeleteContextProvider';

export default function ConfirmDeleteInvitation() {
  const { confirmDeleteProps } = useInvitationConfirmDeleteContext();
  return <ConfirmDelete {...confirmDeleteProps} />;
}
