import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputText } from '@ion/components';

import { useFormContext } from 'app/context/form-context';

const { validateRequired } = formValidators;

const JoinValues = ({ joinSeparator, opId, _path }) => {
  const { validateField, resetError, formState, setField } = useFormContext();
  const separatorName = `${opId}-joinSeparator-${_path}`;

  return (
    <MrInputText
      label="Separator"
      onBlur={validateField}
      onInput={resetError}
      name={separatorName}
      value={joinSeparator}
      onChange={setField}
      errorMsg={formState[separatorName]?.errorMsg}
      validators={[validateRequired()]}
      placeholder="Separator"
      labelPosition="top"
    />
  );
};

JoinValues.propTypes = {
  joinSeparator: pt.string,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default JoinValues;
