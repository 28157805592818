import { urlParameters, matchPixel, localStorage, fetchSync, firstPartyCookie } from './customSyncs/index';

export const CUSTOM_IDENTITY_SYNC = {
  REQUEST_NAME: 'customIdentitySync',
  DESCRIPTION: 'Custom Identity Syncs',
};

export const BRING_YOUR_OWN_SYNCS = {
  [urlParameters.REQUEST_NAME]: urlParameters,
  [matchPixel.REQUEST_NAME]: matchPixel,
  [localStorage.REQUEST_NAME]: localStorage,
  [fetchSync.REQUEST_NAME]: fetchSync,
  [firstPartyCookie.REQUEST_NAME]: firstPartyCookie,
};

export const CUSTOM_IDENTITY_SYNC_SINGLE_SELECT_OPTIONS = [
  {
    value: urlParameters.REQUEST_NAME,
    text: urlParameters.SINGLE_SELECT_DESCRIPTION,
  },
  {
    value: matchPixel.REQUEST_NAME,
    text: matchPixel.SINGLE_SELECT_DESCRIPTION,
  },
  {
    value: localStorage.REQUEST_NAME,
    text: localStorage.SINGLE_SELECT_DESCRIPTION,
  },
  {
    value: fetchSync.REQUEST_NAME,
    text: fetchSync.SINGLE_SELECT_DESCRIPTION,
  },
  {
    value: firstPartyCookie.REQUEST_NAME,
    text: firstPartyCookie.SINGLE_SELECT_DESCRIPTION,
  },
].sort((a, b) => a.value.localeCompare(b.value));

export const BRING_YOUR_OWN_SYNCS_REQUEST_NAMES = [
  urlParameters.REQUEST_NAME,
  matchPixel.REQUEST_NAME,
  localStorage.REQUEST_NAME,
  fetchSync.REQUEST_NAME,
  firstPartyCookie.REQUEST_NAME,
];
