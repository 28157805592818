import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './toggle-button.module.scss';
import { MrIcon } from '../../../index';

const ToggleButton = ({ className, testId = 'ToggleButton', value, label, icon, selected, onToggle, disabled }) => {
  return (
    <div
      className={cx(s.toggleButton, className, disabled ? s.disabled : { [s.selected]: value === selected })}
      data-test-id={testId}
      onClick={onToggle}
    >
      {icon && <MrIcon id={icon} className={s.toggleButtonIcon} />} <span className={s.toggleButtonLabel}>{label}</span>
    </div>
  );
};

ToggleButton.propTypes = {
  className: pt.string,
  testId: pt.string,
  value: pt.string.isRequired,
  label: pt.string.isRequired,
  icon: pt.string,
  selected: pt.string,
  onToggle: pt.func,
  disabled: pt.bool,
};

export default ToggleButton;
