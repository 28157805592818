import React from 'react';
import classNames from 'classnames';
import { colorClass } from './color-coding';

import { MrPill } from '@ion/components';
import propTypes from 'prop-types';
import actions from '@ion/api/audit-logs/actions';
import { readableAction } from '../../actions';

import styles from './index.module.scss';

export default function ActionCell({ action }) {
  const className = classNames(styles.pill, colorClass(action));
  return <MrPill className={className} text={readableAction(action)} />;
}

ActionCell.propTypes = {
  action: propTypes.oneOf(Object.values(actions)),
};
