/**
 *
 * Returns true when the integration is attached to a pipeline.
 *
 * @param (integrationId, pipelines)
 *
 * @returns boolean
 */

import { isNil as _isNil } from 'lodash';

const hasPipelineIntegrations = (id, pipelines) => {
  const pipelineWithIntegration = pipelines.find(pipeline => {
    return pipeline.pipelineIntegrations?.find(pipelineIntegration => {
      return pipelineIntegration.integrationId === id;
    });
  });

  return !_isNil(pipelineWithIntegration);
};

export default hasPipelineIntegrations;
