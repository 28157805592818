import React from 'react';
import pt from 'prop-types';

import { MrInputSelect, formValidators } from '@ion/components';
import { useGetAvailableIntegrations } from '../hooks/useGetAvailableIntegrations';

const { validateRequired } = formValidators;

export const IntegrationSelect = ({ formState, validateField, pipeline, onChange, excludedIntegrationId }) => {
  const { availableIntegrations, hasAvailableIntegrations } = useGetAvailableIntegrations(
    pipeline,
    excludedIntegrationId
  );

  if (!hasAvailableIntegrations) {
    return null;
  }

  const integrationsSelectOptions = availableIntegrations.map(integration => {
    const hasRevisions = integration.playbooks.length > 0;
    return {
      text: hasRevisions ? integration.name : `${integration.name} (No revisions available)`,
      value: integration.id,
      disabled: !hasRevisions,
    };
  });

  return (
    <MrInputSelect
      options={integrationsSelectOptions}
      onBlur={validateField}
      validators={[validateRequired()]}
      name="integration"
      label="Select Integration"
      placeholder="Select..."
      value={formState.integration.value || ''}
      onChange={onChange}
      errorMsg={formState.integration.errorMsg}
      menuPosition="fixed"
      required
    />
  );
};

IntegrationSelect.propTypes = {
  formState: pt.object.isRequired,
  validateField: pt.func.isRequired,
  pipeline: pt.object.isRequired,
  onChange: pt.func.isRequired,
  excludedIntegrationId: pt.string,
};
