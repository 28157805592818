import permission from '../Apollo/permissions';
import { useControlProxy } from './useControlProxy';
import {
  buildVariables as buildPipelineDeployedVariables,
  mutation as pipelineDeployedMutation,
} from '../audit-logs/pipeline-deployed/audit-log-mutation-builder';
import { METHOD, PATH, PIPELINE_SCOPE } from './control-api';
import useTrackWithUser from '../hooks/useTrackWithUser';

export function useDeployPipeline(options = {}) {
  const [trackWithUser] = useTrackWithUser('pipeline_deployed');
  const [executeMutation, response] = useControlProxy({
    permission: permission.deployPipeline,
    additionalQueries: [pipelineDeployedMutation],
    ...options,
  });

  const deployPipelineAndAudit = ({ id, name, clusterId, writekey, pipelineJSON }) => {
    const additionalVariables = buildPipelineDeployedVariables({
      id,
      name,
      clusterId,
      writekey,
    });

    return executeMutation({
      path: PATH.Pipeline,
      method: METHOD.POST,
      body: { pipeline: pipelineJSON, scope: PIPELINE_SCOPE },
      clusterId,
      writekey,
      additionalVariables,
    }).then(response => {
      if (!response.errors) {
        trackWithUser({ clusterId, pipelineWritekey: writekey });
      }
      return response;
    });
  };
  return [deployPipelineAndAudit, response];
}
