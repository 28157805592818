import React from 'react';
import pt from 'prop-types';
import Editor from 'react-simple-code-editor';
import cx from 'classnames';

import customHighlight from './custom-highlight';

import s from './code-editor.module.scss';
import './code-editor.scss';

export default function SimpleCodeEditor({
  id,
  name,
  value,
  placeholder,
  readOnly,
  onFocus,
  onBlur,
  onInput,
  onChange,
  lang,
  rows = 20,
  hasError,
  testId,
}) {
  const rowsClassName = rows ? s[`rows${rows}`] : null;

  return (
    <div className={cx(s.scroll, s.input, rowsClassName)}>
      <div className={cx(s.scrollInner, rowsClassName)}>
        <Editor
          name={name}
          value={value}
          readOnly={readOnly}
          textareaId={id}
          placeholder={placeholder}
          onValueChange={code => {
            // wrap the editor's code string in an object that matches the browser event API
            const event = {
              target: {
                name,
                value: code,
              },
            };
            if (onChange) {
              onChange(event);
            }
          }}
          onFocus={onFocus}
          onBlur={onBlur}
          onInput={onInput}
          highlight={value => customHighlight(value, lang)}
          textareaClassName={s.code}
          preClassName={cx({ [s.hasError]: hasError }, s.code)}
          data-test-id={testId}
        />
      </div>
    </div>
  );
}

SimpleCodeEditor.propTypes = {
  id: pt.string.isRequired,
  placeholder: pt.string,
  onFocus: pt.func,
  value: pt.string,
  label: pt.string,
  name: pt.string.isRequired,
  onBlur: pt.func,
  onInput: pt.func,
  onChange: pt.func,
  errorMsg: pt.string,
  validators: pt.arrayOf(pt.func),
  className: pt.string,
  labelPosition: pt.oneOf(['top', 'left']),
  lang: pt.string.isRequired,
  rows: pt.oneOf([20, 30, 40]), // Similar to rows property for textarea.
  testId: pt.string,
  readOnly: pt.bool,
  hasError: pt.bool,
};
