import { capitalize as _capitalize } from 'lodash';

const formatError = error => {
  let formattedError = error.split('response: ').pop();
  try {
    formattedError = _capitalize(JSON.parse(formattedError).error);
  } catch {
    return error;
  }

  return formattedError;
};

export default formatError;
