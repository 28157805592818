import React from 'react';
import pt from 'prop-types';
import classNames from 'classnames';

import { isEmpty as _isEmpty, keys as _keys } from 'lodash';

import { MrText, MrTruncateWithCount } from '@ion/components';

import allowRowClick from './allow-row-click';

function buildTruncateWithCountItems(pipelineVars) {
  const pipelineVarsKeys = _keys(pipelineVars);
  const pipelineVarsKeysLength = pipelineVarsKeys.length - 1;

  return pipelineVarsKeys.map((pipelineVar, index) => {
    const separator = pipelineVarsKeysLength === index ? '' : ', ';
    const itemText = `${pipelineVar}${separator}`;
    const styleNames = classNames('sm', { semiBold: _isEmpty(pipelineVars[pipelineVar]) });
    return (
      <MrText key={index} component="span" styleNames={styleNames} {...allowRowClick}>
        {itemText}
      </MrText>
    );
  });
}

export default function PipelineVariablesCell(row) {
  if (_isEmpty(row.pipelineVars)) {
    return (
      <MrText component="span" styleNames="sm" {...allowRowClick}>
        None
      </MrText>
    );
  }

  const items = buildTruncateWithCountItems(row.pipelineVars);
  const truncateWithCountProps = {
    items,
    ellipsisProps: {
      ...allowRowClick,
    },
  };

  return <MrTruncateWithCount {...truncateWithCountProps} />;
}

PipelineVariablesCell.propTypes = {
  row: pt.shape({
    pipelineVars: pt.object,
  }),
};
