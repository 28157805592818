import React, { createContext, useContext } from 'react';
import propTypes from 'prop-types';
import useRegisterCallbacks from '@ion/api/hooks/useRegisterCallbacks';

const UserManagementPageContext = createContext(null);

export default function UserManagementPageContextProvider({ children }) {
  const [registerUserInvitedCallback, onUserInvited] = useRegisterCallbacks();

  return (
    <UserManagementPageContext.Provider value={{ onUserInvited, registerUserInvitedCallback }}>
      {children}
    </UserManagementPageContext.Provider>
  );
}

export function useUserManagementPageContext() {
  return useContext(UserManagementPageContext);
}

UserManagementPageContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};
