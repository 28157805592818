import {
  IBMPlexSans,
  mrGray600,
  mrNeutralN5,
  sans,
  transparent,
  mrPrimary500,
  mrBlueB4,
  mrGray200,
} from './duplicate-sass-varaibles';

const cellPadding = {
  padding: '12px 16px',
};

const borderRadius = {
  borderRadius: '0 0 8px 8px',
};

export default {
  tableWrapper: {
    style: {
      tableLayout: 'fixed',
    },
  },
  header: {
    style: {
      borderBottom: `1px solid ${mrGray200}`,
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
      padding: '16px 24px',
    },
  },
  headCells: {
    style: {
      ...cellPadding,
      ...IBMPlexSans,
      color: mrGray600,
      'font-size': '12px',
      '.rdt_TableCol_Sortable': {
        gap: '4px',
      },
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: mrPrimary500,
      border: `1px solid ${mrBlueB4}`,
      '&:last-of-type': borderRadius,
    },
  },
  cells: {
    style: {
      ...cellPadding,
      ...sans,
    },
  },
  pagination: {
    style: borderRadius,
  },
  noData: {
    style: {
      ...sans,
      height: '48px',
      lineHeight: '20px',
      color: '#000000',
    },
  },
  expanderCell: {
    style: {
      '.rdt_TableCol_Sortable': {
        gap: '4px',
      },
    },
  },
  expanderButton: {
    style: {
      '&:hover:not(:disabled)': transparent,
      '&:focus': transparent,
      svg: {
        color: mrNeutralN5,
        width: '20px',
      },
    },
  },
};
