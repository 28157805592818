import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './text.module.scss';

const MrText = ({
  children,
  component = 'p',
  serif,
  className,
  styleNames = '',
  testId = 'MrText',
  required,
  ...props
}) => {
  const Component = component;
  return (
    <Component
      {...props}
      data-test-id={testId}
      className={cx(
        s.text,
        serif && s.serif,
        className,
        styleNames.split(' ').map(c => s[c])
      )}
    >
      {children}
      {required && <span className={s.required}> *</span>}
    </Component>
  );
};

MrText.propTypes = {
  children: pt.node,
  className: pt.string,
  styleNames: pt.string, //eg "bold center"
  component: pt.string,
  serif: pt.bool,
  testId: pt.string,
  required: pt.bool,
};

export default MrText;
