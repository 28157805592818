import { isBoolean } from 'lodash';

export default function parseBool(string) {
  // If it's already a boolean, return it
  if (isBoolean(string)) {
    return string;
  }

  // If string itself is falsey, return false to allow things like `undefined or null to be false.
  if (!string) {
    return false;
  }

  const lowerCasedString = string.toLowerCase();
  switch (lowerCasedString) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      throw new Error(`String must be either 'true' or 'false' (case-insensitive): Got '${string}'`);
  }
}
