// Note: avoid nested routes (eg. /settings/section), as our current route config doesn't work well with react-router's way of doing nested routes

const routes = {
  home: '/',
  logOut: '/log-out',
  loginRedirect: '/login-redirect',
  noOrgsFound: '/no-orgs-found',
  noUserEmail: '/no-user-email',

  // --- Side Nav routes
  // Any new routes added here need to be added to appropriate active route lookup: ion/packages/app/src/components/side-nav/index.js
  dashboard: '/dashboard',
  integrations: '/integrations',
  clusters: '/clusters',
  pipelines: '/pipelines',
  auditLog: '/audit-log',
  schemaReport: '/schema-report',
  // ----
  eventSchemas: '/event-schemas',
  eventSchemasCreate: '/create-event-schemas',

  newIntegration: '/create-integration',
  editPlaybook: '/edit-integration',
  newPipeline: '/create-pipeline',
  connectIntegration: '/connect-integration',
  analyticsJSBuilder: '/analytics-builder',
  selectStageOrDeploy: '/select-stage-or-deploy',
  bulkSelectRevisions: '/bulk-select-revisions',
  bulkSelectPipelines: '/bulk-select-pipelines',
  bulkDeployPipelines: '/bulk-deploy-pipelines',
  // ----

  settings: '/settings',
};

const scrollTimeout = 600;

export { routes, scrollTimeout };
