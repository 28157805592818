export default {
  REQUEST_NAME: 'matchPixel',
  REQUEST_INPUT_NAME: 'matchPixels',
  SINGLE_SELECT_DESCRIPTION: 'Match Pixels',
  DESCRIPTION:
    'Fire multiple match pixels by using unique URLs with a macro replaced by the ajsID, e.g., https://example.com?matchID=__MATCH_ID__ becomes https://example.com?matchID=123. This creates a match table in a third-party system where the ajsID is sent to match with the user.',
  FIELDS: [
    {
      type: 'TEXT',
      property: 'url',
      title: 'URL',
      description: 'The URL where the request should be made',
      required: true,
      default: '',
      placeholder: 'e.g., https://example.com?matchID=__MATCH_ID__',
    },
    {
      type: 'NUMBER',
      property: 'timeToLive',
      title: 'Lifetime (in days)',
      description: 'How long do you want this parameter to exists',
      required: true,
      default: '',
      placeholder: 'e.g., 10',
    },
    {
      type: 'TEXT',
      property: 'matchIDMacro',
      title: 'Match ID Macro',
      description:
        'What is the macro that should be used to replace it with the anonymous ID. This value should exists in the URL.',
      required: true,
      default: '',
      placeholder: 'e.g., __MATCH_ID__',
    },
  ],
};
