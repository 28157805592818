import React from 'react';
import cx from 'classnames';
import pt from 'prop-types';
import { alphabetizeBy } from '@ion/mrdash';

import { MrLink, MrH, MrModal } from '@ion/components';
import { useQueryUserOrganizations } from '@ion/api';
import { useSwitchOrg } from '@ion/api';
import { afterOrgSwitch } from '@ion/app/src/after-org-switch';

import OrgAvatar from 'app/components/org-avatar';

import s from './index.module.scss';
import { useUserContext } from '@ion/user';

export function alphabetizeOrgs(orgs) {
  return alphabetizeBy(orgs, 'display_name');
}

const OrgSwitcher = ({ orgId, closeModal }) => {
  const { currentOrg } = useUserContext();
  const { data: orgs } = useQueryUserOrganizations();
  const displayOrgs = orgs.length === 0 ? [currentOrg] : orgs;

  const [switchOrg] = useSwitchOrg({
    onCompleted: async () => {
      afterOrgSwitch();
    },
  });

  return (
    <MrModal closeModal={closeModal} styleNames="basic allowOverflow">
      <div className={s.header}>
        <MrH h="h3" sans styleNames="noMargin">
          Switch Organizations
        </MrH>
      </div>
      <div className={s.content}>
        {alphabetizeOrgs(displayOrgs).map(org => (
          <MrLink
            key={org.id}
            className={cx(s.org, orgId === org.id && s.selected)}
            onClick={() => {
              switchOrg({ orgId: org.id });
            }}
          >
            <OrgAvatar org={org} />
          </MrLink>
        ))}
      </div>
    </MrModal>
  );
};

OrgSwitcher.propTypes = {
  orgId: pt.string,
  closeModal: pt.func,
  className: pt.string,
};

export default OrgSwitcher;
