import { useReducer } from 'react';
import { produce } from 'immer';
import { isEmpty } from 'lodash';

const hasuraClaims = 'https://hasura.io/jwt/claims';
const hasuraAllowedRolesProperty = 'x-hasura-allowed-roles';

export const DEFAULT_DATA_USER_INFO = {
  sessionToken: null,
  isOrgOverride: false,
  isTokenValid: false,
  user: null,
  currentOrg: null,
  hasRoles: false,
  isLoading: true,
  error: null,
};

export const actions = {
  SET_USER_INFO: Symbol(),
  RESET_USER_INFO: Symbol(),
  SET_ERROR: Symbol(),
};

const reducer = produce((state, { type, payload }) => {
  switch (type) {
    case actions.SET_USER_INFO: {
      const { tokenInfo } = payload;
      const { email, name, sub, __raw } = tokenInfo;
      const hasRoles = !isEmpty(tokenInfo[hasuraClaims][hasuraAllowedRolesProperty]);
      state.isOrgOverride = tokenInfo[hasuraClaims].orgOverride;
      state.isTokenValid = true;
      state.user = { email: email, name: name, id: sub };
      state.sessionToken = __raw;
      state.currentOrg = tokenInfo[hasuraClaims].org;
      state.hasRoles = hasRoles;
      state.isLoading = false;
      break;
    }

    case actions.RESET_USER_INFO:
      state.isOrgOverride = false;
      state.isTokenValid = false;
      state.user = null;
      state.sessionToken = null;
      state.orgs = null;
      state.currentOrg = null;
      state.hasRoles = false;
      break;
    case actions.SET_ERROR:
      state.error = payload.error;
      break;
    default:
      throw Error('unknown action');
  }
  return state;
});

export const useUserState = () => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_DATA_USER_INFO);

  return [state, dispatch];
};

export default useUserState;
