import React from 'react';
import pt from 'prop-types';

import { cellActions, determineCellAction } from './cell-actions/determine-cell-action';

import RemoveStagedRevision from './cell-actions/remove-staged-revision';
import StageForDelete from './cell-actions/stage-for-delete';
import UndoStagedForDelete from './cell-actions/undo-staged-for-delete';
import UnmanagedWarning from './cell-actions/unmanaged-warning';

const ActionCell = row => {
  const cellAction = determineCellAction(row);

  switch (cellAction) {
    case cellActions.STAGE_FOR_DELETE:
      return <StageForDelete {...row} />;
    case cellActions.REMOVE_STAGED_REVISION:
      return <RemoveStagedRevision {...row} />;
    case cellActions.UNDO_STAGED_FOR_DELETE:
      return <UndoStagedForDelete {...row} />;
    case cellActions.UNMANAGED_WARNING:
      return <UnmanagedWarning />;
    default:
      throw new Error(`Unknown delete cell option: ${cellAction}`);
  }
};

ActionCell.propTypes = {
  row: pt.object,
};

export default ActionCell;
