import { useMutation } from '@apollo/client';

import mapErrors from './mapErrors';
import { isAllowed } from '../Apollo/allower';
import { notify } from '../Apollo';

/**
 * A wrapper around useMutation hook for default patterns
 *  extracting onCompleted and onError in case we want to add default functionality (eg. event logging)
 *
 *  errorMsg and successMsg can be either a string or function, used to process the response.
 *  The function can return either a string, or an object: { title, message }
 */
const useMrMutation = (mutation, { onCompleted, onError, successMsg, ...options }) => {
  const [call, { error, ...response }] = useMutation(mutation, {
    ...options,
    onCompleted: data => {
      if (onCompleted) onCompleted(data);
      if (successMsg) {
        const msg = typeof successMsg === 'function' ? successMsg(data) : successMsg;
        notify.success(typeof msg === 'object' ? msg : { message: msg });
      }
    },
    onError: e => {
      if (onError) onError(mapErrors(e));
    },
  });
  const { context } = options;

  return [
    call,
    {
      ...response,
      isAllowed: isAllowed(context?.permission),
      error: mapErrors(error),
    },
  ];
};

export default useMrMutation;
