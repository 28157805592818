import React from 'react';
import propTypes from 'prop-types';

import { KebabMenu } from '@metarouter-private/mr-mui';

import { useBuildMenuItems } from './use-build-menu-items';

export default function ActionCell({ row }) {
  const menuItems = useBuildMenuItems(row);

  return <KebabMenu items={menuItems} testId="inviteKebabMenu" />;
}

ActionCell.propTypes = {
  row: propTypes.shape({
    invitationUrl: propTypes.string,
  }).isRequired,
};
