import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputSelectDeprecated } from '@ion/components';
import { useFormContext } from 'app/context/form-context';

const { validateRequired } = formValidators;

const ToHash = ({ opId, _path, toHash }) => {
  const { setField, validateField, formState } = useFormContext();
  const hashName = `${opId}-toHash-${_path}`;

  return (
    <MrInputSelectDeprecated
      label="Hash function"
      options={['md5', 'sha256']}
      onChange={setField}
      onBlur={validateField}
      name={hashName}
      value={toHash}
      errorMsg={formState[hashName]?.errorMsg}
      validators={[validateRequired()]}
      placeholder
      labelPosition="top"
    />
  );
};

ToHash.propTypes = {
  toHash: pt.oneOf(['md5', 'sha256']),
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default ToHash;
