import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Config } from './auth0';
import propTypes from 'prop-types';
import { routes } from '../constants';

const auth0Domain = auth0Config.domain();
const auth0ClientID = auth0Config.clientID();

const redirectUri = `${window.location.origin}${routes.loginRedirect}`;

export default function MrAuth0Provider({ children }) {
  return (
    <Auth0Provider domain={auth0Domain} clientId={auth0ClientID} redirectUri={redirectUri} useRefreshTokens={true}>
      {children}
    </Auth0Provider>
  );
}

MrAuth0Provider.propTypes = {
  children: propTypes.node,
};
