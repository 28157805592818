import React from 'react';
import pt from 'prop-types';

import { upperFirst as _upperFirst } from 'lodash';

import { formValidators, MrInputNumber, MrInputSelectDeprecated, MrInputText } from '@ion/components';
import { useFormContext } from 'app/context/form-context';
const { validateRequired, validateInteger, validateFloat, validateBoolean } = formValidators;

/**
 *
 * @returns {object[]} Options for select input, from schema
 */
const getOptions = directValueOptions => {
  const options = [];

  for (const key in directValueOptions) {
    const value = directValueOptions[key];

    options.push({
      text: value === 'number' ? 'Float' : _upperFirst(value),
      value,
    });
  }

  return options;
};

const DirectValue = ({ directValue, directType, directValueOptions, opId, _path }) => {
  const { setField, validateField, formState, resetError } = useFormContext();

  return (
    <>
      {((name = `${opId}-directType-${_path}`) => (
        <MrInputSelectDeprecated
          name={name}
          onChange={event => {
            setField(event, {
              otherFields: [
                { name: `${opId}-directValue-${_path}`, value: '', errorMsg: null }, // clear the direct value when its data type changes
              ],
            });
          }}
          onBlur={validateField}
          value={directType ?? ''}
          errorMsg={formState[name]?.errorMsg}
          options={getOptions(directValueOptions)}
          validators={[validateRequired()]}
          placeholder
        />
      ))()}

      {((name = `${opId}-directValue-${_path}`) => {
        // render the correct input based on the value of defaultType
        switch (true) {
          case directType === 'boolean':
            return (
              <MrInputSelectDeprecated
                options={['true', 'false']}
                onChange={setField}
                onBlur={validateField}
                name={name}
                value={directValue}
                errorMsg={formState[name]?.errorMsg}
                validators={[validateRequired(), validateBoolean()]}
                placeholder
              />
            );

          case directType === 'string':
            return (
              <MrInputText
                onChange={setField}
                value={directValue}
                name={name}
                errorMsg={formState[name]?.errorMsg}
                placeholder="direct string"
                fullWidth
              />
            );

          case directType === 'integer':
            return (
              <MrInputNumber
                onBlur={validateField}
                onInput={resetError}
                name={name}
                value={directValue}
                onChange={setField}
                errorMsg={formState[name]?.errorMsg}
                validators={[validateRequired(), validateInteger()]}
                step={1}
                placeholder="direct integer"
              />
            );

          case directType === 'number':
            return (
              <MrInputNumber
                onBlur={validateField}
                onInput={resetError}
                name={name}
                value={directValue}
                onChange={setField}
                errorMsg={formState[name]?.errorMsg}
                validators={[validateRequired(), validateFloat()]}
                placeholder="direct float"
              />
            );
        }
      })()}
    </>
  );
};

DirectValue.propTypes = {
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
  directValue: pt.string,
  directType: pt.oneOf(['boolean', 'number', 'integer', 'string']).isRequired,
  directValueOptions: pt.object.isRequired,
};

export default DirectValue;
