import { isEmpty as _isEmpty } from 'lodash';
import generatePipelinesList from '../bulk-select-revisions/generate-pipelines-list';
import { parseBool } from '@ion/mrdash';

const submitAndRedirect = (formState, pipelines, routes, history, setErrorMessage) => {
  if (parseBool(formState?.deployOnly?.value)) {
    const generatedPipelinesList = generatePipelinesList(pipelines, 'deploy-only');
    if (_isEmpty(generatedPipelinesList)) {
      setErrorMessage(true);
    } else {
      history.push(routes.bulkSelectPipelines, {
        revisions: null,
        pipelinesList: generatedPipelinesList,
      });
    }
  } else if (parseBool(formState?.stageAndDeploy?.value)) {
    history.push(routes.bulkSelectRevisions, {});
  }
};

export default submitAndRedirect;
