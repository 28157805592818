import { useLazyQueryOrganizations } from '@ion/api';

export function useOrganizationsTable() {
  const [getOrganizations, { organizations, pagination, loading }] = useLazyQueryOrganizations();

  return {
    onFetchPage: getOrganizations,
    data: organizations,
    pagination,
    loading,
  };
}
