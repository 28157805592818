import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Root from 'app/root/root';
import asyncWithMrLaunchDarklyProvider from './launch-darkly/providers/with-launch-darkly-provider';
import Hotjar from '@hotjar/browser';

import MrAuth0Provider from 'app/auth0/mr-auth0-provider';

(async () => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      dsn: 'https://c814975d9aa2422a95f8bb1f254cd1c7@o236611.ingest.sentry.io/5356695',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0, // TODO lower this value for prod after testing
      environment: process.env.NODE_ENV,
      release: process.env.RELEASE_VERSION,
    });
    Hotjar.init(2784091, 6);
  }

  const LaunchDarklyProvider = await asyncWithMrLaunchDarklyProvider();

  ReactDOM.render(
    <LaunchDarklyProvider>
      <MrAuth0Provider>
        <Root />
      </MrAuth0Provider>
      ,
    </LaunchDarklyProvider>,
    document.getElementById('root')
  );
})();
