import { forEach as _forEach } from 'lodash';

const getPipelineAndClusterOptions = options => {
  const cluster = new Set();
  const pipeline = new Set();

  _forEach(options, s => {
    const [cs, p] = s.split('&');
    _forEach(cs.split(','), c => {
      cluster.add(c);
    });
    pipeline.add(p);
  });

  const clusters = Array.from(cluster);
  const pipelines = Array.from(pipeline);

  return { clusters, pipelines };
};

export default getPipelineAndClusterOptions;
