import { keys as _keys, forEach as _forEach, isEmpty as _isEmpty, set as _set, cloneDeep as _cloneDeep } from 'lodash';

// relocate singleKey from expressions to mappings
export const relocateSingleKeys = playbook => {
  const nextPlaybookState = _cloneDeep(playbook);
  let p;

  _forEach(nextPlaybookState, (ops, section) => {
    if (section === 'eventSpecific') {
      const events = _keys(ops);
      events.map(e => {
        p = moveSingleKeyToMappings(ops[e], section, e, nextPlaybookState);
      });
    } else {
      p = moveSingleKeyToMappings(ops, section, '', nextPlaybookState);
    }
  });

  return p;
};

const moveSingleKeyToMappings = (ops, section, event, nextPlaybookState) => {
  const expressions = ops?.expressions ?? [];
  const mappings = ops?.mappings ?? [];

  if (!_isEmpty(expressions)) {
    const multi = expressions.filter(ex => !ex.inputKey && _isEmpty(ex.transforms));
    const single = expressions.filter(ex => ex.inputKey && !_isEmpty(ex.transforms));

    _set(nextPlaybookState, event ? `${section}.${event}.expressions` : `${section}.expressions`, multi);
    _set(nextPlaybookState, event ? `${section}.${event}.mappings` : `${section}.mappings`, [...mappings, ...single]);
  }

  return nextPlaybookState;
};
