/**
 *
 * CountParameters counts all the mappings from the playbook.
 * It doesn't count params, vars because they are used in enrichments so that we prevent duplicates.
 *
 * @params playbook: {}
 *
 * @returns number
 */
const CountParameters = (playbook, connection) => {
  const eventSpecificSection = Object.keys(playbook?.eventSpecific ?? {}).map(k => playbook.eventSpecific[k]);

  const count = [playbook.global ?? [], playbook.default ?? [], ...(eventSpecificSection ?? {})].reduce(
    (result, val) => {
      const { mappings = [], enrichments = [], expressions = [], spreadings = [] } = val;
      result += mappings.length + enrichments.length + expressions.length + spreadings.length;
      return result;
    },
    0
  );

  const connectionCount =
    connection.endpointSchema?.params ?? Object.keys(connection.connectionTemplate?.properties ?? {});

  return count + connectionCount.length;
};

export default CountParameters;
