import React from 'react';
import pt from 'prop-types';

import { isEmpty as _isEmpty } from 'lodash';

import s from './index.module.scss';
import { MrInlineIconCard } from '@ion/components';
import useGetMetrics from './use-get-metrics';
import formatDeliveryRate from '../table/columns/format-delivery-rate';
import sumMetrics from './sum-metrics';
import inlineCards from './description';

const formatter = Intl.NumberFormat('en', { notation: 'compact' });

const DashboardInlineCards = ({ clusters, pipelines, since, until, metricsData }) => {
  const [{ metrics, loading }] = useGetMetrics({
    clusters,
    pipelines,
    since,
    until,
  });

  const sumEvents = sumMetrics(metricsData);

  return (
    <div className={s.inlineCards}>
      <MrInlineIconCard className={s.inlineCard} {...inlineCards.totalEvents}>
        <div>{!loading && !_isEmpty(metrics) ? formatter.format(metrics?.totalEvents) : 'N/A'}</div>
      </MrInlineIconCard>
      <MrInlineIconCard className={s.inlineCard} {...inlineCards.notDelivered}>
        <div>
          {formatter.format(sumEvents.CONSENT)} / {formatter.format(sumEvents.PLAYBOOK_FILTER)} /{' '}
          {formatter.format(sumEvents.Errors)}{' '}
        </div>
      </MrInlineIconCard>
      <MrInlineIconCard className={s.inlineCard} {...inlineCards.delivered}>
        <div>
          {formatter.format(sumEvents.Success)} ({formatDeliveryRate(sumEvents)})
        </div>
      </MrInlineIconCard>
    </div>
  );
};

DashboardInlineCards.propTypes = {
  clusters: pt.array.isRequired,
  pipelines: pt.array.isRequired,
  since: pt.instanceOf(Date).isRequired,
  until: pt.instanceOf(Date).isRequired,
  metricsData: pt.array.isRequired,
};

export default DashboardInlineCards;
