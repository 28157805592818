import { notify } from '../../index';
import defaultGraphQLErrorMessage from './defaultGraphQLErrorMessage';

export default () => {
  const errors = defaultGraphQLErrorMessage();
  notify.error({
    title: errors.title,
    message: errors.message,
  });
};
