import moment from 'moment';

/*
  react-dates sets the startDate and endDate to
  noon in the current timezone. For our use cases,
  we want the date range to be to be from 00:00 on the startDate
  to Midnight on the endDate (exclusive)
 */

export default function transformDates({ startDate, endDate }) {
  let transformedStart;
  if (startDate) {
    // start of day (inclusive)
    transformedStart = moment(startDate).startOf('day');
  }

  let transformedEnd;
  if (endDate) {
    // 00:00 the day after endDate (exclusive)
    transformedEnd = moment(endDate).endOf('day').add(1, 'millisecond');
  }

  return {
    startDate: transformedStart,
    endDate: transformedEnd,
  };
}
