import React, { useState, useEffect, useMemo } from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './search.module.scss';
import MrInputText from '../input-text/input-text';
import usePrevious from '../../hooks/use-previous';

function GetNestedFields(o, field) {
  const nestedFields = field.split('.').reverse();
  const currentField = nestedFields.pop();
  if (!o || o === {}) return {};

  if (Array.isArray(o)) {
    const results = [];
    for (const a of o) {
      const result = GetNestedFields(a, field);
      if (result) {
        for (const r of result) if (r) results.push(r);
      }
    }
    return results;
  }
  const nextObj = (o || {})[currentField];
  if (nestedFields.length < 1) {
    if (!nextObj) return [];
    return [nextObj];
  }

  return GetNestedFields(nextObj, nestedFields.reverse().join('.'));
}

/* 
Search field, can be used to filter 'data' or make a db call onChange.
To filter, pass in the original dataset = data prop, and a setData stateHandler to update filtered data.
NOTE: don't pass in the filtered dataset.
*/
const MrSearch = ({
  onChange,
  data,
  fields = ['name'],
  className,
  localStoragePersistenceKey,
  testId = 'MrSearch',
  ...props
}) => {
  const [text, setText] = useState(localStorage.getItem(localStoragePersistenceKey) || '');
  const prev = usePrevious(data);
  const memoizedFields = useMemo(() => {
    return fields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!onChange) return;
    if (data) {
      // only run if data exists, otherwise, causes infinite loop (or if data changes when item is removed)
      if (data.length || (prev && data.length !== prev.length)) {
        onChange(
          data.reduce((ar, d) => {
            if (
              memoizedFields.some(field => {
                let f = GetNestedFields(d, field);
                if (!f) {
                  return false;
                } else if (typeof f === 'object') {
                  f = JSON.stringify(f);
                } else if (typeof f == 'number') {
                  f = f.toString();
                }
                return f.toLowerCase().includes(text.toLowerCase());
              })
            ) {
              ar.push(d);
            }
            return ar;
          }, [])
        );
      }
    } else {
      onChange(text);
    }
  }, [text, onChange, data, prev, memoizedFields]);

  return (
    <MrInputText
      testId={testId}
      name="search"
      {...props}
      value={text}
      onChange={e => {
        const value = e.target.value;
        setText(value);
        if (localStoragePersistenceKey) localStorage.setItem(localStoragePersistenceKey, value);
      }}
      clear={() => {
        setText('');
        if (localStoragePersistenceKey) localStorage.setItem(localStoragePersistenceKey, '');
      }}
      className={cx(s.search, className)}
    />
  );
};

MrSearch.propTypes = {
  data: pt.array,
  setData: pt.func,
  fields: pt.array,
  onChange: pt.func,
  className: pt.string,
  localStoragePersistenceKey: pt.string,
  testId: pt.string,
};

export { MrSearch, GetNestedFields };
