import auditLogMutation from '../insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const mutation = auditLogMutation;

export function buildVariables({ id, pipelineName, pipelineVars, readyForDeploy }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      id,
      pipelineName,
      pipelineVars,
      readyForDeploy,
    }),
  };
}
