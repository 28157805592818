import {
  transform as _transform,
  forEach as _forEach,
  isEqual as _isEqual,
  isArray as _isArray,
  isEmpty as _isEmpty,
} from 'lodash';

const ARRAY = 'array';
const OBJECT = 'object';
const TYPE = 'type';
const PROPERTIES = 'properties';

const handleArrayOf = details => {
  return _transform(details, (result, value, key) => {
    const isTypeArray = _isArray(value) ? value.indexOf(ARRAY) !== -1 : _isEqual(value, ARRAY);
    if (key === TYPE && isTypeArray) {
      let replacedItems = {};
      let replacedValue = _isEqual(value, ARRAY) ? [] : value.filter(e => e !== ARRAY);
      const { items } = result;

      _forEach(items, (itemsValue, itemsKey) => {
        if (_isEqual(typeof itemsValue, OBJECT)) {
          const objType = `${ARRAY} of ${OBJECT}s`;
          replacedItems[itemsKey] = itemsValue;
          if (itemsKey === PROPERTIES) {
            replacedItems = replacedItems[PROPERTIES];
          }

          if (replacedValue.indexOf(objType) === -1) {
            replacedValue.push(objType);
          }
        } else {
          replacedValue.push(`${ARRAY} of ${itemsValue}s`);
        }
      });

      result.items = !_isEmpty(replacedItems) ? replacedItems : undefined;
      result[key] = replacedValue;
    } else if (typeof value === OBJECT) {
      result[key] = handleArrayOf(value);
    } else {
      result[key] = value;
    }
  });
};

export default handleArrayOf;
