import NotificationRoot, { store } from 'react-notifications-component';

import './notification.scss';

const defaults = {
  container: 'bottom-left',
  animationIn: ['notification__fade-in'],
  animationOut: ['notification__fade-out'],
  dismiss: { duration: 3500 },
};

const add = ({ title, message, type, options }) => {
  store.addNotification({
    title,
    message,
    type,
    ...defaults,
    ...options,
  });
};

// predefined notifications, see storybook for examples
store.success = ({ title = '', message, ...options }) => {
  add({ title, message, type: 'success', ...options });
};

store.error = ({ title = '', message, ...options }) => {
  store.addNotification({
    title,
    message,
    type: 'danger',
    ...{
      container: 'center',
      animationIn: ['notification__fade-in'],
      animationOut: ['notification__fade-out'],
      dismiss: {
        duration: 0,
        showIcon: true,
        click: false,
      },
    },
    ...options,
  });
};

store.warning = ({ title = '', message, ...options }) => {
  add({ title, message, type: 'warning', ...options });
};

export { NotificationRoot as default, store as notificationStore };
