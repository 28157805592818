import { useState, useEffect } from 'react';
import { isNil as _isNil } from 'lodash';

const useBuildWithDataRetentionComponentProps = ({ isDisabled, startTime, endTime, setQueryParameter, groupBy }) => {
  const [dateRange, setDateRange] = useState({ since: startTime, until: endTime });

  useEffect(() => {
    setDateRange({ since: startTime, until: endTime });
  }, [startTime, endTime, groupBy]);

  return {
    popperPlacement: 'bottom',
    variant: 'filled',
    disabled: isDisabled,
    value: [dateRange.since, dateRange.until],
    onAccept: value => {
      const [acceptedStartTime, acceptedEndTime] = value;
      if (_isNil(acceptedStartTime) || _isNil(acceptedEndTime)) {
        return;
      }
      setQueryParameter({
        dateRange: { since: acceptedStartTime, until: acceptedEndTime },
        selectedComponent: 'DATE_TIME_RANGE_PICKER',
      });
    },
  };
};

export default useBuildWithDataRetentionComponentProps;
