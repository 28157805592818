import { useEffect } from 'react';
import { useUserContext } from '@ion/user';

const useIdentify = () => {
  const { user, currentOrg, isTokenValid } = useUserContext();
  useEffect(() => {
    if (user && currentOrg && isTokenValid) {
      window?.analytics?.identify(user?.id, {
        ...user,
        company: currentOrg,
      });
    }
  }, [isTokenValid, currentOrg, user]);
};

export default useIdentify;
