import React from 'react';
import pt from 'prop-types';
import { MrH, MrInputText } from '@ion/components';
import s from './index.module.scss';
import AnalyticsBuilderFileHosting, { HOSTING_TYPES } from './analytics-builder-file-hosting';
import AnalyticsBuilderConsent from './analytics-builder-consent';
import AnalyticsBuilderAdvancedSettingsAccordion from './analytics-builder-advanced-settings-accordion';

const AnalyticsBuilderSettings = ({
  formState,
  setField,
  validateField,
  validateRequired,
  validateUrl,
  resetError,
}) => {
  const validURLMsg = `Value must be a URL and cannot end with "/"`;
  return (
    <div>
      <section className={s.section}>
        <MrH h="h5" sans styleNames="noMargin">
          <div className={s.circle}>1</div> File location and settings
        </MrH>
        <div className={s.subSection}>
          <AnalyticsBuilderFileHosting
            value={formState?.fileHostingType?.value}
            onChange={setField}
            errorMsg={formState.fileHostingType?.errorMsg}
            resetError={resetError}
            required
          />

          <MrInputText
            label="Analytics.js Hosting URL"
            onChange={setField}
            value={formState.cdnURL?.value ?? ''}
            onBlur={validateField}
            onInput={resetError}
            name="cdnURL"
            errorMsg={formState.cdnURL?.errorMsg}
            validators={[validateRequired(), validateUrl(validURLMsg, false)]}
            helperText="Where the Analytics.js file will be located"
            placeholder={
              formState?.fileHostingType?.value === HOSTING_TYPES.metaRouter
                ? 'cdn.metarouter.io'
                : 'yourdomain.com/scripts'
            }
            prefix="https://"
            fullWidth
            required
          />

          <MrInputText
            label="Ingestion Website URL"
            onChange={setField}
            value={formState.host?.value ?? ''}
            onBlur={validateField}
            onInput={resetError}
            name="host"
            errorMsg={formState.host?.errorMsg}
            validators={[validateRequired(), validateUrl(validURLMsg, false)]}
            helperText="The event ingestion endpoint for your MetaRouter cluster"
            placeholder="yourdomain.com"
            prefix="https://"
            fullWidth
            required
          />
        </div>
      </section>
      <section className={s.section} style={{ paddingTop: 20, paddingBottom: 20 }}>
        <AnalyticsBuilderAdvancedSettingsAccordion
          formState={formState}
          setField={setField}
          validateField={validateField}
          resetError={resetError}
        />
      </section>
      <section className={s.section}>
        <MrH h="h5" sans styleNames="noMargin">
          <div className={s.circle}>2</div>
          Compliance Settings
        </MrH>
        <div className={s.subSection}>
          <AnalyticsBuilderConsent
            value={formState.impliedConsent?.value}
            onChange={setField}
            resetError={resetError}
            errorMsg={formState.impliedConsent?.errorMsg}
          />

          <MrInputText
            className={s.textField}
            label="Cookie Name"
            onChange={setField}
            value={formState.cookieName?.value ?? ''}
            onBlur={validateField}
            onInput={resetError}
            name="cookieName"
            errorMsg={formState.cookieName?.errorMsg}
            validators={[validateRequired()]}
            helperText="This needs to match with the cookie name in your Consent Manager."
            placeholder="OptOutOnRequest"
            fullWidth
            required
          />
        </div>
      </section>
    </div>
  );
};

AnalyticsBuilderSettings.propTypes = {
  writekey: pt.string,
  formState: pt.object,
  setField: pt.func,
  validateField: pt.func,
  validateRequired: pt.func,
  validateUrl: pt.func,
  resetError: pt.func,
};

export default AnalyticsBuilderSettings;
