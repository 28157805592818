import { useMutation } from '../../index';
import permission from '../../Apollo/permissions';
import mergeQueries from '../../Apollo/merge-queries';
import { validate } from 'parameter-validator';
import { useUserContext } from '@ion/user';

import { buildVariables, mutation } from './remove-integration-mutation-builder';

import {
  mutation as integrationDeletedMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/integration-deleted/audit-log-mutation-builder';

export const combinedMutation = mergeQueries('RemoveIntegrationAndAudit', [mutation, integrationDeletedMutation]);

export default function useRemoveIntegration(options = {}) {
  const { user, currentOrg } = useUserContext();
  const userData = { email: user?.email, org: currentOrg?.display_name };
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Integration removed.',
    context: { permission: permission.deleteIntegration },
    ...options,
    update: buildUpdateCache(userData),
  });

  const removeIntegrationMutation = args => {
    const { integrationId, name } = validate(args, ['integrationId', 'name']);

    return executeMutation({
      variables: {
        ...buildVariables({ integrationId }),
        ...buildAuditLogVariables({ integrationId, name }),
      },
    });
  };

  return [removeIntegrationMutation, response];
}

function buildUpdateCache(userData) {
  return (cache, { data }) => {
    // Remove from cache on "soft delete"
    const deletedIntegration = data.update_integrations_by_pk;

    track({ deletedIntegration, userData });

    // remove it from cache
    cache.evict({ id: cache.identify(deletedIntegration) });
    cache.gc();
  };
}

function track({ deletedIntegration, userData }) {
  __mrTracking?.track('integration_deleted', {
    id: deletedIntegration.id,
    name: deletedIntegration.name,
    starterkitId: deletedIntegration.starterkitId,
    starterkitVersion: deletedIntegration.starterkitVersion,
    userEmail: userData.email,
    orgName: userData.org,
  });
}
