import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import generateId from './../../utils/uuid';

import s from './input-checkbox.module.scss';

const checkboxIconOptions = {
  minus: 'minus',
  checkMark: 'checkMark',
};

const MrInputCheckbox = ({
  disabled,
  label,
  onChange,
  name,
  value,
  className,
  labelPosition = 'left',
  switcher,
  testId = 'MrInputCheckbox',
  fontStyle,
  styleNames = '',
  checkboxIcon = checkboxIconOptions.checkMark,
}) => {
  const id = generateId();

  // handle case where value is string false, cast value to boolean for all other cases
  const v = value === 'false' ? false : Boolean(value);

  const checkmarkIconOptionToClassName = {
    [checkboxIconOptions.checkMark]: s.checkMark,
    [checkboxIconOptions.minus]: s.minus,
  };

  return (
    <div
      className={cx(
        className,
        styleNames.split(' ').map(c => s[c])
      )}
      data-test-id={testId}
    >
      {label && labelPosition === 'left' && (
        <label className={s.labelLeft} htmlFor={id}>
          {label}
        </label>
      )}

      {!switcher && (
        <input
          className={cx(s.checkbox, checkmarkIconOptionToClassName[checkboxIcon])}
          id={id}
          type="checkbox"
          disabled={disabled}
          name={name}
          onChange={onChange}
          checked={v}
          value={v}
        />
      )}

      {switcher && (
        <label className={s.switch}>
          <input id={id} type="checkbox" disabled={disabled} name={name} onChange={onChange} checked={v} value={v} />
          <span className={s.slider}></span>
        </label>
      )}
      {label && labelPosition === 'right' && fontStyle === 'bold' && (
        <label className={s.labelBold} htmlFor={id}>
          {label}
        </label>
      )}
      {label && labelPosition === 'right' && fontStyle !== 'bold' && (
        <label className={s.labelRight} htmlFor={id}>
          {label}
        </label>
      )}

      {label && labelPosition === 'top' && (
        <label className={s.labelTop} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

MrInputCheckbox.propTypes = {
  disabled: pt.bool,
  label: pt.string,
  onChange: pt.func.isRequired,
  name: pt.string.isRequired,
  value: pt.oneOfType([pt.bool, pt.string, pt.number]),
  className: pt.string,
  labelPosition: pt.oneOf(['left', 'right', 'top']),
  switcher: pt.bool,
  testId: pt.string,
  fontStyle: pt.string,
  styleNames: pt.string,
  checkboxIcon: pt.oneOf([checkboxIconOptions.minus, checkboxIconOptions.checkMark]),
};

export default MrInputCheckbox;
