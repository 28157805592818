import { ApolloLink } from '@apollo/client';

const authLink = ({ sessionToken }) =>
  // TODO - testing for no sessionToken should be here

  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${sessionToken}`,
        },
      };
    });

    return forward(operation);
  });

export default authLink;
