import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ id, pipelineName, pipelineVars, readyForDeploy }) {
  const action = actions.pipeline_variable_updated;
  const description = `Pipeline variable was updated on ${pipelineName}`;
  const debug_data = {
    id,
    pipelineName,
    pipelineVars,
    readyForDeploy,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
