import React, { createContext, useContext } from 'react';
import propTypes from 'prop-types';

const FormFieldContext = createContext({ id: null });

export default function FormFieldContextProvider({ id, children }) {
  return <FormFieldContext.Provider value={{ id }}>{children}</FormFieldContext.Provider>;
}

FormFieldContextProvider.propTypes = {
  id: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
};

export function useFormFieldContext() {
  return useContext(FormFieldContext);
}
