import { isNil } from 'lodash';

/*
  The hand-rolled MR Form hook operates of DOM events,
  which means in this case, we have to create a fake
  DOM event because, since we're in React, we don't always
  have a DOM event.

  We should refactor and use something like Formik to prevent
  the need for this kind of thing.

  This function is generic and can be used in other places where we
  need to do this kind of thing if needed.
 */
export default function buildSyntheticDomEvent({ name, value }) {
  if (isNil(value)) {
    return { target: { name: name, value: null } };
  }
  return { target: { name: name, value } };
}
