import { useReducer } from 'react';
import { produce } from 'immer';

const actions = {
  SET_DATE_RANGE: Symbol('SET_DATE_RANGE'),
  SET_FOCUSED_INPUT: Symbol('SET_FOCUSED_INPUT'),
};

const initialState = {
  dateRange: {},
  focusedInput: null,
};

const reducer = produce((state, { type, payload }) => {
  switch (type) {
    case actions.SET_DATE_RANGE:
      state = { ...state, dateRange: payload };
      break;
    case actions.SET_FOCUSED_INPUT:
      state = { ...state, focusedInput: payload };
      break;
  }
  return state;
});

export default function useMrDateRangeState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setDateRange = dateRange => {
    dispatch({
      type: actions.SET_DATE_RANGE,
      payload: dateRange,
    });
  };

  const setFocusedInput = focusedInput => {
    dispatch({
      type: actions.SET_FOCUSED_INPUT,
      payload: focusedInput,
    });
  };

  return {
    setDateRange,
    setFocusedInput,
    ...state,
  };
}
