import React from 'react';
import propTypes from 'prop-types';

import { MrWithDataRetention } from '@ion/components';
import { DateTimeRangePicker as MuiDateTimeRangePicker } from '@metarouter-private/mr-mui';
import useBuildWithDataRetentionComponentProps from './use-build-with-data-retention-component-props';

const DateTimeRangePicker = ({ isDisabled, since, until, setQueryParameter, groupBy }) => {
  const componentProps = useBuildWithDataRetentionComponentProps({
    isDisabled,
    startTime: since,
    endTime: until,
    setQueryParameter,
    groupBy,
  });

  return <MrWithDataRetention Component={MuiDateTimeRangePicker} componentProps={componentProps} />;
};

DateTimeRangePicker.propTypes = {
  isDisabled: propTypes.bool,
  since: propTypes.instanceOf(Date).isRequired,
  until: propTypes.instanceOf(Date).isRequired,
  setQueryParameter: propTypes.func.isRequired,
  groupBy: propTypes.string.isRequired,
};

export default DateTimeRangePicker;
