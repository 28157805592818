import React from 'react';
import { ReactCountDataTableTitle } from '@metarouter-private/mr-mui';

export const OrganizationOverrideTableTitle = () => {
  const countTableTitleProps = {
    title: {
      singular: 'Organization',
      plural: 'Organizations',
    },
  };
  return <ReactCountDataTableTitle {...countTableTitleProps} />;
};
