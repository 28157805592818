import React from 'react';

import { PIPELINE_MODAL_OPTIONS } from './pipeline-modal/PipelineModal';
import MrAddButton from '@ion/components/src/components/add-button/AddButton';
import { usePipelineIntegrationModalContext } from './context/pipeline-integration-modal-context';

const Actions = () => {
  const { onOpenModal } = usePipelineIntegrationModalContext();
  const onClickAddButton = () => {
    onOpenModal(PIPELINE_MODAL_OPTIONS.addIntegration);
  };

  return (
    <>
      <MrAddButton size="medium" variant="outlined" text="Add Integration" onClick={onClickAddButton} />
    </>
  );
};

Actions.propTypes = {};

export default Actions;
