import React from 'react';

import { PageReactDataTable } from '@metarouter-private/mr-mui';

import UserModalContextProvider from '../context/UserModalContextProvider';
import UserConfirmDeleteContextProvider from '../context/UserConfirmDeleteContextProvider';
import { useOrganizationMembersTable } from '../hooks/use-organization-members-table';

import { COLUMNS } from './columns';
import { UserManagementTableTitle } from './UserManagementTableTitle';
import TableAction from './table-action/TableAction';

const PER_PAGE = 5;

export const UserManagementTable = () => {
  const invitationsTable = useOrganizationMembersTable();

  return (
    <UserModalContextProvider>
      <UserConfirmDeleteContextProvider>
        <PageReactDataTable
          title={<UserManagementTableTitle />}
          columns={COLUMNS}
          actions={<TableAction />}
          perPage={PER_PAGE}
          {...invitationsTable}
        />
      </UserConfirmDeleteContextProvider>
    </UserModalContextProvider>
  );
};
