import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import styles from './table-title.module.scss';
import { MrH, MrPill } from '../../../index';

const MrTableTitle = ({ className, testId = 'MrTableTitle', title, titlePill }) => {
  return (
    <div className={cx(styles.tableTitle, className)} data-test-id={testId}>
      <MrH h="h5" className={styles.title} styleNames="noMargin">
        {title}
      </MrH>
      <MrPill text={titlePill} size="small" color="primary-100" />
    </div>
  );
};

MrTableTitle.propTypes = {
  className: pt.string,
  testId: pt.string,
  title: pt.string,
  titlePill: pt.string,
};

export default MrTableTitle;
