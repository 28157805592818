import React from 'react';
import { MrPageDataTable } from '@ion/components';
import columns from './table-columns';
import propTypes from 'prop-types';

import styles from './index.module.scss';

export function AuditLogsTable({ fetchPage, onSort, onError }) {
  return (
    <div className={styles.tableContainer}>
      <MrPageDataTable onSort={onSort} columns={columns} fetchPage={fetchPage} onError={onError} />
    </div>
  );
}

AuditLogsTable.propTypes = {
  fetchPage: propTypes.func.isRequired,
  onSort: propTypes.func.isRequired,
  onError: propTypes.func.isRequired,
};
