import { isEmpty as _isEmpty } from 'lodash';
import { inlineCardsKey } from './use-get-metrics';

function setInlineCardsTotalEvents({ result, setMetrics }) {
  if (_isEmpty(result)) {
    setMetrics({ [inlineCardsKey.totalEvents]: 0 });
    return;
  }
  setMetrics({ [inlineCardsKey.totalEvents]: result[0].TotalEvents });
}

export default setInlineCardsTotalEvents;
