import { useReducer } from 'react';
import { produce } from 'immer';

const actions = {
  SET_CURRENT_PAGE: Symbol('SET_CURRENT_PAGE'),
  BEFORE_PAGE_FETCHED: Symbol('BEFORE_PAGE_FETCHED'),
  PAGE_FETCHED: Symbol('PAGE_FETCHED'),
};

const DEFAULT_PAGE = 1;

const initialState = {
  loading: false,
  data: [],
  currentPage: DEFAULT_PAGE,
  pageCount: null,
};

const reducer = produce((state, { type, payload }) => {
  switch (type) {
    case actions.BEFORE_PAGE_FETCHED:
      state = { ...state, loading: true };
      break;
    case actions.SET_CURRENT_PAGE:
      state = { ...state, currentPage: payload };
      break;
    case actions.PAGE_FETCHED: {
      const {
        data,
        pagination: { count },
      } = payload;
      state.data = data;
      state.pageCount = count;
      state.loading = false;
      break;
    }
  }
  return state;
});

export default function useMrPageDataTableState() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const beforePageFetched = () => {
    dispatch({
      type: actions.BEFORE_PAGE_FETCHED,
      payload: null,
    });
  };

  const setCurrentPage = currentPage => {
    dispatch({
      type: actions.SET_CURRENT_PAGE,
      payload: currentPage,
    });
  };

  const pageFetched = response => {
    dispatch({
      type: actions.PAGE_FETCHED,
      payload: response,
    });
  };

  return {
    beforePageFetched,
    setCurrentPage,
    pageFetched,
    ...state,
  };
}
