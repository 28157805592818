import formatIntegration from '../table/cells/integration/format-integration';

const mapIntegration = (data, integrations) => {
  return data.reduce(
    (result, { facet }) => {
      const [forwarder, id] = facet;
      const integration = integrations?.find(i => i.id === id);
      if (!integration) {
        const { starterkitId: kitId, formattedName } = formatIntegration(forwarder);
        result.cli = { [kitId]: result.cli[kitId] ? result.cli[kitId] + 1 : 1 };
        result.options.push({ label: `${formattedName} - CLI Managed (${result.cli[kitId]})`, value: id });
      } else {
        result.options.push({ label: integration.name, value: id });
      }

      return result;
    },
    { options: [], cli: {} }
  );
};

export default mapIntegration;
