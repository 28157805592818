import React from 'react';

import s from './index.module.scss';
import { MrSpinner } from '@ion/components';

const LoadingSpinner = () => {
  return (
    <div className={s.loadingContainer}>
      <MrSpinner message={'Generating Schema Report...'} />
    </div>
  );
};

export default LoadingSpinner;
