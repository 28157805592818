import React from 'react';
import pt from 'prop-types';

import { useUpdatePipeline } from '@ion/api';
import { MrDataTable } from '@ion/components';
import { usePipelineIntegrationModalContext } from './context/pipeline-integration-modal-context';

import buildTableData, { INTEGRATION_TYPES } from './build-table-data';
import buildViewableDatatableProps from './build-viewable-datatable-props';
import { PIPELINE_MODAL_OPTIONS, PipelineModal } from './pipeline-modal/PipelineModal';
import { conditionalRowStyles } from './conditional-row-styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CONSENT_CATEGORY_FLAGS } from '../../context/gated-consent-categories-context';
import isFeatureEnabled from '../../launch-darkly/is-feature-enabled';
import { PipelineIntegrationsTableTitle } from './title/PipelineIntegrationsTableTitle';

const PipelineIntegrations = ({ pipeline, deployedRevisions }) => {
  const featureFlags = useFlags();
  const hasConsentMvp = isFeatureEnabled({ featureFlags, flagName: CONSENT_CATEGORY_FLAGS.MVP });
  const { onOpenModal } = usePipelineIntegrationModalContext();
  const [, { isAllowed: canTakeActions }] = useUpdatePipeline();
  const tableData = buildTableData({ deployedRevisions, pipeline });

  const onClickIntegrationName = row => {
    if (row.type === INTEGRATION_TYPES.MANAGED) {
      onOpenModal(PIPELINE_MODAL_OPTIONS.editIntegration, row);
    }
  };
  const viewableProps = buildViewableDatatableProps(canTakeActions, onClickIntegrationName, hasConsentMvp);

  return (
    <>
      <MrDataTable
        title={<PipelineIntegrationsTableTitle tableData={tableData} />}
        data={tableData}
        conditionalRowStyles={conditionalRowStyles}
        {...viewableProps}
      />
      <PipelineModal />
    </>
  );
};

PipelineIntegrations.propTypes = {
  pipeline: pt.object,
  deployedRevisions: pt.array.isRequired,
};

export default PipelineIntegrations;
