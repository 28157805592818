import { useEffect } from 'react';

const useScrollToTop = (pathname, elementId) => {
  const layout = document.getElementById(elementId);

  useEffect(() => {
    if (layout) {
      layout.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [pathname, layout]);
};

export default useScrollToTop;
