import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './table.module.scss';

const MrTable = ({ children, className, testId = 'MrTable' }) => {
  return (
    <table className={cx(s.table, className)} data-test-id={testId}>
      {children}
    </table>
  );
};

MrTable.propTypes = {
  children: pt.node,
  className: pt.string,
  testId: pt.string,
};

export default MrTable;
