import { useMutation } from '../../index';
import { mutation, buildVariables } from './audit-log-mutation-builder';

export default function useInsertAuthLoggedIn(apolloOptions = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    ...apolloOptions,
  });

  const insertAuthLoggedInMutation = ({ userId, context }) => {
    return executeMutation({
      variables: {
        ...buildVariables({
          userId,
          context,
        }),
      },
    });
  };

  return [insertAuthLoggedInMutation, response];
}
