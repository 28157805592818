import { toUpper as _toUpper, snakeCase as _snakeCase } from 'lodash';
const parseDeployedIntegrations = (deployedIntegrations, pipelineIntegrations, integrationsData) => {
  return deployedIntegrations.map(deployedIntegration => {
    const stagedRevisions = pipelineIntegrations.map(pipelineIntegration => pipelineIntegration.stagedRevision);
    const stagedRevision = stagedRevisions?.find(
      stagedRevision => stagedRevision?.id === deployedIntegration?.revision?.id
    );
    const integrationData = integrationsData.find(
      integration => integration.id === stagedRevision?.playbook.integration.id
    );
    const stagedPlaybook = integrationData?.playbooks.find(
      playbook => playbook.revision === stagedRevision?.playbook.revision
    );

    const pipelineVars = {};
    stagedPlaybook?.pipeline_variables.forEach(pipelineVariable => {
      const endpointSchemaParam = deployedIntegration.endpointSchema?.params?.find(
        param => param.name === pipelineVariable.name
      );
      const connectionParam = deployedIntegration.connection?.params?.find(
        param => param.name === _toUpper(_snakeCase(pipelineVariable.name))
      );
      const transformStageParam = deployedIntegration.transformStages[0]?.data?.playbook?.params?.find(
        param => param.name === pipelineVariable.name
      );

      if (endpointSchemaParam) {
        pipelineVars[pipelineVariable.name] = endpointSchemaParam.defaultValue;
      }
      if (connectionParam) {
        pipelineVars[pipelineVariable.name] = connectionParam.defaultValue;
      }
      if (transformStageParam) {
        pipelineVars[pipelineVariable.name] = transformStageParam.defaultValue;
      }
    });

    const integration = integrationsData.find(integrationData => integrationData.id === deployedIntegration.id);
    const deployed = {
      integrationId: deployedIntegration.id,
      id: stagedRevision?.id ?? deployedIntegration.revision.id,
      revision: Number(deployedIntegration.transformStages[0].data.playbook.version),
      stagedForDelete: false,
      pipelineVars: pipelineVars,
      starterkitId: deployedIntegration.integrationName,
      integration,
    };
    if (deployedIntegration.consent) {
      deployed.consent = deployedIntegration.consent;
    }
    if (!stagedRevision) {
      deployed.stagedForDelete = true;
    }

    return deployed;
  });
};

export default parseDeployedIntegrations;
