import React from 'react';
import pt from 'prop-types';

import Timestamp from './timestamp';
import DirectValue from './direct-value';
import Date from './date';
import Uuid from './uuid';

const EnrichmentLookup = ({
  selectedSubOperation,
  _path,
  opId,
  uuid,
  timestamp,
  date,
  directValue,
  directType,
  directValueOptions,
}) => {
  switch (selectedSubOperation) {
    case 'uuid':
      return <Uuid _path={_path} opId={opId} uuid={uuid} />;
    case 'timestamp':
      return <Timestamp _path={_path} opId={opId} timestamp={timestamp} />;
    case 'date':
      return <Date _path={_path} opId={opId} date={date} />;
    case 'directValue':
      return (
        <DirectValue
          _path={_path}
          opId={opId}
          directType={directType}
          directValue={directValue}
          directValueOptions={directValueOptions}
        />
      );
    default:
      return null;
  }
};

EnrichmentLookup.propTypes = {
  selectedSubOperation: pt.string,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
  uuid: pt.oneOf(['v1', 'v4']),
  timestamp: pt.string,
  format: pt.oneOf(['seconds', 'milliseconds']),
  date: pt.string,
  directValue: pt.string,
  directType: pt.oneOf(['boolean', 'number', 'integer', 'string']),
  directValueOptions: pt.object,
};

export default EnrichmentLookup;
