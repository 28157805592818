import React from 'react';
import pt from 'prop-types';

import { MrText } from '@ion/components';

const Revision = ({ revision, prefix = '', type = 'revision' }) => {
  return <MrText styleNames="smallGray">{revision ? `${prefix}Revision ${revision}` : `${prefix}No ${type}s`}</MrText>;
};

Revision.propTypes = {
  revision: pt.oneOfType([pt.string, pt.number]),
  prefix: pt.string,
  type: pt.string,
};

export default Revision;
