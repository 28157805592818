import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import useValidatorLookup from './../../hooks/use-validator-lookup';
import generateId from './../../utils/uuid';

import s from './input-text.module.scss';

const MrInputText = ({
  onChange,
  onBlur,
  onInput,
  disabled,
  placeholder,
  value,
  label,
  required,
  name,
  errorMsg,
  spellCheck = false,
  autoComplete = 'off',
  validators,
  readOnly,
  className,
  labelPosition = 'left',
  fullWidth,
  clear,
  autoFocus,
  helperText,
  type = 'text',
  allowPasswordManager = false, // set to true for inputs where a password manager might save or autocomplete login credentials
  prefix,
  suffix,
  testId = 'MrInputText',
}) => {
  const uuid = generateId();
  const { addValidators } = useValidatorLookup(uuid);

  addValidators({ uuid, validators });

  const attrs = {
    className: errorMsg && s.hasError,
    id: uuid,
    disabled,
    placeholder,
    onBlur,
    name,
    autoComplete,
    spellCheck,
    readOnly,
    autoFocus,
    onInput,
    type,
  };

  // disable password managers by default, specifically those that attempt to autocomplete on forms where it is not needed
  if (!allowPasswordManager) {
    attrs['data-lpignore'] = true; // disable lastpass
  }

  return (
    <div className={cx(s.input, fullWidth && s.fullWidth, className)} data-test-id={testId}>
      {label && labelPosition === 'left' && (
        <label className={s.labelLeft} htmlFor={uuid}>
          {label} {required && <span className={s.required}>*</span>}
        </label>
      )}

      {label && labelPosition === 'top' && (
        <div>
          <label className={s.labelTop} htmlFor={uuid}>
            {label} {required && <span className={s.required}>*</span>}
          </label>
        </div>
      )}

      <div className={cx(prefix ? s.hasPrefix : '', suffix ? s.hasSuffix : '', s.field)}>
        {prefix && <div className={s.prefix}>{prefix}</div>}

        <input value={value} onChange={onChange} {...attrs} data-prefix={prefix} data-suffix={suffix} />

        {suffix && <div className={s.suffix}>{suffix}</div>}

        {value && clear && (
          <span className={s.clear} onClick={clear}>
            &#x2715;
          </span>
        )}
      </div>

      {helperText && <p className={s.helperText}>{helperText}</p>}

      {errorMsg && (
        <p className={s.errorMsg} data-test-id={`${testId}ErrorMsg`}>
          {errorMsg}
        </p>
      )}
    </div>
  );
};

MrInputText.propTypes = {
  disabled: pt.bool,
  placeholder: pt.string,
  value: pt.string,
  label: pt.string,
  required: pt.bool,
  onChange: pt.func,
  onInput: pt.func,
  onBlur: pt.func,
  name: pt.string.isRequired,
  errorMsg: pt.string,
  spellCheck: pt.bool,
  autoComplete: pt.oneOf(['off', 'on']),
  validators: pt.arrayOf(pt.func),
  readOnly: pt.bool,
  className: pt.string,
  labelPosition: pt.oneOf(['top', 'left']),
  fullWidth: pt.bool,
  clear: pt.func,
  autoFocus: pt.bool,
  helperText: pt.string,
  type: pt.string,
  allowPasswordManager: pt.bool,
  prefix: pt.string,
  suffix: pt.string,
  testId: pt.string,
};

export default MrInputText;
