import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './page.module.scss';

import { Helmet } from 'react-helmet';

import favicon from './favicon.ico';

const MrPage = ({ children, title = 'MetaRouter', description, path = '/', className }) => {
  const url = `${process.env.WEB_APP_URL}${path}`;
  if (title !== 'MetaRouter') {
    title = `${title} | MetaRouter`;
  }
  return (
    <div className={cx(s.page, className)}>
      {children}
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}

        {/* TODO: probably a better way to do this, but it enables dynamically changing it if we ever want that feature */}
        <link rel="shortcut icon" href={favicon} />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {/* {image && <meta property="og:image" content={image} />} */}

        {/* <!-- Twitter --> */}

        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        {/* {image && <meta property="twitter:image" content={image} />} */}
        {/* {image && <meta property="twitter:card" content="summary_large_image" />} */}
      </Helmet>
    </div>
  );
};

MrPage.propTypes = {
  children: pt.node,
  title: pt.string,
  description: pt.string,
  path: pt.string,
  imagePath: pt.string, // TODO
  className: pt.string,
};

export default MrPage;
