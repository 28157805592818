import React, { useEffect, useState } from 'react';
import pt from 'prop-types';
import { keys as _keys, isNull as _isNull, pickBy as _pickBy } from 'lodash';
import { scrollIntoView } from 'seamless-scroll-polyfill';

import { MrLink, MrWarning } from '@ion/components';

import s from './index.module.scss';
import { friendlyFieldName } from './friendly-field-name';

const radioInputFields = ['impliedConsent', 'fileHostingType'];

const FieldMissingWarning = ({ formState, integrations, identitySyncs }) => {
  const crossDomainData = formState.crossDomain;
  const [scrollTo, setScrollTo] = useState(null);
  let missingFields = _keys(_pickBy(formState, field => !_isNull(field.errorMsg)));
  const totalMissingFields = missingFields.length;
  if (!crossDomainData.value) {
    missingFields = missingFields.filter(field => field !== 'sync-crossDomain-consentCategoryId');
  }
  useEffect(() => {
    const elements = document.getElementsByName(scrollTo)[0];
    const elementId =
      radioInputFields.findIndex(field => field === scrollTo) === -1 ? elements?.id : elements?.nextSibling.id;
    const element = document.getElementById(elementId);
    if (element) {
      scrollIntoView(element, { block: 'center', behavior: 'smooth' });
    }
  }, [scrollTo]);

  return (
    <MrWarning
      header={`Required fields missing: ${totalMissingFields}`}
      body={
        <div>
          {missingFields.map((field, idx) => (
            <MrLink key={field} onClick={() => setScrollTo(field)}>
              {friendlyFieldName(field, integrations, identitySyncs)}
              {idx !== totalMissingFields - 1 ? `, ` : ''}
            </MrLink>
          ))}
        </div>
      }
      visible={true}
      className={s.fieldMissingWarning}
    />
  );
};

FieldMissingWarning.propTypes = {
  formState: pt.object,
  integrations: pt.array,
  identitySyncs: pt.array,
};

export default FieldMissingWarning;
