import React, { forwardRef } from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './form.module.scss';

const MrForm = forwardRef(({ children, onSubmit, id, className, fullWidth, testId = 'MrForm' }, ref) => (
  <form
    className={cx(fullWidth && s.fullWidth, className)}
    id={id}
    ref={ref}
    onSubmit={event => {
      event.preventDefault();
      onSubmit(event);
    }}
    data-test-id={testId}
    noValidate
  >
    {children}
  </form>
));

MrForm.displayName = 'MrForm';

MrForm.propTypes = {
  children: pt.node.isRequired,
  onSubmit: pt.func,
  id: pt.string,
  className: pt.string,
  fullWidth: pt.bool,
  testId: pt.string,
};

export default MrForm;
