import { useRemovePipelineIntegration } from '@ion/api';

export default function useRemovePipelineIntegrations() {
  const [removePipelineIntegration] = useRemovePipelineIntegration();
  const removePipelineIntegrationsMutation = pipelineIntegrations => {
    // TODO (Chris 2024.02.02) - Refactor to remove "forEach" network call loop
    pipelineIntegrations.forEach(pipelineIntegration => {
      removePipelineIntegration(pipelineIntegration);
    });
  };

  return [removePipelineIntegrationsMutation];
}
