import { gql } from '@apollo/client';
import analyticsFile from '../analytics-file';
import { buildAnalyticsFileData } from './build-analytics-file-data';

export const mutation = gql`
  mutation InsertAnalyticsFile($analytics_files_insert_input: analytics_files_insert_input!) {
    insert_analytics_files_one(object: $analytics_files_insert_input) {
      ...analyticsFile
    }
  }
  ${analyticsFile}
`;

export function buildVariables({ settings, identitySyncs, integrations, pipelineId }) {
  const { analyticsFileSettings, analyticsFileIdentitySyncs } = buildAnalyticsFileData(settings, identitySyncs);

  return {
    analytics_files_insert_input: {
      integrations,
      pipelineId,
      analytics_file_settings: { data: analyticsFileSettings },
      analytics_file_identity_syncs: { data: analyticsFileIdentitySyncs },
    },
  };
}
