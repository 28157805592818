import React from 'react';
import pt from 'prop-types';
import { components } from 'react-select';

// augment the dropdown indicator with our mousedown handler
const DropdownIndicator = ({ ...props }) => {
  // eslint-disable-next-line react/prop-types
  props.innerProps.onMouseDown = props.selectProps.toggleOpen;
  return <components.DropdownIndicator {...props} />;
};

DropdownIndicator.propTypes = {
  props: pt.any,
};

export default DropdownIndicator;
