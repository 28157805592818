import React from 'react';

import pt from 'prop-types';

import { isEmpty as _isEmpty } from 'lodash';

import cx from 'classnames';
import s from 'app/event-schemas/panel/tree/index.module.scss';
import Depth from './depth';

const Row = ({ depth, property, percentFound, type, properties, isOpen, setIsOpen }) => {
  const typeLength = Array.isArray(type) ? type.length : 0;
  if (Array.isArray(type)) {
    type = type.join(', ');
  }

  const propertiesLength = properties && Object.entries(properties)?.length;
  const hasProperties = propertiesLength > 0;
  const openCloseClass = hasProperties ? (isOpen ? s.open : s.closed) : '';
  const propertyClasses = cx(s.col, s.property, openCloseClass);

  return (
    <div className={cx(s.row, Depth[depth], hasProperties ? s.clickable : null)} onClick={setIsOpen}>
      <div className={propertyClasses}>
        {property}
        {!properties || propertiesLength === 0 ? ':' : ''}
      </div>
      <div className={s.col}>
        {hasProperties && `{${propertiesLength}} `}
        {(_isEmpty(properties) || typeLength > 0) && type}
      </div>
      <div className={s.col}>{Math.round(percentFound * 100)}%</div>
    </div>
  );
};

const propertiesPropType = {
  percentFound: pt.number,
  type: pt.oneOfType([pt.string, pt.arrayOf(pt.string)]),
};

Row.propTypes = {
  depth: pt.number.isRequired,
  property: pt.string.isRequired,
  ...propertiesPropType,
  properties: pt.shape(propertiesPropType),
  isOpen: pt.bool,
  setIsOpen: pt.func,
};

export default Row;
