const STATUSES = {
  Expired: 'Expired',
  Invited: 'Invited',
};

const DEFAULT_CHIP_STYLES = {
  size: 'small',
  variant: 'soft',
};

const CHIP_STYLES = {
  [STATUSES.Expired]: {
    color: 'default',
    ...DEFAULT_CHIP_STYLES,
  },
  [STATUSES.Invited]: {
    color: 'primary',
    ...DEFAULT_CHIP_STYLES,
  },
};

export function chipProps(status) {
  if (!STATUSES[status]) {
    throw new Error(`Unable to determine chip prop: ${status}`);
  }

  return {
    label: status,
    ...CHIP_STYLES[status],
  };
}
