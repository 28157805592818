import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';

import useMrDateRangeState from './state';
import { buildReactDatesProps } from './build-react-dates-props';

import 'react-dates/lib/css/_datepicker.css';
import './react-dates-style-overrides.scss';

export default function MrDateRangePicker({ className, onDatesChange, maxDate, minDate, allowSameDateSelection }) {
  const state = useMrDateRangeState();

  const props = buildReactDatesProps({ state, onDatesChange, maxDate, minDate, allowSameDateSelection });

  return (
    <div className={classNames(className)}>
      <DateRangePicker {...props} />
    </div>
  );
}

MrDateRangePicker.propTypes = {
  className: propTypes.string,
  minDate: propTypes.instanceOf(Date),
  maxDate: propTypes.instanceOf(Date),
  onDatesChange: propTypes.func,
  allowSameDateSelection: propTypes.bool,
};
