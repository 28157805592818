import { useQuery } from './../index';
import { gql } from '@apollo/client';

import permission from '../Apollo/permissions';
import { useMemo } from 'react';
import { getClusterId } from './get-cluster-id';

export const query = gql`
  query ValidationCluster {
    pipeline_integrations(
      where: { deletedAt: { _is_null: true } }
      order_by: { updatedAt: desc_nulls_last }
      limit: 1
    ) {
      pipeline {
        clusterId
      }
    }
    clusters(where: { deletedAt: { _is_null: true } }, order_by: { createdAt: desc_nulls_last }, limit: 1) {
      id
    }
  }
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, isAllowed, error } = useQuery(query, {
    variables, // can apply any transforms or default vars here
    context: { permission: permission.uploadPlaybooks },
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  const clusterId = useMemo(() => getClusterId(data), [data]);

  return {
    clusterId,
    loading,
    isAllowed,
    error,
  };
};
