import React from 'react';

import styles from './sort-icon.module.scss';

import MrIcon from '../../icons/icon';

const SortIcon = () => {
  return <MrIcon id="icon-arrow-down" className={styles.arrowDown} />;
};

export default SortIcon;
