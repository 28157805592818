import React from 'react';
import pt from 'prop-types';

import { PipelineVariablesForm, RevisionSelect, AddIntegrationFields } from './components';
import { MrH, MrLink, MrForm, useForm, MrButton, generateId, MrButtonList, MrSection } from '@ion/components';

import { ConsentCategorySelect } from './components/ConsentCategorySelect';

export const CONSENT_FORM_TYPES = {
  add: 'add',
  update: 'update',
};

export const PipelineIntegrationForm = ({ pipeline, onSubmit, type, onCancel, selectedIntegration }) => {
  let initialFormState = {};
  let integration = null;
  let pipelineVariables = null;
  let consentCategory = null;
  if (selectedIntegration) {
    integration = selectedIntegration.integration;
    pipelineVariables = selectedIntegration.mergedPipelineVars;
    consentCategory = selectedIntegration.consentCategory;

    Object.keys(pipelineVariables).forEach(variableName => {
      initialFormState[`pipeline-variable-${variableName}`] = { value: pipelineVariables[variableName], errorMsg: '' };
    });
  }
  initialFormState = {
    ...initialFormState,
    integration: { value: integration ? integration.id : '', errorMsg: '' },
    revision: { value: integration ? selectedIntegration.playbookId : '', errorMsg: '' },
    consentCategory: { value: consentCategory ? consentCategory.id : '', errorMsg: '' },
  };

  const formId = generateId();
  const { formState, validateField, setField, submitForm, resetError, removeFields } = useForm(
    formId,
    initialFormState
  );

  const isAddIntegration = type === CONSENT_FORM_TYPES.add;
  const isRevisionSelected = formState.revision.value !== '';

  const onSubmitForm = () => {
    const { data, isValid } = submitForm();
    if (isValid) {
      const pipelineVars = {};
      Object.keys(data).forEach(field => {
        if (field.startsWith('pipeline-variable')) {
          const pipelineVariableName = field.split('-')[2];
          pipelineVars[pipelineVariableName] = data[field];
        }
      });
      onSubmit({ ...data, pipelineVars: pipelineVars });
    }
  };

  const onChangeRevision = event => {
    setField(event);
  };

  return (
    <>
      <div className="header">
        <MrH h="h2" sans>
          {`${isAddIntegration ? 'Add New Integration' : `${selectedIntegration.integration.name}`} `}
        </MrH>
      </div>
      <div className="content">
        <MrForm onSubmit={onSubmitForm} id={formId}>
          <MrSection>
            {isAddIntegration && (
              <AddIntegrationFields
                setField={setField}
                formState={formState}
                validateField={validateField}
                pipeline={pipeline}
                excludedIntegrationId={selectedIntegration}
                removeFields={removeFields}
              />
            )}
            <RevisionSelect
              formState={formState}
              onChange={onChangeRevision}
              validateField={validateField}
              integrationId={formState.integration.value}
            />
            <ConsentCategorySelect formState={formState} setField={setField} />
          </MrSection>
          {isRevisionSelected && (
            <PipelineVariablesForm
              formState={formState}
              resetError={resetError}
              setField={setField}
              revisionId={formState.revision.value}
              integrationId={formState.integration.value}
            />
          )}
          <MrButtonList
            styleNames="marginTop"
            buttons={[
              <MrButton
                testId="addPipelineIntegration"
                text={isAddIntegration ? 'Add Integration' : 'Save'}
                key="create"
              />,
              <MrLink onClick={onCancel} styleNames="gray" key="cancel">
                Cancel
              </MrLink>,
            ]}
          />
        </MrForm>
      </div>
    </>
  );
};

PipelineIntegrationForm.propTypes = {
  pipeline: pt.object.isRequired,
  onSubmit: pt.func.isRequired,
  onCancel: pt.func.isRequired,
  type: pt.string.isRequired,
  selectedIntegration: pt.object,
};
