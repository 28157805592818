import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './app-main.module.scss';
import { MrLoading } from '../../index';

const MrAppMain = ({ children, loading, header, styleNames, className, testId = 'MrAppMain' }) => {
  return (
    <main
      className={cx(
        s.main,
        styleNames?.split(' ').map(c => s[c]),
        className
      )}
      data-test-id={testId}
    >
      {header && <div className={s.header}>{header}</div>}
      {children}
      {loading && <MrLoading />}
    </main>
  );
};

MrAppMain.propTypes = {
  children: pt.node,
  header: pt.node,
  styleNames: pt.string,
  className: pt.string,
  loading: pt.bool,
  testId: pt.string,
};

export default MrAppMain;
