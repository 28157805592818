import { gql } from '@apollo/client';

const mutation = gql`
  mutation InsertAuditLog($audit_logs_insert_input: audit_logs_insert_input!) {
    insert_audit_logs_one(object: $audit_logs_insert_input) {
      id
      timestamp
      action
      description
    }
  }
`;
export default mutation;
