import React from 'react';

import { useUserContext } from '@ion/user';
import SideNav from 'app/components/side-nav';
import Header from 'app/components/app-header';
import ProfileDetails from 'app/settings/profile/details.js';
import { MrAppLayout, MrTab, MrHeaderTabs, MrTabList, MrTabPanel } from '@ion/components';

import { ConsentCategoryPage } from './consent-page/consentCategoriesPage';
import { ConsentCategoriesTableContext } from './consent-page/context/consentCategoriesTableContext';

import { UserManagementPage } from './user-management-page/UserManagementPage';
import { OrganizationOverridePage } from './organization-override-page/OrganizationOverridePage';

import useSettingsFlags from './hooks/useSettingsFlags';
import isAllowedToOverrideOrganization from './organization-override-page/is-allowed-to-override-organization';

const SettingsPage = () => {
  const { user } = useUserContext();
  const { hasConsent, hasUserManagement } = useSettingsFlags();
  const hasPermission = isAllowedToOverrideOrganization();

  return (
    <MrAppLayout title="Settings" header={<Header icon="settings" title="Settings" />} sideNav={<SideNav />}>
      <MrHeaderTabs>
        <MrTabList>
          <MrTab>General</MrTab>
          {hasConsent && <MrTab>Consent Settings</MrTab>}
          {hasUserManagement && <MrTab>User Management</MrTab>}
          {hasPermission && <MrTab>Organization Override</MrTab>}
        </MrTabList>

        <MrTabPanel>
          <ProfileDetails me={user} />
        </MrTabPanel>

        {hasConsent && (
          <MrTabPanel>
            <ConsentCategoriesTableContext>
              <ConsentCategoryPage />
            </ConsentCategoriesTableContext>
          </MrTabPanel>
        )}

        {hasUserManagement && (
          <MrTabPanel>
            <UserManagementPage />
          </MrTabPanel>
        )}

        {hasPermission && (
          <MrTabPanel>
            <OrganizationOverridePage />
          </MrTabPanel>
        )}
      </MrHeaderTabs>
    </MrAppLayout>
  );
};

export default SettingsPage;
