import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputText, MrInputTextarea } from '@ion/components';

import { useFormContext } from 'app/context/form-context';
import Compression from './compression';

const { validateRequired } = formValidators;

const Gcs = ({ opId, _path, bucket, prefix, credentialJson, compression }) => {
  const { validateField, resetError, formState, setField } = useFormContext();

  return (
    <>
      {((name = `${opId}-bucket-${_path}`) => (
        <MrInputText
          label="BUCKET"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={bucket}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateRequired()]}
          placeholder="BUCKET"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-prefix-${_path}`) => (
        <MrInputText
          label="PREFIX"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={prefix}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          placeholder="PREFIX"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-credentialJson-${_path}`) => (
        <MrInputTextarea
          label="CREDENTIAL_JSON"
          fullWidth={true}
          rows={8}
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={credentialJson}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          placeholder="CREDENTIAL_JSON"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-compression-${_path}`) => (
        <Compression name={name} defaultValue={compression} isOptional={false} />
      ))()}
    </>
  );
};

Gcs.propTypes = {
  bucket: pt.string,
  prefix: pt.string,
  credentialJson: pt.string,
  compression: pt.string,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default Gcs;
