import { gql } from '@apollo/client';

export const mutation = gql`
  mutation DeleteOrganizationInvitation($invitationId: String!) {
    auth0_delete_organization_invitation(invitationId: $invitationId) {
      status
    }
  }
`;

export function buildVariables({ invitationId }) {
  return { invitationId };
}
