import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-lua';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';

export function addLineNumber(line, index) {
  return `<span class="token line-number">${index}</span>${line}`;
}

const NEWLINE_SEPARATOR = '\n';
export function addLineNumbers(code) {
  return code.split(NEWLINE_SEPARATOR).concat(['']).map(addLineNumber).join(NEWLINE_SEPARATOR).replace('0', '');
}

export default function customHighlight(value, lang) {
  return addLineNumbers(highlight(value, languages[lang], lang));
}
