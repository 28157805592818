import React from 'react';
import pt from 'prop-types';

import { MrModal, MrH, MrLink, MrIcon } from '@ion/components';
import s from './error-modal.module.scss';

const MrErrorModal = ({ header, modalText, errorMessage, closeModal }) => {
  return (
    <MrModal closeModal={closeModal}>
      <MrLink styleNames="noHover gray" className={s.close} onClick={closeModal}>
        &#x2715;
      </MrLink>
      <MrIcon className={s.icon} id="icon-exclamation-circle-background" />
      <div>
        <MrH className={s.errorHeader} h="h2" styleNames="sans">
          {header}
        </MrH>
        <div className={s.topText}>
          <span className={s.text}>{modalText}</span>
        </div>
      </div>
      <div className={s.bottomText}>
        <span className={s.text}>{errorMessage}</span>
      </div>
    </MrModal>
  );
};

MrErrorModal.propTypes = {
  header: pt.string,
  modalText: pt.string,
  errorMessage: pt.string,
  closeModal: pt.func.isRequired,
};

export default MrErrorModal;
