import React from 'react';
import pt from 'prop-types';

import classNames from 'classnames';
import styles from './input-select.module.scss';

export default function SelectContainer({ className, disabled, testId, children }) {
  const combinedClassName = classNames('react-select-container', className, { [styles.disabled]: disabled });
  return (
    <div className={combinedClassName} data-test-id={testId}>
      {children}
    </div>
  );
}

SelectContainer.propTypes = {
  children: pt.node,
  testId: pt.string,
  className: pt.string,
  disabled: pt.bool,
};
