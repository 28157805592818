import { useReducer } from 'react';
import { produce } from 'immer';

const actions = {
  LOADING_REPORTS: Symbol(),
  UPDATE_REPORT_SETTINGS: Symbol(),
  SET_REPORTS: Symbol(),
  SET_SELECTED_REPORT: Symbol(),
  SORT_REPORTS: Symbol(),
  RESET_REPORTS: Symbol(),
};

const initialState = {
  cluster: null,
  isLoadingReports: false,
  reports: [],
  selectedReport: null,
  writeKeyToPipeline: {},
};

const reducer = produce((state, { type, payload }) => {
  switch (type) {
    case actions.UPDATE_REPORT_SETTINGS:
      state.cluster = payload.cluster;
      state.writeKeyToPipeline = (payload?.cluster?.pipelines ?? []).reduce((res, p) => {
        res[p.writekey] = p;
        return res;
      }, {});
      state.timeframeHours = parseInt(payload.timeframeHours);
      state.earliestSeen = new Date(new Date().setHours(new Date().getHours() - state.timeframeHours)).toISOString();
      break;
    case actions.LOADING_REPORTS:
      state.isLoadingReports = true;
      break;
    // Reports are always ordered by the Control API by event name and write key
    case actions.SET_REPORTS:
      state.reports = payload
        .reduce((res, input) => groupReportsByEvent(input, state, res), [])
        .map(r => convertPipelineNamesToString(r))
        .sort((a, b) => b.lastSeen.getTime() - a.lastSeen.getTime());
      state.isLoadingReports = false;
      break;
    case actions.SORT_REPORTS:
      state.reports = state.reports.sort((a, b) => {
        if (payload.direction === 'asc') {
          return a.lastSeen.getTime() - b.lastSeen.getTime();
        }
        return b.lastSeen.getTime() - a.lastSeen.getTime();
      });
      break;
    case actions.SET_SELECTED_REPORT:
      state.selectedReport = payload;
      break;
    case actions.RESET_REPORTS:
      state = { ...initialState };
  }
  return state;
});

const groupReportsByEvent = (input, state, res) => {
  input.lastSeen = new Date(input.lastSeen);
  input.totalCount = parseInt(input.totalCount);
  input.combinations = parseInt(input.combinations);

  const pipeline = state.writeKeyToPipeline[input.writekey];
  if (res.length > 0 && res[res.length - 1].eventName === input.eventName) {
    const report = res[res.length - 1];
    report.totalCount += input.totalCount;
    report.combinations += input.combinations;
    report.lastSeen = input.lastSeen > report.lastSeen ? input.lastSeen : report.lastSeen;
    pipeline ? report.pipelineNames.push(pipeline.name) : null;
    report.pipelines.push({ ...pipeline, ...input });
    return res;
  }
  input.pipelines = [{ ...pipeline, ...input }];

  const date = input.lastSeen.toLocaleDateString().split('/');
  const hours = (input.lastSeen.getHours() < 10 ? '0' : '') + input.lastSeen.getHours();
  const minutes = (input.lastSeen.getMinutes() < 10 ? '0' : '') + input.lastSeen.getMinutes();
  const seconds = (input.lastSeen.getSeconds() < 10 ? '0' : '') + input.lastSeen.getSeconds();

  input.lastSeenDate = `${date[2]}-${date[0]}-${date[1]}`;
  input.lastSeenTime = `${hours}:${minutes}:${seconds}`;
  input.pipelineNames = pipeline ? [pipeline?.name] : [];

  res.push(input);
  return res;
};

const useEventSchemasState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateReportSettings = (cluster, timeframeHours) => {
    dispatch({
      type: actions.UPDATE_REPORT_SETTINGS,
      payload: { cluster, timeframeHours },
    });
  };

  const loadingReports = () => {
    dispatch({ type: actions.LOADING_REPORTS });
  };

  const setReports = reports => {
    dispatch({
      type: actions.SET_REPORTS,
      payload: reports,
    });
  };

  const setSelectedReport = report => {
    dispatch({
      type: actions.SET_SELECTED_REPORT,
      payload: report,
    });
  };

  const sortReports = (field, direction) => {
    dispatch({
      type: actions.SORT_REPORTS,
      payload: { field, direction },
    });
  };

  const resetReports = () => {
    dispatch({ type: actions.RESET_REPORTS });
  };

  return {
    updateReportSettings,
    loadingReports,
    setReports,
    setSelectedReport,
    sortReports,
    resetReports,
    ...state,
  };
};

const convertPipelineNamesToString = r => {
  const maxCharacters = 70;
  let over = 0;
  let pipelineNames = r.pipelineNames.reduce((res, name) => {
    res = res.length > 0 ? `${res}, ${name}` : name;
    if (res.length > maxCharacters) {
      over++;
    }
    return res;
  }, '');
  if (over > 0) {
    pipelineNames = `${pipelineNames.slice(0, maxCharacters - 3)}... (${over} more)`;
  }
  r.pipelineNames = pipelineNames;
  r.totalCount = r.totalCount.toLocaleString();
  r.combinations = r.combinations.toLocaleString();
  return r;
};

export default useEventSchemasState;
