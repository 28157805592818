import React from 'react';
import pt from 'prop-types';

import { MrH } from '@ion/components';

import ConfirmDelete from 'app/components/confirm-delete/confirm-delete';

import styles from './confirm-unstage-deployed-Integration.module.scss';

const ConfirmUnstageDeployedIntegration = ({ deleteFn, closeModal, integration }) => {
  return (
    <ConfirmDelete deleteFn={deleteFn} closeModal={closeModal}>
      <div className={styles.header}>
        <MrH h="h2" styleNames="sans">
          Unstage Deployed Integration
        </MrH>
      </div>
      <div className={styles.content}>
        <p>
          Stage <strong>{integration.name}</strong> for deletion? This change will not take effect until the pipeline is
          deployed.
        </p>
      </div>
    </ConfirmDelete>
  );
};

ConfirmUnstageDeployedIntegration.propTypes = {
  deleteFn: pt.func,
  closeModal: pt.func,
  integration: pt.object,
};

export default ConfirmUnstageDeployedIntegration;
