import React, { useState } from 'react';

import { useConsentCategoriesTableContext } from '../../../../context/consentCategoriesTableContext';

import { useUpdateConsentCategory } from '@ion/api';
import { ConsentCategoryForm } from '../../../consent-category-form/ConsentCategoryForm';
import { MrErrorModal } from '@ion/components';

export const UpdateCategory = () => {
  const { onCloseModal, selectedCategory, consentCategories } = useConsentCategoriesTableContext();
  const [errorMsg, setErrorMsg] = useState(null);
  const [updateCategory] = useUpdateConsentCategory({
    onCompleted: () => {
      onCloseModal();
    },
    onError: err => {
      setErrorMsg(err?.errorMsg);
    },
  });

  const onSubmit = data => {
    updateCategory({ consentCategoryId: selectedCategory.id, name: data.name.trim(), code: data.code.trim() });
  };

  return (
    <>
      <ConsentCategoryForm
        consentCategory={selectedCategory}
        onSubmit={onSubmit}
        type="edit"
        onCancel={onCloseModal}
        consentCategories={consentCategories}
      />
      {errorMsg && (
        <MrErrorModal
          header="Error Creating Consent Category"
          errorMessage={errorMsg}
          closeModal={() => setErrorMsg(null)}
        />
      )}
    </>
  );
};
