import { useEffect, useState, useCallback } from 'react';

import { useDashboardProxy } from '@ion/api';
import setInlineCardsTotalEvents from './set-inline-cards-total-events';
import isValidInput from '../is-valid-input';

export const inlineCardsKey = {
  totalEvents: 'totalEvents',
};

const query = {
  TotalEvents: 'TotalEvents',
};

function useGetMetrics({ clusters, pipelines, since, until }) {
  const [metrics, setMetrics] = useState({});
  const [getMetrics, { loading }] = useDashboardProxy({
    onCompleted: r => {
      setInlineCardsTotalEvents({ result: r, setMetrics });
    },
    onError: err => {
      console.log(err);
      setMetrics({ [inlineCardsKey.totalEvents]: 0 });
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMetricsCallback = useCallback(getMetrics, []);

  useEffect(() => {
    if (isValidInput({ clusters, pipelines, since, until })) {
      const common = {
        clusters: clusters,
        pipelines: pipelines,
        dateRange: {
          since,
          until,
        },
      };
      getMetricsCallback({
        ...common,
        query: query.TotalEvents,
      });
    } else {
      setMetrics({ [inlineCardsKey.totalEvents]: 0 });
    }
  }, [clusters, pipelines, since, until, getMetricsCallback]);

  return [{ metrics, loading }];
}

export default useGetMetrics;
