import React, { useState } from 'react';

import { MrErrorModal } from '@ion/components';
import { useInsertConsentCategory } from '@ion/api';

import { ConsentCategoryForm } from '../../../consent-category-form/ConsentCategoryForm';
import { useConsentCategoriesTableContext } from '../../../../context/consentCategoriesTableContext';

export const AddCategory = () => {
  const { onCloseModal, consentCategories } = useConsentCategoriesTableContext();
  const [errorMsg, setErrorMsg] = useState(false);

  const [insertCategory] = useInsertConsentCategory({
    onCompleted: () => {
      onCloseModal();
    },
    onError: err => {
      setErrorMsg(err?.errorMsg);
    },
  });
  const onSubmit = data => {
    insertCategory({ name: data.name.trim(), code: data.code.trim() });
  };

  return (
    <>
      <ConsentCategoryForm
        onSubmit={onSubmit}
        type="add"
        onCancel={onCloseModal}
        consentCategories={consentCategories}
      />
      {errorMsg && (
        <MrErrorModal
          header="Error Creating Consent Category"
          errorMessage={errorMsg}
          closeModal={() => setErrorMsg(false)}
        />
      )}
    </>
  );
};
