import React from 'react';

import { Stack, TwoPaneLayout } from '@metarouter-private/mr-mui';

import { MrAppMain } from '@ion/components';
import { UserManagementTable } from './components/UserManagementTable';
import { UserInvitationsTable } from './components/invitations-table/UserInvitationsTable';
import { default as Documentation } from './components/documentation';
import UserManagementPageContextProvider from './context/UserManagementPageContextProvider';

export const UserManagementPage = () => {
  return (
    <MrAppMain styleNames="noBackground">
      <TwoPaneLayout
        paneOne={<Documentation />}
        paneTwo={
          <UserManagementPageContextProvider>
            <Stack direction="column" spacing={2}>
              <UserManagementTable />
              <UserInvitationsTable />
            </Stack>
          </UserManagementPageContextProvider>
        }
      />
    </MrAppMain>
  );
};
