import { gql } from '@apollo/client';

const updatePipelineFragment = gql`
  fragment updatePipeline on pipelines {
    id
    name
  }
`;

export const mutation = gql`
  mutation UpdatePipeline(
    $pipelines_pk_columns: pipelines_pk_columns_input!
    $pipelines_set_input: pipelines_set_input!
  ) {
    update_pipelines_by_pk(pk_columns: $pipelines_pk_columns, _set: $pipelines_set_input) {
      ...updatePipeline
    }
  }
  ${updatePipelineFragment}
`;

export function buildVariables({ pipelineId, name }) {
  return {
    pipelines_pk_columns: {
      id: pipelineId,
    },
    pipelines_set_input: {
      name,
    },
  };
}
