import React from 'react';
import pt from 'prop-types';

import {
  formValidators,
  MrInputText,
  MrInputTextarea,
  MrInputPassword,
  MrInputNumber,
  MrInputSelectDeprecated,
} from '@ion/components';

import { useFormContext } from 'app/context/form-context';
import Compression from './compression';
import Sasl from './Sasl';
import S3 from './s3';
import Gcs from './Gcs';
import Stage from './Stage';
import { BIG_QUERY_OPTIONS } from '../../consts';
import BigQueryOptions from './BigQueryOptions';

const { validateRequired, validateBoolean } = formValidators;

const ParamLookup = ({
  isPipelineVar,
  opId,
  exampleValue,
  isOptional,
  defaultValue,
  _path,
  name,
  saslType,
  stageType,
  username,
  password,
  algorithm,
  region,
  bucket,
  prefix,
  accessKey,
  secretKey,
  serverSideEncryption,
  compression,
  credentialJson,
}) => {
  const { validateField, resetError, formState, setField } = useFormContext();
  const value = `${opId}-defaultValue-${_path}`;

  let Component;
  let extraProps = {};

  switch (name) {
    case 'S_3':
      return (
        <S3
          opId={opId}
          _path={_path}
          region={region}
          bucket={bucket}
          prefix={prefix}
          accessKey={accessKey}
          secretKey={secretKey}
          serverSideEncryption={serverSideEncryption}
          compression={compression}
        />
      );
    case 'GCS':
      return (
        <Gcs
          opId={opId}
          _path={_path}
          bucket={bucket}
          prefix={prefix}
          credentialJson={credentialJson}
          compression={compression}
        />
      );
    case 'AUTHENTICATION (SASL)':
      return (
        <Sasl
          opId={opId}
          _path={_path}
          saslType={saslType}
          username={username}
          password={password}
          algorithm={algorithm}
        />
      );
    case 'STAGE':
      return (
        <Stage
          opId={opId}
          _path={_path}
          stageType={stageType}
          region={region}
          bucket={bucket}
          prefix={prefix}
          accessKey={accessKey}
          secretKey={secretKey}
          serverSideEncryption={serverSideEncryption}
          compression={compression}
          credentialJson={credentialJson}
        />
      );
    case 'COMPRESSION':
      return <Compression name={value} defaultValue={defaultValue} isOptional={isOptional} />;
    case BIG_QUERY_OPTIONS: {
      return <BigQueryOptions name={value} defaultValue={defaultValue} isOptional={isOptional} />;
    }
    case 'OPT_OUT_SCHEMA_CREATION':
    case 'TLS': {
      const validators = isOptional ? [validateBoolean()] : [validateRequired(), validateBoolean()];
      return (
        <MrInputSelectDeprecated
          options={['true', 'false']}
          onBlur={validateField}
          name={value}
          value={formState[value]?.value ?? defaultValue ?? 'true'}
          onChange={setField}
          errorMsg={formState[value]?.errorMsg}
          validators={validators}
          disabled={isPipelineVar}
        />
      );
    }
    case 'BACKLOG_COUNT':
    case 'PROFILE_ID':
    case 'BATCH_SIZE':
      Component = MrInputNumber;
      break;
    case 'PASSWORD':
      Component = MrInputPassword;
      break;
    case 'PRIVATE_KEY':
    case 'CREDENTIAL_JSON':
    case 'CREDENTIALS':
    case 'CREDENTIALS_JSON':
      Component = MrInputTextarea;
      extraProps = {
        fullWidth: true,
        rows: 8,
      };
      break;
    case 'BROKERS':
      Component = MrInputText;
      extraProps = {
        fullWidth: true,
        placeholder: 'broker1:9092,broker2:9092,broker3:9092',
      };
      break;
    default:
      Component = MrInputText;
      break;
  }

  return (
    <Component
      onBlur={validateField}
      onInput={resetError}
      name={value}
      value={isPipelineVar ? '' : formState[value]?.value ?? defaultValue ?? ''}
      onChange={setField}
      errorMsg={isPipelineVar ? null : formState[value]?.errorMsg}
      placeholder={exampleValue}
      validators={!isOptional ? [validateRequired()] : []}
      disabled={isPipelineVar}
      {...extraProps}
    />
  );
};

ParamLookup.propTypes = {
  opId: pt.string.isRequired,
  name: pt.string.isRequired,
  _path: pt.string.isRequired,
  isPipelineVar: pt.bool,
  exampleValue: pt.string,
  isOptional: pt.bool,
  defaultValue: pt.oneOfType([pt.string, pt.number]),
  saslType: pt.string,
  stageType: pt.string,
  password: pt.string,
  username: pt.string,
  algorithm: pt.string,
  region: pt.string,
  bucket: pt.string,
  prefix: pt.string,
  accessKey: pt.string,
  secretKey: pt.string,
  serverSideEncryption: pt.string,
  compression: pt.string,
  credentialJson: pt.string,
};

export default ParamLookup;
