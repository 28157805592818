import React from 'react';
import pt from 'prop-types';

import { MrDropdown } from '@ion/components';
import icon from './assets/icon-more.svg';

import { download } from './download/download';

const DropDown = ({ playbookState, integration, uploadState: { isUploadAllowed, setModal } }) => {
  const onClickDownload = () => download({ playbookState, integration });

  return (
    <MrDropdown icon={icon}>
      <ul>
        <li onClick={onClickDownload}>Download</li>
        {isUploadAllowed && <li onClick={setModal}>Upload</li>}
      </ul>
    </MrDropdown>
  );
};

DropDown.propTypes = {
  integration: pt.object.isRequired,
  playbookState: pt.object.isRequired,
  uploadState: pt.shape({
    isUploadAllowed: pt.bool.isRequired,
    setModal: pt.func.isRequired,
  }).isRequired,
};

export default DropDown;
