import { v4 as uuid } from 'uuid';
import some from 'lodash/some';
import { useMutation } from '../../index';
import { useUserContext } from '@ion/user';

import { query } from '../useQueryIntegrations';
import permission from '../../Apollo/permissions';
import { validate } from 'parameter-validator';

import {
  mutation as insertPipelineMutation,
  buildVariables as buildInsertIntegrationVariables,
} from './insert-integration-mutation-builder';
import {
  mutation as pipelineCreatedMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/integration-created/audit-log-mutation-builder';
import mergeQueries from '../../Apollo/merge-queries';

export const combinedMutation = mergeQueries('InsertPipelineAndAudit', [
  insertPipelineMutation,
  pipelineCreatedMutation,
]);

export default function useInsertIntegration(options = {}) {
  const { user, currentOrg } = useUserContext();
  const userData = { email: user?.email, org: currentOrg?.display_name };
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'New integration added.',
    context: { permission: permission.createIntegration },
    ...options,
    // Update cache on insert
    update: buildUpdateCache(userData),
  });

  const insertIntegrationMutation = args => {
    const { name, starterkitId } = validate(args, ['name', 'starterkitId']);
    const id = uuid();
    return executeMutation({
      variables: {
        ...buildInsertIntegrationVariables({ id, name, starterkitId }),
        ...buildAuditLogVariables({ id, name, starterkitId }),
      },
    });
  };

  return [insertIntegrationMutation, response];
}

function buildUpdateCache(userData) {
  return (cache, { data }) => {
    try {
      // get new org from mutation result
      const newIntegration = data.insert_integrations_one;
      track({ newIntegration, userData });

      // read query results from cache
      const { integrations } = cache.readQuery({ query }) || [];

      if (!integrations) return;

      // Safety check: find if it already exists, to avoid duplication
      if (some(integrations, integration => integration.id === newIntegration.id)) {
        return;
      }

      // update the cache with mutation result
      cache.writeQuery({
        query,
        data: {
          integrations: [newIntegration, ...integrations], // inserts it first
        },
      });
    } catch (e) {
      console.log(e);
      // We should always catch here,
      // as the cache may be empty or the query may fail
    }
  };
}

function track({ newIntegration, userData }) {
  // tracking event
  __mrTracking?.track('integration_created', {
    id: newIntegration.id,
    name: newIntegration.name,
    starterkitId: newIntegration.starterkitId,
    starterkitVersion: newIntegration.starterkitVersion,
    userEmail: userData.email,
    orgName: userData.org,
  });
}
