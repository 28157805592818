import { useMutation } from './../index';
import { gql } from '@apollo/client';
import permission from '../Apollo/permissions';

const mutation = gql`
  mutation DiffSchemas($eventSchema: json!, $generatedSchema: json!) {
    diffSchemas(eventSchema: $eventSchema, generatedSchema: $generatedSchema) {
      destination_valid
      invalid_fields
    }
  }
`;

export default (options = {}) => {
  const [call, response] = useMutation(mutation, {
    // successMsg: 'Event schema updated.',
    ...options,
    context: { permission: permission.createCluster }, // TODO new perms
    // Update cache on insert
    // update: (cache, { data }) => {
    //     try {
    //         // get new org from mutation result
    //         const newItem = data.insert_clusters_one
    //         __mrTracking.track('cluster_created', {
    //             clusterId: newItem.id,
    //             clusterName: newItem.name,
    //             clusterUrl: newItem.url,
    //         })
    //         // read query results from cache
    //         const { clusters } = cache.readQuery({ query }) || []
    //         if (!clusters) return
    //
    //         // Safety check: find if it already exists, to avoid duplication
    //         if (~clusters.findIndex(o => o.id === newItem.id)) return
    //         // update the cache with mutation result
    //         cache.writeQuery({
    //             query,
    //             data: {
    //                 clusters: [newItem, ...clusters], // inserts it first
    //             },
    //         })
    //     } catch (e) {
    //         console.log(e)
    //         // We should always catch here,
    //         // as the cache may be empty or the query may fail
    //     }
    // },
  });
  return [
    // The insert function
    ({ eventSchema, generatedSchema }) => {
      if (!eventSchema) throw new Error('Event Schema required for diffing');
      if (!generatedSchema) throw new Error('Generated Schema required for diffing');

      return call({
        variables: {
          eventSchema,
          generatedSchema,
        },
      });
    },
    response,
  ];
};
