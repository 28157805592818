import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './logo.module.scss';
import logo from './logo.svg';

const MrLogo = ({ className, onClick }) => {
  return <img src={logo} alt="MetaRouter" className={cx(s.logo, className)} onClick={onClick} />;
};

MrLogo.propTypes = {
  className: pt.string,
  onClick: pt.func,
};

export default MrLogo;
