import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from '../../launch-darkly/is-feature-enabled';
import { CONSENT_CATEGORY_FLAGS } from '../../context/gated-consent-categories-context';
import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';

export default function useSettingsFlags() {
  const featureFlags = useFlags();

  return {
    hasConsent: isFeatureEnabled({ featureFlags, flagName: CONSENT_CATEGORY_FLAGS.MVP }),
    hasUserManagement: isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.roUserManagementMVP }),
  };
}
