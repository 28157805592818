import { gql } from '@apollo/client';

export const ORGANIZATION_INVITATIONS_QUERY_NAME = 'OrganizationInvitations';

export const GET_ORGANIZATION_INVITATIONS = gql`
  query ${ORGANIZATION_INVITATIONS_QUERY_NAME}($page: Int!, $perPage: Int!) {
    auth0_organization_invitations(page: $page, perPage: $perPage) {
      invitations {
        id
        invitationUrl
        email
        createdAt
        expiresAt
        roles {
          id
          name
        }
        status
      }
      page
      perPage
      totalCount
    }
  }
`;

export const DEFAULT_PAGINATION_VARIABLES = {
  page: 1,
  perPage: 5,
};
