import React, { useState } from 'react';
import { alphabetizeBy } from '@ion/mrdash';

import { MrIcon, MrLinkButton, MrSearch, MrAppMain, MrAppLayout } from '@ion/components';
import { useQueryIntegrations } from '@ion/api';
import { routes } from 'app/constants';

import SideNav from 'app/components/side-nav';
import Header from 'app/components/app-header';

import IntegrationCard from './card';
import s from './index.module.scss';

import { useInsertIntegration } from '@ion/api';

export function alphabetizeIntegrations(integrations) {
  return alphabetizeBy(integrations, 'name');
}

const Integrations = () => {
  const { data, loading } = useQueryIntegrations();

  const [integrations, setIntegrations] = useState(data);

  const [, { isAllowed: createIntegrationPermission }] = useInsertIntegration();

  return (
    <MrAppLayout
      title="Integrations"
      description="Manage your integrations."
      sideNav={<SideNav />}
      header={<Header icon="integrations" title="Integrations" route="integrations" />}
    >
      <MrAppMain
        loading={loading}
        styleNames="list"
        header={
          <>
            <MrLinkButton
              disabled={!createIntegrationPermission}
              to={createIntegrationPermission ? routes.newIntegration : null}
              className="addNewButton"
            >
              <MrIcon id="plus-circle" fontSize="medium" />
              New Integration
            </MrLinkButton>
            <MrSearch placeholder="Search Integrations" data={data} onChange={setIntegrations} />
          </>
        }
      >
        <div className={s.container}>
          <div className={s.list} data-test-id={'IntegrationsList'}>
            {alphabetizeIntegrations(integrations).map(integration => (
              <IntegrationCard key={integration.id} integration={integration} />
            ))}
          </div>
        </div>
      </MrAppMain>
    </MrAppLayout>
  );
};

export default Integrations;
