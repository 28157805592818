import normalizeOptions from './normalize-options';
import { alphabetizableBy, alphabetizeBy } from '@ion/mrdash';

export default function transformOptions(options, { alphabetized }) {
  const normalizedOptions = normalizeOptions(options);
  if (alphabetized) {
    if (!alphabetizableBy(normalizedOptions, 'label')) {
      return normalizedOptions;
    }
    return alphabetizeBy(normalizedOptions, 'label');
  }
  return normalizedOptions;
}
