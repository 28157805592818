import { useMutation } from '../../index';
import permission from '../../Apollo/permissions';
import { buildVariables, mutation } from './update-integration-mutation-builder';
import { validate } from 'parameter-validator';

/*
  Update Integration but don't create an Audit Log.
  First used to set the "starterkitVersion" on an Integration.
 */
export default function useQuietUpdateIntegration(options = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    context: { permission: permission.updateIntegration },
    ...options,
  });

  const quiteUpdateIntegrationMutation = args => {
    const { integrationId, starterkitVersion } = validate(args, ['integrationId', 'starterkitVersion']);

    return executeMutation({
      variables: {
        ...buildVariables({ integrationId, starterkitVersion }),
      },
    });
  };

  return [quiteUpdateIntegrationMutation, response];
}
