import React from 'react';
import { useConsentCategoriesTableContext } from '../../context/consentCategoriesTableContext';
import { MrModal } from '@ion/components';
import { AddCategory, DeleteCategory, UpdateCategory } from './modal-options';

export const ConsentCategoryModal = () => {
  const { isOpenModal, onCloseModal, modalContentOption } = useConsentCategoriesTableContext();
  if (modalContentOption === undefined || modalContentOption === null) {
    return null;
  }
  const renderContent = () => {
    switch (modalContentOption) {
      case 'addCategory':
        return <AddCategory />;
      case 'editCategory':
        return <UpdateCategory />;
      case 'deleteCategory':
        return <DeleteCategory />;
      default:
        break;
    }
  };

  return <>{isOpenModal && <MrModal closeModal={onCloseModal}>{renderContent()}</MrModal>}</>;
};
