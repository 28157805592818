import React, { createContext, useContext } from 'react';
import propTypes from 'prop-types';

const PageDataTableContext = createContext(null);

export default function MrPageDataTableContextProvider({ pagination, children }) {
  return <PageDataTableContext.Provider value={{ pagination }}>{children}</PageDataTableContext.Provider>;
}

export function usePageDataTableContext() {
  return useContext(PageDataTableContext);
}

MrPageDataTableContextProvider.propTypes = {
  pagination: propTypes.object,
  children: propTypes.node.isRequired,
};
