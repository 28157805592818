import React from 'react';

import styles from './documentation.module.scss';
import { MrButton, MrH, MrLink, MrText } from '@ion/components';
import useTrackWithUser from '@ion/api/hooks/useTrackWithUser';

export const Documentation = () => {
  const [trackWithUser] = useTrackWithUser('views_documentation');

  return (
    <div>
      <MrH h="h4" styleNames="noMargin" sans>
        What are Consent Categories?
      </MrH>
      <MrText className={styles.descriptionBodyText} component="span">
        <p>
          You can use this table to identify your organization&apos;s consent categories. The Friendly Names will be
          used throughout our UI to make it easier for your team to understand how to categorize Syncs and Integrations.
        </p>
        <p>
          The Categories Codes (eg. C1) will be what is actually used by our system and your team when creating consent
          cookies and consent objects within our SDKs.
        </p>
      </MrText>
      <MrLink
        href="https://support.metarouter.io/consent-enforcement"
        target="_blank"
        rel="noreferrer"
        styleNames="noHover"
        onClick={() => trackWithUser()}
      >
        <MrButton text={'Documentation'} />
      </MrLink>
    </div>
  );
};
