import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';

import styles from './section.module.scss';

const MrSection = ({ children, className, testId = 'MrSection' }) => {
  return (
    <div className={classNames(styles.section, className)} data-test-id={testId}>
      {children}
    </div>
  );
};

MrSection.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
  testId: propTypes.string,
};

export default MrSection;
