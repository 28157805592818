import React from 'react';
import pt from 'prop-types';

import { MrText } from '@ion/components';

import SelectOperation from './select-operation';
import s from './operation.module.scss';
import SelectSubOperation from './select-sub-operation';

// TODO: all operations and sub-operations should be managed from a single file, to prevent keys from getting out of sync
const labels = {
  newParam: 'How do you want to define this parameter?',
  default: 'Define the value we should send for this output key.',
  expression: {
    multiKey: 'Define the keys and values we should send as a result of this expression.',
  },
  mapping: {
    default: 'Define what transformation options you would like applied to this mapping.',
    directAssignment: 'Since you have a Direct Assign Mapping selected, there are no transform options.',
  },
};

const ParameterSubheader = ({ selectedOperation, selectedSubOperation, opId, _path }) => {
  let label;
  if (opId === 'newParam') {
    label = labels.newParam;
  } else {
    label = labels[selectedOperation];

    if (label && typeof label === 'object') {
      label = label[selectedSubOperation] || label.default;
    }
    if (!label) {
      label = labels.default;
    }
  }

  return (
    <div className={s.subHeader}>
      <MrText className={s.label} styleNames="light italic">
        {label}
      </MrText>
      <div className={s.select}>
        <div style={{ width: '12rem', marginRight: '1rem' }}>
          <SelectOperation
            name={`${opId}-operation-${_path}`}
            selectedOperation={selectedOperation}
            opId={opId}
            _path={_path}
          />
        </div>
        <div style={{ width: '12rem', marginRight: '1rem' }}>
          <SelectSubOperation
            name={`${opId}-subOperation-${_path}`}
            selectedOperation={selectedOperation}
            selectedSubOperation={selectedSubOperation}
          />
        </div>
      </div>
    </div>
  );
};

ParameterSubheader.propTypes = {
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
  selectedOperation: pt.string,
  selectedSubOperation: pt.string,
};

export default ParameterSubheader;
