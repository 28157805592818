import React from 'react';
import propTypes from 'prop-types';

import { KebabMenu } from '@metarouter-private/mr-mui';
import { useBuildMenuItems } from './use-build-menu-items';
import isAllowedToDeleteUser from './is-allowed-to-delete-user';

export default function UserKebabMenu({ row }) {
  const hasPermission = isAllowedToDeleteUser();
  const menuItems = useBuildMenuItems(row);
  return <KebabMenu disabled={!hasPermission} items={menuItems} testId="userKebabMenu" />;
}

UserKebabMenu.propTypes = {
  row: propTypes.shape({
    invitationUrl: propTypes.string,
  }).isRequired,
};
