import React from 'react';
import pt from 'prop-types';
import { components } from 'react-select';

import { flatMap as _flatMap } from 'lodash';

const ValueContainer = ({ ...props }) => {
  // eslint-disable-next-line react/prop-types
  const { value = [], options, optionName, placeholder } = props.selectProps;
  const flattendOptions = _flatMap(options, o => o.options);
  const selectedContent = `${value.length} ${optionName}`;

  return (
    <components.ValueContainer {...props}>
      {value.length === 0 && placeholder && placeholder}
      {value.length > 0 && value.length === flattendOptions.length && `All ${optionName}s (${value.length})`}
      {value.length !== 0 &&
        value.length !== flattendOptions.length &&
        (value.length === 1 ? selectedContent : `${selectedContent}s`)}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  props: pt.any,
};

export default ValueContainer;
