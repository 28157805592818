import React from 'react';
import cx from 'classnames';
import pt from 'prop-types';
import { upperFirst as _upperFirst, words as _words } from 'lodash';
import { singular } from 'pluralize';

import { MrInputText, MrText, MrH, formValidators, MrDropdown, MrInputCheckbox } from '@ion/components';

import { useFormContext } from 'app/context/form-context';

import validateKeyDuplicates from './validate-key-duplicates';
import s from './operation.module.scss';
import arrow from '../assets/icon-chevron.svg';
import togglePipelineVar from './toggle-pipeline-var';

const { validateRequired, validatePattern } = formValidators;

//TODO I would love to see this refactored out a bit into more composable components like a Var card and New Param Card
// For example 52-89 is all Var card logic
const ParameterHeader = ({
  isNewParam,
  showDeleteModal,
  navState,
  playbookState,
  selectedSubOperation,
  selectedOperation,
  isPipelineVar,
  pipelineVarId,
  inputKey,
  outputKey,
  isExpanded,
  opId,
  _path,
  name,
  expressionName,
  isAllowed,
}) => {
  const { validateField, resetError, formState, setField } = useFormContext();
  //These are unchangeable values
  const hasTwoKeys =
    selectedOperation !== 'enrichments' && selectedSubOperation !== 'multiKey' && selectedOperation !== 'spreadings';
  const friendlyName = selectedSubOperation === 'multiKey' || selectedOperation === 'spreadings';

  const opName = `${_upperFirst(singular(selectedOperation))} Name`;

  const expressionFormName = `${opId}-expressionName-${_path}`;
  const inputKeyFormName = `${opId}-inputKey-${_path}`;
  const outputKeyFormName = `${opId}-outputKey-${_path}`;

  const isSingleKey = selectedOperation === 'expressions' && selectedSubOperation === 'singleKey';
  const inputValue = isSingleKey ? 'input' : inputKey;

  return (
    <header
      className={cx(s.header, !isExpanded && s.cursor)}
      onClick={() => {
        if (!isExpanded) {
          navState.setTransformation(opId);
        }
      }}
    >
      {selectedOperation === 'params' && (
        <>
          <div className={s.title} key="title">
            <MrH h="h3" styleNames="noMargin ellipsis">
              {name}
            </MrH>
          </div>
          {togglePipelineVar(isExpanded, selectedOperation, name) && (
            <div className={s.pipelineVar} key="pipelineVar">
              {pipelineVarId && <input name={`${opId}-pipelineVarId-${_path}`} type="hidden" value={pipelineVarId} />}
              <MrInputCheckbox
                name={`${opId}-pipelineVar-${_path}`}
                label="Pipeline Variable?"
                onChange={setField}
                value={isPipelineVar}
                className={s.defaultToggle}
                switcher={true}
                labelPosition="right"
              />
            </div>
          )}
          <MrText className={s.selectedOperation} styleNames="italic light ellipsis" key="tag">
            {_path.split('.')[0]}
          </MrText>
        </>
      )}

      {!isExpanded && selectedOperation !== 'params' && (
        <>
          {friendlyName && (
            <div className={s.title} key="title">
              <MrText>{opName}:</MrText>
              <MrH h="h3" styleNames="noMargin ellipsis">
                {expressionName}
              </MrH>
            </div>
          )}
          {!friendlyName && (
            <div className={s.title} key="title">
              <MrText>Output Key:</MrText>
              <MrH h="h3" styleNames="noMargin ellipsis">
                {outputKey}
              </MrH>
            </div>
          )}
          <MrText className={s.selectedOperation} styleNames="italic light ellipsis" key="tag">
            <>
              {singular(selectedOperation)}{' '}
              {selectedSubOperation ? `/ ${_words(selectedSubOperation).join(' ')}` : null}
            </>
          </MrText>
        </>
      )}

      {isExpanded && selectedOperation !== 'params' && (
        <div
          key="keys"
          className={s.keys}
          style={{
            // display is controlled via style, as conditional rendering would result in child outputs being omitted from form state
            display: isExpanded ? 'flex' : 'none',
          }}
        >
          {friendlyName && (
            <div className={s.key}>
              <MrInputText
                name={expressionFormName}
                label={opName}
                value={expressionName}
                onChange={setField}
                errorMsg={formState[expressionFormName]?.errorMsg}
                onBlur={validateField}
                onInput={resetError}
                validators={[
                  validateRequired(),
                  validateKeyDuplicates(
                    'expressionName',
                    expressionName,
                    navState,
                    playbookState.getOperationsFromSection('global'),
                    playbookState.getOperationsFromSection('eventSpecific'),
                    playbookState.getOperationsFromSection('default')
                  ),
                ]}
                labelPosition="top"
                fullWidth
              />
            </div>
          )}

          {selectedOperation === 'spreadings' && (
            <div className={s.inputToOutput}>
              <span></span>
            </div>
          )}

          {selectedOperation !== 'enrichments' && selectedSubOperation !== 'multiKey' && (
            <>
              <div className={s.key}>
                <MrInputText
                  name={inputKeyFormName}
                  label="input key"
                  value={inputValue}
                  errorMsg={formState[inputKeyFormName]?.errorMsg}
                  onBlur={validateField}
                  onInput={resetError}
                  onChange={setField}
                  validators={[
                    validateRequired(),
                    validatePattern(/^[-_a-zA-Z0-9[\].]+$/, 'Only alphanumeric characters and - _ . [ ] are allowed'),
                  ]}
                  labelPosition="top"
                  readOnly={selectedOperation === 'expressions'}
                  fullWidth
                />
              </div>

              {hasTwoKeys && (
                <div className={s.inputToOutput}>
                  <span>→</span>
                </div>
              )}
            </>
          )}

          {!friendlyName && (
            <div className={s.key}>
              <MrInputText
                name={outputKeyFormName}
                label="output key"
                value={outputKey}
                errorMsg={formState[outputKeyFormName]?.errorMsg}
                onBlur={validateField}
                onInput={resetError}
                validators={[
                  validateRequired(),
                  validateKeyDuplicates(
                    'outputKey',
                    outputKey,
                    navState,
                    playbookState.getOperationsFromSection('global'),
                    playbookState.getOperationsFromSection('eventSpecific'),
                    playbookState.getOperationsFromSection('default')
                  ),
                  validatePattern(/^[-_a-zA-Z0-9[\].$]+$/, 'Only alphanumeric characters and - _ . [ ] $ are allowed'),
                ]}
                onChange={setField}
                labelPosition="top"
                fullWidth
              />
            </div>
          )}
        </div>
      )}

      {!isNewParam && isAllowed && (
        <>
          {selectedOperation !== 'params' && (
            // TODO: build menu dynamically and only display if menu.length > 0
            <MrDropdown>
              <ul>
                <li onClick={showDeleteModal}>Delete</li>
              </ul>
            </MrDropdown>
          )}

          <img
            src={arrow}
            className={cx(s.toggle, isExpanded && s.open)}
            title={isExpanded ? 'Editing' : 'Edit'}
            onClick={() => {
              if (isExpanded) {
                navState.setTransformation(null);
              }
            }}
          />
        </>
      )}
    </header>
  );
};

ParameterHeader.propTypes = {
  isExpanded: pt.bool.isRequired,
  selectedOperation: pt.string,
  selectedSubOperation: pt.string,
  pipelineVarId: pt.string,
  isPipelineVar: pt.bool,
  expressionName: pt.string,
  inputKey: pt.string,
  outputKey: pt.string,
  playbookState: pt.object.isRequired,
  isEvent: pt.bool,
  isNewParam: pt.bool,
  showDeleteModal: pt.func,
  navState: pt.object.isRequired,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
  name: pt.string,
  isAllowed: pt.bool.isRequired,
};

export default ParameterHeader;
