import { useMemo } from 'react';
import { useQuery } from '../index';
import { gql } from '@apollo/client';
import { transformConsentCategories } from './transform-consent-categories';
import consentCategory from './consent-category';
import permission from '../Apollo/permissions';

export const GET_CONSENT_CATEGORIES_AND_RELATIONSHIPS = gql`
  query ConsentCategoriesAndRelationships {
    consentCategories: consent_categories(where: { deletedAt: { _is_null: true } }, order_by: { createdAt: desc }) {
      ...consentCategory
      pipelineIntegrationConsents: pipeline_integration_consents_aggregate(
        where: { pipeline_integration: { deletedAt: { _is_null: true } } }
      ) {
        aggregate {
          count
        }
      }
      analyticsFileIdentitySyncs: analytics_file_identity_syncs_aggregate(
        where: { analytics_file: { deactivatedAt: { _is_null: true } } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
  ${consentCategory}
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, error, isAllowed } = useQuery(GET_CONSENT_CATEGORIES_AND_RELATIONSHIPS, {
    variables, // can apply any transforms or default vars here
    context: { permission: permission.readConsentCategory },
    fetchPolicy: 'network-only',
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  const transformedData = useMemo(() => transformConsentCategories(data), [data]);

  return {
    data: transformedData,
    loading,
    isAllowed,
    error,
  };
};
