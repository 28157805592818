import { gql } from '@apollo/client';
export default gql`
  fragment playbook on playbooks {
    id
    playbookJson
    connectionJson
    revision
    createdAt
    vars
    pipeline_variables {
      id
      playbookId
      name
    }
  }
`;
