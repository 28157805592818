import React from 'react';
import pt from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import s from './link.module.scss';

const MrLink = ({ to, href, className, component: Component = Link, styleNames, testId = 'MrLink', ...props }) => {
  // use <a /> tag instead of Link if external, etc
  if (!to || href) {
    Component = 'a';
  }

  const newProps = {
    ...props,
  };
  if (to) {
    newProps.to = to;
  } else if (href) {
    newProps.href = href;
    newProps.rel = 'noreferrer';
  }

  return (
    <Component
      to={to}
      className={cx(
        s.a,
        className,
        styleNames?.split(' ').map(c => s[c])
      )}
      data-test-id={testId}
      {...newProps}
    />
  );
};

MrLink.propTypes = {
  to: pt.oneOfType([pt.string, pt.object]), // for internal links
  href: pt.string, // for external links
  className: pt.string,
  styleNames: pt.string,
  component: pt.object,
  testId: pt.string,
};

export default MrLink;
