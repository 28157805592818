import React from 'react';
import propTypes from 'prop-types';
import { Avatar, Stack, Typography } from '@metarouter-private/mr-mui';

export default function NameCell({ name }) {
  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <Avatar sx={{ width: 32, height: 32 }} />
      <Typography>{name}</Typography>
    </Stack>
  );
}

NameCell.propTypes = {
  name: propTypes.string.isRequired,
};
