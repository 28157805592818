import React from 'react';
import pt from 'prop-types';
import { cloneDeep as _cloneDeep } from 'lodash';
import { MrH, MrInputSelectDeprecated } from '@ion/components';
import s from './index.module.scss';
import IntegrationLogo from 'app/components/integrations/integration-logo';
import IdentitySyncCard from './identity-sync-card/identity-sync-card';
import { BRING_YOUR_OWN_SYNCS_REQUEST_NAMES, CUSTOM_IDENTITY_SYNC } from './identity-sync-card/components/consts';
import { useStagedIdentitySyncs } from './useStagedIdentitySyncs';

const AnalyticsBuilderSyncs = ({
  formState,
  identitySyncs,
  stagedIdentitySyncsData = [],
  setField,
  validateField,
  validateRequired,
  validateNumber,
  resetError,
  setModalOpen,
}) => {
  const [stagedIdentitySyncs, addIdentitySyncs] = useStagedIdentitySyncs();

  // remove staged options from identity sync dropdowns
  const identitySyncOptions = identitySyncs.reduce((acc, i) => {
    if (!BRING_YOUR_OWN_SYNCS_REQUEST_NAMES.includes(i.name) && !stagedIdentitySyncs.includes(i.name)) {
      acc.push({
        value: i.name,
        text: (
          <div data-test-id={`${i.name}-integration`}>
            <IntegrationLogo id={i.name} type="dropdownLogo" />
            {i.friendlyName}
          </div>
        ),
      });
    }
    return acc;
  }, []);

  // Add the CUSTOM_IDENTITY_SYNC at the top of the list
  identitySyncOptions.unshift({
    disabled: stagedIdentitySyncs.includes(CUSTOM_IDENTITY_SYNC.REQUEST_NAME),
    value: CUSTOM_IDENTITY_SYNC.REQUEST_NAME,
    text: (
      <div data-test-id={`${CUSTOM_IDENTITY_SYNC.REQUEST_NAME}-integration`}>
        <IntegrationLogo id={CUSTOM_IDENTITY_SYNC.REQUEST_NAME} type="dropdownLogo" />
        {CUSTOM_IDENTITY_SYNC.DESCRIPTION}
      </div>
    ),
  });
  return (
    <section className={s.section}>
      <MrH h="h5" sans styleNames="sans noMargin">
        <div className={s.circle}>3</div> Identity Syncs
      </MrH>
      <p className={s.headerHelperText}>
        Identity Syncs will we be performed when the Sync Injector fires, according to the configuration settings you
        apply here. See our documentation for more information on how you can find and utilize the ID payload that each
        Identity Sync produces.
      </p>
      <div className={s.subSection}>
        {stagedIdentitySyncsData.map(sync => (
          <IdentitySyncCard
            formState={formState}
            key={sync.name}
            resetError={resetError}
            setField={setField}
            setModalOpen={setModalOpen}
            sync={sync}
            validateField={validateField}
            validateNumber={validateNumber}
            validateRequired={validateRequired}
          />
        ))}
        <div className={s.selectContainer}>
          <div className={s.selectTitle}>Add new Identity Sync</div>
          <MrInputSelectDeprecated
            testId="identitySyncs"
            className={s.inputSelect}
            name="identitySyncs"
            options={identitySyncOptions}
            placeholder="Select Identity Syncs..."
            value={stagedIdentitySyncs}
            isSearchable={true}
            onChange={e => {
              // add selected value to list of staged identity syncs
              const newStagedIdentitySyncs = _cloneDeep(stagedIdentitySyncs);
              newStagedIdentitySyncs.push(e.target.value);
              addIdentitySyncs(newStagedIdentitySyncs);
            }}
          />
        </div>
      </div>
    </section>
  );
};

AnalyticsBuilderSyncs.propTypes = {
  formState: pt.object,
  identitySyncs: pt.array,
  stagedIdentitySyncsData: pt.array,
  setField: pt.func,
  validateField: pt.func,
  validateRequired: pt.func,
  validateNumber: pt.func,
  resetError: pt.func,
  setModalOpen: pt.func,
};

export default AnalyticsBuilderSyncs;
