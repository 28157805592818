import { gql } from '@apollo/client';
import integrationFragment from '../integration';

export const mutation = gql`
  mutation UpdateIntegration(
    $integrations_pk_columns_input: integrations_pk_columns_input!
    $integrations_set_input: integrations_set_input!
  ) {
    update_integrations_by_pk(pk_columns: $integrations_pk_columns_input, _set: $integrations_set_input) {
      ...integration
    }
  }
  ${integrationFragment}
`;

export function buildVariables({ integrationId, name, starterkitVersion }) {
  return {
    integrations_pk_columns_input: {
      id: integrationId,
    },
    integrations_set_input: {
      ...(name && { name }),
      ...(starterkitVersion && { starterkitVersion }),
    },
  };
}
