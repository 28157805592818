import React, { useMemo } from 'react';

import pt from 'prop-types';
import { MrInputMultiSelect } from '@ion/components';
import { isEmpty as _isEmpty } from 'lodash';

const PipelineSelect = ({ pipelines, setFilterPipelines, setShow, loadingDetails }) => {
  const options = useMemo(() => {
    const pipelineOptions = pipelines.map(p => ({
      value: p.writekey,
      label: p.name ? p.name : p.writekey,
    }));
    return [
      {
        label: 'Select all',
        options: pipelineOptions,
      },
    ];
  }, [pipelines]);

  const selectPipeline = options => {
    if (_isEmpty(options)) {
      setShow(false);
      setFilterPipelines([]);
      return;
    }

    const addPipelines = options.map(o => o.value);
    setShow(true);
    setFilterPipelines(addPipelines);
  };

  return (
    <>
      <MrInputMultiSelect
        name="pipelineMultiSelect"
        options={options}
        onChange={e => {
          selectPipeline(e.target.value);
        }}
        optionName="Pipeline"
        defaultValue={options[0].options}
        disableOptions={loadingDetails}
      />
    </>
  );
};

PipelineSelect.propTypes = {
  pipelines: pt.arrayOf(
    pt.shape({
      writekey: pt.string.isRequired,
      name: pt.string,
    })
  ).isRequired,
  setFilterPipelines: pt.func.isRequired,
  filteredPipelines: pt.arrayOf(pt.string),
  setShow: pt.func.isRequired,
  loadingDetails: pt.bool.isRequired,
};

export default PipelineSelect;
