import pt from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

import { useQueryConsentCategoriesAndRelationships } from '@ion/api';

const DEFAULT_DATA_CONSENT_CATEGORIES_TABLE = {
  consentCategories: [],
  selectedCategory: {},
  isOpenModal: false,
  modalContentOption: null,
  onOpenModal: () => {},
  onCloseModal: () => {},
};
const ConsentCategoriesTableContextValue = createContext(DEFAULT_DATA_CONSENT_CATEGORIES_TABLE);

export const ConsentCategoriesTableContext = ({ children }) => {
  const { data } = useQueryConsentCategoriesAndRelationships();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalContentOption, setModalContentOption] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});
  const onOpenModal = (modalOption, category) => {
    setIsOpenModal(true);
    setModalContentOption(modalOption);
    setSelectedCategory(category);
  };
  const onCloseModal = () => {
    setIsOpenModal(false);
    setModalContentOption(null);
    setSelectedCategory({});
  };
  return (
    <ConsentCategoriesTableContextValue.Provider
      value={{ selectedCategory, isOpenModal, modalContentOption, onOpenModal, onCloseModal, consentCategories: data }}
    >
      {children}
    </ConsentCategoriesTableContextValue.Provider>
  );
};

export const useConsentCategoriesTableContext = () => {
  return useContext(ConsentCategoriesTableContextValue);
};
ConsentCategoriesTableContext.propTypes = {
  children: pt.node.isRequired,
};
