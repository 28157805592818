import { isEmpty, isNil, isNull, isUndefined, pick } from 'lodash';
import buildSyntheticDomEvent from '../../utils/build-synthetic-dom-event';
import transformOptions from './transform-options';

// js styles applied to the Select component
import reactSelectStyles from './input-select.styles';
import { normalizeOption } from './normalize-options';

const CLASS_NAME_PREFIX = 'react-select';

export default function buildSelectProps(props) {
  const transformedOptions = transformOptions(props.options, {
    alphabetized: props.alphabetized ?? true,
  });

  const selectProps = {
    classNamePrefix: CLASS_NAME_PREFIX,
    ...buildBaseProps(props),
    options: transformedOptions,
    placeholder: getPlaceholder(props.placeholder),
    styles: reactSelectStyles,
    isDisabled: props.disabled ?? false,
    isClearable: props.isClearable ?? false,
    isSearchable: props.isSearchable ?? false,
    onChange: buildOnChange(props),
  };

  return addOptionalProps({ selectProps, props, transformedOptions });
}

function addOptionalProps({ selectProps, props, transformedOptions }) {
  const finalProps = { ...selectProps };
  const value = props.value;

  // If value is not defined, then we are controlling the react-select component.
  // Otherwise the react-select component is storing the selected component state.
  if (!isUndefined(value)) {
    if (isNull(value) || isEmpty(value)) {
      finalProps.value = null;
    } else {
      finalProps.value = getSelectedValue({ options: transformedOptions, value: props.value });
    }
  }

  if (!isUndefined(props.defaultValue)) {
    finalProps.defaultValue = normalizeOption(props.defaultValue);
  }

  const { closeMenuOnScroll, menuPosition } = props;
  if (!isUndefined(closeMenuOnScroll) || !isUndefined(menuPosition)) {
    finalProps.closeMenuOnScroll = buildCloseMenuOnScroll({
      closeMenuOnScroll,
      menuPosition,
    });
  }

  return finalProps;
}

function buildBaseProps(props) {
  return pick(props, ['onBlur', 'onFocus', 'menuPosition', 'maxMenuHeight']);
}

function getSelectedValue({ options, value }) {
  return options.find(option => {
    return !isNil(option.value) && option.value === value;
  });
}

function getPlaceholder(placeholder) {
  return placeholder === true ? `Select...` : placeholder;
}

function buildOnChange({ name, onChange }) {
  return selectedElement => {
    const value = !isNull(selectedElement) ? selectedElement.value : null;
    const syntheticEvent = buildSyntheticDomEvent({ name, value });
    onChange(syntheticEvent);
  };
}

export function buildCloseMenuOnScroll({ closeMenuOnScroll, menuPosition }) {
  // Default to closeOnMenuScroll if menuPosition is fixed
  // because there are bugs if the parent scrolls and menuPosition is fixed.
  const defaultCloseOnScroll = isUndefined(closeMenuOnScroll) && menuPosition === 'fixed';

  /*
    Hack! This is a hack because we are on
    an old version of react-select that has
    a lot of issues around menu placement and
    scrolling and what not.

    In the version we are on, the closeMenuOnScroll
    also closes the menu if the menu itself is scrolling.

    This hack prevents the menu from closing if th menu
    itself is scrolling.

    To fix this hack, we should upgrade to the latest
    version of react-select. Or wait until we can
    replace with MUI's select.

    https://github.com/JedWatson/react-select/issues/4088
   */
  if (closeMenuOnScroll === true || defaultCloseOnScroll) {
    // if the CLASS_NAME_PREFIX is in the className, then the react-select
    // menu is scrolling so we don't want to close.
    return event => {
      // There was a Cypress test case where `event.target` wasn't defined
      // for an unknown reason.
      const className = event?.target?.className;
      if (isNil(className)) {
        return true;
      }
      return !className.includes(CLASS_NAME_PREFIX);
    };
  }
  return closeMenuOnScroll;
}
