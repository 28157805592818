import React from 'react';
import pt from 'prop-types';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useUserContext } from '@ion/user';
import { authLink, httpLink, loggerLink, roleLink, onErrorLink, retryLink } from './links';
import { setToken } from './allower';
import connectedPipelines from '../clusters/connectedPipelines';

const staging = window?.location.hostname === 'localhost';

const dev = staging || process.env.NODE_ENV !== 'production';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        integrations: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        pipelines: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    clusters: {
      fields: {
        pipelines: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        connectedPipelines: {
          read(existing, { readField }) {
            return connectedPipelines(readField);
          },
        },
      },
    },
    playbooks: {
      fields: {
        staged_revisions: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

let notify = {
  /* eslint-disable no-unused-vars*/
  success: ({ title = '', message = '' }) => {},
  warning: ({ title = '', message = '' }) => {},
  error: ({ title = '', message = '' }) => {},
  /* eslint-disable no-unused-vars*/
};

const links = options => [authLink(options), roleLink(options?.hasRoles), onErrorLink, retryLink, httpLink];

// setup your client
const client = options => {
  const allLinks = links(options);
  if (dev) allLinks.unshift(loggerLink);

  return new ApolloClient({
    cache,
    link: ApolloLink.from(allLinks),
  });
};

const Apollo = ({ children, notifications }) => {
  // passed into links
  // can set custom headers or define functionality for errorHandling, etc.
  const { sessionToken, hasRoles } = useUserContext();
  const options = { sessionToken, hasRoles };
  notify = notifications;
  setToken(sessionToken);
  return <ApolloProvider client={client(options)}>{children}</ApolloProvider>;
};

Apollo.propTypes = {
  children: pt.element,
  notifications: pt.shape({
    success: pt.func,
    error: pt.func,
    warning: pt.func,
  }),
};

export { Apollo as default, notify };
