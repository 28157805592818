import { useReducer } from 'react';
import { produce } from 'immer';

import { keys as _keys, isEmpty as _isEmpty, assign as _assign, cloneDeep as _cloneDeep, set as _set } from 'lodash';
import { generateId } from '@ion/components';
import { getOperations } from './get-operations-from-section';
import { replaceEventNameOnClone } from './replace-event-name-on-clone';
import { PLAYBOOK_STANZA } from './constants';

const actions = {
  SET_REVISION: Symbol(),
  CREATE_EVENT: Symbol(),
  SET_RAW_PLAYBOOK: Symbol(),
  UPDATE_PARAMETER: Symbol(),
  DELETE_PARAMETER: Symbol(),
  CREATE_NEW_PARAMETER_FOR_SECTION: Symbol(),
  CANCEL_NEW_PARAMETER_FOR_SECTION: Symbol(),
  PROMOTE_NEW_PARAMETER_TO_PLAYBOOK: Symbol(),
  DELETE_EVENT: Symbol(),
  SET_FILTERS: Symbol(),
};

const initialState = {
  hasChanges: false,
  newParameters: {
    eventSpecific: {},
  },
  baseRevisionId: null,
  pipelineVars: null,
  connectionJSON: null,
  playbook: null,
  changes: [],
  opIndices: {
    global: 0,
    eventSpecific: 0,
    default: 0,
    connection: 0,
    playbook: 0,
  },
};

const reducer = produce((state, { type, payload }) => {
  switch (type) {
    case actions.UPDATE_PARAMETER:
      if (payload.section === 'eventSpecific') {
        const idx = state.playbook[payload.section][payload.eventName][payload.existing.attachmentKey].findIndex(
          ({ opId }) => opId === payload.parameter.opId
        );
        state.playbook[payload.section][payload.eventName][payload.existing.attachmentKey].splice(idx, 1);
        state.playbook[payload.section][payload.eventName][payload.attachmentKey].push(payload.parameter);
      }

      if (payload.section === 'default' || payload.section === 'global') {
        const idx = state.playbook[payload.section][payload.existing.attachmentKey].findIndex(
          ({ opId }) => opId === payload.parameter.opId
        );
        state.playbook[payload.section][payload.existing.attachmentKey].splice(idx, 1);
        state.playbook[payload.section][payload.attachmentKey].push(payload.parameter);
      }

      if (payload.section === 'connection' || payload.section === 'connection/playbook') {
        const connectionType = Object.keys(state.connectionJSON)[0];
        const idx = state.connectionJSON[connectionType].params.findIndex(
          ({ opId }) => opId === payload.parameter.opId
        );
        state.connectionJSON[connectionType][payload.attachmentKey].splice(idx, 1);
        state.connectionJSON[connectionType][payload.attachmentKey].push({
          ...payload.parameter,
          _path: `connection.${payload.attachmentKey}`,
        });
      }

      if (payload.section === 'playbook' || payload.section === 'connection/playbook') {
        const idx = state.playbook[payload.attachmentKey].findIndex(({ opId }) => opId === payload.parameter.opId);
        state.playbook[payload.attachmentKey].splice(idx, 1);
        state.playbook[payload.attachmentKey].push({
          ...payload.parameter,
          _path: `playbook.${payload.attachmentKey}`,
        });
      }
      state.hasChanges = true;
      break;
    case actions.DELETE_PARAMETER: {
      //TODO this has to clear out any pipeline vars since that model is tricky
      // Actually we are good here so long as you can't delete a Var Need to Modify ParameterHeader Line 265 for that to be true
      // We might be able to implement delete which is fine but you wont be able to recover
      let operations;
      if (payload.section === 'eventSpecific') {
        operations = state.playbook[payload.section][payload.event][payload.operationType];
      } else {
        operations = state.playbook[payload.section][payload.operationType];
      }
      operations.forEach((operation, index) => {
        if (operation.opId === payload.opId) {
          operations.splice(index, 1);
        }
      });
      state.opIndices[payload.section] = state.opIndices[payload.section] - 1;
      state.hasChanges = true;
      break;
    }
    case actions.CREATE_NEW_PARAMETER_FOR_SECTION: {
      const newParamDefaultState = {
        opId: 'newParam',
        opIndex: state.opIndices[payload.section] + 1,
        _path: payload.section,
        event: payload.eventName,
        attachmentKey: null,
      };
      if (payload.section === 'eventSpecific') {
        state.newParameters.eventSpecific[payload.eventName] = newParamDefaultState;
      } else {
        state.newParameters[payload.section] = newParamDefaultState;
      }
      state.opIndices[payload.section] = newParamDefaultState.opIndex;
      break;
    }
    case actions.CANCEL_NEW_PARAMETER_FOR_SECTION:
      if (payload.section === 'eventSpecific') {
        state.newParameters.eventSpecific[payload.eventName] = null;
      } else {
        state.newParameters[payload.section] = null;
      }
      state.opIndices[payload.section] = state.opIndices[payload.section] - 1;
      break;
    case actions.PROMOTE_NEW_PARAMETER_TO_PLAYBOOK:
      payload.parameter.opId = generateId();
      payload.parameter._path = payload.section + '.' + payload.attachmentKey;

      if (payload.section === 'eventSpecific') {
        delete state.newParameters.eventSpecific[payload.eventName];
        state.playbook[payload.section][payload.eventName][payload.attachmentKey].push(payload.parameter);
      } else {
        delete state.newParameters[payload.section];
        state.playbook[payload.section][payload.attachmentKey].push(payload.parameter);
      }
      state.hasChanges = true;
      break;
    case actions.CREATE_EVENT: {
      const sortedEvents = _keys(state.playbook.eventSpecific).sort((a, b) => {
        return (
          state.playbook.eventSpecific[a].metadata.eventIndex - state.playbook.eventSpecific[b].metadata.eventIndex
        );
      });
      const lastEvent = sortedEvents[sortedEvents.length - 1];
      const maxIndex = state.playbook.eventSpecific[lastEvent]?.metadata?.eventIndex;
      const nextEventIndex = _isEmpty(sortedEvents) ? 1 : maxIndex + 1;

      const cloneFrom = _cloneDeep(state.playbook.eventSpecific[payload.cloneFrom]);
      const cloned = _assign(cloneFrom, { metadata: { eventIndex: nextEventIndex } });
      const replaced = replaceEventNameOnClone(cloned, payload.eventName);
      const parameters =
        payload.cloneFrom !== ''
          ? replaced
          : {
              mappings: [],
              expressions: [],
              spreadings: [],
              filters: [],
              enrichments: [],
              metadata: {
                eventIndex: nextEventIndex,
              },
            };

      state.playbook.eventSpecific[payload.eventName] = parameters;
      state.hasChanges = true;
      break;
    }
    case actions.DELETE_EVENT:
      //TODO this has to clear out any pipeline vars since that model is tricky
      // Actually maybe not need to see if any params are living down on this level
      delete state.playbook.eventSpecific[payload];
      state.hasChanges = true;
      break;
    case actions.SET_REVISION:
      state.playbook = payload.playbook;
      state.connectionJSON = payload.connection;
      state.baseRevisionId = payload.revisionID;
      state.opIndices = payload.opIndices;
      state.hasChanges = false;
      break;
    case actions.SET_FILTERS: {
      const cloned = _cloneDeep(state.playbook);
      state.playbook = _set(cloned, `${payload.filterPath}.${PLAYBOOK_STANZA.FILTERS}`, payload.newFilters);
      state.hasChanges = true;
      break;
    }
  }
  return state;
});

const usePlaybookState = () => {
  const [state, dispatch] = useReducer(reducer, Object.assign(initialState));

  const deleteParameter = (section, event, operationType, opId) => {
    dispatch({
      type: actions.DELETE_PARAMETER,
      payload: {
        section,
        event,
        operationType,
        opId,
      },
    });
  };

  const updateParameter = (section, eventName, attachmentKey, parameter, existing) => {
    return dispatch({
      type: actions.UPDATE_PARAMETER,
      payload: {
        section,
        eventName,
        parameter,
        attachmentKey,
        existing,
      },
    });
  };

  const createNewParameterForSection = (section, eventName) => {
    dispatch({
      type: actions.CREATE_NEW_PARAMETER_FOR_SECTION,
      payload: {
        section,
        eventName,
      },
    });
  };

  const cancelNewParameterForSection = (section, eventName) => {
    dispatch({
      type: actions.CANCEL_NEW_PARAMETER_FOR_SECTION,
      payload: {
        section,
        eventName,
      },
    });
  };

  /**
   * hydrate formData into playbookData when new param is added,
   * @param {{ section, eventName, operation }}
   * @returns {{newOperationId}} the new transform Id
   */
  const promoteNewParameterToPlaybook = (section, eventName, attachmentKey, parameter) => {
    return dispatch({
      type: actions.PROMOTE_NEW_PARAMETER_TO_PLAYBOOK,
      payload: {
        section,
        eventName,
        parameter,
        attachmentKey,
      },
    });
  };

  const createEvent = (eventName, cloneFrom) => {
    dispatch({
      type: actions.CREATE_EVENT,
      payload: {
        eventName,
        cloneFrom,
      },
    });
  };

  const deleteEvent = eventName => {
    dispatch({
      type: actions.DELETE_EVENT,
      payload: eventName,
    });
  };

  const getOperationsFromSection = section => {
    return getOperations(section, state);
  };

  const getSortedEvents = () => {
    return _keys(state.playbook.eventSpecific).sort((a, b) => {
      return state.playbook.eventSpecific[a].metadata.eventIndex - state.playbook.eventSpecific[b].metadata.eventIndex;
    });
  };

  const setRevision = (playbook, connection, revisionID, opIndices) => {
    return dispatch({
      type: actions.SET_REVISION,
      payload: {
        playbook,
        connection,
        revisionID,
        opIndices,
      },
    });
  };

  const setFilters = (filterPath, newFilters) => {
    return dispatch({
      type: actions.SET_FILTERS,
      payload: {
        filterPath,
        newFilters,
      },
    });
  };

  return {
    createEvent,
    deleteEvent,
    createNewParameterForSection,
    cancelNewParameterForSection,
    promoteNewParameterToPlaybook,
    getSortedEvents,
    deleteParameter,
    updateParameter,
    getOperationsFromSection,
    setRevision,
    setFilters,
    ...state,
  };
};

export default usePlaybookState;
