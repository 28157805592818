import React, { useEffect, useCallback } from 'react';
import { isEmpty as _isEmpty } from 'lodash';

import { MrAppLayout, MrAppMain } from '@ion/components';
import { useQueryClusters, useQueryIntegrations } from '@ion/api';

import SideNav from 'app/components/side-nav';
import Header from 'app/components/app-header';

import DashboardHeader from 'app/dashboard/header/index';
import useDashboardState from 'app/dashboard/state';

import DashboardDataTable from './table';

import DashboardInlineCards from './inline-cards';

const DashboardPage = () => {
  const state = useDashboardState();
  const { data: clusters, loading: loadingClusters } = useQueryClusters();
  const { data: integrations, loading: loadingIntegrations } = useQueryIntegrations();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setClusterOptionsCallback = useCallback(state.setClusterOptions, []);

  useEffect(() => {
    setClusterOptionsCallback(clusters);
  }, [clusters, setClusterOptionsCallback]);

  return (
    <MrAppLayout
      title="Dashboard"
      description="Manage your data pipelines."
      sideNav={<SideNav />}
      header={<Header icon="dashboard" title="Dashboard" />}
    >
      <MrAppMain
        loading={loadingClusters && loadingIntegrations && _isEmpty(state.metricsData)}
        styleNames="list"
        header={
          <DashboardHeader
            setQueryParameter={state.setQueryParameter}
            clusterOptions={state.clusterOptions}
            since={state.dateRange.since}
            until={state.dateRange.until}
            groupBy={state.groupBy}
            setGroupBy={state.setGroupBy}
            configuredClusters={state.configuredClusters}
            configuredPipelines={state.configuredPipelines}
            integrations={integrations}
            isLoadingMetrics={state.isLoadingMetrics}
            noDataComponentMessage={state.noDataComponentMessage}
          />
        }
      >
        <DashboardInlineCards
          clusters={state.clusters}
          pipelines={state.pipelines}
          since={state.dateRange.since}
          until={state.dateRange.until}
          groupBy={state.groupBy}
          metricsData={state.metricsData}
        />
        <DashboardDataTable
          metricsData={state.metricsData}
          integrations={integrations}
          setMetrics={state.setMetrics}
          fetchMetrics={state.fetchMetrics}
          loadingMetrics={state.loadingMetrics}
          setError={state.setError}
          isLoading={state.isLoadingMetrics}
          setEventMetrics={state.setEventMetrics}
          setEventMetricsError={state.setEventMetricsError}
          eventMetricsError={state.eventMetricsError}
          since={state.dateRange.since}
          until={state.dateRange.until}
          expandedRows={state.expandedRows}
          setExpandedRows={state.setExpandedRows}
          noDataComponentMessage={state.noDataComponentMessage}
          groupBy={state.groupBy}
          clusters={state.clusters}
          pipelines={state.pipelines}
          integrationIds={state.integrationIds}
          configuredClusters={state.configuredClusters}
          filterCount={state.filterCount}
          defaultFilter={state.defaultFilter}
        />
      </MrAppMain>
    </MrAppLayout>
  );
};

export default DashboardPage;
