import React from 'react';
import propTypes from 'prop-types';

import { MrDataTable } from '../../index';

import customStyles from './table-styles/custom-styles';
import styles from './grouped-data-table.module.scss';

export default function MrGroupedDataTable({ mainTableProps, buildSubTableProps }) {
  return (
    <MrDataTable
      {...mainTableProps}
      expandableRows={true}
      expandableRowsComponent={row => {
        const subTableProps = buildSubTableProps(row);
        return <MrDataTable className={styles.subTable} customStyles={customStyles} {...subTableProps} />;
      }}
    ></MrDataTable>
  );
}

MrGroupedDataTable.propTypes = {
  mainTableProps: propTypes.object,
  buildSubTableProps: propTypes.func,
};
