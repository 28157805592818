const formatIntegration = name => {
  const starterkitId = name?.replace('-forwarder', '');
  const formattedName = starterkitId
    ?.split('-')
    ?.map(word => word[0].toUpperCase() + word.substring(1))
    ?.join(' ');
  return { starterkitId, formattedName };
};

export default formatIntegration;
