import React from 'react';

export default function Integrations() {
  return (
    <svg width="24" height="24" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 19H13C7.06008 19 12 11 1 11C12 11 7.2 3 13 3H15M15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17C15.8954 17 15 17.8954 15 19ZM15 3C15 4.10457 15.8954 5 17 5C18.1046 5 19 4.10457 19 3C19 1.89543 18.1046 1 17 1C15.8954 1 15 1.89543 15 3ZM10 11H15M15 11C15 12.1046 15.8954 13 17 13C18.1046 13 19 12.1046 19 11C19 9.89543 18.1046 9 17 9C15.8954 9 15 9.89543 15 11Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
