import React from 'react';

import EmailField from './EmailField';
import FormRoleSelect from './FormRoleSelect';

export default function UserFormFields() {
  return (
    <>
      <EmailField />
      <FormRoleSelect />
    </>
  );
}
