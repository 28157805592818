import React from 'react';
import proptypes from 'prop-types';
import { useTransformEvent } from '@ion/api';
import { Drawer, Tabs } from '@metarouter-private/mr-mui';

import { useTransformPlaybookContext } from '../../context/transform-playbook-context';

import DrawerHeader from './components/DrawerHeader';
import InputEditor from './components/InputEditor';
import { OutputEditor } from './components/OutputEditor';
import useTrackWithUser from '@ion/api/hooks/useTrackWithUser';

export const TransformDrawer = ({ isOpen, onClose }) => {
  const { input, clusterId, setIsLoading, playbook, setOutput, setOutputErrors, hasValidInput } =
    useTransformPlaybookContext();

  const [trackOutputEventViewed] = useTrackWithUser('output_event_viewed');

  const [transformEvent] = useTransformEvent({
    onCompleted: ({ errors, data }) => {
      setIsLoading(false);
      if (errors) {
        setOutputErrors(errors);
      } else {
        setOutputErrors(null);
        setOutput(JSON.stringify(data.outputEvent));
      }
    },
    onError: () => {
      // TODO: handle other type of errors
      setIsLoading(false);
    },
  });

  const disabledOutputTab = !clusterId || !hasValidInput;

  const onClickOutputTab = () => {
    setIsLoading(true);
    transformEvent({ playbook: playbook, inputEvent: JSON.parse(input), clusterId: clusterId });
    trackOutputEventViewed();
  };

  return (
    <Drawer
      sx={{
        // Add a width to see the behavior of the header, it can be deleted when the drawer have the editors
        '& .MuiDrawer-paper': {
          padding: 3,
          width: 800,
        },
      }}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <DrawerHeader onCloseDrawer={onClose} />
      <Tabs
        tabs={[
          {
            label: 'Input Event',
            content: <InputEditor />,
          },
          {
            label: 'Output Event',
            content: <OutputEditor />,
            TabProps: {
              disabled: disabledOutputTab,
              onClick: onClickOutputTab,
            },
          },
        ]}
      />
    </Drawer>
  );
};
TransformDrawer.propTypes = {
  isOpen: proptypes.bool.isRequired,
  onClose: proptypes.func.isRequired,
};
