import React from 'react';
import pt from 'prop-types';

import { components } from 'react-select';

import groupHandler from './handler';

const Group = props => {
  const {
    options: groupOption,
    children,
    headingProps,
    selectProps: { options, onChange, value },
  } = props;
  const hasSelectedOption = children.some(opt => opt.props.isSelected);

  const onClick = () => onChange(groupHandler(hasSelectedOption, groupOption, options, value));

  return <components.Group {...props} headingProps={{ onClick, ...headingProps, hasSelectedOption }} />;
};

Group.propTypes = {
  selectProps: pt.shape({
    onChange: pt.func,
    options: pt.array,
    value: pt.array,
  }),
  options: pt.array,
  children: pt.array,
  headingProps: pt.object,
};

export default Group;
