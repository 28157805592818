import { useEffect } from 'react';
import Hotjar from '@hotjar/browser';
import { useUserContext } from '@ion/user';

const useIdentifyHotJarUser = () => {
  const { currentOrg, user } = useUserContext();
  useEffect(() => {
    if (Hotjar.isReady() && user?.email && currentOrg) {
      Hotjar.identify(user.email, {
        name: user.name,
        org: currentOrg?.display_name,
      });
    }
  }, [currentOrg, user]);
};

export default useIdentifyHotJarUser;
