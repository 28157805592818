import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { FormFieldContext, FormEmailField } from '@metarouter-private/mr-mui';
import { useUserModalContext } from '../../context/UserModalContextProvider';

const commonEmailFieldProps = {
  name: 'email',
  label: 'Email address',
  required: true,
};

export default function EmailField() {
  const { selectedUser } = useUserModalContext();

  const formEmailFieldProps = {
    ...commonEmailFieldProps,
    ...(!isEmpty(selectedUser) && {
      disabled: true,
      defaultValue: selectedUser.email,
    }),
  };

  return (
    <FormFieldContext {...formEmailFieldProps}>
      <FormEmailField />
    </FormFieldContext>
  );
}
