import React from 'react';

import ActionCell from '../cells/action-cell';
import RevisionCell from '../cells/RevisionCell';
import IntegrationCell from '../cells/integration/cell';
import ConsentCategoryCell from '../cells/ConsentCategoryCell';
import PipelineVariablesCell from '../cells/pipeline-variables';
import { conditionalCellStyles } from '../conditional-cell-styles';

export const columns = [
  {
    name: 'Integration Name',
    selector: row => row.integration.name,
    cell: row => <IntegrationCell integration={row.integration} />,
    conditionalCellStyles: conditionalCellStyles,
  },
  {
    name: 'Revision',
    cell: row => <RevisionCell {...row} />,
    conditionalCellStyles: conditionalCellStyles,
  },
  {
    name: 'Pipeline Variables',
    selector: row => <PipelineVariablesCell {...row} />,
    conditionalCellStyles: conditionalCellStyles,
  },
];

export const ActionColumn = {
  cell: row => <ActionCell {...row} />,
  button: true,
  width: '77px',
};

export const CategoryColumn = {
  name: 'Consent Category',
  selector: row => <ConsentCategoryCell {...row} />,
  conditionalCellStyles: conditionalCellStyles,
};
