import { gql } from '@apollo/client';

export const mutation = gql`
  mutation DeleteUserOrganizationMembers($userIds: [String]!) {
    auth0_delete_organization_members(userIds: $userIds) {
      status
    }
  }
`;

export function buildVariables({ userIds }) {
  return {
    userIds,
  };
}
