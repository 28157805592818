import React from 'react';

import { PipelineIntegrationModalProvider } from '../pipeline-integrations/context/pipeline-integration-modal-context';
import PipelineIntegrations from '../pipeline-integrations';

import pt from 'prop-types';
import { GatedConsentCategoriesContext } from '../../context/gated-consent-categories-context';

const SwitchPipelineCardFeature = ({ deployedRevisions, pipeline }) => {
  return (
    <GatedConsentCategoriesContext>
      <PipelineIntegrationModalProvider>
        <PipelineIntegrations deployedRevisions={deployedRevisions} pipeline={pipeline} />
      </PipelineIntegrationModalProvider>
    </GatedConsentCategoriesContext>
  );
};

SwitchPipelineCardFeature.propTypes = {
  deployedRevisions: pt.array.isRequired,
  pipeline: pt.object,
};

export default SwitchPipelineCardFeature;
