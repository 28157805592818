import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './inline-icon-card.module.scss';
import { MrIcon, MrIconTooltip } from '../../index';

const MrInlineIconCard = ({
  className,
  testId = 'MrInlineIconCard',
  description,
  children,
  leftIcon,
  rightIcon,
  tooltip,
}) => {
  return (
    <div className={cx(s.inlineIconCard, className)} data-test-id={testId}>
      {leftIcon && <MrIcon id={leftIcon} className={s.leftIcon} />}
      <div className={s.container}>
        <div className={s.description}>
          {description}
          {tooltip && (
            <div>
              <MrIconTooltip {...tooltip} />
            </div>
          )}
        </div>
        <div className={s.children}>{children}</div>
      </div>
      {rightIcon && <MrIcon id={rightIcon} className={s.rightIcon} />}
    </div>
  );
};

MrInlineIconCard.propTypes = {
  className: pt.string,
  testId: pt.string,
  description: pt.string,
  children: pt.element,
  leftIcon: pt.string,
  rightIcon: pt.string,
  tooltip: pt.shape({
    Icon: pt.node,
    message: pt.string.isRequired,
    position: pt.string,
  }),
};

export default MrInlineIconCard;
