import { cloneDeep as _cloneDeep, keys as _keys } from 'lodash';

// top-level playbook keys
const sections = ['global', 'default'];

// children of top-level keys
const subSections = ['mappings', 'enrichments', 'expressions', 'spreadings', 'vars'];

/**
 *
 * Maps over all playbook operations and event objects using the provided functions
 * and preserves any other existing properties (author, params, etc.)
 *
 * @returns {object} Playbook JSON
 */
const mapOperations = ({ playbook: p, opFunction, eventFunction }) => {
  const playbook = _cloneDeep(p);

  sections.forEach(section => {
    subSections.forEach(subSection => {
      if (opFunction && playbook[section] && playbook[section][subSection]) {
        const updated = playbook[section][subSection].map(opFunction);
        playbook[section][subSection] = updated;
      }
    });
  });

  _keys(playbook.eventSpecific).forEach(key => {
    if (eventFunction) {
      playbook.eventSpecific[key] = eventFunction(playbook.eventSpecific[key]);
    }

    subSections.forEach(subSection => {
      if (opFunction && playbook.eventSpecific[key][subSection]) {
        const updated = playbook.eventSpecific[key][subSection].map(opFunction);
        playbook.eventSpecific[key][subSection] = updated;
      }
    });
  });

  playbook.params = playbook?.params?.map(opFunction);

  return playbook;
};

const playbookTemplate = {
  version: '0',
  author: 'MetaRouter',
  global: {},
  eventSpecific: {},
  default: {},
  params: [],
};

export { mapOperations, sections, subSections, playbookTemplate };
