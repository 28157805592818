import actions from '../actions';
import { generateTimestamp } from '../generate-timestamp';

export function buildAuditLog({ consentCategoryId, name, code }) {
  const action = actions.consent_category_deleted;
  const description = `${code} - ${name}`;
  const debug_data = {
    consentCategoryId,
    consentCategoryName: name,
  };
  const timestamp = generateTimestamp();
  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
