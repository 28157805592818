import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './tooltip-deprecated.module.scss';

const MrTooltipDeprecated = ({ children, className, testId = 'MrTooltipDeprecated', text = '', styleNames = '' }) => {
  return (
    <div
      className={cx(
        s.background,
        styleNames.split(' ').map(c => s[c])
      )}
      data-test-id={testId}
    >
      {children}
      <span className={cx(s.text, className)}>{text}</span>
    </div>
  );
};

MrTooltipDeprecated.propTypes = {
  children: pt.node,
  className: pt.string,
  testId: pt.string,
  text: pt.string,
  styleNames: pt.string,
};

export default MrTooltipDeprecated;
