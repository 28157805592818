import React from 'react';
import pt from 'prop-types';

import CountParameters from './count-parameters';
import s from './index.module.scss';

const EventMappingsTab = ({ playbookState }) => {
  return (
    <>
      Event Mappings
      <span className={s.circle}>{CountParameters(playbookState.playbook, playbookState.connectionJSON)}</span>
    </>
  );
};

EventMappingsTab.propTypes = {
  playbookState: pt.object.isRequired,
};

export default EventMappingsTab;
