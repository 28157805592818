import { useReducer, useCallback } from 'react';
import { produce } from 'immer';
import formatIntegration from './table/cells/integration/format-integration';
import {
  isEmpty as _isEmpty,
  flatten as _flatten,
  uniq as _uniq,
  forEach as _forEach,
  reduce as _reduce,
  has as _has,
} from 'lodash';
import getPipelineAndClusterOptions from './get-pipeline-and-cluster-options';
import { dateRange } from './date-range';
import { alphabetizeBy } from '@ion/mrdash';
import getDefaultOptions from './header/get-default-options';
import multiplePipelineNames from './multiple-pipeline-names';
import GROUP_BY_OPTIONS from './group-by-options';
import { customDebounce, mapDebounceSeconds } from './custom-debounce';
import NO_DATA_COMPONENT_MESSAGE from './no-data-component-message';
import isValidInput from './is-valid-input';
import DROP_REASONS from './drop-reasons';

const actions = {
  SET_METRICS: Symbol('SET_METRICS'),
  LOADING_METRICS: Symbol('LOADING_METRICS'),
  SET_QUERY_PARAMETER: Symbol('SET_QUERY_PARAMETER'),
  SET_ERROR: Symbol('SET_ERROR'),
  SET_EVENT_METRICS: Symbol('SET_EVENT_METRICS'),
  SET_EVENT_METRICS_ERROR: Symbol('SET_EVENT_METRICS_ERROR'),
  SET_EXPANDED_ROWS: Symbol('SET_EXPANDED_ROWS'),
  SET_CLUSTER_OPTIONS: Symbol('SET_CLUSTER_OPTIONS'),
  SET_GROUP_BY: Symbol('SET_GROUP_BY'),
};

const initialState = {
  metricsData: [],
  isLoadingMetrics: false,
  clusters: [],
  pipelines: [],
  error: '',
  eventMetricsError: {},
  dateRange: dateRange,
  expandedRows: [],
  cli: {},
  clusterOptions: [],
  groupBy: GROUP_BY_OPTIONS.INTEGRATION,
  configuredClusters: [],
  configuredPipelines: [],
  integrationIds: [],
  filterCount: 0,
  defaultFilter: true,
  noDataComponentMessage: null,
};

const reducer = produce((state, { type, payload }) => {
  const isGroupByIntegration = state.groupBy === GROUP_BY_OPTIONS.INTEGRATION;
  const isGroupByPipeline = state.groupBy === GROUP_BY_OPTIONS.PIPELINE;
  switch (type) {
    case actions.SET_METRICS:
      state.isLoadingMetrics = false;
      if (_isEmpty(payload.metrics) && _isEmpty(state.error)) {
        state.noDataComponentMessage = NO_DATA_COMPONENT_MESSAGE.NO_DATA;
        return;
      }
      state.metricsData = isGroupByIntegration
        ? getIntegrationMetrics(payload.metrics, payload.integrations, payload.filteredMetrics, state)
        : getPipelineMetrics(payload.metrics, getDefaultOptions(state.clusterOptions), payload.filteredMetrics);
      break;
    case actions.LOADING_METRICS:
      state.noDataComponentMessage = null;
      state.isLoadingMetrics = true;
      state.eventMetricsError = {};
      resetState(state);
      break;
    case actions.SET_QUERY_PARAMETER:
      _forEach(payload, (data, name) => {
        state[name] = data;
      });

      if (
        !isValidInput({
          clusters: state.clusters,
          pipelines: state.pipelines,
          since: state.dateRange.since,
          until: state.dateRange.until,
        })
      ) {
        state.noDataComponentMessage = NO_DATA_COMPONENT_MESSAGE.MAKE_SELECTION;
        resetState(state);
        return;
      }

      if (isGroupByPipeline) {
        if (_has(payload, 'dateRange')) {
          state.integrationIds = [];
        }

        // Deselect all
        if (_has(payload, 'integrationIds') && _isEmpty(state.integrationIds)) {
          resetState(state);
          return;
        }
      }

      state.isLoadingMetrics = true;
      state.noDataComponentMessage = null;
      break;
    case actions.SET_ERROR: {
      state.error = payload.error;
      const errorMessage = NO_DATA_COMPONENT_MESSAGE[state.error];
      if (errorMessage) {
        state.noDataComponentMessage = errorMessage;
      }
      break;
    }
    case actions.SET_EVENT_METRICS:
      state.metricsData = state.metricsData.map(d => {
        if (d.Name === payload.selectedRow) {
          if (_isEmpty(payload.data) && _isEmpty(payload.filteredMetrics)) {
            state.eventMetricsError[payload.selectedRow] = NO_DATA_COMPONENT_MESSAGE.NO_DATA;
            return { ...d, eventData: [] };
          }
          return { ...d, eventData: getEventData(payload.data, payload.filteredMetrics) };
        }
        return d;
      });
      break;
    case actions.SET_EVENT_METRICS_ERROR:
      state.eventMetricsError[payload.integrationName] = payload.error;
      break;
    case actions.SET_EXPANDED_ROWS:
      state.expandedRows = payload.data;
      break;
    case actions.SET_CLUSTER_OPTIONS:
      state.clusterOptions = getClusterOptions(payload.clusters, state);
      if (_isEmpty(state.clusters) && _isEmpty(state.pipelines)) {
        state.noDataComponentMessage = NO_DATA_COMPONENT_MESSAGE.NO_DATA;
      }
      break;
    case actions.SET_GROUP_BY:
      state.groupBy = payload.data;
      state.dateRange = dateRange;
      state.clusters = state.configuredClusters;
      state.pipelines = state.configuredPipelines;
      if (state.groupBy === GROUP_BY_OPTIONS.INTEGRATION) {
        state.integrationIds = [];
        state.filterCount = getDefaultOptions(state.clusterOptions).length;
      }

      if (
        isValidInput({
          clusters: state.clusters,
          pipelines: state.pipelines,
          since: state.dateRange.since,
          until: state.dateRange.until,
        })
      ) {
        state.noDataComponentMessage = null;
        state.isLoadingMetrics = true;
      } else {
        state.noDataComponentMessage = NO_DATA_COMPONENT_MESSAGE.NO_DATA;
        state.isLoadingMetrics = false;
      }
      resetState(state);
      state.defaultFilter = true;
      break;
  }
  return state;
});

const useDashboardState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setMetrics = (metrics, integrations, filteredMetrics) => {
    dispatch({
      type: actions.SET_METRICS,
      payload: { metrics, integrations, filteredMetrics },
    });
  };

  const loadingMetrics = () => {
    dispatch({ type: actions.LOADING_METRICS });
  };

  const debouncedSetQueryParameter = customDebounce(data => {
    dispatch({
      type: actions.SET_QUERY_PARAMETER,
      payload: data,
    });
  }, mapDebounceSeconds);

  const setQueryParameter = useCallback(debouncedSetQueryParameter, []);

  const setError = error => {
    dispatch({
      type: actions.SET_ERROR,
      payload: { error },
    });
  };

  const setEventMetrics = (selectedRow, data, filteredMetrics) => {
    dispatch({
      type: actions.SET_EVENT_METRICS,
      payload: {
        selectedRow,
        data,
        filteredMetrics,
      },
    });
  };

  const setEventMetricsError = (integrationName, error) => {
    dispatch({
      type: actions.SET_EVENT_METRICS_ERROR,
      payload: {
        integrationName,
        error,
      },
    });
  };

  const setExpandedRows = data => {
    dispatch({
      type: actions.SET_EXPANDED_ROWS,
      payload: { data },
    });
  };

  const setClusterOptions = clusters => {
    dispatch({
      type: actions.SET_CLUSTER_OPTIONS,
      payload: { clusters },
    });
  };

  const setGroupBy = data => {
    dispatch({
      type: actions.SET_GROUP_BY,
      payload: { data },
    });
  };

  return {
    setMetrics,
    loadingMetrics,
    setQueryParameter,
    setError,
    setEventMetrics,
    setEventMetricsError,
    setExpandedRows,
    setClusterOptions,
    setGroupBy,
    ...state,
  };
};

const getClusterOptions = (clusters, state) => {
  const options = clusters
    .filter(({ pipelines, metricNames }) => pipelines.length !== 0 && !_isEmpty(metricNames))
    .map(cluster => {
      return {
        label: cluster.name,
        options: cluster.pipelines.map(pipeline => {
          return {
            label: pipeline.name,
            value: `${cluster.metricNames}&${pipeline.writekey}`,
          };
        }),
      };
    });

  const flattenedValues = _flatten(options.map(o => o.options)).map(({ value }) => value);
  const { clusters: initialClusters, pipelines: initialPipelines } = getPipelineAndClusterOptions(flattenedValues);
  state.clusters = initialClusters;
  state.pipelines = initialPipelines;

  state.configuredClusters = initialClusters;
  state.configuredPipelines = initialPipelines;
  state.filterCount = flattenedValues.length;
  return options;
};

const getIntegrationFilteredMetrics = (filteredMetrics, integrationId) => {
  const filteredMetricsMap = {};
  const filtered = filteredMetrics?.filter(({ facet }) => facet[1] === integrationId);

  filtered?.forEach(filteredMetric => {
    const reason = filteredMetric.facet[2];
    const filteredEvents = filteredMetric.FilteredEvents;
    filteredMetricsMap[reason] = filteredEvents;
  });

  return sumConsents(filteredMetricsMap);
};

const sumConsents = filteredMetricsMap => {
  return _reduce(
    filteredMetricsMap,
    (result, filteredEvents, reason) => {
      if (reason === DROP_REASONS.CONSENT_DEPRECATED && !result[DROP_REASONS.CONSENT]) {
        result[DROP_REASONS.CONSENT] = filteredEvents;
        return result;
      }

      if (
        (reason === DROP_REASONS.CONSENT && result[DROP_REASONS.CONSENT]) ||
        (reason === DROP_REASONS.CONSENT_DEPRECATED && result[DROP_REASONS.CONSENT])
      ) {
        result[DROP_REASONS.CONSENT] = result[DROP_REASONS.CONSENT] + filteredEvents;
        return result;
      }

      result[reason] = filteredEvents;

      return result;
    },
    {}
  );
};

const getIntegrationMetrics = (metrics, integrations, filteredMetrics, state) => {
  const result = metrics.map(metric => {
    const forwarder = metric.facet[0];
    const integrationId = metric.facet[1];
    const integration = integrations?.find(integration => integration.id === integrationId);

    const { starterkitId: kitId, formattedName } = formatIntegration(forwarder);
    if (!integration) {
      state.cli = { ...state.cli, [kitId]: state.cli[kitId] ? state.cli[kitId] + 1 : 1 };
    }
    const name = integration ? integration.name : `${formattedName} - CLI Managed (${state.cli[kitId]})`;
    const starterkitId = integration ? integration.starterkitId : kitId;

    const filtered = getIntegrationFilteredMetrics(filteredMetrics, integrationId);

    return { ...metric, eventData: [], Name: name, StarterkitId: starterkitId, ...filtered };
  });

  return result;
};

const getPipelineMetrics = (metrics, connectedPipelines, filteredMetrics) => {
  const writeKeyMap = multiplePipelineNames(connectedPipelines);

  const result = metrics.map(metric => {
    const writekey = metric.writeKey;
    const pipelines = _uniq(writeKeyMap[writekey]);
    const filtered = getPipelineFilteredMetrics(filteredMetrics, writekey);
    return { ...metric, eventData: [], Name: pipelines.join(', '), ...filtered };
  });
  return result;
};

const getPipelineFilteredMetrics = (filteredMetrics, writekey) => {
  const filteredMetricsMap = {};
  const filtered = filteredMetrics?.filter(({ facet }) => facet[0] === writekey);
  filtered?.forEach(filteredMetric => {
    const reason = filteredMetric.facet[1];
    const filteredEvents = filteredMetric.FilteredEvents;
    filteredMetricsMap[reason] = filteredEvents;
  });

  return sumConsents(filteredMetricsMap);
};

export function alphabetizeEventNames(data) {
  return alphabetizeBy(data, 'eventName');
}

const mergeEventMetrics = duplicateEventMetrics => {
  return duplicateEventMetrics.reduce((acc, metric) => {
    const found = acc?.find(a => a.eventName === metric.eventName);
    if (!found) {
      acc.push(metric);
    } else {
      const uniq = acc.filter(a => a.eventName !== metric.eventName);
      acc = [...uniq, { ...found, ...metric }];
    }
    return acc;
  }, []);
};

const getEventData = (eventMetrics, filteredMetrics) => {
  const reMapFilteredMetrics = filteredMetrics.map(filteredMetric => {
    const reason = filteredMetric.facet[1];
    return {
      facet: null,
      FilteredEvents: 0,
      eventName: filteredMetric.facet[0],
      [reason]: filteredMetric.FilteredEvents,
    };
  });
  const result = mergeEventMetrics([...reMapFilteredMetrics, ...eventMetrics]).map(r => sumConsents(r));
  return alphabetizeEventNames(result);
};

function resetState(state) {
  state.error = '';
  state.metricsData = [];
  state.expandedRows = [];
  state.cli = {};
  state.eventMetricsError = {};
}

export default useDashboardState;
