import { useMemo } from 'react';
import { useLazyQuery } from './../index';
import { GET_USER_ORGANIZATIONS } from './useQueryUserOrganizations';

export default () => {
  const [getUserOrganizations, { data, loading, error }] = useLazyQuery(GET_USER_ORGANIZATIONS, {});

  const transformedData = useMemo(() => {
    return !data?.auth0_user_orgs ? [] : data.auth0_user_orgs.organizations;
  }, [data]);

  return [
    getUserOrganizations,
    {
      data: transformedData,
      loading,
      error,
    },
  ];
};
