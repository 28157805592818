import { useEffect } from 'react';
import { useUserContext } from '@ion/user';

const usePage = pathname => {
  const { user } = useUserContext();
  useEffect(() => {
    if (user) {
      window?.analytics?.page({
        userEmail: user.email,
      });
    }
  }, [pathname, user]);
};

export default usePage;
