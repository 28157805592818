import React, { Component, Fragment } from 'react';
import store from './store';
import s from './index.module.scss';
import { MrButton, MrH, MrModal } from '@ion/components';

class MrGlobalModal extends Component {
  state = {
    notification: null,
    show: false,
  };

  componentDidMount() {
    store.register({
      addNotification: this.add,
      removeNotification: this.remove,
    });
  }

  add = notification => {
    this.setState({ notification: notification, show: true });
  };

  remove = () => {
    this.setState({ notification: null, show: false });
  };

  renderNotification = () => {
    const notification = this.state.notification;
    const title = notification?.title ?? 'Error';
    const message =
      notification?.message ?? 'Unable to perform action. If the issue keeps happening, contact customer support.';
    const code = notification?.code;

    if (notification) {
      return (
        <MrModal closeModal={this.remove} styleNames="basic">
          <div className={s.header}>
            <MrH h="h3" sans styleNames="noMargin">
              {title}
            </MrH>
          </div>
          <div className={s.content}>
            {message}
            {code && <pre style={{ inlineSize: 380, overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>{code}</pre>}
            <div style={{ textAlign: 'center', margin: 'auto', width: '33.33%' }}>
              {notification.button && notification.button.title && notification.button.onClick && (
                <MrButton
                  text={notification.button.title}
                  styleNames="marginTop"
                  onClick={notification.button.onClick}
                />
              )}
            </div>
          </div>
        </MrModal>
      );
    }
  };

  render() {
    return <Fragment>{this.renderNotification()}</Fragment>;
  }
}

export default MrGlobalModal;
