import React from 'react';
import propTypes from 'prop-types';
import { ModalLauncher } from '@metarouter-private/mr-mui';
import EditUserButton from './EditUserButton';
import UserModal from '../../modal/UserModal';

export default function EditUser({ row }) {
  return <ModalLauncher Launcher={EditUserButton} Modal={UserModal} modalProps={row} />;
}

EditUser.propTypes = {
  row: propTypes.object.isRequired,
};
