import React, { useMemo } from 'react';
import propTypes from 'prop-types';

import styles from './input-radio-group-error.module.scss';
import generateId from '../../utils/uuid';

export default function InputRadioGroupError({ message }) {
  const id = useMemo(() => generateId(), []);
  return (
    <div className={styles.errorMsg} id={id}>
      {message}
    </div>
  );
}

InputRadioGroupError.propTypes = {
  message: propTypes.string.isRequired,
};
