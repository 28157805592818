import { gql } from '@apollo/client';

export const mutation = gql`
  mutation BuildAjsFile($settingsJson: jsonb!) {
    build_ajs_file(settingsJson: $settingsJson) {
      status
      data
    }
  }
`;

export function buildVariables({ settingsJson }) {
  return {
    settingsJson,
  };
}
