import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@metarouter-private/mr-mui';

export default function InvitationConfirmDeleteMessage({ email }) {
  return (
    <Typography align="left" variant="body1" color="text.secondary">
      Are you sure you want to delete the invitation for{' '}
      <Typography fontWeight="700" component="span">
        {email}
      </Typography>
      ? This action cannot be undone.
    </Typography>
  );
}

InvitationConfirmDeleteMessage.propTypes = {
  email: PropTypes.string,
};
