import { useMemo } from 'react';
import { useMutation, useQueryRoles } from '../../index';
import { mutation } from './auth0-edit-organization-member-mutation-builder';
import { buildVariables as buildEditOrganizationMemberVariables } from './auth0-edit-organization-member-mutation-builder';
import {
  mutation as userRoleEditedMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/user-role-edited/audit-log-mutation-builder';
import permission from '../../Apollo/permissions';
import mergeQueries from '../../Apollo/merge-queries';
import mapAuth0UserRoleData from './map-auth0-user-role-data';
import { ORGANIZATION_MEMBERS_QUERY_NAME } from './auth0-organization-members-query-builder';

export const combinedMutation = mergeQueries('EditOrganizationMemberAndAudit', [mutation, userRoleEditedMutation]);

export default function useEditOrganizationMember({ onCompleted, ...options } = {}) {
  const {
    data: { auth0_roles },
    loading: auth0RolesLoading,
  } = useQueryRoles();
  const [executeMutation, response] = useMutation(combinedMutation, {
    context: { permission: permission.updateUsers },
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(getResponseBody({ data }));
      }
    },
    refetchQueries: [ORGANIZATION_MEMBERS_QUERY_NAME],
    ...options,
  });

  const roleListMap = useMemo(
    () => Object.assign({}, ...auth0_roles.map(role => ({ [role.name]: role.id }))),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth0RolesLoading]
  );

  const editOrganizationMember = ({ user, roleId, name, email }) => {
    const { roleIds, roleName } = mapAuth0UserRoleData(roleListMap, roleId);
    return executeMutation({
      variables: buildVariables({ user, roleName, roleIds, name, email }),
    });
  };

  return [editOrganizationMember, response];
}

function getResponseBody(response) {
  return response.data.auth0_edit_organization_member;
}

export function buildVariables({ user, roleName, roleIds, name, email }) {
  return {
    ...buildEditOrganizationMemberVariables({ userId: user.id, roleIds, name, email }),
    ...buildAuditLogVariables({ email: user.email, oldRole: user.roles[0]?.name, newRole: roleName }),
  };
}
