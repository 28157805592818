import React, { useState } from 'react';
import { useConsentCategoriesTableContext } from '../../../../context/consentCategoriesTableContext';
import { useRemoveConsentCategory } from '@ion/api';
import { MrButton, MrButtonList, MrErrorModal, MrH, MrLink, MrText } from '@ion/components';

export const DeleteCategory = () => {
  const [errorMsg, setErrorMsg] = useState(false);
  const { onCloseModal, selectedCategory } = useConsentCategoriesTableContext();
  const [deleteCategory] = useRemoveConsentCategory({
    onCompleted: () => {
      onCloseModal();
    },
    onError: err => {
      setErrorMsg(err?.errorMsg);
    },
  });
  const onDeleteCategory = () => {
    deleteCategory({
      consentCategoryId: selectedCategory.id,
      name: selectedCategory.name,
      code: selectedCategory.code,
    });
  };

  return (
    <>
      <div className="header">
        <MrH h="h2" sans styleNames="noMargin">
          {`Delete ${selectedCategory.displayName}?`}
        </MrH>
      </div>

      <div className="content">
        <MrText styleNames="text">
          Please confirm that you want to permanently delete
          <strong>{` ${selectedCategory.displayName}`}</strong>
        </MrText>

        <MrButtonList
          styleNames="marginTop"
          buttons={[
            <MrButton
              testId="deleteCategoryButton"
              text="Delete"
              key="create"
              styleNames="red"
              onClick={onDeleteCategory}
            />,
            <MrLink onClick={onCloseModal} styleNames="gray" key="cancel">
              Cancel
            </MrLink>,
          ]}
        />
      </div>
      {errorMsg && (
        <MrErrorModal
          header="Error Deleting Consent Category"
          errorMessage={errorMsg}
          closeModal={() => setErrorMsg(false)}
        />
      )}
    </>
  );
};
