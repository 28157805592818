export default {
  REQUEST_NAME: 'urlParameters',
  SINGLE_SELECT_DESCRIPTION: 'URL Parameters',
  REQUEST_INPUT_NAME: 'urlParameters',
  DESCRIPTION:
    'Collects multiple URL parameters form the page URL and sends them to the server. Each URL parameter can be stored for X amount of time.',
  FIELDS: [
    {
      type: 'TEXT',
      property: 'parameter',
      title: 'Parameter Name',
      description: 'URL parameter in the query string',
      required: true,
      default: '',
      placeholder: 'e.g., gclid',
    },
    {
      type: 'NUMBER',
      property: 'timeToLive',
      title: 'Lifetime (in days)',
      description: 'How long do you want this parameter to exists even if it is not present in the URL?',
      required: true,
      default: 14,
      placeholder: 14,
    },
  ],
};
