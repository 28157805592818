import React from 'react';
import pt from 'prop-types';

import { MrLink } from '@ion/components';

import { routes } from 'app/constants';

import s from './index.module.scss';
import NavIcon from 'app/components/nav-icon';

const PipelineSection = ({ title }) => {
  return (
    <div className={s.section}>
      <NavIcon icon="clusters" />
      <MrLink styleNames="gray" to={routes.clusters} className={s.title} title={`Configure the ${title} cluster`}>
        {title}
      </MrLink>
    </div>
  );
};

PipelineSection.propTypes = {
  title: pt.string.isRequired,
  configure: pt.bool,
};

export default PipelineSection;
