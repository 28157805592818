import { useLazyQueryOrganizationMembers } from '@ion/api';

export function useOrganizationMembersTable() {
  const [getOrganizationMembers, { members, pagination, loading }] = useLazyQueryOrganizationMembers();

  return {
    onFetchPage: getOrganizationMembers,
    data: members,
    pagination,
    loading,
  };
}
