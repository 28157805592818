const preventUpload = (fileName, extension, result, playbookState, clusterId) => {
  const extensions = ['json', 'yml', 'yaml'];
  const connectionType = Object.keys(playbookState.connectionJSON)[0];

  if (extensions.indexOf(extension) === -1) {
    return `json or yaml are available for file type`;
  }

  if (fileName !== 'playbook' && fileName !== 'connection') {
    return `playbook or connection is only available for file name`;
  }

  // check if the file is invalid format(eg pipeline)
  const { writeKey, integrations, connection, endpointSchema, integrationName, pipelines } = result;
  if (writeKey || integrations || connection || endpointSchema || integrationName || pipelines) {
    return 'Uploaded file is invalid playbook format';
  }

  const { version } = result;

  if (fileName === 'playbook') {
    if (!version) return 'version is a required field for playbook';
  }

  // SDK connection kits
  if (!version && connectionType === 'connectionTemplate' && fileName === 'connection') {
    return 'SDK connection is not editable';
  }

  if (!version && connectionType === 'endpointSchema' && fileName === 'connection') {
    // HTTP, make sure default field exists
    if (result.default === undefined) {
      return 'Default field in endpoint schema is required';
    }
  }

  if (!clusterId) {
    return 'You need at least one available cluster';
  }

  return null;
};

export default preventUpload;
