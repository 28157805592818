import React from 'react';
import { MrText } from '../../index';

export function buildEllipsisGenerator({ items, props }) {
  function Ellipsis(node) {
    const renderedItems = node.props.children;
    return (
      <MrText component="span" {...props}>
        {`...(${items.length - renderedItems.length})`}
      </MrText>
    );
  }

  return Ellipsis;
}
