import React, { useState, useEffect } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { MrCard, MrH, MrDropdown, MrTooltipDeprecated, MrPill } from '@ion/components';
import { useRemoveIntegration, useQueryPipelines, useGetPipeline } from '@ion/api';

import IntegrationLogo from '../../components/integrations/integration-logo';
import hasDeployedIntegrations from './has-deployed-integrations';
import hasPipelineIntegrations from './has-pipeline-integrations';

import ConfirmDelete from 'app/components/confirm-delete/confirm-delete';
import Revision from 'app/components/revision';
import { routes } from 'app/constants';
import StarterKits from '../../starterkits/starterkits-state';

import s from '../index.module.scss';

const IntegrationCard = ({ integration }) => {
  const history = useHistory();
  const [hasPipelineIntegration, setHasPipelineIntegration] = useState(false);
  const [modalOpen, setModalOpen] = useState();
  const [removeIntegration, { isAllowed: deleteIntegrationPermission }] = useRemoveIntegration();
  const { data: pipelines } = useQueryPipelines();
  const [getDeployedPipeline] = useGetPipeline();
  const { kits, kitById } = StarterKits();
  const kit = kitById(kits, integration?.starterkitId);
  const isBeta = kit?.metadata?.isBeta;

  useEffect(() => {
    const hasPipelineIntegration = hasPipelineIntegrations(integration.id, pipelines);

    if (hasPipelineIntegration) {
      setHasPipelineIntegration(hasPipelineIntegration);
    }
  }, [pipelines, integration.id]);

  const deleteIntegrationIfNotDeployed = (pipes, integration) => {
    const getDeployedPipePromises = pipes.map(p => {
      return getDeployedPipeline({ clusterId: p.clusterId, writekey: p.writekey }).then(r => {
        return r;
      });
    });
    Promise.allSettled(getDeployedPipePromises).then(results => {
      const deployedPipes = results.map(r => r.value);
      if (!hasDeployedIntegrations(integration.id, deployedPipes)) {
        removeIntegration({ integrationId: integration.id, name: integration.name });
      } else {
        // TODO show an error informing the user that the Integration is still deployed and can't be deleted
        console.error(`INTEGRATION ${integration.id} IS DEPLOYED`);
      }
    });
  };

  return (
    <>
      <MrCard
        className={cx(s.card, s.integrationCard)}
        styleNames="link"
        onClick={() =>
          history.push(routes.editPlaybook, {
            integrationId: integration.id,
            playbookId: integration?.playbooks[0]?.id,
          })
        }
      >
        {isBeta && (
          <MrTooltipDeprecated className={s.tooltip} text="In Beta - avoid using in production, some bugs may occur.">
            <MrPill className={s.pill} text="BETA"></MrPill>
          </MrTooltipDeprecated>
        )}

        {deleteIntegrationPermission && (
          <MrDropdown className={s.cardDropdown}>
            <ul>
              <li
                onClick={e => {
                  e.stopPropagation();
                  setModalOpen(true);
                }}
              >
                Delete
              </li>
            </ul>
          </MrDropdown>
        )}

        <IntegrationLogo id={integration.starterkitId} className={s.logo} />

        <div className={s.truncated}>
          <MrH h="h3" className={s.integrationName} sans styleNames="ellipsis noMargin">
            {integration.name}
          </MrH>

          <Revision revision={integration.playbooks[0]?.revision} type="playbook" />
        </div>
      </MrCard>

      {modalOpen && (
        <ConfirmDelete
          disableDelete={hasPipelineIntegration}
          deleteFn={() => deleteIntegrationIfNotDeployed(pipelines, integration)}
          closeModal={() => {
            setModalOpen(false);
          }}
        >
          <div className={s.header}>
            <MrH h="h3" sans styleNames="noMargin">
              Delete {integration.name}?
            </MrH>
          </div>
          <div className={s.content}>
            {hasPipelineIntegration && (
              <p>
                Unable to delete integrations that are attached to pipelines. Please remove this integration from all
                pipelines first and return to delete.
              </p>
            )}
            {!hasPipelineIntegration && (
              <p>
                Deleting the <strong>{integration.name}</strong> integration cannot be undone, and all data, including
                revisions, will be lost.
              </p>
            )}
          </div>
        </ConfirmDelete>
      )}
    </>
  );
};

IntegrationCard.propTypes = {
  integration: pt.object,
};

export default IntegrationCard;
