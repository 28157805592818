import { gql } from '@apollo/client';
import pipelineIntegration from '../pipelineIntegration';
import { pipelineIntegrationConsent, stagedRevision } from './mutation-builder-variables';

/*
  TODO (CHRIS 2024.02.14) -
  Hack! Hasura lacks support for nested/related object updates. 
  https://github.com/hasura/graphql-engine/issues/1573

  We are using Upsert as a workaround to allow dynamic updates of nested objects.
  If Hasura ever supports this properly in the future, we should migrate to that solution.
 */

export const mutation = gql`
  mutation UpdatePipelineIntegration($pipeline_integrations_insert_input: pipeline_integrations_insert_input!) {
    insert_pipeline_integrations_one(
      object: $pipeline_integrations_insert_input
      on_conflict: { constraint: active_pipeline_integration, update_columns: [pipelineId] }
    ) {
      ...pipelineIntegration
    }
  }
  ${pipelineIntegration}
`;

export function buildVariables({
  pipelineIntegration,
  playbookId,
  pipelineVars,
  readyForDeploy,
  stagedForDelete,
  consentCategoryId,
}) {
  const {
    pipelineId,
    integrationId,
    stagedRevision: { id: stagedRevisionId },
  } = pipelineIntegration;
  const pipelineIntegrationConsentId = pipelineIntegration.pipelineIntegrationConsent?.id;
  return {
    pipeline_integrations_insert_input: {
      pipelineId,
      integrationId,
      ...(consentCategoryId && pipelineIntegrationConsent({ pipelineIntegrationConsentId, consentCategoryId })),
      ...stagedRevision({ stagedRevisionId, playbookId, pipelineVars, readyForDeploy, stagedForDelete }),
    },
  };
}
