import React from 'react';
import cx from 'classnames';
import pt from 'prop-types';

import { MrH } from '@ion/components';
import s from './index.module.scss';

const ParametersSideNav = ({ navState, playbookState }) => {
  const sortedEvents = playbookState.getSortedEvents();

  //TODO need to refactor scroll to
  const scrollTo = ({ opId }) => {
    navState.setTransformation(opId);
    // setExpandedCard({ opId, scrollTo: true })
  };

  const getNavText = ({ outputKey, expressionName, name }) => {
    if (outputKey) {
      return outputKey;
    }
    if (name) return name;

    if (expressionName?.length > 0) {
      return expressionName;
    }

    // placeholder for when no nav text is available, such as when changing to / from multi-key expression
    return '---';
  };

  return (
    <nav className={s.nav}>
      <div onClick={() => navState.setSection('params', sortedEvents)} className={s.header}>
        <MrH h="h4" sans>
          1. Parameters
        </MrH>
      </div>
      {navState.selectedSection === 'params' && (
        <ul>
          {playbookState.getOperationsFromSection('params').map(({ opId, name, isOptional }) => {
            return (
              <li
                className={cx(s.ellipsis, opId === navState.selectedTransformation ? s.current : null)}
                key={opId}
                onClick={() => {
                  scrollTo({ opId });
                }}
              >
                {getNavText({ name })}
                {!isOptional && <span className={s.required}>*</span>}
              </li>
            );
          })}
        </ul>
      )}

      <div onClick={() => navState.setSection('global', sortedEvents)} className={s.header}>
        <MrH h="h4" sans>
          2. Global
        </MrH>
      </div>

      {navState.selectedSection === 'global' && (
        <ul>
          {playbookState.getOperationsFromSection('global').map(({ outputKey, expressionName, opId, name }) => {
            return (
              <li
                className={cx(s.ellipsis, opId === navState.selectedTransformation ? s.current : null)}
                key={opId}
                onClick={() => {
                  scrollTo({ opId });
                }}
              >
                {getNavText({ outputKey, expressionName, name })}
              </li>
            );
          })}
        </ul>
      )}

      <div className={s.header} onClick={() => navState.setSection('eventSpecific', sortedEvents)}>
        <MrH h="h4" sans>
          3. Event Specific
        </MrH>
      </div>

      {navState.selectedSection === 'eventSpecific' && (
        <ul>
          {sortedEvents.map(event => (
            <li
              key={event}
              className={cx(s.ellipsis, navState.selectedEvent === event ? s.current : null)}
              onClick={() => navState.setEvent(event)}
            >
              {event}
            </li>
          ))}
          <li
            className={'newEvent' === navState.selectedEvent ? s.current : null}
            onClick={() => navState.setEvent('newEvent')}
          >
            New Event
          </li>
        </ul>
      )}

      <div className={s.header} onClick={() => navState.setSection('default', sortedEvents)}>
        <MrH h="h4" sans>
          4. Default
        </MrH>
      </div>

      {navState.selectedSection === 'default' && (
        <ul>
          {playbookState.getOperationsFromSection('default').map(({ outputKey, expressionName, opId }) => {
            return (
              <li
                className={cx(s.ellipsis, opId === navState.selectedTransformation ? s.current : null)}
                key={opId}
                onClick={() => {
                  scrollTo({ opId });
                }}
              >
                {getNavText({ outputKey, expressionName })}
              </li>
            );
          })}
        </ul>
      )}
    </nav>
  );
};

ParametersSideNav.propTypes = {
  navState: pt.shape({
    selectedSection: pt.string.isRequired,
    selectedEvent: pt.string,
    selectedTransformation: pt.string,
    setTransformation: pt.func,
    setEvent: pt.func,
    setSection: pt.func,
  }).isRequired,
  playbookState: pt.object.isRequired,
};

export default ParametersSideNav;
