import { gql } from '@apollo/client';

export default gql`
  fragment consentCategory on consent_categories {
    id
    name
    code
    createdAt
    orgId
  }
`;
