import React from 'react';
import pt from 'prop-types';

import { MrCard } from '@ion/components';

import styles from '../index.module.scss';
import { CardBody } from './components';
import IntegrationTableTitle from '../integration-table-title/IntegrationTableTitle';

const IdentitySyncCard = ({
  formState,
  resetError,
  setField,
  setModalOpen,
  sync,
  validateField,
  validateNumber,
  validateRequired,
}) => {
  const { name: syncName, friendlyName: syncFriendlyName, fields } = sync;

  const onClickDelete = () => {
    setModalOpen({ data: sync, type: 'Identity Sync' });
  };

  return (
    <MrCard
      className={styles.builderCard}
      top={
        <IntegrationTableTitle
          logoName={syncName}
          title={syncFriendlyName}
          onClickDelete={onClickDelete}
          deleteTitle='"Remove Staged Identity Sync"'
        />
      }
      bottom={
        <CardBody
          syncName={syncName}
          fields={fields}
          setField={setField}
          validateField={validateField}
          validateNumber={validateNumber}
          validateRequired={validateRequired}
          formState={formState}
          resetError={resetError}
        />
      }
    />
  );
};

IdentitySyncCard.propTypes = {
  formState: pt.object,
  resetError: pt.func,
  setField: pt.func,
  setModalOpen: pt.func,
  sync: pt.object.isRequired,
  validateField: pt.func,
  validateNumber: pt.func,
  validateRequired: pt.func,
};

export default IdentitySyncCard;
