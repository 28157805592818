import includes from 'lodash/includes';
import { READABLE_ACTION_TO_ACTION, READABLE_ACTIONS } from './actions';

export function searchTextToActions(searchText) {
  const lowerCaseSearchText = searchText.toLowerCase();

  const matchingReadableActions = READABLE_ACTIONS.filter(readableAction =>
    includes(readableAction.toLowerCase(), lowerCaseSearchText)
  );

  return matchingReadableActions.map(readableAction => READABLE_ACTION_TO_ACTION[readableAction]);
}
