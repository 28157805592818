import React from 'react';
import pt from 'prop-types';

import {
  MrInputText,
  MrInputSelectDeprecated,
  MrInputCheckbox,
  MrInputNumber,
  formValidators,
  MrText,
} from '@ion/components';

import { useFormContext } from 'app/context/form-context';
import s from './index.module.scss';

const { validateRequired, validateInteger, validateFloat, validateBoolean } = formValidators;

//TODO need to double check these default options are applicable across all of the things
const DefaultValue = ({ opId, _path, calculatedForm }) => {
  const { setField, validateField, formState, resetError } = useFormContext();

  const defaultOptions = [
    {
      text: 'String',
      value: 'string',
    },
    {
      text: 'integer',
      value: 'integer',
    },
    {
      text: 'Boolean',
      value: 'boolean',
    },
    {
      text: 'Float',
      value: 'number',
    },
    {
      text: 'Empty Array',
      value: 'emptyArray',
    },
    {
      text: 'Empty Object',
      value: 'emptyObject',
    },
  ];
  const defaultType = `${opId}-defaultType-${_path}`;
  const defaultValue = `${opId}-defaultValue-${_path}`;

  return (
    <>
      <div className={s.defaultValue}>
        <MrInputCheckbox
          name={`${opId}-applyDefault-${_path}`}
          label="Set a default value for this key?"
          onChange={setField}
          value={calculatedForm.applyDefault}
          className={s.defaultToggle}
          switcher={true}
          labelPosition="right"
        />
        {calculatedForm.applyDefault && (
          <>
            <MrInputSelectDeprecated
              name={defaultType}
              onChange={event => {
                setField(event, {
                  otherFields: [
                    { name: defaultValue, value: '', errorMsg: null }, // clear the default value when its data type changes
                  ],
                });
              }}
              onBlur={validateField}
              value={calculatedForm.defaultType}
              errorMsg={formState[defaultType]?.errorMsg}
              options={defaultOptions}
              validators={[validateRequired()]}
              placeholder
            />
            {calculatedForm.defaultType === 'boolean' && (
              <MrInputSelectDeprecated
                options={['true', 'false']}
                onChange={setField}
                onBlur={validateField}
                name={defaultValue}
                value={calculatedForm.defaultValue?.toString() || 'true'}
                errorMsg={formState[defaultValue]?.errorMsg}
                validators={[validateRequired(), validateBoolean()]}
                placeholder
              />
            )}
            {calculatedForm.defaultType === 'string' && (
              <div className={s.withHelper}>
                <MrInputText
                  name={defaultValue}
                  value={formState[defaultValue]?.value?.toString() ?? calculatedForm.defaultValue?.toString() ?? ''}
                  onChange={setField}
                  errorMsg={formState[defaultValue]?.errorMsg}
                  placeholder="default string"
                />
                <MrText styleNames="smallGray">Leave blank to default to an empty string</MrText>
              </div>
            )}
            {calculatedForm.defaultType === 'integer' && (
              <MrInputNumber
                onBlur={validateField}
                name={defaultValue}
                value={formState[defaultValue]?.value ?? calculatedForm.defaultValue}
                onChange={setField}
                errorMsg={formState[defaultValue]?.errorMsg}
                onInput={resetError}
                validators={[validateRequired(), validateInteger()]}
                step={1}
                placeholder="default integer"
              />
            )}
            {calculatedForm.defaultType === 'number' && (
              <MrInputNumber
                onBlur={validateField}
                name={defaultValue}
                value={formState[defaultValue]?.value ?? calculatedForm.defaultValue}
                onChange={setField}
                onInput={resetError}
                errorMsg={formState[defaultValue]?.errorMsg}
                validators={[validateRequired(), validateFloat()]}
                placeholder="default float"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

DefaultValue.propTypes = {
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
  calculatedForm: pt.shape({
    defaultType: pt.string,
    applyDefault: pt.bool,
    defaultValue: pt.any,
  }).isRequired,
};

export default DefaultValue;
