import { unionBy as _unionBy, values as _values, flatten as _flatten } from 'lodash';

// validateKeyDuplicates validates if the key(outputKey or expressionName) can be added not causing a duplicate mapping.
const validateKeyDuplicates = (key, val, navState, globalOperations, eventOperations, defaultOperations) => {
  const section = navState.selectedSection;
  const event = navState.selectedEvent;
  const opId = navState.selectedTransformation;

  const operations = section => {
    switch (section) {
      case 'global':
        return [...(_unionBy(_flatten(_values(eventOperations)), key) ?? []), ...defaultOperations];
      case 'eventSpecific':
        return eventOperations[event] ?? [];
      case 'default':
        return defaultOperations ?? [];
      default:
        return [];
    }
  };

  const inUse = [...globalOperations, ...operations(section)].filter(o => o.opId !== opId).find(o => o[key] === val);

  return () => {
    const msg = key === 'outputKey' ? 'output key' : 'name';
    return inUse ? `This ${msg} is already in use` : null;
  };
};

export default validateKeyDuplicates;
