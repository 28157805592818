export const cellActions = {
  STAGE_FOR_DELETE: 'STAGE_FOR_DELETE',
  REMOVE_STAGED_REVISION: 'REMOVE_STAGED_REVISION',
  UNMANAGED_WARNING: 'UNMANAGED_WARNING',
  UNDO_STAGED_FOR_DELETE: 'UNDO_STAGED_FOR_DELETE',
};

export function determineCellAction({ stagedForDelete, stagedRevisionId, deployedRevision }) {
  if (!stagedRevisionId) {
    return cellActions.UNMANAGED_WARNING;
  }

  if (stagedForDelete) {
    return cellActions.UNDO_STAGED_FOR_DELETE;
  }

  if (deployedRevision) {
    return cellActions.STAGE_FOR_DELETE;
  }

  return cellActions.REMOVE_STAGED_REVISION;
}
