import React from 'react';
import pt from 'prop-types';
import { format as f } from 'date-fns';

const MrDate = ({ date, format = 'MMMM d, yyyy', short }) => {
  if (!date) {
    return '';
  }

  const newDate = new Date(date);

  if (short) {
    if (newDate.getFullYear() === new Date().getFullYear()) {
      format = 'MMM d';
    } else {
      format = 'MMM d, yyyy';
    }
  }

  try {
    return <>{f(newDate, format)}</>;
  } catch (error) {
    return date; // return the original value if not a valid date
  }
};

MrDate.propTypes = {
  date: pt.oneOfType([pt.string, pt.instanceOf(Date)]),
  format: pt.string,
  short: pt.bool,
};

export default MrDate;
