import getCommonTittle from './getCommonTitle';

/**
 *
 * Source: https://wix-ux.com/when-life-gives-you-lemons-write-better-error-messages-46c5223e1a2f
 * Good error message example:
 *
 *
 *             │ Say what happened           │Say why it happened
 *             │ in title                    │
 *             │                             │
 *             ▼                             │
 *        Unable to connect your Account     │
 *       ┌────────────────────────┐ ┌────────▼─────────────────────────────────────────────────────────────────┐
 * ┌────►│Your changes were saved,│ │but we could not connect your account due to a technical issue on our end.│
 * │     ├────────────────────────┴─┴─┬─┬───────────────────────────────────────────────────────┬──────────────┘
 * │     │Please try connecting again.│ │If the issue keeps happening, contact customer support.│
 * │     └────────────────▲───────────┘ └──────────▲────────────────────────────────────────────┘
 * │                      │                        │
 * │                      │                        │
 * Provide reassurance    Help them fix it         Give them a way out
 *
 */
const contactSupport = 'If the issue keeps happening, contact customer support.';
const builderServer =
  'The builder server appears to be having some issues. No options will be available in the dropdown section.';
const apolloIssues = 'Server returned an error.';

export default {
  // region network, these errors happen when there are timeouts, or when we cannot connect to the Apollo server
  networkIssues: {
    title: 'Connection issues',
    message: 'We are unable to connect to our servers. Please try again later.',
  },
  // endregion

  // region graphQL These errors happen when we connect to external APIs, control proxy, or builder server
  graphQL: {
    default: {
      title: 'Unable to send data to server',
      message: `Could not connect to server to perform action. ${contactSupport}`,
    },
    invalidJWT: {
      title: 'Invalid credentials',
      message: 'Unable to retrieve your credentials. Please log out and try again.',
    },
    expiredJWT: {
      title: 'Your session has expired',
      message: 'Your session ended because there was no activity. Try signing in again.',
    },
    permissionDenied: {
      title: 'Not allowed',
      message: 'Your login credentials are not allowed to perform this action.',
    },
    // https://www.apollographql.com/docs/apollo-server/v2/data/errors/#graphql_validation_failed
    validationFailed: {
      title: 'GraphQL operation is invalid',
      message: "The GraphQL operation is not valid against the server's schema.",
    },
    userControlProxyErrors: {
      title: (action = 'unknown', resource = 'unknown', writekey = 'unknown') => {
        return `Unable to ${action} ${resource} for writekey ${writekey}`;
      },
      message: `${apolloIssues} ${contactSupport}`,
    },
    // region builder server
    ClientSideIntegrations: {
      title: 'Failed to get available client side integrations',
      message: `${builderServer} ${contactSupport}`,
    },
    IdentitySyncs: {
      title: 'Failed to get available identity syncs',
      message: `${builderServer} ${contactSupport}`,
    },
    BuildAjsFile: {
      title: 'Failed to generate file',
      message: `${builderServer} ${contactSupport}`,
    },
    RemoveAnalyticsFile: {
      title: getCommonTittle('RemoveAnalyticsFile'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    UpsertAnalyticsFile: {
      title: getCommonTittle('UpsertAnalyticsFile'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    // endregion
    // region clusters
    CreateCluster: {
      title: getCommonTittle('CreateCluster'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    RemoveCluster: {
      title: getCommonTittle('RemoveCluster'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    // endregion
    // region integrations
    InsertIntegration: {
      title: getCommonTittle('InsertIntegration'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    RemoveIntegration: {
      title: getCommonTittle('RemoveIntegration'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    UpdateIntegrationName: {
      title: getCommonTittle('UpdateIntegrationName'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    UpsertIntegrationNotesOne: {
      title: getCommonTittle('UpsertIntegrationNotes'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    // endregion
    // region organizations
    InsertOrg: {
      title: getCommonTittle('InsertOrganization'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    RemoveOrg: {
      title: getCommonTittle('RemoveOrganization'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    SwitchOrg: {
      title: getCommonTittle('SwitchOrg'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    UpdateOrg: {
      title: getCommonTittle('UpdateOrg'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    // endregion
    // region pipelines
    CreatePipeline: {
      title: getCommonTittle('CreatePipeline'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    CreateStagedRevision: {
      title: getCommonTittle('CreateStagedRevision'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    RemovePipeline: {
      title: getCommonTittle('RemovePipeline'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    RemoveStagedRevision: {
      title: getCommonTittle('RemoveStagedRevision'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    UpdatePipeline: {
      title: getCommonTittle('UpdatePipeline'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    UpdateStagedRevision: {
      title: getCommonTittle('UpdateStagedRevision'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    // endregion
    // region playbooks
    CreatePlaybookRevision: {
      title: getCommonTittle('CreatePlaybookRevision'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    // endregion
    // region schemas
    DiffSchemas: {
      title: getCommonTittle('DiffSchemas'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    RemoveEventSchema: {
      title: getCommonTittle('RemoveEventSchema'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    UpsertEventSchema: {
      title: getCommonTittle('UpdateEventSchema'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    // endregion
    // region users
    RemoveOrgUser: {
      title: getCommonTittle('RemoveOrgUser'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    InsertOrgUsers: {
      title: getCommonTittle('InsertOrgUsers'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    UpdateMe: {
      title: getCommonTittle('UpdateUserDetails'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    UpdateOrgUser: {
      title: getCommonTittle('UpdateOrgUser'),
      message: `${apolloIssues} ${contactSupport}`,
    },
    // endregion
  },
  // endregion

  // role
  role: {
    title: 'No role assigned',
    message: 'You are a member of this Org but do not yet have a Role assigned. Please contact support.',
  },
};
