import React, { useState } from 'react';

import { MrAppLayout, MrAppMain, MrSearch } from '@ion/components';
import { useQueryClusters } from '@ion/api';

import SideNav from 'app/components/side-nav';
import Header from 'app/components/app-header';

import Card from './card';

import s from './index.module.scss';

const ClustersPage = () => {
  const { data, loading: loadingClusters } = useQueryClusters();

  const [clusters, setClusters] = useState(data);

  return (
    <MrAppLayout
      title="Clusters"
      description="Manage your clusters."
      sideNav={<SideNav />}
      header={<Header icon="clusters" title="Clusters" />}
    >
      <MrAppMain
        loading={loadingClusters}
        styleNames="list"
        header={
          <>
            <MrSearch placeholder="Search Clusters" data={data} onChange={setClusters} />
          </>
        }
      >
        <div className={s.list}>
          {!loadingClusters &&
            clusters.map(cluster => {
              return <Card key={cluster.id} cluster={cluster} />;
            })}
        </div>
      </MrAppMain>
    </MrAppLayout>
  );
};

export default ClustersPage;
