import React, { useState } from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import { Prompt } from 'react-router-dom';

import { MrButton, MrInputSelectDeprecated, MrLinkButton } from '@ion/components';

import s from './index.module.scss';
import { useQuietUpdateIntegration, useInsertPlaybookRevision } from '@ion/api';
import { useUserContext } from '@ion/user';
import { getParams } from '../get-params';
import { globalModalStore } from '@ion/global';

import DownloadUploadPlaybook from 'app/playbook-editor/revision-header/download-upload';
import validateRequiredParams from './validate-required-params';

import icon from './download-upload/assets/save.svg';
import { TransformDrawer } from '../transform-drawer/TransformDrawer';
import FeatureGate from '../../launch-darkly/gates/feature-gate';
import { TESTING_SUITE_FLAGS } from '../../launch-darkly/testing-suite-flags';
import { PlayCircleIcon } from '@metarouter-private/mr-mui';
import { isAllowedToTransformEvent } from '@ion/api/control/is-allowed-to-transform-event';
import useTrackWithUser from '@ion/api/hooks/useTrackWithUser';

const PlaybookRevisionHeader = ({
  playbookState,
  navState,
  integration, // used for revision selecting
  version,
  isAllowed,
}) => {
  const [isOpenTransformDrawer, setIsOpenTransformDrawer] = useState(false);
  const { orgId } = useUserContext();
  const [quietUpdateIntegration] = useQuietUpdateIntegration();

  const [trackTestingSuiteOpen] = useTrackWithUser('testing_suite_open');

  const [insertPlaybook] = useInsertPlaybookRevision({
    orgId,
    onCompleted: async response => {
      const {
        id,
        integrationId,
        integration: { starterkitVersion },
      } = response.insert_playbooks.returning[0];

      // When revision 1 is saved, it updates the starter kit version in integration table.
      if (starterkitVersion === '0') {
        await quietUpdateIntegration({ integrationId, starterkitVersion: version });
      }

      // For revision jump(from revision 2 to revision 5), need to set navState.setRevision
      navState.setRevision(id);
    },
  });

  const handleOpenDrawer = () => {
    setIsOpenTransformDrawer(true);
    trackTestingSuiteOpen();
  };

  const handleCloseDrawer = () => {
    setIsOpenTransformDrawer(false);
  };

  const toggleTransformDrawer = () => {
    if (isOpenTransformDrawer) {
      handleCloseDrawer();
    } else {
      handleOpenDrawer();
    }
  };

  // revision options and numbers might be able to hoist
  const revisionOptions = integration.playbooks
    ? integration.playbooks.map(p => ({ value: p.id, text: `Revision ${p.revision}` }))
    : [];
  const disabled = !playbookState.hasChanges || !isAllowed;

  const onSaveRevision = () => {
    const params = getParams(playbookState.connectionJSON, playbookState.playbook);
    try {
      validateRequiredParams(params);
    } catch (e) {
      return globalModalStore.addNotification({
        title: 'Unable to save revision',
        message: 'Required parameters are missing. Please populate all required parameters and retry.',
        code: e,
      });
    }

    insertPlaybook({
      integrationId: integration.id,
      playbookJson: playbookState.playbook,
      connectionJson: playbookState.connectionJSON,
      vars: null, //Deprecated field will remove but will be available for backwards compataiblity
      pipeline_variables: {
        data: params.filter(p => p.pipelineVarId).map(o => ({ name: o.name })),
      },
      staged_revisions: null,
    });
  };

  return (
    <div className={cx(s.content)}>
      <TransformDrawer isOpen={isOpenTransformDrawer} onClose={handleCloseDrawer} />
      {integration.playbooks.length ? (
        <div className={s.revisions} title="Revert to:">
          <MrInputSelectDeprecated
            testId="revisionsSelect"
            name="revisions"
            placeholder="revert to"
            value={playbookState.baseRevisionId || ''}
            onChange={e => {
              if (!e.target?.value) {
                return;
              }

              navState.setRevision(e.target.value);
            }}
            options={revisionOptions}
            alphabetized={false}
          />
        </div>
      ) : (
        <div className={s.empty} />
      )}
      <div className={s.buttons}>
        <FeatureGate flagName={TESTING_SUITE_FLAGS.SLICE_1}>
          <MrButton
            text="Test Playbook"
            size="medium"
            onClick={toggleTransformDrawer}
            startIcon={<PlayCircleIcon />}
            variant="outlined"
            disabled={!isAllowedToTransformEvent()}
          />
        </FeatureGate>

        <MrLinkButton
          disabled={disabled}
          onClick={e => {
            if (!disabled) {
              onSaveRevision();
            }
            e.preventDefault();
          }}
          className={s.button}
        >
          <img src={icon} />
          Save as New Revision
        </MrLinkButton>

        {playbookState.hasChanges && (
          <Prompt
            message={location => confirm(`Continue to ${location.pathname}? Changes you made may not be saved.`)}
          />
        )}
        <DownloadUploadPlaybook
          integration={integration}
          playbookState={playbookState}
          insertPlaybook={insertPlaybook}
        />
      </div>
    </div>
  );
};

PlaybookRevisionHeader.propTypes = {
  integration: pt.object,
  playbookState: pt.object.isRequired,
  navState: pt.object.isRequired,
  version: pt.string,
  isAllowed: pt.bool.isRequired,
};

export default PlaybookRevisionHeader;
