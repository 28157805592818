import { useMutation } from './../index';
import { gql } from '@apollo/client';

import { query } from './useQueryEventSchemas';

import permission from '../Apollo/permissions';

const mutation = gql`
  mutation UpsertEventSchema($eventSchema: json!, $eventSchemaId: String) {
    upsertEventSchema(eventSchema: $eventSchema, eventSchemaId: $eventSchemaId) {
      eventSchemaId
    }
  }
`;

export default ({ ...options }) => {
  let inputEventSchema;
  const [call, response] = useMutation(mutation, {
    successMsg: 'Event schema updated.',
    ...options,
    context: { permission: permission.createCluster }, // TODO new perms
    update: (cache, { data }) => {
      const newItem = {
        __typename: 'event_schemas',
        id: data.upsertEventSchema.eventSchemaId,
        json_schema: inputEventSchema,
      };
      const { event_schemas } = cache.readQuery({ query }) || [];
      if (!event_schemas) return;

      const eventSchemas = [...event_schemas];
      const existingIndex = eventSchemas.findIndex(o => o.id === newItem.id);
      if (existingIndex >= 0) {
        eventSchemas[existingIndex] = newItem;
      } else {
        eventSchemas.push(newItem);
      }

      cache.writeQuery({
        query,
        data: {
          event_schemas: eventSchemas,
        },
      });
    },
  });
  return [
    // The insert function
    ({ eventSchema, eventSchemaId }) => {
      if (!eventSchema) throw new Error('Upsert Event Schema: No JSON schema present');
      inputEventSchema = eventSchema;
      return call({
        variables: {
          eventSchema,
          eventSchemaId,
        },
      });
    },
    response,
  ];
};
