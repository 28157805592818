import { differenceWith as _differenceWith, isEqual as _isEqual } from 'lodash';

const groupHandler = (hasSelectedOption, groupOption, options, value) => {
  const mapped = groupOption.map(o => o.data);

  // select all
  if (hasSelectedOption && options.length === 1) {
    return [];
  }

  // group select
  if (!hasSelectedOption) {
    return [...value, ...mapped];
  }

  // group deselect
  const diff = _differenceWith(value, mapped, _isEqual);

  return diff;
};

export default groupHandler;
