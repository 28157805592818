import React from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';

import s from './spinner.module.scss';

const MrSpinner = ({ className, message = null, size = 'lg', textPosition = 'bottom' }) => {
  return (
    <div className={cx(s.spinnerContainer, s[textPosition], s[size], className)} data-test-id={'spinner-container'}>
      <div>
        <span className={s.spinner} data-test-id={'spinner-element'} />
      </div>
      {message && (
        <div className={s.messageContainer} data-test-id={'spinner-message'}>
          {message}
        </div>
      )}
    </div>
  );
};

MrSpinner.propTypes = {
  className: propTypes.string,
  message: propTypes.string,
  size: propTypes.string,
  textPosition: propTypes.string,
};

export default MrSpinner;
