// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-event-schemas-panel-details-module__toggle{cursor:pointer;margin-left:auto;width:1.8rem;transition:transform 200ms ease-out;transform:scaleY(-1)}.src-event-schemas-panel-details-module__toggle.src-event-schemas-panel-details-module__open{transform:scaleY(1)}.src-event-schemas-panel-details-module__container{display:flex;justify-content:space-between;margin-top:24px;width:100%;gap:8px}.src-event-schemas-panel-details-module__container .src-event-schemas-panel-details-module__inlineIconCard{width:33.3%}", "",{"version":3,"sources":["/usr/src/packages/app/src/event-schemas/panel/details.module.scss"],"names":[],"mappings":"AA2BA,gDACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,mCAAA,CACA,oBAAA,CAEA,6FACE,mBAAA,CAIJ,mDACE,YAAA,CACA,6BAAA,CACA,eAAA,CACA,UAAA,CACA,OAAA,CACA,2GACE,WAAA","file":"details.module.scss","sourcesContent":["@import '../../../../component-library/src/scss/theme/brand-vars';\n@import '../../../../component-library/src/scss/theme/layout-vars';\n@import '../../../../component-library/src/scss/theme/shadow-vars';\n@import '../../../../component-library/src/scss/theme/mixins';\n\n// this file is only for global variable and mixins and should not output any styles\n\n///////////////////////////////////////////////////////////////////////////////\n\n@mixin cardList($tile) {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: center;\n\n  @if ($tile == true) {\n    justify-content: flex-start;\n    margin: -1rem;\n  }\n\n  & > div {\n    margin: 1rem;\n  }\n}\n\n.toggle {\n  cursor: pointer;\n  margin-left: auto;\n  width: 1.8rem;\n  transition: transform 200ms ease-out;\n  transform: scaleY(-1);\n\n  &.open {\n    transform: scaleY(1);\n  }\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 24px;\n  width: 100%;\n  gap: 8px;\n  .inlineIconCard {\n    width: 33.3%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"toggle": "src-event-schemas-panel-details-module__toggle",
	"open": "src-event-schemas-panel-details-module__open",
	"container": "src-event-schemas-panel-details-module__container",
	"inlineIconCard": "src-event-schemas-panel-details-module__inlineIconCard"
};
module.exports = exports;
