import { useMutation } from '../../index';
import permission from '../../Apollo/permissions';
import mergeQueries from '../../Apollo/merge-queries';

import { mutation as updatePipelineMutation } from './update-pipeline-mutation-builder';
import { buildVariables as buildUpdatePipelineVariables } from './update-pipeline-mutation-builder';

import { mutation as pipelineUpdatedMutation } from '../../audit-logs/pipeline-updated/audit-log-mutation-builder';
import { buildVariables as buildPipelineUpdatedVariables } from '../../audit-logs/pipeline-updated/audit-log-mutation-builder';

export const combinedMutation = mergeQueries('UpdatePipelineAndAudit', [
  updatePipelineMutation,
  pipelineUpdatedMutation,
]);

export default function useUpdatePipeline(options = {}) {
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Pipeline updated.',
    context: { permission: permission.updatePipeline },
    ...options,
  });
  const updateAndAuditMutation = ({ pipelineId, name }) => {
    const variables = buildVariables({ pipelineId, name });
    return executeMutation(variables);
  };

  return [updateAndAuditMutation, response];
}

export function buildVariables({ pipelineId, name }) {
  return {
    variables: {
      ...buildUpdatePipelineVariables({ pipelineId, name }),
      ...buildPipelineUpdatedVariables({ pipelineId, name }),
    },
  };
}
