import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputNumber } from '@ion/components';

import { useFormContext } from 'app/context/form-context';

const { validateRequired, validateNotEqual, validateInteger, validateInRange } = formValidators;

const Substring = ({ start, end, opId, _path }) => {
  const { resetError, validateField, formState, setField } = useFormContext();

  const startName = `${opId}-start-${_path}`;
  const endName = `${opId}-end-${_path}`;

  return (
    <>
      <MrInputNumber
        label="Start"
        onBlur={validateField}
        onInput={resetError}
        name={startName}
        value={start}
        onChange={setField}
        errorMsg={formState[startName]?.errorMsg}
        validators={[validateRequired(), validateInteger(), validateInRange(0, end)]}
        placeholder="start"
        labelPosition="top"
        min={0}
      />
      <MrInputNumber
        label="End"
        onBlur={validateField}
        onInput={resetError}
        name={endName}
        value={end}
        onChange={setField}
        errorMsg={formState[endName]?.errorMsg}
        validators={[
          validateRequired(),
          validateNotEqual(start, 'Values for Start and End must be different'),
          validateInteger(),
        ]}
        placeholder="end"
        labelPosition="top"
      />
    </>
  );
};

Substring.propTypes = {
  start: pt.number,
  end: pt.number,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default Substring;
