import invariant from 'tiny-invariant';
import { gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

export const query = gql`
  query SearchAuditLogs($where: audit_logs_bool_exp!, $limit: Int!, $offset: Int!, $order_by: [audit_logs_order_by!]) {
    audit_logs_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    audit_logs(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
      id
      timestamp
      action
      description
      user {
        name
        email
      }
      org {
        name
      }
    }
  }
`;

export function buildVariables(args) {
  const { email, description, actions, dateRange, pagination, sortOrder } = args;

  return {
    ...buildWhere({ email, description, actions, dateRange }),
    ...buildLimitAndOffset(pagination),
    ...buildOrderBy({ sortOrder }),
  };
}

export function buildWhere({ email = '', description = '', actions = [], dateRange }) {
  const orClause = compact([buildEmail(email), buildDescription(description), buildActions(actions)]);

  return {
    where: {
      ...(!isEmpty(orClause) && { _or: orClause }),
      ...buildDateRange(dateRange),
    },
  };
}

function buildEmail(email) {
  if (isEmpty(email)) {
    return null;
  }

  return {
    user: {
      email: {
        _ilike: `%${email}%`,
      },
    },
  };
}

export function buildDescription(description) {
  if (isEmpty(description)) {
    return null;
  }
  return {
    description: {
      _ilike: `%${description}%`,
    },
  };
}

export function buildActions(actions) {
  if (isEmpty(actions)) {
    return null;
  }
  return {
    action: {
      _in: actions,
    },
  };
}

export function buildDateRange({ startDate, endDate }) {
  return {
    _and: [
      {
        timestamp: {
          _gte: startDate,
        },
      },
      {
        timestamp: {
          _lt: endDate,
        },
      },
    ],
  };
}

export function buildLimitAndOffset({ page, perPage }) {
  invariant(page > 0, `page must be greater than 0, given '${page}'`);

  const offset = (page - 1) * perPage;
  return {
    limit: perPage,
    offset,
  };
}

export function buildOrderBy(args) {
  const sortOrder = args.sortOrder ?? 'desc';

  invariant(['asc', 'desc'].includes(sortOrder), `Invalid sortOrder: '${sortOrder}'`);

  // Currently, only supporting sort by timestamp
  const order_by = {
    timestamp: sortOrder,
  };
  return { order_by };
}
