import React from 'react';
import pt from 'prop-types';

import SelectStageOrDeploy from 'app/pipelines/bulk-stage-and-deploy/select-stage-or-deploy/index.js';

const SelectStageOrDeployPage = () => {
  return <SelectStageOrDeploy />;
};

SelectStageOrDeployPage.propTypes = {
  location: pt.object,
};

export default SelectStageOrDeployPage;
