import React from 'react';
import propTypes from 'prop-types';
import { compact, isEmpty, map } from 'lodash';

import { Box, Grid } from '@metarouter-private/mr-mui';
import { ERROR_TYPES } from '@ion/api/control/transform-event/transform-errors';

import ErrorMessage from './ErrorMessage';

const DEFAULT_ERROR_MESSAGES = {
  [ERROR_TYPES.EMPTY_EVENT]: 'The transformations resulted in an empty output event',
  [ERROR_TYPES.FILTERED]: 'This event was filtered by the playbook',
};

export default function ErrorMessages({ errorType, errors }) {
  const messages = compact(map(errors, 'message'));
  if (!isEmpty(messages)) {
    return (
      <ErrorMessagesContainer>
        {messages.map((message, index) => (
          <ErrorMessage key={index} message={message} />
        ))}
      </ErrorMessagesContainer>
    );
  }

  const defaultMessage = DEFAULT_ERROR_MESSAGES[errorType];

  if (!defaultMessage) {
    return null;
  }

  return (
    <ErrorMessagesContainer>
      <ErrorMessage message={defaultMessage} />
    </ErrorMessagesContainer>
  );
}

ErrorMessages.propTypes = {
  errorType: propTypes.string.isRequired,
  errors: propTypes.array.isRequired,
};

function ErrorMessagesContainer({ children }) {
  return (
    <Box component="div">
      <Grid container padding={1}>
        {children}
      </Grid>
    </Box>
  );
}

ErrorMessagesContainer.propTypes = {
  children: propTypes.node.isRequired,
};
