import React from 'react';
import { MrSpinner } from '../../index';
import styles from './progress-component.module.scss';
import propTypes from 'prop-types';

export default function ProgressComponent({ message }) {
  return (
    <div className={styles.spinnerContainer}>
      <MrSpinner message={message ?? 'Loading...'} />
    </div>
  );
}

ProgressComponent.propTypes = {
  message: propTypes.string,
};
