import { gql } from '@apollo/client';

export const ORGANIZATION_QUERY_NAME = 'Organizations';

export const query = gql`
  query ${ORGANIZATION_QUERY_NAME}($page: Int!, $perPage: Int!) {
    auth0_organizations(page: $page, perPage: $perPage) {
      organizations {
        id
        name
        display_name
        metadata {
          orgOverride
        }
      }
      start
      limit
      total
    }
  }
`;
