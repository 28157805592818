import pt from 'prop-types';
import { useQueryConsentCategories } from '@ion/api';
import React, { createContext, useContext } from 'react';

const DEFAULT_DATA_CONSENT_CATEGORIES = {
  consentCategories: [],
  loading: false,
  isAllowed: false,
  error: null,
};
const ConsentCategoriesContextValue = createContext(DEFAULT_DATA_CONSENT_CATEGORIES);

export const ConsentCategoriesContext = ({ children }) => {
  const { data, loading, error, isAllowed } = useQueryConsentCategories();

  return (
    <ConsentCategoriesContextValue.Provider value={{ consentCategories: data, loading, isAllowed, error }}>
      {children}
    </ConsentCategoriesContextValue.Provider>
  );
};

export const useConsentCategoriesContext = () => {
  return useContext(ConsentCategoriesContextValue);
};
ConsentCategoriesContext.propTypes = {
  children: pt.node.isRequired,
};
