import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputText } from '@ion/components';

import { useFormContext } from 'app/context/form-context';

const { validateRequired } = formValidators;

const PluckValues = ({ extractKey, opId, _path }) => {
  const { validateField, resetError, formState, setField } = useFormContext();
  const extractKeyName = `${opId}-extractKey-${_path}`;

  return (
    <MrInputText
      label="Extract Key"
      onInput={resetError}
      onBlur={validateField}
      name={extractKeyName}
      value={formState[extractKeyName]?.value ?? extractKey}
      onChange={setField}
      errorMsg={formState[extractKeyName]?.errorMsg}
      placeholder="key to be plucked"
      labelPosition="top"
      validators={[validateRequired()]}
    />
  );
};

PluckValues.propTypes = {
  extractKey: pt.string,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default PluckValues;
