import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputSelectDeprecated } from '@ion/components';

import { useFormContext } from 'app/context/form-context';
const { validateRequired } = formValidators;

const Compression = ({ name, isOptional, defaultValue }) => {
  const { setField, validateField, formState } = useFormContext();
  // MrInputSelect get only string values
  const options = [
    {
      text: 'NONE_UNSPECIFIED',
      value: '0',
    },
    {
      text: 'snappy',
      value: '1',
    },
    {
      text: 'gzip',
      value: '2',
    },
    {
      text: 'uncompressed',
      value: '3',
    },
  ];

  return (
    <MrInputSelectDeprecated
      name={name}
      options={options}
      value={defaultValue ?? ''}
      onChange={e => setField(e)}
      onBlur={validateField}
      errorMsg={formState[name]?.errorMsg}
      placeholder="select one"
      label="COMPRESSION"
      validators={!isOptional ? [validateRequired()] : null}
      labelPosition="top"
    />
  );
};

Compression.propTypes = {
  name: pt.string,
  isOptional: pt.bool.isRequired,
  defaultValue: pt.string,
};

export default Compression;
