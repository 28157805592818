import React from 'react';
import { OverlayContainer, useModal, useOverlay, usePreventScroll } from '@react-aria/overlays';
import { FocusScope } from '@react-aria/focus';

import cx from 'classnames';
import s from 'app/event-schemas/panel/right-panel.module.scss';
import pt from 'prop-types';

// TODO (Ohms 2022.10.21) - Refactor into a component?  Only difference to modal is CSS
//  probably a simpler solution with less duplication.
const RightPanel = ({ styleName, className, children, testId = 'MrRightPanel', ...props }) => {
  // Handle interacting outside the dialog and pressing the Escape key to close the modal.
  const ref = React.useRef();
  const { overlayProps, underlayProps } = useOverlay(props, ref);

  // Prevent scrolling while the modal is open, and hide content outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  return (
    <div className={cx(s.modal, className, s[styleName])} data-test-id={testId} {...underlayProps}>
      <FocusScope contain restoreFocus autoFocus>
        <div {...overlayProps} {...modalProps} ref={ref} className={s.content}>
          {children}
        </div>
      </FocusScope>
    </div>
  );
};

// TODO (Ohms 2022.10.21) - Refactor into a component?  See above
const MrRightPanel = ({ children, closeModal, ...props }) => {
  return (
    <>
      <OverlayContainer>
        <RightPanel {...props} isOpen onClose={closeModal} isDismissable>
          {children}
        </RightPanel>
      </OverlayContainer>
    </>
  );
};

RightPanel.propTypes = {
  styleName: pt.string,
  className: pt.string,
  children: pt.node,
  testId: pt.string,
};

MrRightPanel.propTypes = {
  children: pt.node,
  closeModal: pt.func.isRequired,
};

export default MrRightPanel;
