// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-clusters-index-module__toggle{cursor:pointer;margin-left:auto;width:1.8rem;transition:transform 200ms ease-out;transform:scaleY(-1)}.src-clusters-index-module__toggle.src-clusters-index-module__open{transform:scaleY(1)}.src-clusters-index-module__list{margin-left:80px !important;align-items:flex-start;display:flex;flex-direction:row;flex-wrap:wrap;width:100%;justify-content:center;justify-content:flex-start;margin:-1rem}.src-clusters-index-module__list>div{margin:1rem}.src-clusters-index-module__card{min-width:336px;max-width:480px}", "",{"version":3,"sources":["/usr/src/packages/app/src/clusters/index.module.scss"],"names":[],"mappings":"AA2BA,mCACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,mCAAA,CACA,oBAAA,CAEA,mEACE,mBAAA,CAIJ,iCACE,2BAAA,CA9BA,sBAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,UAAA,CACA,sBAAA,CAGE,0BAAA,CACA,YAAA,CAGF,qCACE,WAAA,CAqBJ,iCACE,eAAA,CACA,eAAA","file":"index.module.scss","sourcesContent":["@import '../../../component-library/src/scss/theme/brand-vars';\n@import '../../../component-library/src/scss/theme/layout-vars';\n@import '../../../component-library/src/scss/theme/shadow-vars';\n@import '../../../component-library/src/scss/theme/mixins';\n\n// this file is only for global variable and mixins and should not output any styles\n\n///////////////////////////////////////////////////////////////////////////////\n\n@mixin cardList($tile) {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: center;\n\n  @if ($tile == true) {\n    justify-content: flex-start;\n    margin: -1rem;\n  }\n\n  & > div {\n    margin: 1rem;\n  }\n}\n\n.toggle {\n  cursor: pointer;\n  margin-left: auto;\n  width: 1.8rem;\n  transition: transform 200ms ease-out;\n  transform: scaleY(-1);\n\n  &.open {\n    transform: scaleY(1);\n  }\n}\n\n.list {\n  margin-left: 80px !important;\n  @include cardList(true);\n}\n\n.card {\n  min-width: 336px;\n  max-width: 480px;\n}\n"]}]);
// Exports
exports.locals = {
	"toggle": "src-clusters-index-module__toggle",
	"open": "src-clusters-index-module__open",
	"list": "src-clusters-index-module__list",
	"card": "src-clusters-index-module__card"
};
module.exports = exports;
