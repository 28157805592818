import React, { useState, useCallback, useEffect } from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import ToggleButton from './toggle-button';

import s from './button-group.module.scss';

const MrButtonGroup = ({
  className,
  testId = 'MrButtonGroup',
  onClick,
  defaultValue,
  items,
  overrideValue,
  disabled,
}) => {
  const [selected, setSelected] = useState(defaultValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSelectedCallback = useCallback(setSelected, []);

  useEffect(() => {
    setSelectedCallback(overrideValue);
  }, [overrideValue, setSelectedCallback]);

  return (
    <div className={cx(s.buttonGroup, className)} data-test-id={testId}>
      {items.map(item => (
        <ToggleButton
          className={s.toggleButtons}
          key={item.value}
          selected={selected}
          onToggle={() => {
            if (!disabled) {
              setSelected(item.value);
              onClick(item.value);
            }
          }}
          {...item}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

MrButtonGroup.propTypes = {
  className: pt.string,
  testId: pt.string,
  overrideValue: pt.string,
  defaultValue: pt.string,
  onClick: pt.func.isRequired,
  items: pt.arrayOf(
    pt.shape({
      value: pt.string,
      label: pt.string,
      icon: pt.string,
    })
  ).isRequired,
  disabled: pt.bool,
};

export default MrButtonGroup;
