import { ApolloLink, Observable } from '@apollo/client';

import { getRole } from '../allower';
import { notify } from '../index';
import errors from './onErrors/errors';

const roleLink = hasRoles => {
  return new ApolloLink((operation, forward) => {
    const permission = operation.getContext().permission;
    if (!permission) {
      return forward(operation);
    }

    const role = getRole(permission);
    if (hasRoles === false) {
      notify.error({
        title: errors.role.title,
        message: errors.role.message,
      });
    }

    if (!role) {
      return new Observable(observer => {
        observer.next({
          data: null,
          errors: [
            {
              message: `no role allowed for permission '${permission}'`,
              extensions: {
                code: 'permission-denied',
              },
            },
          ],
        });
        observer.complete();
      });
    }

    operation.setContext(({ headers }) => {
      return {
        headers: {
          ...headers,
          'x-hasura-role': role,
        },
      };
    });

    return forward(operation);
  });
};

export default roleLink;
