import React from 'react';
import pt from 'prop-types';
import { MrButton } from '@ion/components';

import s from './index.module.scss';

const Header = ({ cluster, timeframeHours, resetReports }) => {
  const title = () => {
    if (!cluster) {
      return null;
    }
    return (
      <div className={s.clusterTitle}>
        {' '}
        Schema Report for {cluster.name} (Last {timeframeHours} hours)
      </div>
    );
  };

  return (
    <>
      {title()}
      <MrButton text={'New Report'} className={s.report} onClick={() => resetReports()} />
    </>
  );
};

Header.propTypes = {
  cluster: pt.shape({
    name: pt.string,
  }),
  timeframeHours: pt.number,
  resetReports: pt.func.isRequired,
};

export default Header;
