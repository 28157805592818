import React, { useCallback, useState } from 'react';
import pt from 'prop-types';

import { MrIcon } from '@ion/components';

import ConfirmUnstageDeployedIntegration from '../../../integration/confirm-unstage-deployed-Integration';
import { useStageRevisionForDelete } from '@ion/api';

export function StageForDelete(row) {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [stageRevisionForDelete] = useStageRevisionForDelete();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stageRevisionForDeleteCallback = useCallback(stageRevisionForDelete, []);

  const { stagedRevisionId, integration } = row;

  return (
    <>
      <MrIcon id="icon-trash" onClick={() => setConfirmDelete(true)} title="Stage for delete" />
      {confirmDelete && (
        <ConfirmUnstageDeployedIntegration
          deleteFn={() => {
            stageRevisionForDeleteCallback({
              id: stagedRevisionId,
              stagedForDelete: true,
            });
          }}
          closeModal={() => setConfirmDelete(false)}
          integration={integration}
        />
      )}
    </>
  );
}

StageForDelete.propTypes = {
  row: pt.object,
};

export default StageForDelete;
