import { gql } from '@apollo/client';
import { default as pipelineFragment } from '../pipeline';

export const mutation = gql`
  mutation InsertPipeline($pipelines_insert_input: pipelines_insert_input!) {
    insert_pipelines_one(object: $pipelines_insert_input) {
      ...pipeline
    }
  }
  ${pipelineFragment}
`;

export function buildVariables({ id, name, writekey, clusterId }) {
  return {
    pipelines_insert_input: {
      id,
      name,
      writekey,
      clusterId,
    },
  };
}
