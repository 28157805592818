import React from 'react';
import ActionCell from './action-cell';
import TextCell from './text-cell';

export const COLUMNS = [
  {
    name: 'Display Name',
    cell: row => <TextCell text={row.display_name} />,
  },
  {
    name: 'Name',
    cell: row => <TextCell text={row.name} />,
  },
  {
    name: 'Identifier',
    cell: row => <TextCell text={row.id} />,
  },
  {
    name: 'Org Override',
    cell: row => <ActionCell row={row} />,
    width: '150px',
  },
];
