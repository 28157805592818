// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-audit-logs-audit-logs-table-index-module__toggle{cursor:pointer;margin-left:auto;width:1.8rem;transition:transform 200ms ease-out;transform:scaleY(-1)}.src-audit-logs-audit-logs-table-index-module__toggle.src-audit-logs-audit-logs-table-index-module__open{transform:scaleY(1)}.src-audit-logs-audit-logs-table-index-module__tableContainer{max-width:1312px}", "",{"version":3,"sources":["/usr/src/packages/app/src/audit-logs/audit-logs-table/index.module.scss"],"names":[],"mappings":"AA2BA,sDACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,mCAAA,CACA,oBAAA,CAEA,yGACE,mBAAA,CAIJ,8DACE,gBAAA","file":"index.module.scss","sourcesContent":["@import '../../../../component-library/src/scss/theme/brand-vars';\n@import '../../../../component-library/src/scss/theme/layout-vars';\n@import '../../../../component-library/src/scss/theme/shadow-vars';\n@import '../../../../component-library/src/scss/theme/mixins';\n\n// this file is only for global variable and mixins and should not output any styles\n\n///////////////////////////////////////////////////////////////////////////////\n\n@mixin cardList($tile) {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: center;\n\n  @if ($tile == true) {\n    justify-content: flex-start;\n    margin: -1rem;\n  }\n\n  & > div {\n    margin: 1rem;\n  }\n}\n\n.toggle {\n  cursor: pointer;\n  margin-left: auto;\n  width: 1.8rem;\n  transition: transform 200ms ease-out;\n  transform: scaleY(-1);\n\n  &.open {\n    transform: scaleY(1);\n  }\n}\n\n.tableContainer {\n  max-width: 1312px;\n}\n"]}]);
// Exports
exports.locals = {
	"toggle": "src-audit-logs-audit-logs-table-index-module__toggle",
	"open": "src-audit-logs-audit-logs-table-index-module__open",
	"tableContainer": "src-audit-logs-audit-logs-table-index-module__tableContainer"
};
module.exports = exports;
