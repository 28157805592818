import React from 'react';
import pt from 'prop-types';
import { useLocation } from 'react-router-dom';

import { useUserContext } from '@ion/user';
import FeatureGate from '@ion/app/src/launch-darkly/gates/feature-gate';
import { MrH, MrCopy, MrDownloadButton, MrCodeEditor, useScrollToTop } from '@ion/components';

import s from './index.module.scss';
import BaseTable from './base-table/BaseTable';
import { READABLE_HOSTING_TYPE } from './analytics-builder-file-hosting';
import ConsentCategoryRow from './ConsentCategoryRow';
import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';
import { INDEXED_DB_INT_VALUE, READABLE_STORAGE_TYPE } from './analytics-builder-advanced-settings';

const AnalyticsBuilderResults = ({ writekey, resultsData, analyticsSnippet, generatedFile, pipelineName }) => {
  const { pathname } = useLocation();
  const crossDomainResults = resultsData.syncInjectorSettings?.syncs.find(s => s.name === 'crossDomain');
  useScrollToTop(pathname, 'AppLayout');

  const { user, currentOrg } = useUserContext();
  const userData = { email: user?.email, org: currentOrg?.display_name };
  function track({ userData, pipelineName }) {
    __mrTracking?.track('analytics_file_downloaded', {
      orgName: userData?.org,
      userEmail: userData?.email,
      pipelineName: pipelineName,
    });
  }

  const syncInjectorDataFiltered = resultsData.syncInjectorSettings?.syncs.filter(s => s.name !== 'crossDomain');
  return (
    <>
      <div className={s.buttonContainer}>
        <MrDownloadButton
          compressFile={resultsData.compressFile}
          download={generatedFile}
          mimeType="text/javascript"
          buttonText="Download File"
          filename={resultsData.compressFile ? `${writekey}.js.gz` : `${writekey}.js`}
          compressionType="gzip"
          trackDownload={() => track({ userData, pipelineName })}
        />
      </div>
      <section className={s.section}>
        <MrH h="h5" sans styleNames="sans noMargin">
          Code Snippet
        </MrH>
        <div className={s.subSection}>
          <div className={s.snippetTitle}>
            <p>
              The MetaRouter tag should be added near the top of the {'<head>'} tag and before any other script or CSS
              tags.{' '}
              <a
                className={s.resultsLink}
                target="_blank"
                rel="noreferrer"
                href="https://docs.metarouter.io/docs/analyticsjs-for-web"
              >
                Learn More
              </a>
            </p>
            <div>
              <MrCopy text={analyticsSnippet} />
            </div>
          </div>
          <pre>
            <MrCodeEditor lang="lua" name="analyticsSnippet" value={analyticsSnippet} />
          </pre>
        </div>
      </section>

      <section className={s.section}>
        <MrH h="h5" sans styleNames="sans noMargin">
          <div className={s.circle}>1</div> File location and settings
        </MrH>
        <div className={s.subSection}>
          <BaseTable>
            <tbody>
              {resultsData.fileHostingType && (
                <tr>
                  <td>Hosting type</td>
                  <td>{READABLE_HOSTING_TYPE[resultsData.fileHostingType]}</td>
                </tr>
              )}
              <tr>
                <td>Analytics.js Hosting URL</td>
                <td>{resultsData.cdnURL}</td>
              </tr>
              <tr>
                <td>Ingestion URL</td>
                <td>{resultsData.host}</td>
              </tr>
              <tr>
                <td>Writekey</td>
                <td>{resultsData.writeKey}</td>
              </tr>
              <tr>
                <td>Compress File</td>
                <td>{resultsData.compressFile ? 'yes' : 'no'}</td>
              </tr>
              <tr>
                <td>Use HTTP cookie</td>
                <td>{resultsData.syncInjectorSettings?.itpSettings?.enabled ? 'yes' : 'no'}</td>
              </tr>
              <FeatureGate flagName={FEATURE_FLAGS.builderServerAdvancedSettings}>
                <tr>
                  <td className={s.setting}>Namespace</td>
                  <td className={s.value}>{resultsData?.windowVariableName}</td>
                </tr>
                <tr>
                  <td className={s.setting}>Storage Prefix</td>
                  <td className={s.value}>{resultsData?.syncInjectorSettings?.storage?.marker}</td>
                </tr>
                <tr>
                  <td className={s.setting}>Storage Type</td>
                  <td className={s.value}>{READABLE_STORAGE_TYPE[resultsData?.syncInjectorSettings?.storage?.type]}</td>
                </tr>
                {resultsData?.syncInjectorSettings?.storage?.type === INDEXED_DB_INT_VALUE && (
                  <tr>
                    <td className={s.setting}>Storage Database</td>
                    <td className={s.value}>{resultsData?.syncInjectorSettings?.storage?.database}</td>
                  </tr>
                )}
              </FeatureGate>
            </tbody>
          </BaseTable>
        </div>
      </section>

      <section className={s.section}>
        <MrH h="h5" sans styleNames="sans noMargin">
          <div className={s.circle}>2</div> Compliance Settings
        </MrH>
        <div className={s.subSection}>
          <BaseTable>
            <tbody>
              <tr>
                <td>Compliance Setting</td>
                <td>
                  {resultsData.syncInjectorSettings?.privacy?.impliedConsent
                    ? 'Implied consent (opt-in by default)'
                    : 'Explicit consent (opt-out by default)'}
                </td>
              </tr>
              <tr>
                <td>Cookie Name</td>
                <td>{resultsData.syncInjectorSettings?.privacy?.cookieName}</td>
              </tr>
            </tbody>
          </BaseTable>
        </div>
      </section>

      <section className={s.section}>
        <MrH h="h5" sans styleNames="sans noMargin">
          <div className={s.circle}>3</div> Identity Syncs
        </MrH>
        <div className={s.subSection}>
          {syncInjectorDataFiltered.map((sync, index) => (
            <div key={index}>
              <MrH h="h6" sans styleNames="sans noMargin">
                {sync.friendlyName}
              </MrH>
              <BaseTable>
                <tbody>
                  <ConsentCategoryRow consentCategoryId={sync.consentCategoryId} />
                  {Object.entries(sync.settings).map(entry => (
                    // setting = entry[0], value = entry[1]
                    <tr key={entry[0]}>
                      <td className={s.setting}>{entry[0]}</td>
                      <td className={s.value}>{typeof entry[1] === 'string' ? entry[1] : JSON.stringify(entry[1])}</td>
                    </tr>
                  ))}
                </tbody>
              </BaseTable>
            </div>
          ))}
        </div>
      </section>

      <FeatureGate flagName="ecmAjsCrossDomainIdentitySync">
        <section className={s.section}>
          <MrH h="h5" sans styleNames="sans noMargin">
            <div className={s.circle}>4</div> Cross-Domain Identity Sync
          </MrH>
          <div className={s.subSection}>
            <div key={'crossDomain'}>
              <BaseTable testId="crossDomainResults">
                <tbody>
                  {crossDomainResults && (
                    <>
                      <ConsentCategoryRow consentCategoryId={crossDomainResults?.consentCategoryId} />
                      <tr>
                        <td className={s.setting}>Urls</td>
                        <td className={s.value}>{crossDomainResults?.settings?.syncURLs.join(', ')}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </BaseTable>
            </div>
          </div>
        </section>
      </FeatureGate>
      <FeatureGate flagName="ecmAjsClientSideIntegrations">
        <section className={s.section}>
          <MrH h="h5" sans styleNames="sans noMargin">
            <div className={s.circle}>&#65290;</div> Client-Side Integrations
          </MrH>
          <div className={s.subSection}>
            {resultsData.integrations?.map((integration, index) => (
              <div key={index}>
                <MrH h="h6" sans styleNames="sans noMargin">
                  {integration.friendlyName}
                </MrH>
                <BaseTable testId="clientSideIntegrationsResults">
                  <tbody>
                    {Object.entries(integration.options).map(entry => (
                      // setting = entry[0], value = entry[1]
                      <tr key={entry[0]}>
                        <td className={s.setting}>{entry[0]}</td>
                        <td className={s.value}>
                          {typeof entry[1] === 'string' ? entry[1] : JSON.stringify(entry[1])}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </BaseTable>
              </div>
            ))}
          </div>
        </section>
      </FeatureGate>
    </>
  );
};

AnalyticsBuilderResults.propTypes = {
  writekey: pt.string,
  resultsData: pt.object,
  analyticsSnippet: pt.string,
  generatedFile: pt.string,
  compressFile: pt.bool,
  pipelineName: pt.string,
};

export default AnalyticsBuilderResults;
