import yaml from 'js-yaml';

const GetDataFromFile = (extension, fileData) => {
  let data;
  if (extension === 'yaml' || extension === 'yml') {
    data = yaml.load(fileData);
  }

  if (extension === 'json') {
    data = JSON.parse(fileData);
  }

  return data;
};

export default GetDataFromFile;
