import React from 'react';
import { omit } from 'lodash';

import CodeEditorContainer from './code-editor-container';
import SimpleCodeEditor from './simple-code-editor';

export default function MrCodeEditor(props) {
  return <CodeEditorContainer {...props} CodeEditor={SimpleCodeEditor} />;
}

CodeEditorContainer.propTypes = omit(CodeEditorContainer.propTypes, ['CodeEditor']);
