import { forEach as _forEach, isEmpty as _isEmpty } from 'lodash';
/**
 *
 * ValidateRequiredParams throws errors if there are some uncompleted required fields.
 *
 * @params params: []
 *
 * @returns {err}
 */
const validateRequiredParams = params => {
  _forEach(params, ({ name, isOptional, pipelineVarId, defaultValue }) => {
    const emptyParam = (name, pipelineVarId, defaultValue) => {
      if (name === 'AUTHENTICATION (SASL)') {
        return _isEmpty(defaultValue.username) || _isEmpty(defaultValue.password);
      }

      if (name === 'STAGE') {
        return _isEmpty(defaultValue.bucket);
      }
      return pipelineVarId === undefined && defaultValue === undefined;
    };

    if (!isOptional && emptyParam(name, pipelineVarId, defaultValue)) {
      throw `${name} is required`;
    }
  });
};

export default validateRequiredParams;
