import auditLogMutation from '../insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const consentCategoryDeletedAuditLogMutation = auditLogMutation;

export function buildVariables({ consentCategoryId, name, code }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      consentCategoryId,
      name,
      code,
    }),
  };
}
