import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';
import { MrAvatar } from '@ion/components';
import StarterKits from '../../starterkits/starterkits-state';
import s from './logos.module.scss';

const IntegrationLogo = ({ className, id, type = 'logo', ...props }) => {
  const { kits, kitById } = StarterKits();

  // retrieve integration starterkit from starterkits array
  const starterkit = kitById(kits, id);

  let logo;

  try {
    logo = require(`./logos/${id}.svg`).default;
  } catch (error) {
    return <MrAvatar className={cx(s[type], className)} name={id} {...props} />;
  }

  return <MrAvatar src={logo} className={cx(s[type], className)} name={starterkit?.friendlyName} {...props} />;
};

IntegrationLogo.propTypes = {
  className: pt.string,
  id: pt.string,
  type: pt.string,
};

export default IntegrationLogo;
