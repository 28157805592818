import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './pill.module.scss';
import MrText from '../text/text';

const MrPill = ({ className, testId = 'MrPill', text = '', styleNames = '', size = '', color = '' }) => {
  return (
    <MrText
      className={cx(
        s.pill,
        className,
        styleNames.split(' ').map(c => s[c]),
        s[size],
        s[color]
      )}
      data-test-id={testId}
    >
      {text}
    </MrText>
  );
};

MrPill.propTypes = {
  className: pt.string,
  testId: pt.string,
  text: pt.string,
  styleNames: pt.string,
  size: pt.oneOf(['small', 'medium']),
  color: pt.oneOf(['primary-100']),
};

export default MrPill;
