// ND Tech Debt - These styles are duplicated from Sass variables
// Because we use CSS modules, I we can't set children styles
// via the CSS Overrides Feature - https://react-data-table-component.netlify.app/?path=/docs/api-css-escape--page
// And we can't access the Sass variables in JavaScript.
// So we need to figure out a different solution.

export const mrGray600 = '#525365';
export const mrNeutralN5 = '#808295';
export const mrPrimary500 = '#f5f9ff';
export const mrBlueB4 = '#338cf1';
export const mrGray200 = '#EBECF4';

export const IBMPlexSans = {
  'font-family': "'IBM Plex Sans', Arial, sans-serif",
  'font-style': 'normal',
  'font-size': '14px',
};

export const sans = {
  ...IBMPlexSans,
  'font-weight': '400',
};

export const transparent = {
  backgroundColor: 'transparent',
};

export const borderRadius = {
  borderRadius: '0 0 8px 8px',
};
