import { isEmpty as _isEmpty, omit as _omit } from 'lodash';

export function transformResponse(data) {
  if (!data || _isEmpty(data.analytics_files)) return null;

  const analyticsFile = data.analytics_files[0];

  const topLevel = _omit(analyticsFile, ['analytics_file_identity_syncs', 'analytics_file_settings']);

  const identitySyncs = analyticsFile.analytics_file_identity_syncs.map(sync => {
    const topLevel = _omit(sync, ['identity_sync', '__typename']);
    return {
      ...topLevel,
      name: sync.identity_sync.name,
      friendlyName: sync.identity_sync.friendlyName,
    };
  });

  const settings = _omit(analyticsFile.analytics_file_settings, '__typename');

  return { ...topLevel, identitySyncs, settings };
}
