import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputText } from '@ion/components';

import { useFormContext } from 'app/context/form-context';

const { validateRequired } = formValidators;

const Date = ({ opId, _path, date }) => {
  const { validateField, resetError, formState, setField } = useFormContext();

  const dateName = `${opId}-date-${_path}`;

  return (
    <MrInputText
      label="format"
      onBlur={validateField}
      onInput={resetError}
      name={dateName}
      value={date}
      onChange={setField}
      errorMsg={formState[dateName]?.errorMsg}
      placeholder="format string"
      validators={[validateRequired()]}
      labelPosition="top"
    />
  );
};

Date.propTypes = {
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
  date: pt.string,
};

export default Date;
