import { useState, useEffect } from 'react';

import useEventListener from './use-event-listener';
export default () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  // Handler to call on window resize
  function handleResize() {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  // Add event listener
  useEventListener('resize', handleResize);

  useEffect(() => {
    // Call handler right away so state gets updated with initial window size
    handleResize();
  }, []);

  return windowSize;
};
