import React from 'react';
import propTypes from 'prop-types';

import styles from './input-textarea.module.scss';

import { useFormFieldContext } from '../shared/form/form-field-context-provider';

export default function TextArea({ attrs, testId }) {
  const { id } = useFormFieldContext();

  return (
    <div className={styles.input} data-test-id={testId}>
      <textarea id={id} {...attrs} />
    </div>
  );
}

TextArea.propTypes = {
  attrs: propTypes.object.isRequired,
  testId: propTypes.string,
};
