import React from 'react';
import pt from 'prop-types';
import { MrIcon, MrText } from '@ion/components';
import IntegrationLogo from 'app/components/integrations/integration-logo';

import styles from './integration-table-title.module.scss';

const IntegrationTableTitle = ({ logoName, title, onClickDelete, deleteTitle }) => {
  return (
    <div className={styles.syncTitle}>
      <IntegrationLogo id={logoName} />
      <MrText styleNames="sans bold">{title}</MrText>
      <MrIcon className={styles.rightIcon} id="icon-trash" onClick={onClickDelete} title={deleteTitle} />
    </div>
  );
};

IntegrationTableTitle.propTypes = {
  logoName: pt.string.isRequired,
  title: pt.string.isRequired,
  onClickDelete: pt.func.isRequired,
  deleteTitle: pt.string.isRequired,
};
export default IntegrationTableTitle;
