import { useMutation } from './../index';
import { gql } from '@apollo/client';
import permission from '../Apollo/permissions';
import { query } from './useQueryIntegrations';

const mutation = gql`
  mutation UpsertIntegrationNotesOne($object: integration_notes_insert_input!) {
    insert_integration_notes_one(
      object: $object
      on_conflict: { constraint: integration_notes_integration_id_key, update_columns: [notes] }
    ) {
      integrationId
      notes
      id
    }
  }
`;

export default (options = {}) => {
  const [call, response] = useMutation(mutation, {
    errorMsg: ({ errorMsg }) => {
      return {
        title: `Notes Save Request Failed`,
        message: errorMsg,
      };
    },
    context: { permission: permission.updateIntegration },
    ...options,
    update: (cache, { data }) => {
      const newItem = data.insert_integration_notes_one;

      const { integrations } = cache.readQuery({ query }) || [];
      if (!integrations) return;

      const updated = integrations.map(i => {
        if (i.id === newItem.integrationId) {
          return { ...i, integration_notes: newItem };
        }
        return i;
      });

      cache.writeQuery({
        query,
        data: {
          integrations: updated,
        },
      });
    },
  });
  return [
    ({ notes, integrationId }) => {
      if (!notes) {
        throw new Error({ param: 'notes', msg: 'Nothing to update' });
      }
      if (!integrationId) {
        throw new Error('No integration selected');
      }
      return call({
        variables: {
          object: {
            integrationId,
            notes,
          },
        },
      });
    },
    response,
  ];
};
