import React from 'react';
import propTypes from 'prop-types';
import Select from 'react-select';

import SelectContainer from './select-container';
import buildSelectProps from './build-select-props';
import HiddenInput from '../shared/form/hidden-input';
import FormField from '../shared/form/form-field';

/*
  ND - As a part of refactoring `MrInputSelect`, I decided to keep the existing API to remain
  consistent with the existing MrInputSelectDeprecated API and other MrInput component API's. In a future refactor,
  FormField should be extracted out of the component and used by the caller. And other MrInput* components should
  use FormField instead of duplicating it.
 */

export default function MrInputSelect({
  /* FormField Props */
  label,
  helperText,
  validators,
  errorMsg,
  /* End FormField Props */
  name,
  value,
  disabled,
  className,
  testId = 'MrInputSelect',
  alphabetized,
  ...selectProps
}) {
  return (
    <FormField label={label} helperText={helperText} validators={validators} errorMsg={errorMsg}>
      <SelectContainer className={className} disabled={disabled} testId={testId}>
        <Select {...buildSelectProps({ name, disabled, value, alphabetized, ...selectProps })} />
        <HiddenInput name={name} value={value} />
      </SelectContainer>
    </FormField>
  );
}

const optionsPropType = propTypes.oneOfType([
  propTypes.arrayOf(propTypes.string),
  propTypes.arrayOf(
    propTypes.shape({
      text: propTypes.oneOfType([propTypes.string, propTypes.element]).isRequired,
      value: propTypes.oneOfType([propTypes.string, propTypes.object]).isRequired,
      disabled: propTypes.bool,
    })
  ),
]);

const valueType = propTypes.oneOfType([propTypes.string, propTypes.array, propTypes.object]);

MrInputSelect.propTypes = {
  /* FormField Props */
  label: propTypes.string,
  validators: propTypes.arrayOf(propTypes.func),
  helperText: propTypes.string,
  errorMsg: propTypes.string,
  /* End FormField Props */
  options: optionsPropType.isRequired,
  name: propTypes.string.isRequired,
  value: valueType,
  defaultValue: valueType,
  disabled: propTypes.bool,
  className: propTypes.string,
  testId: propTypes.string,
  placeholder: propTypes.oneOfType([propTypes.string, propTypes.bool]),
  onChange: propTypes.func,
  isSearchable: propTypes.bool,
  isClearable: propTypes.bool,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  selectedVariant: propTypes.oneOf(['green', 'orange']),
  alphabetized: propTypes.bool,
  // advanced react-select props
  closeMenuOnScroll: propTypes.oneOfType([propTypes.bool, propTypes.func]),
  menuPosition: propTypes.string,
  maxMenuHeight: propTypes.number, // defaults to 300 in react-select
};
