import React from 'react';

import { MrAppMain } from '@ion/components';
import { OrganizationOverrideTable } from './components/OrganizationOverrideTable';

export const OrganizationOverridePage = () => {
  return (
    <MrAppMain styleNames="noBackground">
      <OrganizationOverrideTable />
    </MrAppMain>
  );
};
