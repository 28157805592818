import React, { createContext, useCallback, useContext, useState } from 'react';
import pt from 'prop-types';

const StagedIdentitySyncsContext = createContext({
  stagedIdentitySyncs: [],
  addIdentitySyncs: () => {
    //
  },
});

export const StagedIdentitySyncsProvider = ({ children }) => {
  const [stagedIdentitySyncs, setStagedIdentitySyncs] = useState([]);

  const addIdentitySyncs = useCallback(item => {
    setStagedIdentitySyncs(item);
  }, []);

  return (
    <StagedIdentitySyncsContext.Provider value={{ stagedIdentitySyncs, addIdentitySyncs: addIdentitySyncs }}>
      {children}
    </StagedIdentitySyncsContext.Provider>
  );
};

export const useStagedIdentitySyncs = () => {
  const context = useContext(StagedIdentitySyncsContext);
  if (!context) {
    throw new Error('useStagedIdentitySyncs must be used within a StagedIdentitySyncsProvider');
  }
  return [context.stagedIdentitySyncs, context.addIdentitySyncs];
};

StagedIdentitySyncsProvider.propTypes = {
  children: pt.node.isRequired,
};
