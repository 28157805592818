import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputSelectDeprecated } from '@ion/components';

import { useFormContext } from 'app/context/form-context';
const { validateRequired } = formValidators;

const Uuid = ({ opId, _path, uuid }) => {
  const { setField, validateField, formState } = useFormContext();
  const versionName = `${opId}-uuid-${_path}`;

  return (
    <MrInputSelectDeprecated
      name={versionName}
      options={['v1', 'v4']}
      value={uuid}
      onChange={setField}
      onBlur={validateField}
      errorMsg={formState[versionName]?.errorMsg}
      placeholder="select one"
      label="version"
      validators={[validateRequired()]}
      labelPosition="top"
    />
  );
};

Uuid.propTypes = {
  uuid: pt.oneOf(['v1', 'v4']).isRequired,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default Uuid;
