import { useUserContext } from '@ion/user';
import { useQueryIntegrations } from '@ion/api';
import { isNil } from 'lodash';

export const useGetAvailableIntegrations = (pipeline, excludedIntegrationId) => {
  const { orgId } = useUserContext();
  let availableIntegrations = null;
  const stagedRevisionsData = pipeline.pipelineIntegrations.map(
    pipelineIntegration => pipelineIntegration.stagedRevision
  );

  const { data: integrationsData, loading: loadingIntegration } = useQueryIntegrations({
    variables: { limit: null, orgId },
  });
  const isLoading = loadingIntegration;
  if (!isLoading) {
    if (isNil(stagedRevisionsData)) {
      availableIntegrations = [];
    } else {
      availableIntegrations = integrationsData.filter(integration => {
        if (integration.id === excludedIntegrationId) {
          return false;
        }
        return !stagedRevisionsData.some(revision => revision.playbook.integration.id === integration.id);
      });
    }
  }

  return {
    integrationsData: integrationsData,
    availableIntegrations: availableIntegrations,
    hasAvailableIntegrations: !isNil(availableIntegrations) && availableIntegrations.length > 0,
  };
};
