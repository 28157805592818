import React from 'react';
import pt from 'prop-types';

import { MrH, MrText, MrAppMain } from '@ion/components';

import GeneralCard from 'app/settings/general';

import s from '../index.module.scss';

const Details = ({ me }) => {
  return (
    <MrAppMain className={s.main}>
      <GeneralCard title="Profile">
        <section>
          <MrH h="h3" styleNames="noMargin">
            {me.name}
          </MrH>
          <div className={s.spacer} />
          <MrText styleNames="label">email</MrText>
          <MrText>{me.email}</MrText>
        </section>
      </GeneralCard>
    </MrAppMain>
  );
};

Details.propTypes = {
  me: pt.shape({
    name: pt.string,
    email: pt.string.isRequired,
  }).isRequired,
};

export default Details;
