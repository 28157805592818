import React from 'react';
import pt from 'prop-types';
import { startCase as _startCase } from 'lodash';

import s from './index.module.scss';

import { MrTable, MrAppMain, MrDate, MrTwoPaneLayout } from '@ion/components';
import IntegrationLogo from '../../components/integrations/integration-logo';
import AssignedPipelinesTableBody from './AssignedPipelinesTableBody';

const Overview = ({ integration, kit }) => {
  return (
    <MrTwoPaneLayout
      className={s.commonTable}
      paneOne={
        <>
          <div className={s.header}> Details </div>
          <MrTable className={s.details}>
            <tbody>
              <tr>
                <td> Created </td>
                <td>
                  {' '}
                  <MrDate date={integration.createdAt} format="d MMM yyyy, HH:mm" />{' '}
                </td>
              </tr>

              <tr>
                <td> Integration </td>
                <td>
                  <div className={s.integration}>
                    <IntegrationLogo id={integration.starterkitId} className={s.logo} />
                    {kit?.friendlyName ?? _startCase(integration.starterkitId)}
                  </div>
                </td>
              </tr>
            </tbody>
          </MrTable>
        </>
      }
      paneTwo={
        <MrAppMain>
          <div className={s.header}> Assigned Pipelines </div>
          <div className={s.scrollable}>
            <MrTable className={s.assigned}>
              <thead>
                <tr>
                  <th> Pipeline </th>
                  <th> Revision </th>
                </tr>
              </thead>

              <AssignedPipelinesTableBody playbooks={integration.playbooks} />
            </MrTable>
          </div>
        </MrAppMain>
      }
    />
  );
};

Overview.propTypes = {
  integration: pt.object.isRequired,
  kit: pt.object.isRequired,
};
export default Overview;
