import { isEmpty, pick } from 'lodash';
import isNil from 'lodash/isNil';
import classNames from 'classnames';

const DEFAULT_PROPS = {
  disableClock: true,
  disableCalendar: true,
};

export default function buildDateTimeRangePickerProps(props) {
  return {
    ...DEFAULT_PROPS,
    ...buildBasicProps(props),
    ...buildValue(props),
    ...buildOnChange(props),
    ...buildClassName(props),
  };
}

function buildBasicProps(props) {
  return pick(props, 'onChange', 'minDate', 'maxDate', 'disabled', 'onClockClose', 'errorMsg');
}

function buildValue({ startTime, endTime }) {
  return { value: [startTime, endTime] };
}

export function buildOnChange({ onChange, minDate, maxDate }) {
  if (isNil(onChange)) {
    return;
  }

  const translateOnChange = value => {
    let startTime = null;
    let endTime = null;

    if (!isNil(value)) {
      [startTime, endTime] = value;
    }

    if (!isNil(minDate) && !isNil(startTime) && startTime < minDate) {
      startTime = minDate;
    }

    if (!isNil(maxDate) && !isNil(endTime) && endTime > maxDate) {
      endTime = maxDate;
    }

    onChange({ startTime, endTime });
  };
  return {
    onChange: translateOnChange,
  };
}

export function buildClassName({ disabled, disabledColor = 'grey', errorMsg, startTime, endTime }) {
  const disabledColorClassName = disabled ? `disabledColor-${disabledColor}` : null;
  const errorColorClassName = errorMsg || startTime > endTime ? 'errorColor' : null;
  const className = classNames(disabledColorClassName || errorColorClassName);

  if (!isEmpty(className)) {
    return { className };
  }
}
