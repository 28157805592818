import React from 'react';
import pt from 'prop-types';

import TextArea from './TextArea';
import FormField from '../shared/form/form-field';

import s from './input-textarea.module.scss';

const MrInputTextarea = ({
  onChange,
  onBlur,
  onInput,
  disabled,
  placeholder,
  value,
  label,
  name,
  errorMsg,
  spellCheck = false,
  autoComplete = 'off',
  validators,
  readOnly,
  fullWidth,
  autoFocus,
  helperText,
  rows = 4,
  testId = 'MrInputTextarea',
  required,
}) => {
  const attrs = {
    type: 'text',
    className: errorMsg && s.hasError,
    disabled,
    placeholder,
    onBlur,
    name,
    autoComplete,
    spellCheck,
    readOnly,
    autoFocus,
    onInput,
    rows,
    value,
    onChange,
  };

  const formFieldProps = {
    label,
    required,
    validators,
    helperText,
    errorMsg,
    fullWidth,
  };

  return (
    <FormField {...formFieldProps}>
      <TextArea attrs={attrs} testId={testId} />
    </FormField>
  );
};

MrInputTextarea.propTypes = {
  disabled: pt.bool,
  placeholder: pt.string,
  value: pt.string,
  label: pt.string,
  onChange: pt.func,
  onInput: pt.func,
  onBlur: pt.func,
  name: pt.string.isRequired,
  errorMsg: pt.string,
  spellCheck: pt.bool,
  autoComplete: pt.oneOf(['off', 'on']),
  validators: pt.arrayOf(pt.func),
  readOnly: pt.bool,
  fullWidth: pt.bool,
  autoFocus: pt.bool,
  helperText: pt.string,
  rows: pt.number,
  testId: pt.string,
  required: pt.bool,
};

export default MrInputTextarea;
