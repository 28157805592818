import pt from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClusters } from '@ion/api';
import { Select } from '@metarouter-private/mr-mui';

const ClusterSelect = ({ defaultValue, onChange, testId = 'ClusterSelect' }) => {
  const { data: clustersData } = useQueryClusters();
  const [clusterId, setClusterId] = useState(defaultValue ?? '');

  const options = useMemo(
    () =>
      clustersData.map(cluster => {
        return { label: cluster.name, value: cluster.id };
      }),
    [clustersData]
  );

  function selectCluster(clusterId) {
    setClusterId(clusterId);
    onChange(clusterId);
  }

  function onChangeCluster(event) {
    selectCluster(event.target.value);
  }

  useEffect(() => {
    if (options.length === 1 && clusterId === '') {
      selectCluster(options[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <Select
      data-test-id={testId}
      label="Cluster"
      onChange={onChangeCluster}
      options={options || [{ label: 'No clusters available', value: '' }]}
      value={clusterId}
    />
  );
};

ClusterSelect.propTypes = {
  defaultValue: pt.string,
  onChange: pt.func.isRequired,
  testId: pt.string,
};

export default ClusterSelect;
