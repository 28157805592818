import React, { useState } from 'react';
import pt from 'prop-types';
import { isNil } from 'lodash';
import cx from 'classnames';

import useValidatorLookup from './../../hooks/use-validator-lookup';
import generateId from './../../utils/uuid';

import s from './code-editor.module.scss';

const CodeEditorContainer = ({
  name,
  value,
  label,
  errorMsg,
  validators,
  onBlur,
  onInput,
  onChange,
  readOnly,
  className,
  labelPosition = 'left',
  lang,
  rows = 20,
  testId = 'MrCodeEditor',
  CodeEditor,
}) => {
  const uuid = generateId();
  const { addValidators } = useValidatorLookup(uuid);
  const [hasFocus, setHasFocus] = useState();

  addValidators({ uuid, validators });

  return (
    <div className={cx(className)}>
      {label && labelPosition === 'left' && (
        <label className={s.labelLeft} htmlFor={uuid}>
          {label}
        </label>
      )}

      {label && labelPosition === 'top' && (
        <div>
          <label className={s.labelTop} htmlFor={uuid}>
            {label}
          </label>
        </div>
      )}

      <div className={cx(hasFocus && s.hasFocus, errorMsg && s.hasError, s.container)}>
        <CodeEditor
          id={uuid}
          lang={lang}
          name={name}
          value={value}
          placeholder={`Put Some ${lang} Code Here`}
          readOnly={readOnly}
          onChange={onChange}
          onBlur={event => {
            if (onBlur) {
              onBlur(event);
            }
            setHasFocus(false);
          }}
          onFocus={() => setHasFocus(true)}
          onInput={onInput}
          rows={rows}
          hasError={!isNil(errorMsg)}
          testId={testId}
        />
      </div>

      {errorMsg && <p className={s.errorMsg}>{errorMsg}</p>}
    </div>
  );
};

CodeEditorContainer.propTypes = {
  value: pt.string,
  label: pt.string,
  name: pt.string.isRequired,
  onBlur: pt.func,
  onInput: pt.func,
  onChange: pt.func,
  errorMsg: pt.string,
  validators: pt.arrayOf(pt.func),
  className: pt.string,
  labelPosition: pt.oneOf(['top', 'left']),
  lang: pt.string.isRequired,
  rows: pt.oneOf([20, 30, 40]), // Similar to rows property for textarea.
  testId: pt.string,
  readOnly: pt.bool,
  CodeEditor: pt.node,
};

export default CodeEditorContainer;
