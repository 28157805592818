import React from 'react';
import ReactQuill from 'react-quill';
import pt from 'prop-types';
import cx from 'classnames';

import s from './text-editor.module.scss';
import './text-editor.scss';

const MrTextEditor = ({ className, testId = 'MrTextEditor', onChange, value, placeholder }) => {
  return (
    <ReactQuill
      className={cx(s.textEditor, className)}
      data-test-id={testId}
      theme="snow"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

MrTextEditor.propTypes = {
  children: pt.node,
  className: pt.string,
  testId: pt.string,
  onChange: pt.func,
  value: pt.object,
  placeholder: pt.string,
};

export default MrTextEditor;
