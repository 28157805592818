import { gql } from '@apollo/client';

export const mutation = gql`
  mutation EditOrganizationMember($userId: String!, $roleIds: [String]!, $name: String, $email: String) {
    auth0_edit_organization_member(userId: $userId, roleIds: $roleIds, name: $name, email: $email) {
      id
      name
      email
      roles {
        id
        name
      }
    }
  }
`;

export function buildVariables({ userId, roleIds, name, email }) {
  return {
    userId,
    roleIds,
    name,
    email,
  };
}
