import React, { useState } from 'react';
import { assign as _assign } from 'lodash';
import {
  CONSENT_FORM_TYPES,
  PipelineIntegrationForm,
} from '../../../pipeline-integration-form/PipelineIntegrationForm';
import { MrErrorModal } from '@ion/components';
import { usePipelineCardContext } from '../../../../card/context/pipeline-card-context';
import { usePipelineIntegrationModalContext } from '../../../context/pipeline-integration-modal-context';
import { useUpdatePipelineIntegration } from '@ion/api';

export const EditIntegration = () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const { pipeline } = usePipelineCardContext();
  const { onCloseModal, selectedIntegration } = usePipelineIntegrationModalContext();
  const [updatePipelineIntegration] = useUpdatePipelineIntegration({
    onCompleted: () => {
      onCloseModal();
    },
    onError: err => {
      setErrorMsg(err.errorMsg);
    },
  });

  const onSubmit = data => {
    const consentCategoryId = data.consentCategory;
    updatePipelineIntegration({
      pipelineIntegration: selectedIntegration.pipelineIntegration,
      playbookId: data.revision,
      ...(consentCategoryId && { consentCategoryId }),
      pipelineVars: _assign({}, selectedIntegration.mergedPipelineVars, data.pipelineVars),
      readyForDeploy: true,
    });
  };
  return (
    <>
      <PipelineIntegrationForm
        pipeline={pipeline}
        onSubmit={onSubmit}
        type={CONSENT_FORM_TYPES.update}
        onCancel={onCloseModal}
        selectedIntegration={selectedIntegration}
      />

      {errorMsg && (
        <MrErrorModal
          header="Error Editing Staged Revision"
          errorMessage={errorMsg}
          closeModal={() => setErrorMsg(false)}
        />
      )}
    </>
  );
};
