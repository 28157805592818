import { useMutation } from '../../index';
import permission from '../../Apollo/permissions';
import { buildVariables, mutation } from './build-ajs-file-mutation-builder';
import { useUserContext } from '@ion/user';

import {
  mutation as ajsFileBuiltMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/ajs-file-built/audit-log-mutation-builder';
import mergeQueries from '../../Apollo/merge-queries';

export const combinedMutation = mergeQueries('BuildAjsFileAndAudit', [mutation, ajsFileBuiltMutation]);

export default function useBuildAjsFile(options = {}) {
  const { user, currentOrg } = useUserContext();
  const userData = { email: user?.email, org: currentOrg?.display_name };
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    context: { permission: permission.updatePipelineAJSBuilder },
    update: buildUpdateCache(userData),
  });

  const buildAjsFileMutation = ({ pipelineId, pipelineName, settingsJson }) => {
    settingsJson.clientName = currentOrg.name;
    return executeMutation({
      variables: {
        ...buildVariables({ settingsJson }),
        ...buildAuditLogVariables({ pipelineId, pipelineName }),
      },
    });
  };

  return [buildAjsFileMutation, response];
}

function buildUpdateCache(userData) {
  return (_, { data }) => {
    const pipelineName = data?.insert_audit_logs_one?.description?.replace('File built for ', '');
    track({ userData, pipelineName });
  };
}

function track({ userData, pipelineName }) {
  __mrTracking?.track('analytics_file_saved', {
    orgName: userData.org,
    userEmail: userData.email,
    pipelineName: pipelineName,
  });
}
