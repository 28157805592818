import { useMemo } from 'react';
import { useQuery } from './../index';
import { gql } from '@apollo/client';
import { transformConsentCategories } from './transform-consent-categories';
import consentCategory from './consent-category';
import permission from '../Apollo/permissions';

export const GET_CONSENT_CATEGORIES = gql`
  query ConsentCategories {
    consentCategories: consent_categories(where: { deletedAt: { _is_null: true } }, order_by: { createdAt: desc }) {
      ...consentCategory
    }
  }
  ${consentCategory}
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, error, isAllowed } = useQuery(GET_CONSENT_CATEGORIES, {
    variables, // can apply any transforms or default vars here
    context: { permission: permission.readConsentCategory },
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  const transformedData = useMemo(() => transformConsentCategories(data), [data]);

  return {
    data: transformedData,
    loading,
    isAllowed,
    error,
  };
};
