import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import s from './back.module.scss';
import back from './assets/icon-arrow-left.svg';
import MrLink from '../link/link';

const MrBack = ({ children = `Back`, className, ...props }) => {
  return (
    <MrLink styleNames="gray" {...props} className={cx(s.back, className)}>
      <img src={back} alt="" className={s.img} />
      {children}
    </MrLink>
  );
};

MrBack.propTypes = {
  children: pt.node,
  className: pt.string,
};

export default MrBack;
