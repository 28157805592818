import React from 'react';
import isNil from 'lodash/isNil';
import { useTransformPlaybookContext } from '../../../context/transform-playbook-context';
import { MrCodeEditor } from '@ion/components';
import { CircularProgress, Grid } from '@metarouter-private/mr-mui';
import { OutputError } from './output-error/OutputError';

export const OutputEditor = () => {
  const { output, isLoading, outputErrors } = useTransformPlaybookContext();

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }
  if (outputErrors) {
    return <OutputError outputErrors={outputErrors} />;
  }

  if (isNil(output)) {
    return null;
  }

  return <MrCodeEditor name="outputEvent" lang="json" rows={30} value={output} readOnly />;
};
