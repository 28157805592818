import React from 'react';

export default function Clusters() {
  return (
    <svg width="24" height="24" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 13.9989V16.9989M12 18.9989C12 20.1035 11.1046 20.9989 10 20.9989C8.89543 20.9989 8 20.1035 8 18.9989M12 18.9989C12 17.8944 11.1046 16.9989 10 16.9989M12 18.9989H19M8 18.9989C8 17.8944 8.89543 16.9989 10 16.9989M8 18.9989H1M5.01 10.999H5M15.8 7.99909H4.2M15.8 7.99909C16.9201 7.99909 17.4802 7.99883 17.908 7.78084C18.2843 7.58909 18.5903 7.28313 18.782 6.90681C19 6.47899 19 6.11903 19 4.99892C19 3.87882 19 3.42657 18.782 2.99875C18.1774 1.81219 16.9454 1.99899 15.8 1.99899H4.2C3.05634 1.99899 1.8206 1.81605 1.21799 2.99875C1 3.42657 1 3.87882 1 4.99892C1 6.11903 1 6.47899 1.21799 6.90681C1.40973 7.28313 1.71569 7.58909 2.09202 7.78084C2.51984 7.99883 3.07989 7.99909 4.2 7.99909M15.8 7.99909C16.9181 7.99909 18.207 8.01099 18.782 9.13945C19 9.56728 19 9.87892 19 10.999C19 12.1191 19 12.4791 18.782 12.9069C18.5903 13.2832 18.2843 13.5892 17.908 13.7809C17.4802 13.9989 16.9201 13.9989 15.8 13.9989H4.2C3.0799 13.9989 2.51984 13.9989 2.09202 13.7809C1.71569 13.5892 1.40973 13.2832 1.21799 12.9069C1 12.4791 1 12.1191 1 10.999C1 9.87892 1 9.56728 1.21799 9.13945C1.79145 8.01396 3.08371 7.99909 4.2 7.99909M5.01 4.99892H5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
