import { useQuery } from './../index';
import { gql } from '@apollo/client';

import pipeline from './pipeline';
import permission from '../Apollo/permissions';
export const query = gql`
  query Pipeline($pipelineId: uuid!) {
    pipelines_by_pk(id: $pipelineId) {
      ...pipeline
    }
  }
  ${pipeline}
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, isAllowed, error } = useQuery(query, {
    variables, // can apply any transforms or default vars here
    context: { permission: permission.readPipeline },
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: data?.pipelines_by_pk,
    loading,
    isAllowed,
    error,
  };
};
