export const nameColumnProperties = {
  maxWidth: '100%',
  minWidth: '221px',
  compact: true,
};

export const dataColumnProperties = {
  maxWidth: '195px',
  minWidth: '78px',
};

export const defaultProperties = {
  sortable: true,
};
