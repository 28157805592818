import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import useValidatorLookup from './../../hooks/use-validator-lookup';
import generateId from '../../utils/uuid';

import s from './toggle-text.module.scss';

const MrToggleText = ({
  onChange,
  label,
  options = { option1: { name: 'OFF', value: 'false' }, option2: { name: 'ON', value: 'true' } },
  name,
  value,
  className,
  errorMsg,
  validators,
  testId = 'MrToggleText',
}) => {
  const uuid = generateId();
  const { addValidators } = useValidatorLookup(uuid);

  addValidators({ uuid, validators });

  const attrs = {
    type: 'hidden',
    className: errorMsg && s.hasError,
    id: uuid,
    name,
    onChange: onChange,
  };

  const buildOnClick = value => {
    return () => {
      onChange({ target: { name, value } });
    };
  };

  return (
    <div className={cx(s.toggleText, className)} data-test-id={testId}>
      {label && (
        <label className={s.label} htmlFor={uuid}>
          {label}
        </label>
      )}
      {/* hidden input stores the selected value for form submission */}
      <input value={value} {...attrs} />

      <div className={s.toggleContainer}>
        {[options.option1, options.option2].map((option, index) => {
          const key = `option${index}`;
          const classes = cx(s.toggleOption, { [s.selected]: value === option.value });
          const onClick = buildOnClick(option.value);
          return (
            <div className={classes} onClick={onClick} key={key}>
              {option.name}
            </div>
          );
        })}
      </div>
      {errorMsg && <p className={s.errorMsg}>{errorMsg}</p>}
    </div>
  );
};

MrToggleText.propTypes = {
  className: pt.string,
  testId: pt.string,
  onChange: pt.func.isRequired,
  label: pt.string,
  name: pt.string.isRequired,
  value: pt.string,
  options: pt.shape({
    option1: pt.shape({
      name: pt.string,
      value: pt.string,
    }),
    option2: pt.shape({
      name: pt.string,
      value: pt.string,
    }),
  }).isRequired,
  errorMsg: pt.string,
  validators: pt.arrayOf(pt.func),
};

export default MrToggleText;
