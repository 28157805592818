import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputNumber } from '@ion/components';

import { useFormContext } from 'app/context/form-context';

const { validateRequired, validateInteger } = formValidators;

const TruncateFloat = ({ opId, precision, _path }) => {
  const { validateField, resetError, formState, setField } = useFormContext();

  const precisionName = `${opId}-precision-${_path}`;

  return (
    <MrInputNumber
      label="Precision"
      onBlur={validateField}
      onInput={resetError}
      name={precisionName}
      value={formState[precisionName]?.value ?? precision}
      onChange={setField}
      errorMsg={formState[precisionName]?.errorMsg}
      validators={[validateRequired(), validateInteger()]}
      placeholder="Precision"
      min={0}
      max={Number.MAX_SAFE_INTEGER}
      step={1}
      labelPosition="top"
    />
  );
};

TruncateFloat.propTypes = {
  precision: pt.number,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default TruncateFloat;
