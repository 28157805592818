import React, { useState, useEffect, useCallback } from 'react';
import pt from 'prop-types';
import { debounce as _debounce } from 'lodash';

import { MrTextEditor, usePrevious } from '@ion/components';
import { UpsertIntegrationNotesOne } from '@ion/api';
import isEditedNotes from './is-edited-notes';

import s from './index.module.scss';

const Notes = ({ integration }) => {
  const initial = integration?.integration_notes?.notes;
  const [notes, setNotes] = useState(initial ?? {});
  const previous = usePrevious(notes);
  const [saveNotes] = UpsertIntegrationNotesOne({
    successMsg: 'Note saved',
  });

  const handleChange = (content, delta, source, editor) => {
    const contents = editor.getContents();
    setNotes(contents);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    _debounce(async notes => {
      saveNotes({ integrationId: integration.id, notes });
    }, 2000),
    []
  );

  // TODO (Chris 2023.07.26) - figure out way to fix exhaustive-deps warning without causing additional saves
  useEffect(() => {
    if (notes && isEditedNotes(initial, previous)) {
      debouncedSave(notes);
    }
  }, [notes, debouncedSave]);

  return (
    <div className={s.notes} data-test-id="revisionNotes">
      <MrTextEditor value={notes} placeholder="Enter your revision notes" onChange={handleChange} />
    </div>
  );
};

Notes.propTypes = {
  integration: pt.object.isRequired,
};
export default Notes;
