import pt from 'prop-types';
import React from 'react';
import { ConsentCategoriesContext } from './consent-categories-context';

import { useFlags } from 'launchdarkly-react-client-sdk';
import isFeatureEnabled from '../launch-darkly/is-feature-enabled';

export const CONSENT_CATEGORY_FLAGS = {
  MVP: 'configurableConsentMvp',
  ENABLED_ORGS: 'ecmConfigurableConsent',
};

export const GatedConsentCategoriesContext = ({ children }) => {
  const featureFlags = useFlags();
  const showConsentCategories = isFeatureEnabled({ featureFlags, flagName: CONSENT_CATEGORY_FLAGS.MVP });
  const hasConsentModule = isFeatureEnabled({ featureFlags, flagName: CONSENT_CATEGORY_FLAGS.ENABLED_ORGS });

  if (showConsentCategories && hasConsentModule) {
    return <ConsentCategoriesContext>{children}</ConsentCategoriesContext>;
  }

  return <>{children}</>;
};

GatedConsentCategoriesContext.propTypes = {
  children: pt.node.isRequired,
};
