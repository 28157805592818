import castType from '@ion/app/src/pipelines/cast-type';
import {
  BIG_QUERY_OPTIONS,
  BIG_QUERY_OPTIONS_DISABLE_DATASET_CREATION,
  BIG_QUERY_OPTIONS_UNSPECIFIED,
} from '../consts';
/**
 *
 * @param {{ formFields: object }}
 * @returns {{object}}
 */
export const buildParameterFromFormData = normalizedFormData => {
  const playbookOutput = buildStandardParameters(normalizedFormData);

  switch (normalizedFormData.operation) {
    case 'mappings':
      return handleMapping(normalizedFormData, playbookOutput);
    case 'expressions':
      return handleExpression(normalizedFormData, playbookOutput);
    case 'spreadings':
      return handleSpreading(normalizedFormData, playbookOutput);
    case 'enrichments':
      return handleEnrichment(normalizedFormData, playbookOutput);
    case 'params':
      return handleParam(normalizedFormData, playbookOutput);
    default:
      return playbookOutput;
  }
};

/**
 * Takes form data and normalizes the keys to remove path and OpId references
 * @param {{ formData: object, path: string, opId: string }}
 * @returns {{normalizeData: object}}
 */
export const normalizeFormData = (formData, path, opId) => {
  return Object.keys(formData).reduce((accum, key) => {
    accum[key.replace(`${opId}-`, '').replace(`-${path}`, '')] = formData[key];
    return accum;
  }, {});
};

/**
 * Adds parameters that are standard across all parameters
 * @param {{ data: object }}
 * @returns {{object}}
 */
export const buildStandardParameters = data => {
  const path = data.path ?? data._path;
  const section = path.split('.')[0];

  return {
    event: data.event ?? null,
    attachmentKey: data.operation ?? null,
    opId: data.opId,
    _path: `${section}.${data.operation}`,
    opIndex: parseInt(data.opIndex),
  };
};

/**
 * Takes mapping form data and builds it playbook structure
 * @param {{ mappingData: object }}
 * @returns {{normalizeData: object}}
 */
export const handleMapping = (mappingData, playbookOutput) => {
  playbookOutput.inputKey = mappingData.inputKey;
  playbookOutput.outputKey = mappingData.outputKey;

  if (castType(mappingData.applyDefault)) {
    if (mappingData.defaultType === 'emptyArray' || mappingData.defaultType === 'emptyObject') {
      playbookOutput.defaultJson = mappingData.defaultType;
    } else {
      const typeLookup = {
        boolean: 'defaultBool',
        number: 'defaultFloat',
        integer: 'defaultInt',
        string: 'defaultString',
      };
      playbookOutput[typeLookup[mappingData.defaultType]] = castType(mappingData.defaultValue) ?? '';
      playbookOutput.applyDefault = true;
    }
  } else {
    playbookOutput.applyDefault = false;
  }

  switch (mappingData.subOperation) {
    case 'toScalarString':
      return handleToScalarString(mappingData, playbookOutput);
    case 'toScalarFloat':
      return handleToScalarFloat(mappingData, playbookOutput);
    case 'toScalarTruncatedInt':
      return handleToScalarTruncatedInt(mappingData, playbookOutput);
    case 'toScalarRoundedInt':
      return handleToScalarRoundedInt(mappingData, playbookOutput);
    case 'toScalarBool':
      return handleToScalarBool(mappingData, playbookOutput);
    case 'toHash':
      return handleToHash(mappingData, playbookOutput);
    case 'truncateFloat':
      return handleTruncateFloat(mappingData, playbookOutput);
    case 'modifyStringTrim':
      return handleModifyStringTrim(mappingData, playbookOutput);
    case 'modifyStringUppercase':
      return handleModifyStringUppercase(mappingData, playbookOutput);
    case 'modifyStringLowercase':
      return handleModifyStringLowercase(mappingData, playbookOutput);
    case 'substring':
      return handleSubstring(mappingData, playbookOutput);
    case 'splitString':
      return handleSplitString(mappingData, playbookOutput);
    case 'replaceString':
      return handleReplaceString(mappingData, playbookOutput);
    case 'pluckValues':
      return handlePluckValues(mappingData, playbookOutput);
    case 'joinValues':
      return handleJoinValues(mappingData, playbookOutput);
    default:
      return {
        ...playbookOutput,
      };
  }
};

const handleJoinValues = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        joinValues: {
          separator: mappingData.joinSeparator,
        },
      },
    ],
  };
};

const handlePluckValues = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        pluckValues: {
          extractKey: mappingData.extractKey,
        },
      },
    ],
  };
};

const handleReplaceString = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        replaceString: {
          replace: mappingData.replace,
          with: mappingData.replaceWith,
        },
      },
    ],
  };
};

const handleSplitString = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        splitString: {
          separator: mappingData.splitSeparator,
          maxElements: castType(mappingData.maxElements),
        },
      },
    ],
  };
};

const handleSubstring = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        substring: {
          start: castType(mappingData.start),
          end: castType(mappingData.end),
        },
      },
    ],
  };
};

const handleModifyStringLowercase = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        modifyString: 'lowercase',
      },
    ],
  };
};

const handleModifyStringUppercase = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        modifyString: 'uppercase',
      },
    ],
  };
};

const handleModifyStringTrim = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        modifyString: 'trim',
      },
    ],
  };
};

const handleTruncateFloat = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        truncateFloat: {
          precision: castType(mappingData.precision),
        },
      },
    ],
  };
};

const handleToHash = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        toHash: mappingData.toHash,
      },
    ],
  };
};

const handleToScalarBool = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        toScalar: 'bool',
      },
    ],
  };
};

const handleToScalarRoundedInt = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        toScalar: 'rounded_int',
      },
    ],
  };
};

const handleToScalarTruncatedInt = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        toScalar: 'truncated_int',
      },
    ],
  };
};

const handleToScalarFloat = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        toScalar: 'float',
      },
    ],
  };
};

const handleToScalarString = (mappingData, playbookOutput) => {
  return {
    ...playbookOutput,
    transforms: [
      {
        toScalar: 'string',
      },
    ],
  };
};

/**
 * Takes expression form data and builds it playbook structure
 * @param {{ expressionData: object }}
 * @returns {{normalizeData: object}}
 */
export const handleExpression = (expressionData, playbookOutput) => {
  switch (expressionData.subOperation) {
    case 'singleKey':
      return handleSingleKeyExpression(expressionData, playbookOutput);
    case 'multiKey':
      return handleMultiKeyExpression(expressionData, playbookOutput);
  }
};

/**
 * Takes expression form data and builds it playbook structure
 * @param {{ data: object }}
 * @returns {{playbookData: object}}
 */
export const handleSingleKeyExpression = (data, parameter) => {
  const section = parameter._path.split('.')[0];
  parameter.transforms = [
    {
      expression: {
        body: data.body,
        lang: data.lang,
      },
    },
  ];
  parameter.inputKey = data.inputKey ?? 'input';
  parameter.outputKey = data.outputKey ?? '';
  parameter.attachmentKey = 'mappings';
  parameter._path = `${section}.mappings`;
  return parameter;
};

/**
 * Takes expression form data and builds it playbook structure
 * @param {{ data: object }}
 * @returns {{playbookData: object}}
 */
export const handleMultiKeyExpression = (data, parameter) => {
  parameter.body = data.body;
  parameter.lang = data.lang;
  parameter.expressionName = data.expressionName ?? '';
  parameter.attachmentKey = data.operation;
  return parameter;
};

/**
 * Takes enrichment form data and builds it playbook structure
 * @param {{ enrichmentData: object }}
 * @returns {{normalizeData: object}}
 */
export const handleEnrichment = (enrichmentData, playbookOutput) => {
  playbookOutput.outputKey = enrichmentData.outputKey;

  //handle specific subOperation enrichment fields
  switch (enrichmentData.subOperation) {
    case 'uuid':
      return handleUuidEnrichment(enrichmentData, playbookOutput);
    case 'timestamp':
      return handleTimestampEnrichment(enrichmentData, playbookOutput);
    case 'date':
      return handleDateEnrichment(enrichmentData, playbookOutput);
    case 'directValue':
      return handleDirectValueEnrichment(enrichmentData, playbookOutput);
    default:
      return playbookOutput;
  }
};

const handleUuidEnrichment = (enrichmentData, playbookOutput) => {
  return {
    ...playbookOutput,
    uuid: enrichmentData.uuid ?? null,
  };
};

const handleTimestampEnrichment = (enrichmentData, playbookOutput) => {
  return {
    ...playbookOutput,
    timestamp: enrichmentData.timestamp ?? null,
  };
};

const handleDateEnrichment = (enrichmentData, playbookOutput) => {
  return {
    ...playbookOutput,
    date: {
      format: enrichmentData.date ?? null,
    },
  };
};

const handleDirectValueEnrichment = (enrichmentData, playbookOutput) => {
  const directValueOptions = {
    boolean: 'staticBool',
    number: 'staticFloat',
    integer: 'staticInt',
    string: 'staticString',
  };

  const { directType, directValue } = enrichmentData;
  const castedValue = 'string' === directType ? directValue : castType(directValue);

  return {
    ...playbookOutput,
    [directValueOptions[enrichmentData.directType]]: castedValue,
  };
};

/**
 * Takes spreading form data and builds it playbook structure
 * @param {{ spreadingData: object }}
 * @returns {{normalizeData: object}}
 */
export const handleSpreading = (spreadingData, playbookOutput) => {
  return {
    ...playbookOutput,
    attachmentKey: spreadingData.operation,
    expressionName: spreadingData.expressionName ?? '',
    inputKey: spreadingData.inputKey ?? '',
    flatten: {
      separator: spreadingData.separator,
      prefix: spreadingData.prefix,
      depth: parseInt(spreadingData.depth),
    },
  };
};

/**
 * Takes var form data and builds it playbook structure
 * @param {{ varData: object }}
 * @returns {{normalizeData: object}}
 */
export const handleParam = (paramData, playbookOutput) => {
  const name = paramData.name;
  playbookOutput.name = name;
  playbookOutput.isOptional = castType(paramData.isOptional) ?? false;

  switch (name) {
    case 'AUTHENTICATION (SASL)':
      return handleSasl(paramData, playbookOutput);
    case 'STAGE':
      return handleStage(paramData, playbookOutput);
    case 'S_3':
      return handleS3(paramData, playbookOutput);
    case 'GCS':
      return handleGcs(paramData, playbookOutput);
    case 'BROKERS':
      return handleBrokers(paramData, playbookOutput);
    case BIG_QUERY_OPTIONS:
      return handleBigQueryOptions(paramData, playbookOutput);
    default:
      return {
        ...playbookOutput,
        ...(paramData.exampleValue && { exampleValue: paramData.exampleValue ?? '' }),
        ...(paramData.defaultValue && { defaultValue: paramData.defaultValue ?? '' }),
        ...(paramData.pipelineVarId && { pipelineVarId: paramData.pipelineVarId }),
        ...(paramData.type && { type: paramData.type }),
      };
  }
};
const handleBrokers = (paramData, playbookOutput) => {
  return {
    ...playbookOutput,
    defaultValue: paramData.defaultValue?.split(','),
  };
};

const handleBigQueryOptions = (paramData, playbookOutput) => {
  return {
    ...playbookOutput,
    defaultValue:
      paramData.defaultValue === BIG_QUERY_OPTIONS_DISABLE_DATASET_CREATION
        ? [BIG_QUERY_OPTIONS_DISABLE_DATASET_CREATION]
        : [BIG_QUERY_OPTIONS_UNSPECIFIED],
  };
};

const handleSasl = (paramData, playbookOutput) => {
  return {
    ...playbookOutput,
    defaultValue: {
      saslType: paramData.saslType,
      username: paramData.username,
      password: paramData.password,
      ...(paramData.algorithm && { algorithm: castType(paramData.algorithm) }),
    },
  };
};

const handleStage = (paramData, playbookOutput) => {
  const stageType = paramData.stageType;
  const oneOfDefaultValue =
    stageType === 'GCS' ? handleGcs(paramData, playbookOutput) : handleS3(paramData, playbookOutput);

  return {
    ...playbookOutput,
    defaultValue: {
      stageType,
      ...oneOfDefaultValue.defaultValue,
    },
  };
};

const handleS3 = (paramData, playbookOutput) => {
  return {
    ...playbookOutput,
    defaultValue: {
      region: paramData.region,
      bucket: paramData.bucket,
      prefix: paramData.prefix,
      accessKey: paramData.accessKey,
      secretKey: paramData.secretKey,
      serverSideEncryption: paramData.serverSideEncryption,
      compression: castType(paramData.compression),
    },
  };
};

const handleGcs = (paramData, playbookOutput) => {
  return {
    ...playbookOutput,
    defaultValue: {
      bucket: paramData.bucket,
      prefix: paramData.prefix,
      credentialJson: paramData.credentialJson,
      compression: castType(paramData.compression),
    },
  };
};
