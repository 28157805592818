import React from 'react';
import pt from 'prop-types';

import { useAllower } from '@ion/api';

import DropDown from './dropdown';
import UploadModal from './upload/modal';
import useUploadState from './upload/state';

const downloadIntegration = `downloadIntegration`;
const uploadEndpointSchemas = `uploadEndpointSchemas`;
const uploadPlaybooks = `uploadPlaybooks`;

const DownloadUploadPlaybook = ({
  integration, // used for revision selecting
  playbookState,
  // playbook, // Playbook needs to exist at this point or were fucked, starter kit can be a playbook
  // connectionJson
  insertPlaybook,
}) => {
  const hasDownloadPermission = useAllower(downloadIntegration);
  const hasUploadEndpointSchemasPermission = useAllower(uploadEndpointSchemas);
  const hasUploadPlaybooksPermission = useAllower(uploadPlaybooks);

  const uploadState = useUploadState({
    isUploadAllowed: hasUploadEndpointSchemasPermission || hasUploadPlaybooksPermission,
    isDropDownViewable: hasDownloadPermission || hasUploadEndpointSchemasPermission || hasUploadPlaybooksPermission,
  });

  return (
    <>
      {uploadState.isDropDownViewable && (
        <DropDown playbookState={playbookState} integration={integration} uploadState={uploadState} />
      )}
      {uploadState.isModalOpen && (
        <UploadModal
          insertPlaybook={insertPlaybook}
          playbookState={playbookState}
          integration={integration}
          uploadState={uploadState}
        />
      )}
    </>
  );
};

DownloadUploadPlaybook.propTypes = {
  integration: pt.object.isRequired,
  playbookState: pt.object.isRequired,
  insertPlaybook: pt.func,
};

export default DownloadUploadPlaybook;
