import React from 'react';
import pt from 'prop-types';

import { formValidators, MrInputText } from '@ion/components';

import { useFormContext } from 'app/context/form-context';

const { validateNotEqual } = formValidators;

const ReplaceString = ({ replace, replaceWith, opId, _path }) => {
  const { validateField, resetError, formState, setField } = useFormContext();
  return (
    <>
      {((name = `${opId}-replace-${_path}`) => (
        <MrInputText
          label="Replace"
          onBlur={validateField}
          name={name}
          value={formState[name]?.value ?? replace}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          placeholder="string to be replaced"
          labelPosition="top"
        />
      ))()}

      {((name = `${opId}-replaceWith-${_path}`) => (
        <MrInputText
          label="With"
          onBlur={validateField}
          onInput={resetError}
          name={name}
          value={formState[name]?.value ?? replaceWith}
          onChange={setField}
          errorMsg={formState[name]?.errorMsg}
          validators={[validateNotEqual(replace, 'Values for Replace and With must be different')]}
          placeholder="replace with"
          labelPosition="top"
        />
      ))()}
    </>
  );
};

ReplaceString.propTypes = {
  replace: pt.string,
  replaceWith: pt.string,
  opId: pt.string.isRequired,
  _path: pt.string.isRequired,
};

export default ReplaceString;
