import React from 'react';
import propTypes from 'prop-types';
import { Typography } from '@metarouter-private/mr-mui';

export default function TextCell({ text }) {
  return <Typography>{text}</Typography>;
}

TextCell.propTypes = {
  text: propTypes.string.isRequired,
};
