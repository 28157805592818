import { useEffect } from 'react';
import pt from 'prop-types';

import preProcessPlaybook from './pre-process-playbook';

const GetPlaybooks = ({ integration, kit, navState, setRevision, opIndices }) => {
  const selectedRevision = navState.selectedRevision;
  let playbook;

  if (integration.playbooks.length === 0) {
    playbook = kit;
  }

  if (!selectedRevision && integration.playbooks.length !== 0) {
    playbook = integration.playbooks[0];
  }

  if (selectedRevision) {
    playbook = integration.playbooks.find(p => p.id === selectedRevision);
  }

  const {
    playbook: processedPlaybook,
    connection: processedConnection,
    opIndices: processedOpIndices,
  } = preProcessPlaybook(
    playbook.playbookJson,
    playbook.connectionJson,
    playbook.vars,
    playbook?.playbookJson?.metadata?.opIndices ?? opIndices
  );

  // TODO (Chris 2023.07.26) - refactor useEffect hook logic
  useEffect(() => {
    setRevision(processedPlaybook, processedConnection, playbook.id, processedOpIndices);
  }, [selectedRevision]);

  return null;
};

GetPlaybooks.propTypes = {
  integration: pt.object.isRequired,
  kit: pt.object.isRequired,
  navState: pt.shape({
    selectedRevision: pt.string,
    selectedSection: pt.string,
  }).isRequired,
  setRevision: pt.func.isRequired,
  location: pt.object,
  opIndices: pt.object.isRequired,
};

export default GetPlaybooks;
