import { useQuery } from './../index';
import { gql } from '@apollo/client';

// TODO: not using the pipeline fragment because it fetches the entire graph

/**
Fetches all pipelines with latest pipeline_revision and associated integrations for inserting a new pipelineRevision
 */
export const query = gql`
  query LatestPipelines {
    pipelines(order_by: { createdAt: desc }) {
      id
      name
      writekey
    }
  }
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, isAllowed, error } = useQuery(query, {
    variables,
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: data?.pipelines || [],
    loading,
    isAllowed,
    error,
  };
};
