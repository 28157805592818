import { useQuery } from '../../index';
import { query } from './auth0-roles-query-builder';
import permission from '../../Apollo/permissions';

export default function useQueryRoles({ onCompleted, ...options } = {}) {
  const { data, loading, error, isAllowed } = useQuery(query, {
    context: { permission: permission.readUsersRoles },
    onCompleted: response => {
      if (onCompleted) {
        onCompleted(getResponseBody({ response }));
      }
    },
    ...options,
  });

  return {
    data: data || { auth0_roles: [] },
    loading,
    isAllowed,
    error,
  };
}

function getResponseBody(response) {
  return response.data.auth0_roles;
}
