import React from 'react';
import pt from 'prop-types';
import cx from 'classnames';

import { Helmet } from 'react-helmet';
import s from './loading.module.scss';

const MrLoading = ({ className }) => {
  return (
    <div className={cx(s.loading, className)}>
      <div />
      <Helmet>
        <title>Loading...</title>
      </Helmet>
    </div>
  );
};

MrLoading.propTypes = {
  children: pt.node,
  className: pt.string,
};

export default MrLoading;
