import auditLogMutation from '../insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';
import { validate } from 'parameter-validator';

export const mutation = auditLogMutation;

export function buildVariables(pipelineIntegration) {
  const { id: pipelineIntegrationId, integration, pipeline } = pipelineIntegration;

  const { name: integrationName } = validate(integration, ['name']);
  const { name: pipelineName } = validate(pipeline, ['name']);

  return {
    audit_logs_insert_input: buildAuditLog({
      pipelineIntegrationId,
      pipelineName,
      integrationName,
    }),
  };
}
