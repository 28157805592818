import React from 'react';
import pt from 'prop-types';
import camelCase from 'lodash/camelCase';

import styles from './svg.module.scss';

// this component uses the provided ID to display the corresponding SVG found in svg-defs.js
export default function MrSvg({ id, title, ...props }) {
  return (
    <svg className={styles[camelCase(id)]} {...props}>
      {title && <title>{title}</title>}
      <use xlinkHref={`#${id}`} {...props} />
    </svg>
  );
}

MrSvg.propTypes = {
  id: pt.string.isRequired,
  title: pt.string,
};
